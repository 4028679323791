import { useDaysOffQuery, useTeacherMutation } from "old/src/hooks/useTeacher";
import { useMemo, useState } from "react";
import { addDays, addSeconds } from "src/utils/dates";
import css from "./index.module.css";
import { DDSButton } from "@web-monorepo/dds";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { logTutorEvent, useLogTutorEventOnFirstRender } from "src/utils/log";
import DayOffRow, { DayOffByDay, DayOffInfo } from "./DayOffRow";
import { useMediaQuery } from "react-responsive";
import { theme } from "@classdojo/web/nessie";
import { useDayOffFormStateContext } from "old/src/pageComponents/Teachers/Availability/useOOOForm";
import { IconCheckCircleFilled, IconX } from "@web-monorepo/dds-icons";
import { UnstyledButton } from "@classdojo/web";
import {
  formatFullDate,
  formatHour,
  formatDayOfMonth,
  startOfMonth,
  formatMonthYear,
  formatDateTzAgnostic,
} from "src/pageComponents/TutorDayOff/utils";

const formatDayOffInfo = (dayOff: { ts: string; duration: number; id: number }): DayOffInfo => {
  const startTime = new Date(dayOff.ts);
  const endTime = addSeconds(startTime, dayOff.duration);

  const secondsInDay = 24 * 60 * 60;
  let dayOffType: "partial" | "full" | "multiple";

  if (formatHour(startTime) === "12:00 AM" && dayOff.duration === secondsInDay) {
    dayOffType = "full";
  } else if (formatFullDate(startTime) === formatFullDate(endTime)) {
    dayOffType = "partial";
  } else {
    dayOffType = "multiple";
  }

  return {
    dayOffType,
    formattedDate:
      dayOffType === "multiple"
        ? `${formatDateTzAgnostic(startTime)} ➞ ${formatDateTzAgnostic(addDays(endTime, -1))}`
        : formatFullDate(startTime),
    formattedHours: `${formatHour(startTime)} - ${formatHour(endTime)}`,
    dayOffId: dayOff.id,
    dayOfMonth: formatDayOfMonth(startTime),
    startOfMonth: startOfMonth(startTime),
    startTime,
  };
};

export default function () {
  const { goToNextStep, successBannerVisible, hideSuccessBanner } = useDayOffFormStateContext();
  useLogTutorEventOnFirstRender("marketplace.tutor.day_off_list_ooo_viewed");

  const { daysOff, refetch: refetchDaysOff } = useDaysOffQuery();
  const { deleteDaysOff } = useTeacherMutation();
  const [dayOffIdToBeDeleted, setDayOffIdToBeDeleted] = useState<number>();
  const isMobile = useMediaQuery({ query: `(max-width: ${theme.breakpoints.s})` });

  const daysOffByMonth = useMemo(() => {
    if (!daysOff) {
      return [];
    }
    const dayOffsInfo: DayOffInfo[] = daysOff.map(formatDayOffInfo);
    const daysOffByMonth = groupBy(dayOffsInfo, (dayOff) => dayOff.startOfMonth.getTime());
    const sortedMonths = orderBy(Object.keys(daysOffByMonth), (month) => parseInt(month, 10));

    return sortedMonths.map((month) => {
      const daysOff: DayOffByDay[] = [];
      const partialDaysOffByDayOfMonth: Record<number, DayOffInfo[]> = {};

      for (const dayOff of daysOffByMonth[month]) {
        if (dayOff.dayOffType === "partial") {
          partialDaysOffByDayOfMonth[dayOff.dayOfMonth] = partialDaysOffByDayOfMonth[dayOff.dayOfMonth] || [];
          partialDaysOffByDayOfMonth[dayOff.dayOfMonth].push(dayOff);
        } else {
          daysOff.push({
            type: dayOff.dayOffType,
            dayOff,
            dayOfMonth: dayOff.dayOfMonth,
            formattedDate: dayOff.formattedDate,
          });
        }
      }
      for (const [dayOfMonth, partialDayOffs] of Object.entries(partialDaysOffByDayOfMonth)) {
        daysOff.push({
          type: "partial",
          daysOff: partialDayOffs,
          dayOfMonth: parseInt(dayOfMonth, 10),
          formattedDate: partialDayOffs[0].formattedDate,
        });
      }
      return {
        month: formatMonthYear(new Date(parseInt(month, 10))),
        daysOffByDay: orderBy(daysOff, (dayOff) => dayOff.dayOfMonth),
      };
    });
  }, [daysOff]);

  const deleteDayOff = (dayOffId: number) => {
    deleteDaysOff.mutate(
      { id: dayOffId },
      {
        onSuccess: () => {
          refetchDaysOff();
        },
        onError: () => {
          logTutorEvent({ eventName: "tutor.marketplace.errorOnDeleteOOO", metadata: { dayOffId } });
        },
      },
    );
  };

  return (
    <div
      className={css.generalWrapper}
      onScroll={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={css.scrollWrapper}>
        <div className={css.dayOffListWrapper}>
          {daysOffByMonth.map(({ month, daysOffByDay }, idxMonth) => {
            return (
              <div key={idxMonth} className={css.monthSection}>
                <div className={css.monthName}>{month}</div>
                <div>
                  {daysOffByDay.map((dayOffByDay, idxDay) => (
                    <DayOffRow
                      key={idxDay}
                      dayOffByDay={dayOffByDay}
                      dayOffIdToBeDeleted={dayOffIdToBeDeleted}
                      deleteDayOff={deleteDayOff}
                      onDeleteDayOffCanceled={() => setDayOffIdToBeDeleted(undefined)}
                      onDayOffSelectedToBeDeleted={(dayOffId) => setDayOffIdToBeDeleted(dayOffId)}
                      isMobile={isMobile}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {successBannerVisible && (
        <div className={css.successBannerWrapper}>
          <div className={css.successBannerText}>
            <IconCheckCircleFilled size="m" />
            Your OOO was added
          </div>
          <UnstyledButton data-name="close-ooo-success-banner" onClick={hideSuccessBanner}>
            <IconX size="m" />
          </UnstyledButton>
        </div>
      )}
      <div className={css.addDayOffButtonWrapper}>
        <DDSButton onClick={goToNextStep} data-name="add-day-off" variant="primary" width="fill">
          Add OOO
        </DDSButton>
      </div>
    </div>
  );
}
