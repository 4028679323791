import { HeadlineText } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useQueryParams from "src/hooks/useQueryParams";

const AITransparencyNote = () => {
  const learnersLink =
    "https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms%23h_01GM9RVTNXWXZ13AX7EY4G5GW5#h_01GM9RVTNXWXZ13AX7EY4G5GW5";
  const serviceProvidersLink = "https://tutor.classdojo.com/third-party-service-providers/";
  const principlesAIEdLink = "https://edtechprinciples.com/";
  const parentalConsentLink =
    "https://classdojo.zendesk.com/hc/articles/12016320121101-What-consent-must-parents-give-for-their-child-to-use-Dojo-Tutorin";
  const personalInformationLink =
    "https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms%23h_2843be58-eb16-4535-8579-138369598e4d#h_2843be58-eb16-4535-8579-138369598e4d";
  const activeAccountLink =
    "https://classdojo.zendesk.com/hc/articles/12016429302157-What-happens-when-a-learner-account-becomes-inactive&amp;sa=D&amp;source=editors&amp;ust=1712088667698360&amp;usg=AOvVaw1_AtYQ8eZSsRyCh7Q8U7gK";
  const deIdentifiedLink =
    "https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a";

  const query = useQueryParams();

  // useWatch too early in render cycle, element may not exist yet
  // cannot use simple # because of hash based routing, urls have two #

  useEffect(() => {
    if (query.ref) {
      const destination = document.getElementById(query.ref);
      if (destination) {
        destination.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [query]);

  return (
    <>
      <HeadlineText as="h1" level={1} sx={sxTextCenter}>
        Dojo Tutor
      </HeadlineText>
      <HeadlineText as="h1" level={1} sx={sxTextCenter}>
        AI Transparency Note
      </HeadlineText>
      <br />
      <p sx={sxItalic}>How data is used in Dojo Tutor&rsquo;s AI features</p>
      <br />
      <HeadlineText as="h2" level={1}>
        Introduction
      </HeadlineText>
      <br />
      <p>
        Dojo Tutor is committed to continually enhancing our platform to provide the best possible learning experience
        for our{" "}
        <a target="_blank" href={learnersLink}>
          learners
        </a>
        , tutors, and families. In pursuit of this goal, we are thrilled to introduce artificial intelligence
        &#40;&ldquo;AI&rdquo;&#41; technology into our tutoring services. Dojo Tutor has partnered with trusted{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service providers
        </a>{" "}
        AssemblyAI and Anthropic to launch new AI features aimed at improving the quality and efficiency of our Dojo
        Tutor service, and meeting the needs of our{" "}
        <a target="_blank" href={learnersLink}>
          learners
        </a>
        , tutors, and families.
      </p>
      <br />
      <p>
        Our innovative features will leverage text-only transcripts of tutoring session recordings, combined with
        carefully crafted instructions or &ldquo;prompts,&rdquo; to generate such &ldquo;output&rdquo; as faster and
        higher quality feedback on tutoring sessions, holistic summaries of each tutoring session, tailored lesson plans
        and coaching plans, personalized homework assignments, and improved assessments of learners&rsquo; tutoring
        needs. With the help of these AI enhancements to the Dojo Tutor experience{" "}
        <a target="_blank" href={learnersLink}>
          learners
        </a>{" "}
        and tutors will be able to enjoy faster, more personalized and holistic tutoring assessments, lesson summaries,
        and learning plans. In launching this new AI technology in our service, we hope to empower our learners and
        tutors by helping them increase their engagement, strengthen their connection, and deepen their learning.
      </p>
      <br />
      <HeadlineText as="h2" level={3}>
        Our Commitment
      </HeadlineText>
      <br />
      <p>
        We understand the importance of ensuring strong privacy and security protections for our{" "}
        <a target="_blank" href={learnersLink}>
          learners
        </a>
        , tutors, and families as we integrate new, rapidly changing AI technology, and we want to assure you that we
        will handle your data with the utmost care and responsibility.
      </p>
      <br />
      <p>
        As part of our ongoing commitment to transparency and responsibility in handling your data, we are providing the
        following information on our new Dojo Tutor AI features and the choices you have with regard to them. In
        addition, Dojo Tutor supports the Education Technology Industry&rsquo;s{" "}
        <a target="_blank" href={principlesAIEdLink}>
          Principles for AI in Education
        </a>{" "}
        to ensure safe and responsible use of data in AI technology. ClassDojo was deeply involved with the development
        of the Principles as a member of the AI Education Steering Committee. By aligning with these principles, we aim
        to harness the power of AI technology to enrich the learning experience for all our users while upholding the
        highest standards of privacy, security, and ethical conduct. Read more about the Principles for AI in Education{" "}
        <a target="_blank" href={principlesAIEdLink}>
          here
        </a>{" "}
        that are intended to help guide the industry&rsquo;s development of responsible AI that meets the needs of
        students, educators and families.
      </p>
      <br />
      <p>This article covers:</p>
      <ul>
        <li>
          <Link to="#how-dojo-tutor-use-ai-features">How Dojo Tutor Uses AI Features</Link>
        </li>
        <li>
          <Link to="#what-information-is-used">What Information is Used to Provide the AI features</Link>
        </li>
        <li>
          <Link to="#how-dojo-tutor-uses-data">How Dojo Tutor Uses Data to Provide the AI features</Link>
        </li>
        <li>
          <Link to="#how-we-work-with-trusted-services">
            How We Work with Trusted Service Providers to Offer AI-enhanced Tutoring Services
          </Link>
        </li>
        <li>
          <Link to="#how-parents-can-opt-out">How Can Parents Opt Out After Providing Consent?</Link>
        </li>
        <li>
          <Link to="#limitations-of-dojo-tutor-ai">Limitations of Dojo Tutor AI Features</Link>
        </li>
      </ul>
      <HeadlineText as="h2" level={1} id="how-dojo-tutor-use-ai-features">
        How Dojo Tutor Uses AI Features
      </HeadlineText>
      <br />
      <HeadlineText as="h3" level={3}>
        Tutoring Session Transcripts
      </HeadlineText>
      <br />
      <p>
        With{" "}
        <a target="_blank" href={parentalConsentLink}>
          parental consent
        </a>
        <sup>
          <Link to="#footnote" sx={sxLinkWithoutUnderline}>
            *
          </Link>
        </sup>
        , Dojo Tutor records the tutoring sessions that take place over Zoom between learners and tutors. These
        recordings are automatically deleted after 14 days by Zoom &#40;unless we are required by law to retain it, need
        it to ensure the security of our community or our Service, or to enforce our Terms&#41; Within this 14-day
        retention period, Dojo Tutor has these video recordings transcribed through the use of its{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service provider
        </a>
        , Assembly AI &#40;&ldquo;Tutoring Session Transcripts&rdquo;&#41;. Additionally, within the 14-day period in
        which Zoom retains these recordings, Dojo Tutor may download from Zoom and store them on ClassDojo&rsquo;s
        servers for up to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
        our platform safe and secure.
      </p>
      <br />
      <p>
        Dojo Tutor shares only the audio portion of the Zoom recordings of tutoring sessions between the learner and
        tutor with the{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service provider
        </a>{" "}
        and ensures that the audio recordings are retained only transiently and deleted immediately upon creating the
        Tutoring Session Transcripts. Additionally, Dojo Tutor ensures that the audio recordings can not be used by the{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service provider
        </a>{" "}
        for any other purpose. Dojo Tutor also ensures that the{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service provider
        </a>{" "}
        can&rsquo;t retain the Tutoring Session Transcripts for more than thirty &#40;30&#41; days, and can be deleted
        earlier upon request by Dojo Tutor.
      </p>
      <br />
      <HeadlineText as="h3" level={3}>
        AI Insights on Tutoring Session Transcripts
      </HeadlineText>
      <br />
      <p>
        ClassDojo obtains{" "}
        <a target="_blank" href={parentalConsentLink}>
          parental consent
        </a>
        <sup>
          <Link to="#footnote" sx={sxLinkWithoutUnderline}>
            *
          </Link>
        </sup>{" "}
        prior to allowing any AI processing on children&rsquo;s{" "}
        <a target="_blank" href={personalInformationLink}>
          personal information
        </a>{" "}
        &#40;or the disclosure of any children&rsquo;s personal information to any{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service provider
        </a>{" "}
        to perform this processing&#41;. Assembly AI &#40;at the direction and instruction of Dojo Tutor&#41; utilizes
        an integrated Large Language Model &#40;&ldquo; LLM &rdquo;&#41; subprocessor,{" "}
        <a target="_blank" href={serviceProvidersLink}>
          Anthropic
        </a>{" "}
        to perform certain AI related functions upon these Tutoring Session Transcripts in order to obtain AI-generated
        insights on tutoring sessions between our learners and tutors. The various AI related functions performed on the
        Tutoring Session Transcripts are done through the use of Dojo Tutor-directed instructions or
        &ldquo;prompts&rdquo; to Anthropic &#40; <i>e.g</i>., &ldquo;summarize the tutoring session&rdquo;&#41;. These
        text-only Tutoring Session Transcripts are sent to Anthropic with a de-identified transaction ID linked to the
        tutoring session recording so as not to identify the learner &#40;however, the learner&rsquo;s name may be
        included within the Tutoring Session Transcript itself if identified in the tutoring session&#41;.
        Anthropic&rsquo;s LLM then processes the text-only Tutoring Session Transcripts &#40;&ldquo;inputs&rdquo;&#41;
        and prompts and generates a result &#40;&ldquo;output&rdquo;&#41; based on those inputs and prompts. Anthropic
        returns the result or &ldquo;output&rdquo; to AssemblyAI who in turn sends the &ldquo;output&rdquo; to Dojo
        Tutor. Input and output data processed by Anthropic is retained only transiently to provide the service and for
        trust and safety purposes; then deleted immediately upon creation of the outputs.
      </p>
      <br />
      <HeadlineText as="h2" level={1} id="what-information-is-used">
        What Information is Used to Provide the AI features
      </HeadlineText>
      <br />
      <p>
        The following chart details the information used to provide the various AI features as well as the processes or
        &ldquo;prompts&rdquo; performed on the &ldquo;inputs&rdquo; to obtain the results or &ldquo;output&rdquo;. We
        will update this chart as we update our features and seek additional{" "}
        <a target="_blank" href={parentalConsentLink}>
          parental consent
        </a>
        <sup>
          <Link to="#footnote" sx={sxLinkWithoutUnderline}>
            *
          </Link>
        </sup>{" "}
        for any{" "}
        <a target="_blank" href={learnersLink}>
          learner
        </a>{" "}
        <a target="_blank" href={personalInformationLink}>
          personal information
        </a>{" "}
        that is used.
      </p>
      <br />
      <br />
      <div sx={sxTableWrapper} id="what-information-is-used-table">
        <table sx={sxTable}>
          <thead>
            <tr>
              <td width="15%" sx={sxTableHeader}>
                Dojo AI Feature and Processing Done on Inputs
              </td>
              <td width="15%" sx={sxTableHeader}>
                User Information Used to Provide the AI Feature &#40;Inputs&#41;
              </td>
              <td width="15%" sx={sxTableHeader}>
                User Types Input is Collected from and Output Disclosed to
              </td>
              <td width="15%" sx={sxTableHeader}>
                How this Information is provided to the LLM
              </td>
              <td width="40%" sx={sxTableHeader}>
                Retention Period / Storage / Security
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="15%">
                <b>Trial Tutoring Session feedback</b>
                <br />
                <br />
                Create automated written assessments from the trial tutoring session that the tutor can further edit
                &#40;to allow for human input&#41;.
              </td>
              <td width="15%">
                &ndash; <b>Single Text-only Tutoring Session Transcript</b>
                <br />
                Created from Zoom Audio only recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction ID</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />
                - Tutor
                <br />
                - Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor, Learner, Parent &#40;and others parent chooses to share with&#41;
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless we
                    are required by law to retain it, need it to ensure the security of our community or our Service, or
                    to enforce our Terms&#41;.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <br />
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <br />
                <ul>
                  <li>
                    <u>AssemblyAI:</u> Up to 30 days to provide the service.
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>{" "}
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Up to 30 days to provide the service.
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <br />
                <u>Storage</u>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U.S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
            <tr>
              <td width="15%">
                <b>Tutoring Session summaries</b>
                <br />
                <br />
                Create short written summaries from transcripts of what was covered in the tutoring session. Summaries
                are editable &#40;to allow for human input&#41;.
              </td>
              <td width="15%">
                &ndash; <b>Single Text-only Tutoring Session Transcript</b>
                <br />
                Created from Zoom Audio only recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction ID</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />- Tutor <br />- Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor, Learner, Parent &#40;and others parent chooses to share with&#41;
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless
                    noted above&#41;.
                  </li>
                  <li>
                    <u>Dojo Tutor:</u> Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <br />
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <ul>
                  <li>AssemblyAI: Up to 30 days to provide the service.</li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs.
                  </li>
                  <li>
                    Dojo Tutor: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <br />
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u> Up to 30 days to provide the service.
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <u>Storage</u>
                <ul>
                  <li>
                    {" "}
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U .S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
            <tr>
              <td width="15%">
                <b>Tutoring Progress reports</b>
                <br />
                <br />
                Generate automated, progress reports on how the learner is progressing based on recent tutoring
                sessions. Reports are editable by the tutor &#40; to allow for human input&#41; .
              </td>
              <td width="15%">
                &ndash; <b>Recent Text-only Tutoring Session transcripts &#40;subscriber&#41;</b>
                <br />
                Created from Zoom Audio only recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction IDs</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />- Tutor <br />- Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor, Learner, Parent &#40;and others parent chooses to share with&#41;
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless
                    noted above&#41;
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <u>Storage</u>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U .S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                    <br />
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
            <tr>
              <td width="15%">
                <b>Post-Tutoring Session Feedback and Targeted Coaching</b>
                <br />
                <br />
                Create automated write-up of feedback and tips from each tutoring session, as well as tailored coaching
                recommendations based on recent tutoring session material and the learner&rsquo;s unique needs.
                Write-ups and recommendations are editable by the tutor &#40;to allow for human input&#41;.
              </td>
              <td width="15%">
                &ndash; <b>Single Text-only Tutoring Session Transcript &#40;trial or subscriber&#41;</b>
                <br />
                Created from Zoom Audio only recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction ID</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />- Tutor <br />- Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor, Learner, Parent &#40;and others parent chooses to share with&#41;
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless
                    noted above&#41;
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <u>Storage</u>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U .S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                    <br />
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
            <tr>
              <td width="15%">
                <b>Recommended Homework Assignments and Home Activities for Learners and Parents</b>
                <br />
                <br />
                Generate recommendations for activities parents and learners can do at home to support their tutoring
                efforts, as well as homework assignments that reflect the material covered in the tutoring session.
                Recommendations and recommended assignments are editable by the tutor &#40;to allow for human
                input&#41;.
              </td>
              <td width="15%">
                &ndash; <b>Single Text-only Tutoring Session Transcript &#40;trial or subscriber&#41;</b> <br />
                Created from Zoom Audio only recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction ID</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />- Tutor <br />- Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor, Learner, Parent &#40;and others parent chooses to share with&#41;
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless
                    noted above&#41;.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <u>Storage</u>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U .S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                    <br />
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
            <tr>
              <td width="15%">
                <b>Lesson Plans for Tutoring Sessions</b>
                <br />
                <br />
                Recommend tailored, lesson plans that account for the learner&rsquo;s needs, goals, and progress. Plans
                are editable by the tutor &#40;to allow for human input&#41;.
              </td>
              <td width="15%">
                &ndash; <b>Single Text- only Tutoring Session Transcript &#40;trial or subscriber&#41;</b>
                <br />
                Created from Zoom Audio only recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction ID</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />- Tutor <br />- Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor, Learner, Parent &#40;and others parent chooses to share with&#41;
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless
                    noted above&#41;.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <u>Storage</u>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U .S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
            <tr>
              <td width="15%">
                <b>Tutoring Session Highlights</b>
                <br />
                <br />
                Generate highlights containing summaries of Tutoring Session Transcripts capturing the learner&rsquo;s
                breakthroughs during each tutoring session. Summaries are editable by the tutor &#40;to allow for human
                input&#41;.
              </td>
              <td width="15%">
                &ndash; <b>Single Text- only Tutoring Session Transcript &#40;trial or subscriber&#41;</b>
                <br />
                Created from Zoom Audio only Recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction ID</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />- Tutor <br />- Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor, Learner, Parent &#40;and others parent chooses to share with&#41;
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless
                    noted above&#41;.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    Dojo Tuto r : Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <u>Storage</u>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U .S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                    <br />
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
            <tr>
              <td width="15%">
                <b>Tutor/Session Quality Analysis</b>
                <br />
                <br />
                Create internal assessment of tutor and session quality based on session content and engagement.
                Assessments are editable by the Dojo Tutor personnel &#40;to allow for human input&#41;.
              </td>
              <td width="15%">
                &ndash; <b>Single Text- only Tutoring Session Transcript &#40;trial or subscriber&#41;</b>
                <br />
                Created from Zoom Audio only Recording of Tutoring Session. No video sent to Anthropic and no audio or
                video sent to LLM.
                <br />
                &ndash; <b>De-identified User Transaction ID</b>
                <br />
                Created by Assembly to link the Inputs and Outputs to the user so that Anthropic does not receive any
                directly identifiable user data &#40;except to the extent any direct identifying information is
                contained in the transcript&#41;.
              </td>
              <td width="15%">
                <u>Input collected from</u>
                <br />- Tutor <br />- Learner &#40;with parent consent&#41;
                <br />
                <br />
                <u>Output disclosed to</u>
                <br />
                Tutor
              </td>
              <td width="15%">
                Text only Tutoring Session Transcripts and de-identified user transaction ID &#40;both created by
                AssemblyAI&#41; along with prompts or &ldquo;instructions&rdquo; provided by Dojo Tutor are sent by
                Assembly AI to an integrated LLM - Anthropic for processing.
              </td>
              <td width="40%">
                <u>Retention Period</u>
                <br />
                <i sx={sxMarginLeft}>Zoom Audio Recordings</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Retained only transiently to provide the transcription service then deleted
                    immediately once transcript is produced.
                  </li>
                  <li>
                    <u>Zoom</u>: 14 days to provide and maintain the service, and for security purposes &#40;unless
                    noted above&#41;.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Within Zoom&rsquo;s 14-day retention period for these recordings, Dojo Tutor may
                    either download, store and retain the recordings &#40;or elect to have Zoom retain these&#41; for up
                    to an additional 30 days to provide and maintain the service, conduct internal analysis, and keep
                    our platform safe and secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Tutoring Session Transcripts &#40;text only&#41; and User Transaction IDs:</i>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <i sx={sxMarginLeft}>Output:</i>
                <ul>
                  <li>
                    <u>Assembly AI</u>: Up to 30 days to provide the service
                  </li>
                  <li>
                    <u>Anthropic</u>: Retained only transiently to provide the service and for trust and safety purposes
                    then deleted immediately upon creation of Outputs
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: Retained for only as long as the learner&rsquo;s account is{" "}
                    <a target="_blank" href={activeAccountLink}>
                      active
                    </a>
                    , unless required by law to retain them, to enforce our policies, or to keep our community secure.
                  </li>
                </ul>
                <u>Storage</u>
                <ul>
                  <li>
                    <u>AssemblyAI</u>: U.S.
                  </li>
                  <li>
                    <u>Anthropic</u>: U .S.
                  </li>
                  <li>
                    <u>Dojo Tutor</u>: U.S.
                    <br />
                  </li>
                </ul>
                <u>Security</u>
                <br />
                Encrypted at rest and in transit.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr sx={{ pageBreakBefore: "always", display: "none" }} />* The processing/prompts and outputs listed are
      summaries of instructions provided and the examples of the resulting outputs.
      <br />
      <br />
      <HeadlineText as="h2" level={1} id="how-dojo-tutor-uses-data">
        How Dojo Tutor Uses the Data to Provide the AI Features
      </HeadlineText>
      <br />
      <p>
        Dojo Tutor uses Tutoring Session Transcripts &#40;text only&#41; as &ldquo;inputs&rdquo; to generate AI insights
        through the use of{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service provider
        </a>
        , Assembly, that integrates with an LLM AI subprocessor, Anthropic, to provide this AI service. At the specific
        direction and instruction of Dojo Tutor, Anthropic will perform certain AI related functions upon these Tutoring
        Session Transcripts in order to obtain AI-generated insights on tutoring sessions between our learners and
        tutors &#40;&ldquo;AI Outputs&rdquo;&#41;. The various AI related tasks performed on the transcripts are done
        through the use of Dojo Tutor directed instructions or &ldquo;prompts&rdquo; to Anthropic &#40;<i>e.g.</i>
        &ldquo;summarize the tutoring session&rdquo;&#41;. To protect the privacy of users, Dojo Tutor does not share
        any direct identifiers of tutors or learners &#40;except to the extent that any direct identifiers are included
        within the Tutoring Session Transcripts&#41;.
        <br />
        <br />
        Data obtained from the AI Outputs and Tutoring Session Transcripts may be accessed, processed and used by Dojo
        Tutor to provide and maintain the Service, perform internal analysis, and keep our platform safe and secure. For
        example, the Tutoring Session Transcripts will be sent to Anthropic to create automated tutoring assessments,
        lesson summaries, and learning plans. For more information on the &ldquo;prompts&rdquo; used to generate the AI
        insights, the information shared with Antropic, and Dojo Tutor&rsquo;s use of AI, please see our table above.
        Dojo Tutor does not use any{" "}
        <a target="_blank" href={personalInformationLink}>
          personal information
        </a>{" "}
        contained in any Tutoring Session Transcripts to train any of Dojo Tutor&rsquo;s artificial intelligence models.
        Dojo Tutor will only train our models with data that has been de
        <a target="_blank" href={deIdentifiedLink}>
          -identified
        </a>{" "}
        in such a way that it is not traceable to any Dojo Tutor user . Additionally, Dojo Tutor does not allow any
        Anthropic or any other LLM or AI third-party service provider it may utilize to use any information
        &#40;personal information or de-identified&#41; to train, improve or develop their artificial intelligence
        models. This commitment includes the information contained in the chart above, such as the Tutoring Session
        Transcripts, used to provide the AI Outputs. Your child&#39;s data &#40;or you if you are a tutor&#41; used to
        provide the &ldquo;inputs&rdquo; &#40; e.g. , the Tutoring Session Transcripts&#41; may be temporarily retained
        by Anthropic and related{" "}
        <a target="_blank" href={serviceProvidersLink}>
          service providers
        </a>
        , but in no event longer than thirty &#40;30&#41; days. Additionally, any AI Outputs will also be deleted within
        thirty &#40;30&#41; days of performing the processing on the &ldquo;inputs&rdquo; &#40;<i>e.g</i>,. the Tutoring
        Session Transcripts&#41; by Anthropic and Assembly.
      </p>
      <br />
      <HeadlineText as="h2" level={1} id="how-we-work-with-trusted-services">
        How We Work With Trusted Service Providers To Offer AI-Enhanced Tutoring Services
      </HeadlineText>
      <br />
      To enable these new AI-enhanced features on the Dojo Tutor platform, Dojo Tutor works with trusted service
      providers{" "}
      <a target="_blank" href={serviceProvidersLink}>
        AssemblyAI
      </a>{" "}
      and its AI subprocessor,{" "}
      <a target="_blank" href={serviceProvidersLink}>
        Anthropic
      </a>
      . In engaging with these{" "}
      <a target="_blank" href={serviceProvidersLink}>
        third party service providers
      </a>
      , Dojo Tutor remains committed to upholding our values of transparency, accountability, fairness, and safety in
      the use of user data in AI technology.
      <br />
      <br />
      <b>Figure 1</b> below details how Dojo Tutor works with our trusted service providers to provide AI-enhanced
      tutoring services:
      <div sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img
          alt=""
          src="https://static.classdojo.com/img/2024/04/AIZoomTranscriptionFlow.png"
          sx={{
            width: "90%",
            margin: "auto",
            maxWidth: "687px",
            maxHeight: "194px",
          }}
          title=""
        />
      </div>
      <p sx={sxRightCenter}>Fig. 1</p>
      <br />
      <br />
      <HeadlineText as="h2" level={1} id="how-parents-can-opt-out">
        How Can Parents Opt Out After Providing Consent?
      </HeadlineText>
      <br />
      <p>
        If you, as the parent or guardian that has previously provided consent for your child&rsquo;s personal
        information to be used in connection with Dojo Tutor&rsquo;s AI use, would like to at any time prevent its
        further use, you have the right to opt out of Dojo Tutor&rsquo;s use of your child&rsquo;s Tutoring Session
        Transcripts for use in the AI features by directing us to delete your child&rsquo;s Tutoring Session Transcript.
        Additionally, you may request deletion of any personal information contained in the AI Output generated from the
        Tutoring Session Transcripts. Please keep in mind that a request to delete information may affect your
        child&rsquo;s ability to access the Services or use certain features. To exercise this deletion right, please
        contact us at <a href="mailto:tutoringprivacy@classdojo.com">tutoringprivacy@classdojo.com</a> or 735 Tehama
        Street, San Francisco, CA 94103 . Please be aware that to protect children&rsquo;s privacy and security, we take
        reasonable steps to verify a parent or legal guardian&rsquo;s identity before gaining access to any of their
        child&rsquo;s personal information. For more information about exercising your rights, visit the &ldquo;Parental
        Choices&rdquo; section of the Dojo Tutor Privacy Policy.
      </p>
      <br />
      <HeadlineText as="h2" level={1} id="limitations-of-dojo-tutor-ai">
        Limitations of Dojo Tutor AI
      </HeadlineText>
      <br />
      While these AI tools mentioned above are designed to support and enrich learning, it is crucial to recognize that
      the AI-generated content and solutions provided by our platform are not perfect and are based on and limited to
      the data the AI has been trained on. As a result, there may be instances where the AI&rsquo;s outputs might not
      fully grasp the nuances of a new or complex problem, leading to potential inaccuracies, incorrect or misleading
      information, or misinterpretations. Given the reliance on AI to tailor educational content and provide immediate
      assistance, we recommend our tutors &#40;and other users&#41; critically assess the AI&rsquo;s guidance and verify
      its accuracy, especially when it pertains to complex subjects or advanced topics, and have provided the ability
      for tutors to provide human input on the various AI insights or &ldquo;outputs&rdquo; prior to sharing with{" "}
      <a target="_blank" href={learnersLink}>
        learners
      </a>{" "}
      and parents. Dojo Tutor is committed to reducing the limitations of AI by focusing on responsible AI development.
      This includes ensuring that our AI-powered tutoring features are built with privacy, security, inclusion, trust,
      and safety at their core. Our approach to integrating AI into educational tools reflects our dedication to
      providing a supportive, effective, and respectful learning environment for all users.
      <br />
      <br />
      <HeadlineText as="h2" level={1}>
        How To Contact Us
      </HeadlineText>
      <br />
      Should you have any questions about Dojo Tutor&rsquo;s use of AI, our privacy or safety practices in the handling
      of your data, or for any other purpose, please contact us by email at tutoring-privacy@classdojo.com.
      <br />
      <br />
      You may also write to us at the following address:
      <br />
      Attn: Chief Privacy Officer
      <br />
      Dojo Tutor
      <br />
      735 Tehama Street <br />
      San Francisco, California, 94103
      <br />
      United States of America.
      <br />
      <br />
      <br />
      <div sx={sxFootnote} id="footnote">
        * When Dojo Tutor is used at the direction of a school, this consent will be from the school under what is
        commonly referred to as “school consent” under COPPA. Currently, Dojo Tutor is used in the out of the school
        context.
      </div>
    </>
  );
};

export default AITransparencyNote;

const sxTextCenter: ThemeUIStyleObject = {
  textAlign: "center",
};

const sxRightCenter: ThemeUIStyleObject = {
  textAlign: "right",
  maxWidth: "687px",
  paddingRight: "dt_xl",
  margin: "auto",
};

const sxItalic: ThemeUIStyleObject = {
  fontStyle: "italic",
};

const sxTable: ThemeUIStyleObject = {
  borderCollapse: "collapse",
  minWidth: "1000px",

  td: {
    verticalAlign: "top",
    border: "dt_functional",
    borderCollapse: "collapse",
    padding: "dt_xxs",
  },

  "thead > tr > td": {
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: RAW_cssValue("#26185B"),
  },

  "tbody > tr > td": {
    fontSize: "12px",
  },
};

const sxTableWrapper: ThemeUIStyleObject = {
  display: "block",
  overflowX: "auto",
};

const sxTableHeader: ThemeUIStyleObject = {
  fontWeight: "bold",
  color: "dt_content_light",
  backgroundColor: RAW_cssValue("#26185B"),
};

const sxMarginLeft: ThemeUIStyleObject = {
  marginLeft: "dt_s",
};

const sxFootnote: ThemeUIStyleObject = {
  fontSize: "14px",
  fontStyle: "italic",
};

const sxLinkWithoutUnderline: ThemeUIStyleObject = {
  textDecoration: "none",
};
