import { setEntityId, setEntityType } from "@classdojo/log-client";
import { useQuery } from "@tanstack/react-query";
import SessionApi from "src/api/session";
import { getEntityType, SessionData } from "src/hooks/api/auth/useLoginMutation";
import queryClient from "src/queryClient";
import { datadogRum } from "@datadog/browser-rum";
import { setDefaultTimezone } from "src/utils/dates";

const SESSION_QUERY_KEY = "sessionData";

export const useSessionQuery = () => {
  const query = useQuery(
    [SESSION_QUERY_KEY],
    () =>
      SessionApi.getSessionData()
        .then((response) => {
          const uid = response.data.user_details.uid;
          const userType = getEntityType(response.data.roles);
          setEntityId(uid);
          setEntityType(userType);
          setDefaultTimezone(response.data.user_details.timezone);
          datadogRum.setUser({
            id: uid,
            userType,
          });
          return response;
        })
        .catch(() => {
          return null;
        }),
    {
      staleTime: Infinity,
    },
  );

  return {
    isInitialLoading: query.isInitialLoading,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isFetching: query.isFetching,
    sessionData: query.data?.data as SessionData | undefined,
  };
};

export const setSessionQueryData = (data: SessionData) =>
  queryClient.setQueryData([SESSION_QUERY_KEY], () => ({ data }));

export const clearSessionQueryData = () =>
  queryClient.setQueryData([SESSION_QUERY_KEY], () => {
    return null;
  });

export const getSessionData = () =>
  (queryClient.getQueryData([SESSION_QUERY_KEY]) as undefined | { data: SessionData })?.data as SessionData | undefined;
