import { create } from "zustand";
import CancelEnrollmentModal from "old/src/components/CancelEnrollmentModal";
import TutorModal from "src/components/TutorModal";

type ModalType =
  | {
      type: "cancel";
      enrollmentId: string;
      fromReschedule?: boolean;
    }
  | {
      type: "subscribe";
    }
  | {
      type: "teacher";
      teacherUID: string;
      options?: {
        onSelectTutor: () => void;
        backButtonText: string;
      };
    };

type ModalStore = {
  modal?: ModalType;
  hideModal: () => void;
  showCancelModal: (enrollmentId: string, fromReschedule?: boolean) => void;
  showTeacherModal: (
    teacherUID: string,
    options?: {
      onSelectTutor: () => void;
      backButtonText: string;
    },
  ) => void;
};

export const useModalStore = create<ModalStore>((set) => ({
  hideModal: () => set({ modal: undefined }),
  showCancelModal: (enrollmentId, fromReschedule?: boolean) =>
    set({ modal: { type: "cancel", enrollmentId, fromReschedule } }),
  showTeacherModal: (teacherUID, options) => set({ modal: { type: "teacher", teacherUID, options } }),
}));

const Modal = () => {
  const modal = useModalStore((state) => state.modal);

  if (modal == null) return null;

  return (
    <>
      {modal.type === "cancel" && (
        <CancelEnrollmentModal enrollmentId={modal.enrollmentId} fromReschedule={modal.fromReschedule} />
      )}
      {modal.type === "teacher" && <TutorModal tutorId={modal.teacherUID} options={modal.options} />}
    </>
  );
};

export default Modal;
