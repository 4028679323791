import { theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import RadioGroupOption from "./RadioGroup/RadioGroupOption";
import RadioGroup from "./RadioGroup";
import { logTutorEvent } from "src/utils/log";
import { TutorSubject } from "src/utils/constants";

type SubjectSelectProps = {
  subjects: { subject: TutorSubject; label: string; emoji: string }[];
  onChange: (value: string) => void;
  value: string;
};

const SubjectSelect = ({ subjects, onChange, value }: SubjectSelectProps) => {
  return (
    <RadioGroup
      sx={
        subjects.length <= 1
          ? sxHorizontalRadioGroup1Option
          : subjects.length <= 2
            ? sxHorizontalRadioGroup2Options
            : sxHorizontalRadioGroup3Options
      }
      value={value}
      onChange={(value) => {
        logTutorEvent({
          eventName: "parent.marketplace.checkout_select_subject",
          eventValue: value,
        });
        onChange(value);
      }}
    >
      {subjects.map(({ subject, label, emoji }, index) => (
        <RadioGroupOption key={index} value={subject} sxOption={sxClickableOptionLarge} name={label}>
          <div sx={sxButtonContent}>
            <div>{emoji}</div>
            <div>{label}</div>
          </div>
        </RadioGroupOption>
      ))}
    </RadioGroup>
  );
};

export default SubjectSelect;

const sxHorizontalRadioGroup3Options: ThemeUIStyleObject = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: "dt_s",
  width: "100%",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    gridTemplateColumns: "1fr",
  },
};

const sxHorizontalRadioGroup2Options: ThemeUIStyleObject = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "dt_s",
  width: "100%",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    gridTemplateColumns: "1fr",
  },
};

const sxHorizontalRadioGroup1Option: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  width: "50%",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
  },
};

const sxClickableOptionLarge: ThemeUIStyleObject = {
  border: "dt_card",
  alignItems: "center",
  paddingInline: "dt_xs",
  flexGrow: 1,
  textAlign: "center",
  fontSize: "23px",
  display: "flex",
  justifyContent: "center",

  "& > div:first-of-type": {
    display: "none",
  },

  "&[data-checked='true']": {
    borderColor: "dt_content_accent",
  },
};

const sxButtonContent: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_xxs",
  justifyContent: "center",
  height: 90,

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    height: "auto",
    flexDirection: "row",
    gap: "dt_s",
  },
};
