import { createBrowserHistory } from "history";
import type { Middleware } from "redux";
import { sanitizeQueryString } from "../../utils/queryStrings";

//
// Keep a history of redux actions and url changes.
// This history is useful for debugging purpose, when we send
// logs for exceptions or info.
//
export class UserActionsHistory {
  private maxNumberOfActionsToKeep = 40;
  private history: string[] = [];
  private initialUrl = "";
  private initialTime = new Date().getTime();
  historyTotalCount = 0;

  private _keepMostRecent() {
    // Items are top (most recent) to bottom (oldest). So we want to pick the first 'x' items.
    this.history = this.history.slice(0, this.maxNumberOfActionsToKeep);
  }

  private getElapsedTime() {
    const elapsedSeconds = Math.round((new Date().getTime() - this.initialTime) / 1000);
    // format elapsed seconds as 34 min 23 s
    const minutes = Math.floor(elapsedSeconds / 60);
    const seconds = elapsedSeconds % 60;
    if (minutes > 0) {
      return `${minutes} min ${seconds} s`;
    }
    return `${seconds} s`;
  }

  private addEntry(entry: string) {
    this.history.unshift(`${this.getElapsedTime()} ${entry}`);
    this._keepMostRecent();
    this.historyTotalCount++;
  }

  private addBrowserInitialUrl(initialUrl: string) {
    this.initialUrl = sanitizeQueryString(initialUrl);
  }

  // ----------
  // Public API

  public getHistory() {
    const divider = "---------------------------------";

    const prettyHistory = [
      `[Top (most recent) to bottom (oldest)]`,
      divider,
      ...this.history.map((h: string) => `• ${h}`),
    ];

    if (this.historyTotalCount > this.maxNumberOfActionsToKeep) {
      prettyHistory.push(`... ${this.historyTotalCount - this.maxNumberOfActionsToKeep} more actions.`);
    }

    prettyHistory.push(divider, `[Initial URL] ${this.initialUrl}`);

    return prettyHistory;
  }

  public reduxMiddleware: Middleware = () => (next) => (action) => {
    // To respect legacy logic of actions that we want to skip going into the history.
    // We could probably refactor this or move the logic somewhere else.
    const shouldIgnoreAction = !action?.type || action?.meta?.skipErrorActionLog;

    if (!shouldIgnoreAction) {
      this.addEntry(action.type);
    }
    return next(action);
  };

  public listenToBrowserUrlChanges() {
    const history = createBrowserHistory();

    this.addBrowserInitialUrl(window.location.href);

    history.listen(({ location, action }) => {
      let locationString = `[URL Change:${action}]`;
      if (location.pathname) locationString = `${locationString} ${location.pathname}`;
      if (location.search) locationString = `${locationString} ${location.search}`;
      if (location.hash) locationString = `${locationString} ${location.hash}`;

      this.addEntry(locationString);
    });
  }
}
