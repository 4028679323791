import { createSearchParams, useNavigate } from "react-router-dom";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import NewSingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import Field from "src/components/form/Field";
import { BodyText, PasswordTextField, TextField, theme } from "@classdojo/web/nessie";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PhoneInput, { isPossiblePhoneNumber, Value } from "react-phone-number-input";
import useSignUpParentMutation from "src/hooks/api/auth/useSignUpParentMutation";
import { useGlobalStore } from "old/src/store";
import { useState } from "react";
import { AxiosError } from "axios";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { sxPhoneInput } from "src/pageComponents/NewSingleClass/SharedStyles";
import { TranslatedString } from "@classdojo/web/pods/i18n/translate";
import { logTutorEvent } from "src/utils/log";
import { test as isValidEmailAddress } from "src/validators/email";
import { useNavigateWhenLoggedInSwitchesLoaded } from "src/hooks/useFeatureSwitches";
import { getDefaultTimezone } from "src/utils/dates";

const INVALID_MOBILE_NUMBER = "Invalid mobile number";
const MOBILE_NUMBER_EXISTS = "Mobile number already exists";
const EMAIL_ALREADY_USED = "Email already used";

const KNOWN_ERROR_MESSAGES = [INVALID_MOBILE_NUMBER, MOBILE_NUMBER_EXISTS, EMAIL_ALREADY_USED];

const FormSchema = z.object({
  firstName: z.string().min(1).default(""),
  lastName: z.string().min(1).default(""),
  email: z.string().refine((value) => isValidEmailAddress(value)),
  password: z.string().min(8).max(32).default(""),
  mobileNumber: z.string().min(10),
});
type FormData = z.infer<typeof FormSchema>;

const DirectBookingParentSignup = () => {
  const push = useNavigate();

  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
  });
  const signUpParentMutation = useSignUpParentMutation();
  const [state] = useGlobalStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tutorId = state.booking?.teacher_uid;
  const navigateOnLoggedInSwitchesLoaded = useNavigateWhenLoggedInSwitchesLoaded();

  useOnFirstRender(() => {
    if (state.booking) {
      if (!tutorId || !state.booking.grade || !state.booking.first_name_child) {
        push("/direct-booking-subscription/tutor-introduction");
      } else {
        logTutorEvent({
          eventName: "parent.marketplace.directbooking.viewed.account_creation",
          metadata: { tutor_id: tutorId },
        });
      }
    }
  });

  const onSubmit = async (values: FormData) => {
    setIsSubmitting(true);
    signUpParentMutation.mutate(
      {
        email: values.email,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        mobile_number: values.mobileNumber,
        time_zone: state.booking?.timezone ?? getDefaultTimezone(),
      },
      {
        onSuccess: () => {
          logTutorEvent({
            eventName: "parent.marketplace.directbooking.completed.account_creation",
            metadata: { tutor_id: tutorId },
          });

          setIsSubmitting(false);
          const searchParams = createSearchParams({ directBookingFlow: "true" }).toString();
          navigateOnLoggedInSwitchesLoaded(
            {
              pathname: "/subscription/booking/select-class-frequency",
              search: searchParams,
            },
            { replace: true },
          );
        },
        onError: (error: AxiosError<{ error: { detail: string; code?: string } }>) => {
          setIsSubmitting(false);
          if (error.code == "ERR_BAD_REQUEST") {
            const errorData = error.response?.data;
            if (errorData?.error.detail == "Mobile Phone Number Already In Use") {
              return form.setError("mobileNumber", {
                type: "manual",
                message: MOBILE_NUMBER_EXISTS,
              });
            }
            if (errorData?.error?.code === "ERR_DUPLICATE_EMAIL") {
              push(`/login?email=${encodeURIComponent(values.email)}&redirectTo=direct-booking`);
            }
          }
          return Promise.reject(error);
        },
      },
    );
  };

  return (
    <NewSingleClassForm
      onSubmit={form.handleSubmit(onSubmit)}
      contentMaxWidth={480}
      showSubmitButton
      buttonText="Continue"
      title="Setup your account"
      disabled={!form.formState.isValid || isSubmitting || form.formState.isSubmitSuccessful}
      sxTitle={sxTitle}
      progressBarPercentage={80}
    >
      <div sx={{ display: "flex", flexDirection: "column", gap: "18px", marginBottom: "30px" }}>
        <Field
          form={form}
          name="firstName"
          render={({ value, onChange, error }) => (
            <div sx={sxField}>
              <TextField
                sx={sxSmallInput}
                defaultValue={value}
                onChange={onChange}
                type="text"
                aria-label={"firstName" as TranslatedString}
                label="Your first name"
              />
              {error && (
                <BodyText level={2} kind="danger" sx={sxError}>
                  Password must be between 8 and 32 characters long
                </BodyText>
              )}
            </div>
          )}
        />
        <Field
          form={form}
          name="lastName"
          render={({ value, onChange, error }) => (
            <div sx={sxField}>
              <TextField
                sx={sxSmallInput}
                defaultValue={value}
                onChange={onChange}
                type="text"
                aria-label={"lastName" as TranslatedString}
                label="Your last name"
              />
              {error && (
                <BodyText level={2} kind="danger" sx={sxError}>
                  Password must be between 8 and 32 characters long
                </BodyText>
              )}
            </div>
          )}
        />
        <Field
          form={form}
          name="email"
          render={({ value, onBlur, onChange, error }) => (
            <div sx={sxField}>
              <TextField
                sx={sxSmallInput}
                defaultValue={value}
                type="email"
                aria-label={"email" as TranslatedString}
                label="Your email"
                onChange={(email) => onChange(email)}
                onBlur={onBlur}
              />
              {error && (
                <BodyText level={2} kind="danger" sx={sxError}>
                  {KNOWN_ERROR_MESSAGES.includes(error.message ?? "") ? error.message : "Invalid email"}
                </BodyText>
              )}
            </div>
          )}
          fieldMayBeAutofilled
        />
        <Field
          form={form}
          name="password"
          render={({ value, onBlur, onChange, error }) => (
            <div sx={sxField}>
              <PasswordTextField
                sx={sxPasswordField}
                defaultValue={value}
                aria-label={"password" as TranslatedString}
                label="Password"
                onChange={onChange}
                onBlur={onBlur}
              />
              {error && (
                <BodyText level={2} kind="danger" sx={sxError}>
                  Password must be between 8 and 32 characters long
                </BodyText>
              )}
            </div>
          )}
        />
        <Field
          form={form}
          name="mobileNumber"
          render={({ value, onBlur, onChange, error }) => (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label sx={sxFieldWithLabel}>
              <BodyText sx={sxPhoneLabel}>Mobile Number</BodyText>
              <PhoneInput
                sx={sxCustomPhoneInput}
                onChange={(value) => onChange(value as string)}
                onBlur={() => {
                  if (!isPossiblePhoneNumber(value || "")) {
                    form.setError("mobileNumber", {
                      type: "manual",
                      message: INVALID_MOBILE_NUMBER,
                    });
                  }
                  onBlur();
                }}
                value={value as Value}
                defaultCountry="US"
                limitMaxLength
              />
              {error && (
                <BodyText level={2} kind="danger" sx={sxError}>
                  {error.message && KNOWN_ERROR_MESSAGES.includes(error.message) && error.message}
                </BodyText>
              )}
              <BodyText sx={sxSmsOptOut}>
                By clicking continue you agree that you may receive SMS notifications such as class reminders or tutor
                notifications from us and can opt out any time.
              </BodyText>
            </label>
          )}
        />
      </div>
    </NewSingleClassForm>
  );
};

export default DirectBookingParentSignup;

const sxError: ThemeUIStyleObject = { paddingLeft: "dt_m" };
const sxField: ThemeUIStyleObject = { display: "flex", flexDirection: "column", gap: "dt_s", width: "100%" };

const sxPhoneLabel: ThemeUIStyleObject = {
  lineHeight: "23px",
  color: "dt_content_primary",
  fontWeight: 700,
  fontSize: "15px",
};

const sxFieldWithLabel: ThemeUIStyleObject = {
  ...sxField,
  gap: RAW_cssValue("4px"),
};

const sxTitle: ThemeUIStyleObject = {
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    textAlign: "left",
    fontSize: "23px",
    fontWeight: 800,
    marginBottom: "dt_xs",
    lineHeight: "28px",
    letterSpacing: "-0.1px",
  },
};

const sxSmsOptOut: ThemeUIStyleObject = {
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15px",
  letterSpacing: "0.2px",
  color: "dt_content_secondary",
};

const sxSmallInput: ThemeUIStyleObject = {
  width: "100%",
  gap: RAW_cssValue("4px"),
  "label > span": {
    padding: "0",
    fontSize: "15px",
  },
  input: {
    height: "50px",
    fontSize: "15px",
  },
};

const sxCustomPhoneInput: ThemeUIStyleObject = {
  ...sxPhoneInput,
  height: "50px",

  ".PhoneInputInput": {
    padding: "dt_xs",
    border: "none",
    height: 62,
    backgroundColor: "transparent",
    outline: "none",
    fontWeight: "600",
    fontSize: "15px",
  },
};

const sxPasswordField: ThemeUIStyleObject = {
  ...sxSmallInput,
  button: {
    height: "48px",
  },
};
