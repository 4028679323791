import { DropdownMenu } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { ComponentProps, useState } from "react";
import { getDefaultTimezone } from "src/utils/dates";
import { getDisplayValue, getTimezones } from "src/utils/timezones";

const FakeTranslate = (value: string) => value as string & { __translatedString: true };

const TimezoneSelect = ({
  selectedValue,
  onChange,
  disabled = false,
}: {
  selectedValue: string;
  onChange: (timezone: string) => void;
  disabled?: boolean;
}) => {
  const [value, setValue] = useState<string>(selectedValue);

  const defaultTimezone = getDefaultTimezone();
  const timezones = getTimezones(defaultTimezone);

  const timezoneOptions: ComponentProps<typeof DropdownMenu>["options"] = timezones.map((timezoneOption) => ({
    ...timezoneOption,
    onClick: () => handleChange(timezoneOption.value),
  }));

  const handleChange = (timezone: string) => {
    setValue(timezone);
    onChange(timezone);
  };

  return (
    <div sx={sxWrapper}>
      <DropdownMenu
        caret
        color="dt_content_accent"
        label={FakeTranslate("Timezone")}
        options={timezoneOptions}
        data-name="Timezone"
        trigger={<span>{getDisplayValue(value)}</span>}
        disabled={disabled}
      />
    </div>
  );
};

export default TimezoneSelect;

const sxWrapper: ThemeUIStyleObject = {
  display: "inline-block",
};
