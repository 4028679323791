type Options = {
  alwaysShowDecimals?: boolean;
};

export default function formatCurrency(amount: number, { alwaysShowDecimals = false }: Options = {}) {
  const USD = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
  const formatted = USD.format(amount);

  if (alwaysShowDecimals) return formatted;

  return formatted.replace(/\.00$/, "");
}
