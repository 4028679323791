import { paths } from "@classdojo/ts-api-types/api";
import { useQuery } from "@tanstack/react-query";
import { monolithApiClient } from "src/configs/axios.config";
import { useSessionQuery } from "src/hooks/useSession";

type Endpoint = paths["/api/marketplace/subscription"]["get"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export type Subscription = Response["activeSubscriptions"][number];
export type SubscriptionSchedule = Subscription["schedule"];

function removeEmptyDays(subscription: Subscription): Subscription {
  return {
    ...subscription,
    schedule: Object.fromEntries(Object.entries(subscription.schedule).filter((entry) => entry[1].times.length > 0)),
  };
}

export default () => {
  const { sessionData } = useSessionQuery();
  const query = useQuery(["subscription"], () => monolithApiClient.get<Response>("/subscription"), {
    keepPreviousData: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(sessionData?.roles.includes("parent")),
  });

  return {
    isFetching: query.isFetching,
    isLoading: query.isLoading,
    subscriptions: query.data?.data.activeSubscriptions && query.data?.data.activeSubscriptions.map(removeEmptyDays),
  };
};
