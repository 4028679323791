export const ErrorBannerValues = {
  rateLimit: "rateLimit",
  unknown: "unknown",
  apiDown: "apiDown",
  loggedOut: "loggedOut",
  apiDownLoadShedding: "apiDown-loadShedding",
  pubnub: "pubnub",
  requestBlocked: "requestBlocked",
  studentsNotInClass: "studentsNotInClass",
  moduleLoadError: "moduleLoadError",
  homeIslands: "homeIslands",
  homeIslandsLoggedOut: "homeIslandsLoggedOut",
} as const;
export type ErrorBannerValue = (typeof ErrorBannerValues)[keyof typeof ErrorBannerValues];
