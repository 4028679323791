type ClassNames = string | { [key: string]: boolean } | undefined | null | ClassNames[] | false;

export default function cn(...args: ClassNames[]) {
  const acc = [] as string[];

  args.forEach((arg) => {
    if (!arg) return;

    if (typeof arg === "string") {
      acc.push(arg);
    } else if (Array.isArray(arg)) {
      acc.push(cn(...arg));
    } else if (typeof arg === "object") {
      acc.push(...Object.keys(arg).filter((key) => arg[key]));
    }
  });

  return acc.join(" ");
}
