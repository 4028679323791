/*
 * # Midnight Refresh
 *
 * To ensure no client remains on an old app for very long, we refresh the page
 * around midnight.
 *
 * We use two approaches.
 *
 * In both approaches, we stagger the refreshes across all clients by adding a
 * random number of seconds to midnight, up to 1 hour.
 *
 * Approach 1: We use setTimeout and check at 2-second intervals to see if
 * we're past "midnight". This approach will also log a product event.
 *
 * Approach 2 : We use a <meta> tag to force a page refresh at "midnight"
 *
 */
import * as logClient from "@classdojo/log-client";
import random from "lodash/random";
import env from "./env";
import { localStorage } from "@web-monorepo/safe-browser-storage";

declare const window: Window & {
  location: Location & {
    reload: (force?: boolean) => boolean;
  };
};

declare const global: {
  appVersion?: string;
};

const ONE_HOUR_MILLISECONDS = 60 * 60 * 1000;
const lastRefreshKey = "lastRefresh";
export function hardRefresh() {
  // Prevent accidental refresh loops
  const lastRefresh = parseInt(localStorage.getItem(lastRefreshKey) || "0", 10);
  if (!isNaN(lastRefresh) && Date.now() - lastRefresh < ONE_HOUR_MILLISECONDS) {
    // We just refreshed, don't do it again
    return;
  }
  localStorage.setItem(lastRefreshKey, Date.now().toString(10));

  // reload() _technically_ doesn't take any parameters
  // But... in firefox, it takes an optional "force" boolean argument,
  // this gets ignored in all other browsers
  window.location.reload(true);
}

// We want to use the same value for both approaches
// An hour in milliseconds
const MILLISECONDS = random(0, 3600000);

// Approach 1
export function scheduleRefresh(prefix: string) {
  // method relies on client side windowlocation. early exit if SSR unavailable.
  if (typeof window === "undefined") return;

  const midnight = nextMidnight() + MILLISECONDS;

  function maybeRefresh() {
    if (Date.now() > midnight) {
      logClient.logEvent(`${prefix}:midnight_refresh`);
      setTimeout(hardRefresh, 1000);
    }
  }

  setInterval(maybeRefresh, 2000);

  if (env.isProd && ["home"].includes(prefix)) {
    setTimeout(() => warnForOldAppVersion(prefix), 60000);
  }
}

const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

interface AppAgeQuery {
  buildAgeInDays: number;
  releaseAgeInDays: number;
}

type AppData = {
  isOld: boolean;
  lastModifiedString: string;
};
async function isOldAppVersion({ buildAgeInDays, releaseAgeInDays }: AppAgeQuery): Promise<AppData> {
  const results = { isOld: false, lastModifiedString: "" };
  try {
    if (!global.appVersion) {
      // We don't know what version we're on, just pretend we're up to date
      return results;
    }

    const currentDate = Date.now();
    const minBuildDate = currentDate - buildAgeInDays * ONE_DAY_MILLISECONDS;
    const releaseDate = getAppVersionDate(global.appVersion);

    if (releaseDate > minBuildDate) {
      // The release we're on is new enough, we don't need to check for an update
      return results;
    }

    // check if we can get an updated VERSION.txt from server
    const response = await fetch(`/VERSION.txt?c=${currentDate}`, { method: "HEAD" });
    if (!response.ok) {
      // We can't get the latest version, just pretend we're up to date
      return results;
    }

    const lastModifiedString = response.headers.get("last-modified") || "";
    results.lastModifiedString = lastModifiedString;
    if (!lastModifiedString) {
      // This should always be there, just pretend we're up to date
      return results;
    }

    const lastModifiedDate = Date.parse(lastModifiedString);
    if (Number.isNaN(lastModifiedDate)) {
      // What server did we talk to?
      return results;
    }

    const minModifiedDay = releaseDate + releaseAgeInDays * ONE_DAY_MILLISECONDS;
    results.isOld = lastModifiedDate > minModifiedDay;

    return results;
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch {
    return results;
  }
}

async function warnForOldAppVersion(prefix: string) {
  const { isOld, lastModifiedString } = await isOldAppVersion({ buildAgeInDays: 3, releaseAgeInDays: 1 });

  if (isOld) {
    // there is a newer VERSION.txt file available, but we are still executing an old app version
    logClient.logMessage(
      "WARN",
      `[MIDNIGHT_REFRESH] There is a newer release available for ${prefix} [${lastModifiedString}], but we are still running with old appVersion=[${global.appVersion}]`,
    );
  }
}

/**
 * Forcibly refreshes the page if the app's build is old enough.
 * @param query.buildAgeInDays - The number of days since the build was created.
 * If the build is newer than this, we won't refresh.
 * @param query.releaseAgeInDays - The number of days since the release was created.
 * If we have an old build (using `buildAgeInDays`), we will refresh if there is a release newer than this.
 */
export async function refreshIfOldVersion(query: AppAgeQuery) {
  const { isOld } = await isOldAppVersion(query);
  if (isOld) {
    hardRefresh();
  }
}

function getAppVersionDate(appVersion: string) {
  // '202210171246.3908.0-teach'
  const dateString = appVersion.substring(0, 8);
  if (dateString.length !== 8 || Number.isNaN(parseInt(dateString, 10))) {
    return Date.now();
  }

  const year = appVersion.substring(0, 4);
  const month = appVersion.substring(4, 6);
  const day = appVersion.substring(6, 8);
  const releaseDate = Date.parse(`${year}-${month}-${day}`);

  if (Number.isNaN(releaseDate)) {
    return Date.now();
  }

  return releaseDate;
}

// Approach 2
export function addMetaTagToRefreshNearMidnight() {
  const head = document.querySelector("head");
  if (!head) return; // head could be null

  const midnight = Math.round(secondsUntilMidnight() + MILLISECONDS / 1000);

  const meta = document.createElement("meta");
  meta.setAttribute("http-equiv", "refresh");
  meta.setAttribute("content", midnight.toString());
  head.appendChild(meta);
}

function nextMidnight(now = new Date()) {
  now.setSeconds(0);
  now.setMinutes(0);
  now.setHours(0);
  now.setDate(now.getDate() + 1);
  return now.valueOf();
}

function secondsUntilMidnight(now = new Date()) {
  return (nextMidnight(now) - Date.now()) / 1000;
}
