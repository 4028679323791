import { createStyles } from "@mantine/core";
import marketplaceTheme from "src/utils/marketplaceTheme";

export default createStyles((_theme) => ({
  purple: {
    height: "300px",
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    background: marketplaceTheme.colors.dt_background_accent,
    zIndex: 0,
  },

  waveWrapper: {
    position: "absolute",
    top: "-198px",
    width: "100%",
    height: "198px",
    overflow: "hidden",
  },

  wave: {
    background: "url(wave.svg) repeat-x",
    position: "absolute",
    top: "0",
    width: "6400px",
    height: "198px",
    animation: "wave 12s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite",
    transform: "translate3d(0, 0, 0)",

    ["@keyframes wave"]: {
      "0%": {
        marginLeft: 0,
      },
      "100%": {
        marginLeft: "-1600px",
      },
    },
  },
}));
