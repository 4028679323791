/**
 * We have non-React code (e.g. sagas) that need to trigger page navigations.
 * This module acts as a pass-through so non-React code can trigger page
 * navigations as long as someone else provides the implementation.
 */
import { useEffect } from "react";
import { useNavigate } from "react-router";
import type { NavigateFunction } from "react-router";

let _navigate: NavigateFunction | undefined;

export function setNavigateImpl(navigateFn: NavigateFunction) {
  _navigate = navigateFn;
}

export const navigate: NavigateFunction = (...args) => {
  if (!_navigate) throw new Error("navigate implementation was not provided");

  // @ts-expect-error I couldn't figure out how to pass the arguments through without type errors
  // when NavigateFunction has multiple signatures.
  return _navigate(...args);
};

/**
 * React component that grabs `navigate()` from context and sets the implementation
 */
export function RouterNavigateProvider() {
  const navigate = useNavigate();

  useEffect(() => {
    setNavigateImpl(navigate);
  }, [navigate]);
  return null;
}
