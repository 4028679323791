import { createStyles } from "@mantine/core";

export default createStyles((_theme) => ({
  modalBodyContainer: {
    height: "600px",
    overflowY: "scroll",
    paddingRight: "24px",
    marginRight: "-10px",
    paddingLeft: "24px",
  },

  modalSubtitle: {
    fontWeight: "bold",
  },

  checkboxContainer: {
    display: "flex",
    padding: "8px 0px",
  },

  checkboxInput: {
    marginRight: "8px",
  },

  continueButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },

  continueButton: {
    marginTop: "12px",
    width: "300px",
    height: "54px",
  },
}));
