import { forwardRef } from "react";
import { type Props as DefaultProps, DefaultTextField, Variant as DefaultVariant } from "./DefaultTextField";
import { type Props as InsetLabelProps, InsetLabelField, Variant as InsetLabelVariant } from "./InsetLabelField";
import type { Simplify } from "type-fest";

export const Variants = [DefaultVariant, InsetLabelVariant] as const;
export type Props = Simplify<DefaultProps | InsetLabelProps>;
export { Sizes } from "./DefaultTextField";
export { TypeAttributes } from "./shared";

export const DDSTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  switch (props.variant) {
    case InsetLabelVariant:
      return <InsetLabelField ref={ref} {...props} />;
    default:
      return <DefaultTextField ref={ref} {...props} />;
  }
});
