import { useState } from "react";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { Button, theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { sxClickableOption } from "src/pageComponents/NewSingleClass/SharedStyles";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import CheckboxGroup from "src/components/CheckboxGroup";
import CheckboxGroupOption from "src/components/CheckboxGroup/CheckboxGroupOption";
import Field from "src/components/form/Field";
import SingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import { sxAddPill, sxMoreButton } from "src/pageComponents/NewSingleClass/SharedStyles";
import { InputWithButton } from "@classdojo/web";
import { logTutorEvent } from "src/utils/log";
import { IconX } from "@web-monorepo/dds-icons";

const accommodationsOptions = [
  { label: "IEP", value: "iep" },
  { label: "Speech services", value: "speech_services" },
  { label: "Special education program", value: "special_education_program" },
  { label: "Behavioral interventions", value: "behavioral_interventions" },
  { label: "ESL (English as a second language)", value: "esl_english_as_a_second_language" },
  { label: "Gifted & Talented program", value: "gifted_talented_program" },
  { label: "None", value: "none" },
];

const FormSchema = z.object({
  accommodations: z.array(z.string()).optional(),
});
type FormData = z.infer<typeof FormSchema>;

const AdditionalSingleSessionStepFive = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      accommodations: [],
    },
  });

  useOnFirstRender(() => {
    if (state.booking) {
      const { accommodations } = state.booking;
      if (accommodations) form.setValue("accommodations", accommodations, { shouldValidate: true });
    }
  });

  useOnFirstRender(() => {
    logTutorEvent("parent.marketplace.retrial_child_accommodations_viewed");
  });

  const [newAccommodation, setNewAccommodation] = useState("");
  const [showInput, setShowInput] = useState(false);

  const addAccommodation = () => {
    const newAccommodationOption = {
      label: newAccommodation,
      value: newAccommodation.toLowerCase().replace(/\s+/g, "_"),
    };

    accommodationsOptions.push(newAccommodationOption);
    const currentAccommodations = form.getValues("accommodations") || [];
    form.setValue("accommodations", [...currentAccommodations, newAccommodationOption.value]);
    setNewAccommodation("");
    setShowInput(false);
  };

  const onInputChange = (value: string) => {
    setNewAccommodation(value);
  };

  const showInputField = () => {
    setShowInput(true);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 0);
  };

  const onNextStep: SubmitHandler<FormData> = async (values) => {
    logTutorEvent("parent.marketplace.retrial_child_accommodations_completed");
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state?.booking,
        accommodations: values.accommodations,
      },
    });

    push(`/additional-single-class/step-six`);
  };

  return (
    <SingleClassForm
      disabled={!form.formState.isValid}
      title={`Does ${state?.booking?.first_name_child ?? ""} get any extra support at school?`}
      subtitle="Select as many as you want"
      onBack={() => push("/additional-single-class/step-four")}
      onSubmit={form.handleSubmit(onNextStep)}
    >
      <div sx={sxFieldsWrapper}>
        <Field
          form={form}
          name="accommodations"
          render={({ value, onChange }) => (
            <div sx={sxField}>
              <CheckboxGroup
                sx={sxHorizontalRadioGroup}
                value={value || []}
                onChange={(value) => {
                  onChange(value);
                }}
              >
                {accommodationsOptions.map(({ label, value }, index) => (
                  <CheckboxGroupOption key={index} value={value} sxOption={sxClickableOption}>
                    {label}
                  </CheckboxGroupOption>
                ))}
                <button sx={sxMoreButton} onClick={showInputField} type="button">
                  + Add answer
                </button>
              </CheckboxGroup>
              {showInput && (
                <div sx={sxAddPill}>
                  <InputWithButton
                    inputProps={{
                      type: "text",
                      label: "",
                      value: newAccommodation,
                      onChange: onInputChange,
                      placeholder: "Type your own",
                    }}
                    button={{
                      text: "Add",
                      props: {
                        onClick: () => addAccommodation(),
                        "data-name": "add-accommodation-button",
                      },
                    }}
                    preventSubmission={true}
                  />
                  <Button
                    data-name="close-add-accomodation"
                    kind="secondary"
                    size="s"
                    icon={<IconX size="s" />}
                    sx={{ width: 42, height: 42, position: "absolute", right: -10, top: -10 }}
                    onClick={() => setShowInput(false)}
                  />
                </div>
              )}
            </div>
          )}
        />
      </div>
    </SingleClassForm>
  );
};

export default AdditionalSingleSessionStepFive;

const sxFieldsWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  marginTop: "dt_xl",
  marginBottom: "dt_xl",
  width: "100%",
  gap: "dt_xl",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: "0",
    gap: "dt_l",
  },
};

const sxField: ThemeUIStyleObject = { display: "flex", flexDirection: "column", gap: "dt_xs" };

const sxHorizontalRadioGroup: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "dt_s",
  width: "100%",
  justifyContent: "center",
};
