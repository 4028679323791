import cancelClass from "./cancelClass";
import createAssessment from "./createAssessment";
import createGeneratedAssessmentReview from "./createGeneratedAssessmentReview";
import deleteDaysOff from "./deleteDaysOff";
import getAvailability from "./getAvailability";
import getCanceledClasses from "./getCanceledClasses";
import getClassById from "./getClassById";
import getDaysOff from "./getDaysOff";
import getGeneratedAssessment from "./getGeneratedAssessment";
import getSessionSchedule from "./getSessionSchedule";
import getScheduleSlots from "./getScheduleSlots";
import getTeacherPrivateProfile from "./getTeacherPrivateProfile";
import getTeacherPublicProfile from "./getTeacherPublicProfile";
import getTeacherSubscriptionAvailability from "./getTeacherSubscriptionAvailability";
import getUploadPhotoUrl from "./getUploadPhotoUrl";
import getUploadVideoUrl from "./getUploadVideoUrl";
import onboardTeacher from "./onboardTeacher";
import updateAvailability from "./updateAvailability";
import updateDaysOff from "./updateDaysOff";
import updateTeacherProfile from "./updateTeacherProfile";
import getSubscriptionConflicts from "src/api/teacher/getSubscriptionConflicts";
import getTutorMeeting from "src/api/teacher/getTutorMeeting";

export default {
  createAssessment,
  deleteDaysOff,
  getAvailability,
  getDaysOff,
  getSessionSchedule,
  getScheduleSlots,
  getSubscriptionConflicts,
  getTeacherPrivateProfile,
  getTeacherPublicProfile,
  getTeacherSubscriptionAvailability,
  getUploadPhotoUrl,
  getUploadVideoUrl,
  onboardTeacher,
  updateAvailability,
  updateDaysOff,
  updateTeacherProfile,
  getGeneratedAssessment,
  getTutorMeeting,
  getClassById,
  getCanceledClasses,
  cancelClass,
  createGeneratedAssessmentReview,
};
