import SlideSheet from "src/components/SlideSheet";
import { IconX } from "@web-monorepo/dds-icons";
import css from "./OutOfOfficeSlideSheet.module.css";
import OOOSlideSheetBody from "./OOOSlideSheetBody";
import { SemanticTypography } from "@web-monorepo/dds";
import { createContext, useContext } from "react";

const SlideSheetContext = createContext<{
  close: () => void;
}>({
  close: () => {},
});

export const useSlideSheetContext = () => {
  return useContext(SlideSheetContext);
};

const OutOfOfficeSlideSheet = ({ mounted, close }: { mounted: boolean; close: () => void }) => {
  return (
    <SlideSheet mounted={mounted} transitionDuration={250} timingFunction="easy" onClose={close}>
      <SlideSheetContext.Provider value={{ close }}>
        <div className={css.header}>
          <div className={css.title}>Your upcoming OOO</div>
          <div className={css.closeButton} onClick={close} role="tab" tabIndex={0}>
            <IconX size="s" sx={{ color: "dt_content_secondary" }} />
          </div>
        </div>
        <SemanticTypography>
          <OOOSlideSheetBody />
        </SemanticTypography>
      </SlideSheetContext.Provider>
    </SlideSheet>
  );
};

export default OutOfOfficeSlideSheet;
