import { RAW_cssValue } from "@classdojo/web/nessie/stylingLib";
import { Box, Text } from "UILibrary";
import { HEADER_HEIGHT } from "src/constants";

const ParentInvoices = () => {
  return (
    <Box sx={{ minHeight: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
      <Box
        sx={{
          maxWidth: "780px",
          marginLeft: "auto",
          marginRight: "auto",
          width: "90%",
          marginBottom: RAW_cssValue("100px"),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          mb={24}
        >
          <Text
            sx={
              {
                fontSize: "28px",
                lineHeight: "32px",
                color: "#2C2A50",
                fontWeight: 700,
              } as any
            }
          >
            Subscriptions
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ParentInvoices;
