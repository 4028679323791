import { FormEventHandler, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePricesQuery, usePublicTeacherProfileQuery } from "old/src/hooks/useParent";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import NewSingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import useValidateAndAssignTutorMutation from "src/hooks/useValidateAndAssignTutorMutation";
import { showNotification } from "@mantine/notifications";
import { ParentClassData } from "src/api/parent/getClassById";
import useBookFreeTrialSessionMutation from "src/hooks/useBookFreeTrialSessionMutation";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { logTutorEvent } from "src/utils/log";
import { Avatar, Image } from "UILibrary";
import { Button, LabelText, theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { formatWithPattern, addMinutes, parseWithPattern, getLocalizedDateTime } from "src/utils/dates";
import formatCurrency from "src/utils/formatCurrency";
import { useManualFeatureSwitch } from "src/hooks/useFeatureSwitches";

const getDisplayDateTime = (date: string, time: string) => {
  const ts = formatWithPattern(getLocalizedDateTime(date, time), "eee MMM d, h:mm a");
  return ts;
};

type FreeTrialProps = {
  classData: ParentClassData;
  sessionToBeReplacedId: string;
};

const FreeTrial = ({ classData, sessionToBeReplacedId }: FreeTrialProps) => {
  const subPriceSwitch = useManualFeatureSwitch("web_marketplace_sub_funnel_prices_oct2024_v2");

  const [state, dispatch] = useGlobalStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { teacher, isInitialLoading: isTeacherLoading } = usePublicTeacherProfileQuery(classData.teacher.uid);
  const isLoading = isTeacherLoading || isSubmitting;
  const push = useNavigate();
  const { prices } = usePricesQuery({
    experiment: "web_marketplace_sub_funnel_prices_oct2024_v2",
    variant: subPriceSwitch.data,
  });
  const displayPrice = useMemo(() => {
    const price = prices?.find((price) => price.quantity === 1);
    if (price == null) return null;

    return formatCurrency(price.unit_amount / 100);
  }, [prices]);

  const validateAndAssignTutorMutation = useValidateAndAssignTutorMutation();
  const bookFreeTrialSessionMutation = useBookFreeTrialSessionMutation();

  useOnFirstRender(() => {
    logTutorEvent("parent.marketplace.retrial_checkout_free_class_viewed");
  });

  const bookTrialSession = () => {
    bookFreeTrialSessionMutation.mutate(
      { sessionId: classData.session.sessionId, replacementForSession: sessionToBeReplacedId },
      {
        onSuccess: () => {
          dispatch({
            type: actionTypes.UPDATE_BOOKING,
            payload: {
              ...state.booking,
              status: "paid",
            },
          });
          setIsSubmitting(false);
          logTutorEvent("parent.marketplace.retrial_checkout_free_class_completed");
          push(`/additional-single-class/booking/${classData.classId}/success`);
        },
        onError: () => {
          showNotification({
            message: "Something went wrong. Please try again.",
            color: "red",
          });
          setIsSubmitting(false);
        },
      },
    );
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsSubmitting(true);

    validateAndAssignTutorMutation.mutate(
      { sessionId: classData.session.sessionId },
      {
        onSuccess: () => {
          dispatch({
            type: actionTypes.UPDATE_BOOKING,
            payload: {
              ...state.booking,
            },
          });
          bookTrialSession();
        },
        onError: () => {
          setIsSubmitting(false);
          showNotification({
            message: "We no longer have tutors at the selected time. Please select another time.",
            color: "#F2FBE7",
          });
          push(`/additional-single-class/step-six`);
        },
      },
    );
  };

  return (
    <NewSingleClassForm
      onSubmit={onSubmit}
      onBack={() => push("/additional-single-class/step-six")}
      contentMaxWidth={480}
      buttonText="Schedule class"
      showSubmitButton={false}
      title={`Book ${classData.childName}’s trial class`}
      sxTitle={sxTitle}
    >
      {teacher && (
        <div sx={sxCard}>
          <div sx={sxCenter}>
            {teacher?.photo_url ? (
              <Image
                styles={{
                  image: {
                    background: "dt_backgroung_secondary",
                    objectFit: "cover",
                  },
                }}
                width={90}
                height={90}
                radius={160}
                mb={12}
                src={teacher?.photo_url}
                alt="ClassDojo teacher profile"
              />
            ) : (
              <Avatar size={90} radius={160} />
            )}
          </div>
          <div sx={sxColumn}>
            <div sx={sxSubtitle}>
              {teacher.first_name} {teacher.last_name}
            </div>
            <div sx={sxCenterText}>Certified {classData.subject} teacher</div>
          </div>
          <div>
            <div sx={{ ...sxSubtitle, paddingTop: "dt_s" }}>Trial class</div>
            <div sx={sxCenterText}>
              {getDisplayDateTime(state.booking?.date ?? "", state.booking?.time ?? "")} -{" "}
              {formatWithPattern(addMinutes(parseWithPattern(state?.booking?.time, ["h:mm a"]), 25), "h:mm a")}
            </div>
          </div>
        </div>
      )}
      <div sx={sxPriceWrapper}>
        <LabelText level={1}>Total cost</LabelText>
        <LabelText level={1}>
          <del sx={sxOldPrice}>{displayPrice}</del> $0
        </LabelText>
      </div>
      <Button type="submit" sx={sxSubmitButton} disabled={isLoading} data-name="additional_free_trial_session:submit">
        Schedule class
      </Button>
    </NewSingleClassForm>
  );
};

export default FreeTrial;

const sxCard: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_xs",
  border: "dt_card",
  borderRadius: "dt_radius_s",
  width: "100%",
  paddingTop: "dt_l",
  paddingBottom: "dt_l",
  letterSpacing: "0.2px",
  color: "dt_content_primary",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "20px",
};

const sxSubmitButton: ThemeUIStyleObject = {
  backgroundColor: "dt_content_accent",
  width: "100%",
  marginTop: "dt_l",
};

const sxSubtitle: ThemeUIStyleObject = {
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "23px",
  color: "dt_content_primary",
  textAlign: "center",
};

const sxOldPrice: ThemeUIStyleObject = {
  fontWeight: 300,
  color: "dt_content_secondary",
};

const sxPriceWrapper: ThemeUIStyleObject = {
  display: "flex",
  width: "100%",
  gap: "dt_xs",
  backgroundColor: "dt_background_secondary",
  borderRadius: "dt_radius_xs",
  padding: "dt_s",
  marginTop: "dt_s",
  justifyContent: "space-between",
};

const sxCenterText: ThemeUIStyleObject = {
  textAlign: "center",
};

const sxColumn: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const sxCenter: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
};

const sxTitle: ThemeUIStyleObject = {
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    textAlign: "left",
    fontSize: "23px",
    fontWeight: 800,
    marginBottom: "dt_xs",
    lineHeight: "28px",
    letterSpacing: "-0.1px",
  },
};
