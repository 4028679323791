import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";
import { sanitizeId } from "src/utils/sanitizeId";

type Endpoint = paths["/api/marketplace/parent/class/{classId}"]["put"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"];

export default ({ id, ...body }: { id: string } & Body) => {
  const sanitizedId = sanitizeId(id);
  return monolithApiClient.put<Response>(`/parent/class/${sanitizedId}`, body);
};
