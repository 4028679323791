import { CSSObject } from "@mantine/core";

const Button = (): Record<string, CSSObject> => ({
  root: {
    height: "60px",
    padding: "16px 24px",
    borderRadius: "32px",
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 700,
  },
});

export default Button;
