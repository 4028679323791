import { PortraitIcon } from "@classdojo/web/nessie/components/iconsV2/output";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";

type ProfilePhotoProps = {
  alt: string;
  className?: string;
  size?: number;
  src?: string | null;
};

const ProfilePhoto = ({ alt, className, size = 24, src }: ProfilePhotoProps) => {
  return src ? (
    <div
      sx={{
        ...sxProfilePhoto,
        width: size,
        height: size,
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        clipPath: "circle()",
      }}
      className={className}
      role="img"
      aria-label={alt}
    />
  ) : (
    <div sx={{ ...sxDefaultPhotoWrapper, width: size, height: size }} className={className}>
      <PortraitIcon color="dt_content_tertiary" width={size * 0.8} height={size * 0.8} />
    </div>
  );
};

export default ProfilePhoto;

const sxProfilePhoto: ThemeUIStyleObject = {
  backgroundColor: "dt_background_secondary",
  borderRadius: "dt_radius_round",
};

const sxDefaultPhotoWrapper: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "dt_background_secondary",
  borderRadius: "dt_radius_round",
};
