import { RAW_cssValue } from "@classdojo/web/nessie/stylingLib";
import { Box, Text } from "UILibrary";

const htmlCopy = `
  <style type="text/css">
  .ritz .waffle a {
    color: inherit;
  }

  .ritz .waffle .s3 {
    border-bottom: 2px SOLID #000000;
    border-right: 2px SOLID #000000;
    background-color: #c9daf8;
    text-align: left;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 8pt;
    vertical-align: middle;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s8 {
    background-color: #4a86e8;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 11pt;
    vertical-align: top;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s9 {
    background-color: #c9daf8;
    text-align: left;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 8pt;
    vertical-align: middle;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s5 {
    border-bottom: 2px SOLID #000000;
    border-right: 2px SOLID #000000;
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 8pt;
    vertical-align: bottom;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s6 {
    border-bottom: 2px SOLID #000000;
    border-right: 2px SOLID #000000;
    background-color: #c9daf8;
    text-align: left;
    color: #333333;
    font-family: 'Times New Roman';
    font-size: 8pt;
    vertical-align: middle;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s1 {
    border-bottom: 1px SOLID transparent;
    border-right: 2px SOLID #000000;
    background-color: #8eaadb;
    text-align: left;
    font-weight: bold;
    color: #000000;
    font-family: 'docs-Calibri', Arial;
    font-size: 8pt;
    vertical-align: middle;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s7 {
    border-bottom: 2px SOLID #000000;
    background-color: #4285f4;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 11pt;
    vertical-align: top;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s2 {
    background-color: #4285f4;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 11pt;
    vertical-align: top;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s4 {
    border-bottom: 2px SOLID #000000;
    border-right: 2px SOLID #000000;
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 8pt;
    vertical-align: middle;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s10 {
    background-color: #4285f4;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-family: 'Times New Roman';
    font-size: 11pt;
    vertical-align: bottom;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }

  .ritz .waffle .s0 {
    border-right: 2px SOLID #000000;
    background-color: #8eaadb;
    text-align: left;
    font-weight: bold;
    color: #000000;
    font-family: 'docs-Calibri', Arial;
    font-size: 8pt;
    vertical-align: middle;
    white-space: normal;
    overflow: hidden;
    word-wrap: break-word;
    direction: ltr;
    padding: 0px 3px 0px 3px;
  }
</style>
<div class="ritz grid-container" dir="ltr">
  <table class="waffle" cellspacing="0" cellpadding="0">
    <tbody>
      <tr style="height: 19px">
        <td class="s0">Data</td>
        <td class="s1" dir="ltr">User Type (e.g. parent, learner, tutor).</td>
        <td class="s1" dir="ltr">How is data collected and who is the source?</td>
        <td class="s0">What is the purpose for the collection? </td>
        <td class="s0" dir="ltr">How will the data be used?</td>
        <td class="s1" dir="ltr">Security Measures. Is data encrypted in transit and rest?</td>
        <td class="s1" dir="ltr">Where is the data stored (location and vendor)? </td>
        <td class="s1" dir="ltr">Retention. When is the data deleted?</td>
        <td class="s1" dir="ltr">Is data shared or accesed by a service provider (or processor)? </td>
        <td class="s1" dir="ltr">What other third parties (non-service provider) is the data shared with (or given access to the data)?</td>
        <td class="s1" dir="ltr">Is this data <span style="text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;color:#1155cc;">
            <a target="_blank" href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">sold </a>
          </span>or <span style="text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;color:#1155cc;">
            <a target="_blank" href="https://www.google.com/url?q=https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/%231798.140(ah)(1)&amp;sa=D&amp;source=editors&amp;ust=1671571848778420&amp;usg=AOvVaw11s56Ef1ZT9xoXf0bfcr-Y">shared</a>
          </span> to any third parties for a business or commercial purpose in the preceding 12 months? </td>
        <td class="s1" dir="ltr">Is this data used for targeted advertising?</td>
      </tr>
      <tr>
        <th style="height:3px;" class="freezebar-cell freezebar-horizontal-handle"></th>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
        <td class="freezebar-cell"></td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" colspan="12">Registration and User Profile Data </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3" dir="ltr">First and Last Name</td>
        <td class="s4">Learner (provided by parent), Parent, Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4" dir="ltr">Personalize the user experience in the platform. We also use it for contract signing and payments for tutors.</td>
        <td class="s4">To provide the service listed in the purpose column, and send out surveys for product improvement.</td>
        <td class="s4">Encrypted in transit and at rest.</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Intercom, OpenPhone, Zoom, Twilio, SendGrid, Stripe, Blueshift, Typeform, Amazon Web Services, Deel, Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4" dir="ltr">No, but used for email marketing purposes for Parents</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Individual&#39;s country of residence or state </td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4">Location determined though IP and during registration through country location selection for area code</td>
        <td class="s5" dir="ltr">Provide accurate time schedules for their classes based on their timezones. We also use it for contract signing and payments for tutors.</td>
        <td class="s5">To provide the service listed in the purpose column as well as Product Analytics, segmenting data for product improvement.</td>
        <td class="s4">Encrypted in transit and rest.</td>
        <td class="s5">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Deel, Intercom, Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s5">No</td>
        <td class="s5" dir="ltr">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Individual’s Phone number/mobile number </td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4" dir="ltr">Tutors: send class reminders, send rescheduling alerts, among other transactional notifications. We also use it for contract signing, background check, and payments. Parents: send reminders, reschedule alerts, and other notifications. We also send them automatized communication flows after their 1st class to remind them to buy an ongoing subscription. We also send texts if they started an enrollment process but didn&#39;t finish.</td>
        <td class="s4">To provide the service listed in the purpose column and Product Analytics. E.g. determine if those users that provide their phone numbers have better conversion rates than those who don&#39;t.</td>
        <td class="s4">Encrypted in transit and at rest.</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Intercom, OpenPhone, Twilio, Stripe, Typeform, Amazon Web Services, Deel, Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4" dir="ltr">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Individual’s Email address</td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4" dir="ltr">Tutors: send class reminders, send rescheduling alerts, among other transactional notifications. We also use it for contract signing and payments for tutors.s. Parents: send reminders, reschedule alerts, and other notifications. We also send them automatized communication flows after their 1st class to remind them to buy an ongoing subscription. We also send texts if they started an enrollment process but didn&#39;t finish.</td>
        <td class="s4">To provide the service listed in the purpose column, and Request users randomly to complete surveys.</td>
        <td class="s4">Encrypted in transit and at rest.</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Intercom, Zoom, SendGrid, Stripe, Blueshift, Typeform, Amazon Web Services, Deel, Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Password </td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4">Access to platform</td>
        <td class="s4">To provide the service listed in the purpose column.</td>
        <td class="s4">Encrypted in transit and at rest.</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">App or product username</td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4">Access to platform</td>
        <td class="s4">To provide the service listed in the purpose column.</td>
        <td class="s4">Encrypted in transit and at rest.</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Intercom, Zoom, SendGrid, Stripe, Blueshift, Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" colspan="12">Demographics and Special Indicator Data </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Age/DOB</td>
        <td class="s4" dir="ltr">Learner (provided by parent), Tutor </td>
        <td class="s4">User Directly</td>
        <td class="s4" dir="ltr">Learners: Assign them a tutor that fits best with their needs. <br>
          <br>Tutors: Input into background check
        </td>
        <td class="s4">To provide the service listed in the purpose column and Product Analytics, segmenting data for product improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Language information (native, preferred or primary language spoken by a student)</td>
        <td class="s4" dir="ltr">Learner (provided by parent), Parent, Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4">Personalize the user experience in platform</td>
        <td class="s4">To provide the service listed in the purpose column and Product Analytics, segmenting data for product improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4" dir="ltr">No, but used for email marketing purposes for Parents</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" dir="ltr" colspan="12">Financial Information</td>
      </tr>
      <tr style="height: 19px">
        <td class="s6">
          <span style="font-size:8pt;font-family:Times New Roman;color:#333333;">Bank account number, credit card number, debit card number, or any other financial information </span>
          <span style="font-size:8pt;font-family:Times New Roman;color:#ff0000;">[CCPA]</span>
        </td>
        <td class="s4">Parent, Tutor [collected by Stripe not DojoTutoring]</td>
        <td class="s4">User Directly (by Stripe for product payments and Deel for contractor payments)</td>
        <td class="s4">Parents: charge them for classes and give refunds if needded. Tutors: pay out for the classes taught.</td>
        <td class="s4" dir="ltr">To provide the service listed in the purpose column. We may use the last 4 digits of the card and the name of the card holder (provided by Stripe) used by the user to help identify transactions through Customer Support.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4" dir="ltr">To receive payments from parents we use Stripe as a payment gateway. Data is stored in Stripe&#39;s servers, we don&#39;t store any data provided by Stripe in our servers, we access it directly through Stripe&#39;s interface, where we get access to only the last 4 digits of the Credit Card used for the purchase (not the full number). We use that information for customer support (e.g. searching for a subscription). To payout tutors we use Deel. We do not collect any specific information of Tutors bank account information: we make payments to Deel (platform) and they withdraw the payments as they like.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Stripe, Deel</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" colspan="12">Student or Unique User ID </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Vendor/App assigned student ID number</td>
        <td class="s4">Learner, Parent, Tutor -- (classdojo user ID)</td>
        <td class="s4">ClassDojo DataBase</td>
        <td class="s4">Connect Dojo Tutor with ClassDojo in the future.</td>
        <td class="s4">To provide the service listed in the purpose column and Product Analytics, segmenting data for product improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4" dir="ltr">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" colspan="12">Location Data </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Geolocation data (non-precise)</td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4">IP Address</td>
        <td class="s4" dir="ltr">Detect timezone, which is used for product experience personalization</td>
        <td class="s4">To provide the service listed in the purpose column and Product Analytics, segmenting data for product improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Zoom, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4" dir="ltr">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s7" colspan="12">User Content</td>
      </tr>
      <tr style="height: 101px">
        <td class="s3">
          <span style="font-size:8pt;font-family:Times New Roman;color:#000000;">Photographs, videos or audio files </span>
          <span style="font-size:8pt;font-family:Times New Roman;color:#ff0000;">[CCPA]</span>
        </td>
        <td class="s4" dir="ltr">Parent, Tutor, Learner. <br>
          <br>Tutor&#39;s profile pictures, Learner and Parent&#39;s profile pictures (optional) <br>
        </td>
        <td class="s4" dir="ltr">User Directly, Zoom recordings (including transcripts and Zoom chat) - </td>
        <td class="s4" dir="ltr">Display picture to parent and tutor to help identify either party and introduce familiarity. We also record the sessions and store them for 2 weeks for safety and security purposes.</td>
        <td class="s4" dir="ltr">To provide the service listed in the purpose column. Sessions recordings will be reviewed for quality, including in case there is an abuse report or any other incident that may have happened during the session.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US (Tutor photos) and Zoom&#39;s servers (sessions recordings).</td>
        <td class="s4">Session recordings are only stored for 2 weeks and then automatically deleted.</td>
        <td class="s4" dir="ltr">Amazon Web Services, Zoom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s8" colspan="12">Communications (online communications that are captured (SMS/email, forum posts or other) </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Communications (online communications that are captured (SMS/email)</td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4">Customer Support</td>
        <td class="s4">To provide the service listed in the purpose column. We may use previous communications in Customer Support to make reference to previous cases we attended.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Intercom, Twilio, OpenPhone, Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Tutors&#39; feedback to learners</td>
        <td class="s4" dir="ltr">Tutor, Learner (Tutor inputs about learner)</td>
        <td class="s4">User Directly</td>
        <td class="s4">Parent Communication</td>
        <td class="s4">To provide the service listed in the purpose column. Sent privately to parents after the session being described comes to an end.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Comments on tutoring sessions</td>
        <td class="s4">Parents</td>
        <td class="s4">User Directly</td>
        <td class="s4">Parent feedback about the quality of the service</td>
        <td class="s4">To provide the service listed in the purpose column. Sent privately to tutors after the sessions reviewed.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s8" colspan="12">Academic Data and Academic Assessment Data:</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Academic achievement status and/or grade level</td>
        <td class="s4">Learner (provided by parent) </td>
        <td class="s4">User Directly</td>
        <td class="s4">Grade level used to pair them with the right tutor</td>
        <td class="s4">To provide the service listed in the purpose column. Sent privately to tutors after the sessions reviewed.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Intercom, OpenPhone, Zoom, Twilio, SendGrid, Blueshift, Typeform, Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Any other assessment data</td>
        <td class="s4">Learner (provided by parent) -- parent provides a qualitative description of how their child (the learner) is doing and where they need the most help. Information is used by Tutor to plan their tutoring session.</td>
        <td class="s4">User Directly</td>
        <td class="s4">Help Tutor understand where the kid needs the most help and prepare their session accordingly.</td>
        <td class="s4">To provide the service listed in the purpose column and Product research.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" dir="ltr" colspan="12">Tutoring Session Related Data </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Learner tutoring subject areas </td>
        <td class="s4">Learner (provided by parent) </td>
        <td class="s4">User Directly</td>
        <td class="s4">Pair learner with the best tutor.</td>
        <td class="s4">To provide the service listed in the purpose column. Product Analytics, segmenting data for product improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Typeform</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Learner tutoring attendance </td>
        <td class="s4">Learner (captured automatically)</td>
        <td class="s4">Zoom API</td>
        <td class="s4">Share with parent as part of the learner&#39;s performance</td>
        <td class="s4">To provide the service listed in the purpose column. Product Analytics, segmenting data for product improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s9">Learner Tutoring Assessments </td>
        <td class="s4">Tutor provides assessments of the learners&#39;s performance during the tutoring session and shares with parents through the platform (we store the data)</td>
        <td class="s4">User Directly</td>
        <td class="s4">Provide parent with feedback </td>
        <td class="s4">To provide the service listed in the purpose column and Product research.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s10" colspan="12">Tutor Application Data</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Tutor names</td>
        <td class="s4">Tutor</td>
        <td class="s4">User Directly</td>
        <td class="s4">Personalize the user experience in platform</td>
        <td class="s4">To provide the service listed in the purpose column and Product research</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Intercom, OpenPhone, Zoom, Twilio, SendGrid, Blueshift, Typeform, Amazon Web Services, Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4" dir="ltr">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3" dir="ltr">Tutor&#39;s teaching experience</td>
        <td class="s4" dir="ltr">Tutor</td>
        <td class="s4" dir="ltr">User Directly through specific questions (years of experience, grades/topics they can teach, prior tutoring experiences) and free form questions where they can expand on anything they think is relevant for their application.</td>
        <td class="s4" dir="ltr">To evaluate how qualified the teacher is to tutor students on Dojo Tutoring. </td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Typeform, Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 119px">
        <td class="s3" dir="ltr">Tutor Address</td>
        <td class="s4" dir="ltr">Tutor</td>
        <td class="s4" dir="ltr">User Directly through Deel&#39;s interface</td>
        <td class="s4" dir="ltr">We include the Tutor&#39;s address in the contracting agreement we sign with Tutors. Address in required to send any relevant information related to the contract.</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4" dir="ltr">In Deel&#39;s servers, we do not store it in our servers but have access through Deel&#39;s interface.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Deel, Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 119px">
        <td class="s3" dir="ltr">Tutor application Video</td>
        <td class="s4" dir="ltr">Tutor</td>
        <td class="s4" dir="ltr">User submits</td>
        <td class="s4" dir="ltr">To evaluate how qualified the teacher is to tutor students on Dojo Tutoring. </td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4"></td>
        <td class="s4" dir="ltr"></td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr"></td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" dir="ltr" colspan="12">Background check related information (Tutors)</td>
      </tr>
      <tr style="height: 19px">
        <td class="s9" dir="ltr">Social Security Number</td>
        <td class="s4" dir="ltr">Tutor</td>
        <td class="s4" dir="ltr">User inputs Directly through Checkr&#39;s interface to perform the background check</td>
        <td class="s4" dir="ltr">To perform background checks to all of the tutors we onboard to our platform (with the consent of the tutor). Background checks are necessary to ensure that our learners will be safe around any tutor in Dojo Tutoring.</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4" dir="ltr">All data is stored in Checkr&#39;s servers, we do not store any of the data on our servers. However we have access to the last four digits through our login to Checkr.</td>
        <td class="s4" dir="ltr">Deleted when no longer needed. Tutors can choose to delete their data with Checkr, after the result of a background check is completed. </td>
        <td class="s4" dir="ltr">Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s9" dir="ltr">Electronic Signature</td>
        <td class="s4" dir="ltr">Tutor</td>
        <td class="s4" dir="ltr">User inputs directly on Checkr and Deel&#39;s interface to authorize contracts and background checks.</td>
        <td class="s4" dir="ltr">To sign contracts and perform background checks</td>
        <td class="s4" dir="ltr">Authorization</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4" dir="ltr">All data is stored on Checkr and Deel&#39;s servers</td>
        <td class="s4" dir="ltr">Deleted when no longer needed. Tutors can choose to delete their data with Checkr, after the result of a background check is completed. </td>
        <td class="s4" dir="ltr">Checkr, Deel</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3" dir="ltr">Background Check Report</td>
        <td class="s4" dir="ltr">Tutor</td>
        <td class="s4" dir="ltr">To perform the background check, applicants share with Checkr their full name, phone number, e-mail address, date of birth, social security number, zip code and electronic signature. <br>Through the Checkr portal, we have access to this personal information (except social security numbers, we can only access the last 4 numbers) and the background check report information. This includes Sex Offender Search (sources include but are not limited to National Sex Offender Public Website and national and state sources), Global Watchlist Search (sources include but are not limited to Office Of Inspector General, European Union Consolidated List, Drug Enforcement Agency Fugitives, Government Sanction Databases, US Terrorist List), National Search (sources include but are not limited to Warrant sources, Department of Corrections sources, Arrest sources, Court sources), and County Searches (sources include but are not limited to County Courthouse records). </td>
        <td class="s4" dir="ltr">To perform background checks to all of the tutors we onboard to our platform (with the consent of the tutor). Background checks are necessary to ensure that our learners will be safe around any tutor in Dojo Tutoring.</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4" dir="ltr">All data is stored in Checkr&#39;s servers, we do not store any of the data on our servers. However we have access to the data obtained during the background check as listed in this row through our login to Checkr.</td>
        <td class="s4" dir="ltr">Deleted when no longer needed. Tutors can choose to delete their data with Checkr, after the result of a background check is completed. </td>
        <td class="s4" dir="ltr">Checkr</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" colspan="12">Survey Data </td>
      </tr>
      <tr style="height: 180px">
        <td class="s3">Survey Data – specify what fields are collected in surveys and if free form fields are included </td>
        <td class="s4">Parents (not collected from learners). Net Promote Scores (NPS), spending patterns, reasons why the chose to try Dojo Tutoring, reasons why they decided to withdraw from our service. <br>
          <br>
        </td>
        <td class="s4">User Directly</td>
        <td class="s4">Research surveys</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Net Promote Scores (NPS), spending patterns, reasons why the chose to try Dojo Tutoring, reasons why they decided to withdraw from our service. <br>
          <br>Parents provide this data.
        </td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Typeform</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s2" colspan="12">Automatically Collected Data or Device Data and/or Meta Data </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Device specific information (i.e. operating system, hardware version, device settings, etc.)</td>
        <td class="s4">Parent, Tutor, Learner</td>
        <td class="s4" dir="ltr">Zoom, Intercom</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Zoom, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3" dir="ltr">Hardware Connection Method information (i.e. Wifi, Camera, Microphone)</td>
        <td class="s4">Parent, Tutor, Learner</td>
        <td class="s4" dir="ltr">Zoom, Intercom</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Zoom, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Unique device IDs (e.g. Android ID, UDID, etc.</td>
        <td class="s4">Parent, Tutor, Leaner</td>
        <td class="s4" dir="ltr">Zoom, Intercom</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Zoom, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">IP Address (specify if logged in user or website visitor or otherwise obtained)</td>
        <td class="s4">Parent, Tutor, Leaner</td>
        <td class="s4" dir="ltr">Zoom, Intercom</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Zoom, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Browser Type of logged in user or website visitor</td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4" dir="ltr">Zoom, Intercom</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Zoom, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Access time of logged in user or website visitor</td>
        <td class="s4">Leaner, Parent, Tutor</td>
        <td class="s4">Database logs</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Time spent on site of logged in user or website visitor</td>
        <td class="s4">Learner Parent, Tutor</td>
        <td class="s4">Database logs</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Amazon Web Services</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Referring URLs</td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4" dir="ltr">Intercom</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Click paths of logged in user or website visitor </td>
        <td class="s4">Parent, Tutor</td>
        <td class="s4" dir="ltr">Intercom</td>
        <td class="s4" dir="ltr">Provide support to our users</td>
        <td class="s4">To provide the service listed in the purpose column and Product Improvement.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">Exchangeable Image File Format (EXIF)</td>
        <td class="s4">Tutor</td>
        <td class="s4" dir="ltr">When user uploads profile picture</td>
        <td class="s4" dir="ltr">Tutors upload images as Profile Pictures. Parents use the Profile Pictures to ensure that the teacher on the sessions is the same one they booked the session with.</td>
        <td class="s4" dir="ltr">To provide the service listed in the purpose column</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4" dir="ltr">Amazon Web Services, Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
      <tr style="height: 19px">
        <td class="s10" colspan="12">
          <span style="font-size:11pt;font-family:Times New Roman;font-weight:bold;color:#000000;">Other</span>
          <span style="font-size:11pt;font-family:Times New Roman;font-weight:bold;color:#000000;"></span>
          <span style="font-size:11pt;font-family:Times New Roman;font-weight:bold;color:#000000;">Categories</span>
        </td>
      </tr>
      <tr style="height: 19px">
        <td class="s3">User Support Tickets</td>
        <td class="s4">Parents, Tutors</td>
        <td class="s4">Directly by the user</td>
        <td class="s4">Customer Support</td>
        <td class="s4">To provide the service listed in the purpose column, and Identify the issue that the user is reporting and attempting to solve it.</td>
        <td class="s4">Encrypted in transit and at rest</td>
        <td class="s4">Mysql database on AWS. AWS servers are located within the US.</td>
        <td class="s4">Deleted when no longer needed</td>
        <td class="s4">Intercom</td>
        <td class="s4" dir="ltr">None</td>
        <td class="s4">No</td>
        <td class="s4">No</td>
      </tr>
    </tbody>
  </table>
</div>
`;

const Transparency = () => {
  // TODO: we should refactor this to avoid dangerouslySetInnerHTML
  return (
    <Box sx={{ maxWidth: "1400px", margin: "auto", padding: RAW_cssValue("20px") }}>
      <Box>
        <Text
          component="h1"
          sx={
            {
              fontSize: "50px",
              lineHeight: "100%",
              letterSpacing: "-0.5px",
              color: "#2C2A50",
            } as any
          }
        >
          Transparency
        </Text>
      </Box>

      <Text mb={28}>
        Below you can see what information we collect, why and how we collect it, where it’s stored, and how it’s
        different for each type of user on our platform.
      </Text>

      <Box sx={{ overflow: "auto" }} mb={28}>
        <Box dangerouslySetInnerHTML={{ __html: htmlCopy }} sx={{ display: "inline-block" }}></Box>
      </Box>
    </Box>
  );
};

export default Transparency;
