import { createStyles } from "@mantine/core";
import { NAVBAR_BREAKPOINT } from "old/src/UILibrary/constants";

export default createStyles(() => ({
  wrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    margin: "auto",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      padding: "24px",
    },

    h1: {
      fontSize: "24px",
      lineHeight: "30px",
      color: "#2C2A50",
      fontWeight: 700,
      margin: 0,
      marginBottom: "16px",
      [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },

    p: {
      fontSize: "18px !important",
      lineHeight: "28px !important",
      color: "#7174A0 !important",
      fontWeight: 400,
      margin: 0,
      marginBottom: "32px",
      [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
        marginBottom: "24px",
      },
    },
  },

  logo: {
    width: "312px",
    marginBottom: "40px",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      width: "240px",
      marginBottom: "24px",
    },
  },

  loginForm: {
    textAlign: "left",
    minWidth: "364px",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      minWidth: "auto",
      width: "100%",
    },
  },
}));
