import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { theme, BodyText, Button } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { sxClickableOption, sxAddPill, sxMoreButton } from "src/pageComponents/NewSingleClass/SharedStyles";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import CheckboxGroup from "src/components/CheckboxGroup";
import CheckboxGroupOption from "src/components/CheckboxGroup/CheckboxGroupOption";
import Field from "src/components/form/Field";
import SingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import { InputWithButton } from "@classdojo/web";
import { useState } from "react";
import { logTutorEvent } from "src/utils/log";
import { IconX } from "@web-monorepo/dds-icons";

export const interests = [
  { label: "👾 Minecraft", value: "minecraft" },
  { label: "🪩 Dance", value: "dance" },
  { label: "⚽️ Soccer", value: "soccer" },
  { label: "🏀 Basketball", value: "basketball" },
  { label: "🧞‍♂️ Roblox", value: "roblox" },
  { label: "🤸🏽 Gymnastics", value: "gymnastics" },
  { label: "🏈 Football", value: "football" },
  { label: "🎨 Art", value: "art" },
  { label: "🎮 Video games", value: "video_games" },
  { label: "🏊 Swimming", value: "swimming" },
];

const FormSchema = z.object({
  interests: z.array(z.string()).optional(),
});
type FormData = z.infer<typeof FormSchema>;

const AdditionalSingleSessionStepFour = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      interests: [],
    },
  });

  const [interestsList, setInterestsList] = useState([...interests]);

  useOnFirstRender(() => {
    if (state.booking) {
      const { interests } = state.booking;
      if (interests) form.setValue("interests", interests, { shouldValidate: true });
    }
  });

  useOnFirstRender(() => {
    logTutorEvent("parent.marketplace.retrial_child_interests_viewed");
  });

  const [newInterest, setNewInterest] = useState("");
  const [showInput, setShowInput] = useState(false);

  const addInterest = () => {
    const newInterestOption = {
      label: newInterest,
      value: newInterest.toLowerCase().replace(/\s+/g, "_"),
    };

    setInterestsList((prevInterests) => [...prevInterests, newInterestOption]);

    const currentInterests = form.getValues("interests") || [];
    form.setValue("interests", [...currentInterests, newInterestOption.value]);

    setNewInterest("");
    setShowInput(false);
  };

  const onInputChange = (value: string) => {
    setNewInterest(value);
  };

  const showInputField = () => {
    setShowInput(true);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 0);
  };

  const onNextStep: SubmitHandler<FormData> = async (values) => {
    logTutorEvent("parent.marketplace.retrial_child_interests_completed");
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state?.booking,
        interests: values.interests,
      },
    });
    push("/additional-single-class/step-five");
  };

  return (
    <SingleClassForm
      disabled={!form.formState.isValid}
      title={`What does ${state?.booking?.first_name_child ?? ""} like?`}
      subtitle="Select as many as you want"
      onSubmit={form.handleSubmit(onNextStep)}
      onBack={() => push("/additional-single-class/step-three")}
    >
      <div sx={sxFieldsWrapper}>
        <Field
          form={form}
          name="interests"
          render={({ value, onChange, error }) => (
            <div sx={sxField}>
              <CheckboxGroup
                sx={sxHorizontalRadioGroup}
                value={value || []}
                onChange={(value) => {
                  onChange(value);
                }}
              >
                {interestsList.map(({ value, label }, index) => (
                  <CheckboxGroupOption key={index} value={value} sxOption={sxClickableOption}>
                    {label}
                  </CheckboxGroupOption>
                ))}
                <button sx={sxMoreButton} onClick={showInputField} type="button">
                  + Add answer
                </button>
              </CheckboxGroup>

              {showInput && (
                <div sx={sxAddPill}>
                  <InputWithButton
                    inputProps={{
                      type: "text",
                      label: "",
                      value: newInterest,
                      onChange: onInputChange,
                      placeholder: "Type your own",
                    }}
                    button={{
                      text: "Add",
                      props: {
                        onClick: () => addInterest(),
                        "data-name": "add-accommodation-button",
                      },
                    }}
                    preventSubmission={true}
                  />
                  <Button
                    data-name="close-add-accomodation"
                    kind="secondary"
                    size="s"
                    icon={<IconX size="s" />}
                    sx={{ width: 42, height: 42, position: "absolute", right: -10, top: -10 }}
                    onClick={() => setShowInput(false)}
                  />
                </div>
              )}
              {error?.message && (
                <BodyText level={2} kind="danger">
                  {error.message}
                </BodyText>
              )}
            </div>
          )}
        />
      </div>
    </SingleClassForm>
  );
};

export default AdditionalSingleSessionStepFour;

const sxFieldsWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  marginTop: "dt_xl",
  marginBottom: "dt_xl",
  width: "100%",
  gap: "dt_xl",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: "0",
    gap: "dt_l",
  },
};

const sxField: ThemeUIStyleObject = { display: "flex", flexDirection: "column", gap: "dt_xs" };

const sxHorizontalRadioGroup: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "dt_s",
  width: "100%",
  justifyContent: "center",
};
