import { actionTypes } from "./actionTypes";
import { localStorage } from "@web-monorepo/safe-browser-storage";

export const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_BOOKING:
      localStorage.setItem("booking", JSON.stringify(action?.payload));
      return {
        ...state,
        booking: action.payload,
      };

    case actionTypes.REMOVE_BOOKING:
      return {
        ...state,
        booking: null,
      };

    case actionTypes.TOGGLE_BOOK_MODAL:
      return {
        ...state,
        bookModal: action.payload,
      };

    case actionTypes.SET_CHECKOUT_FUNNEL:
      if (action.payload) localStorage.setItem("funnel", JSON.stringify(action?.payload));
      else localStorage.removeItem("funnel");

      return {
        ...state,
        checkoutFunnel: action.payload,
      };

    case actionTypes.CLEAR_STORAGE:
      localStorage.removeItem("funnel");
      localStorage.removeItem("booking");
      return {
        ...state,
        booking: null,
        checkoutFunnel: null,
      };

    case actionTypes.DISABLE_EXPERIMENTS:
      localStorage.setItem("doNotRunExperiments", "true");
      return {
        ...state,
        doNotRunExperiments: true,
      };

    case actionTypes.ENABLE_EXPERIMENTS:
      localStorage.setItem("doNotRunExperiments", "false");
      return {
        ...state,
        doNotRunExperiments: false,
      };

    default:
      return state;
  }
};
