import { DDSButton } from "@web-monorepo/dds";
import css from "./TooManyDaysOff.module.css";
import { useDayOffFormStateContext } from "old/src/pageComponents/Teachers/Availability/useOOOForm";
import useHelperWidgetAPI from "src/hooks/useHelperWidgetAPI";
import { useSlideSheetContext } from "old/src/pageComponents/Teachers/Availability/OutOfOfficeSlideSheet";
import { useCallback } from "react";
import { useLogTutorEventOnFirstRender } from "src/utils/log";

const TooManyDaysOff = () => {
  const { goBack } = useDayOffFormStateContext();
  const { close: closeSlideSheet } = useSlideSheetContext();
  const { openWidget } = useHelperWidgetAPI();
  useLogTutorEventOnFirstRender("marketplace.tutor.day_off_too_long_warning_viewed");

  const contactSupport = useCallback(() => {
    closeSlideSheet();
    openWidget({ email: "", name: "" });
  }, [closeSlideSheet, openWidget]);

  return (
    <div className={css.wrapper}>
      <div className={css.title}>Are you trying to go on an extended leave?</div>
      <div className={css.description}>
        If you’d like to take more than 2 consecutive weeks of out-of-office time, please contact support.
      </div>
      <div className={css.buttonsWrapper}>
        <DDSButton onClick={goBack} variant="secondary" data-name="backButtonTooManyDaysOffWarning" width="fill">
          Go back
        </DDSButton>
        <DDSButton onClick={contactSupport} data-name="contactSupportTooManyDaysOffWarning" width="fill">
          Contact Support
        </DDSButton>
      </div>
    </div>
  );
};

export default TooManyDaysOff;
