import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/day_off"]["get"];
type Params = Endpoint["parameters"]["query"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export default (params: Params) => {
  return monolithApiClient.get<Response>(`/day_off`, { params });
};
