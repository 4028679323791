import RadioGroup from "./RadioGroup";
import RadioGroupOption from "./RadioGroup/RadioGroupOption";
import { TutorGrade } from "src/utils/constants";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";

export const NewGradeSelect = ({
  offeredGradesAndLabels,
  onChange,
  grade,
}: {
  offeredGradesAndLabels: { grade: TutorGrade; label: string }[];
  onChange: (value: TutorGrade) => void;
  grade: TutorGrade;
}) => {
  const largeGrades = offeredGradesAndLabels.filter(({ grade }) =>
    ["pre-kindergarten", "kindergarten"].includes(grade),
  );
  return (
    <div sx={sxWrapper}>
      <div sx={sxSubjectLabel}>Select current grade</div>
      <RadioGroup
        value={grade}
        onChange={(value) => {
          onChange(value);
        }}
      >
        <div sx={sxHorizontalRadioGroup}>
          {largeGrades.map(({ grade, label }, index) => (
            <div key={index} sx={{ ...sxLargeOption, ...(largeGrades.length === 1 ? { width: "100%" } : undefined) }}>
              <RadioGroupOption value={grade} sxOption={{ ...sxOption }}>
                <span sx={sxOptionLabel}>{label}</span>
              </RadioGroupOption>
            </div>
          ))}
        </div>
        <div sx={sxHorizontalRadioGroup}>
          {offeredGradesAndLabels
            .filter(({ grade }) => !largeGrades.map(({ grade }) => grade).includes(grade))
            .map(({ grade, label }, index) => (
              <div key={index} sx={sxSmallOption}>
                <RadioGroupOption value={grade} sxOption={{ ...sxOption }}>
                  <span sx={sxOptionLabel}>{label}</span>
                </RadioGroupOption>
              </div>
            ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default NewGradeSelect;

const sxWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_xs",
};

const sxLargeOption: ThemeUIStyleObject = {
  width: "47%",
  marginBottom: "dt_s",
  minWidth: "135px",
};

const sxSmallOption: ThemeUIStyleObject = {
  width: "30%",
  marginBottom: "dt_s",
  minWidth: "82px",
};

const sxHorizontalRadioGroup: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "center",
  gap: "5%",
};

const sxSubjectLabel: ThemeUIStyleObject = {
  padding: "dt_xs",
  fontSize: "18px",
  fontWeight: 700,
  color: "dt_content_primary",
  lineHeight: "18px",
};

export const sxOption: ThemeUIStyleObject = {
  border: "dt_card",
  height: 50,
  alignItems: "center",
  padding: "dt_m",
  paddingTop: "dt_s",
  paddingBottom: "dt_s",
  fontSize: "18px",
  fontWeight: 700,
  textAlign: "center",

  "div:first-of-type": {
    display: "none",
  },
  "&[data-checked='true']": {
    borderColor: "dt_content_accent",
  },
};

const sxOptionLabel: ThemeUIStyleObject = {
  color: "dt_content_secondary",
};
