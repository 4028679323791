import { useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles/Reschedule.styles";
import { Box, Text, BaseRadio, SelectInput, Button, ActionIcon, ArrowLeft, X, Progress } from "UILibrary";
import { HEADER_HEIGHT } from "src/constants";
import ErrorEnrollmentModal from "old/src/components/ErrorEnrollmentModal";
import { useClassByIdQuery, useParentMutation } from "old/src/hooks/useParent";
import { useScheduleSlotsQuery } from "old/src/hooks/useTeacher";
import { showErrorNotification } from "old/src/utils";
import { logTutorEvent } from "src/utils/log";
import { useSessionQuery } from "src/hooks/useSession";
import useWatch from "@classdojo/web/hooks/useWatch";
import { showSuccessNotification } from "src/utils/notification";
import marketplaceTheme from "src/utils/marketplaceTheme";
import { formatWithPattern, startOf, addDays, parse, parseWithPattern } from "src/utils/dates";

const ParentReschedule = () => {
  const { enrollmentId } = useParams();
  const push = useNavigate();
  const { classes: styles } = useStyles();
  const { classData } = useClassByIdQuery(enrollmentId);
  const { availableSlots } = useScheduleSlotsQuery(
    classData && {
      subject: classData.subject,
      grade: classData.grade,
    },
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [rescheduleStep, setRescheduleStep] = useState(1);
  const [selectedTime, setSelectedTime] = useState("");
  const { rescheduleClass } = useParentMutation();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [disableSubmitReschedule, setDisableSubmitReschedule] = useState(false);
  const [errorMessage] = useState({ title: "Error updating your class", message: "Try again or contact us please" });
  const { sessionData } = useSessionQuery();

  useWatch([classData], () => {
    const subscriptionId = classData?.subscriptionId;
    const sessionId = classData?.session?.sessionId;
    if (sessionId) {
      if (subscriptionId) {
        logTutorEvent("parent.marketplace.trial_class_reschedule.redirect_to_subscription_reschedule");
        push(`/parents/reschedule/subscription/session/${sessionId}`);
      } else {
        logTutorEvent("parent.marketplace.trial_class_reschedule.redirect_to_session_based_trial_reschedule");
        push(`/parents/reschedule/trial/session/${sessionId}`);
      }
    }
  });

  const handleSelectDate = (value: string) => {
    logTutorEvent("parent.marketplace.trial_class_reschedule.input_date");
    setRescheduleStep(2);
    setSelectedDate(value);
  };

  const backToSelectDate = () => {
    setRescheduleStep(1);
    setSelectedDate("");
  };

  const handleSelectTime = (value: string) => {
    logTutorEvent("parent.marketplace.trial_class_reschedule.input_time");
    setRescheduleStep(3);
    setSelectedTime(value);
  };

  const reschedule = async () => {
    logTutorEvent("parent.marketplace.trial_class_reschedule.datetime_step_completed");
    const ts = parseWithPattern(selectedTime, ["h:mm a"], parseWithPattern(selectedDate, ["EEEE, MMM dd"]));

    const enrollmentUid = enrollmentId;
    if (!enrollmentUid) {
      showErrorNotification("Error rescheduling class, not found identifier");
    }

    if (enrollmentUid == null) return;

    const uid = String(enrollmentUid?.slice(0, 24));

    rescheduleClass.mutate(
      { uid, ts: ts.toISOString() },
      {
        onSuccess: (data) => {
          push(`/parents/reschedule/${data.data.uid}`, { replace: true });
          push(`/parents/classes`);
          logTutorEvent("parent.marketplace.trial_class_reschedule.success");
          showSuccessNotification("Class rescheduled");
        },
        onError: (err: any) => {
          const errMessage = err?.response?.data?.error?.detail;
          if (errMessage == "ERR_TUTOR_CLASS_ALREADY_STARTED") {
            showErrorNotification(
              "Error rescheduling class",
              "Your class has already started. You cannot reschedule a started class",
            );
            setDisableSubmitReschedule(true);
          } else {
            showErrorNotification("Error rescheduling class");
            return Promise.reject(err);
          }
        },
      },
    );
  };

  const availableDays = useMemo(() => {
    let day = startOf(new Date(), "day");
    const availableSlotsTimes = availableSlots ? availableSlots.map((slot: string) => parse(slot)) : [];

    const days = Array.from({ length: 7 }, () => {
      day = addDays(day, 1);
      const nextDay = addDays(day, 1);
      const slots = availableSlotsTimes
        .filter((slot: Date) => slot >= day && slot < nextDay)
        .map((slot: Date) => formatWithPattern(slot, "hh:mm a"));
      return { day: formatWithPattern(day, "eeee, MMM dd"), slots };
    });

    return days;
  }, [availableSlots]);

  const renderDaysList = () => {
    return (
      <BaseRadio.Group
        onChange={handleSelectDate}
        styles={{
          label: { backgroundColor: "red", fontSize: "40px" },
          root: { color: "red" },
          error: { color: "red" },
          description: { color: "red" },
          required: { color: "red" },
        }}
      >
        {availableDays &&
          availableDays.map((day) => (
            <BaseRadio
              disabled={day.slots.length <= 0}
              value={day.day}
              key={day.day}
              label={day.day}
              styles={{
                body: {
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  alignContent: "space-around",
                  width: "100%",
                  height: "60px",
                  paddingRight: "18px",
                  borderRadius: "24px",
                },
                label: {
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                  letterSpacing: "-0.25px",
                  color: "#2C2A50",
                },
                radio: {
                  cursor: day.slots.length <= 0 ? "not-allowed" : "pointer",
                },
              }}
            />
          ))}
      </BaseRadio.Group>
    );
  };

  const renderSelectedDateCard = () => (
    <Box
      sx={
        {
          display: "flex",
          height: "60px",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "dt_background_accent",
          borderRadius: "24px",
          padding: "0px 18px",
          marginBottom: "20px",
        } as any
      }
    >
      <Text
        sx={
          {
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "22px",
            letterSpacing: "-0.25px",
            color: " #2C2A50",
          } as any
        }
      >
        {selectedDate}
      </Text>
      <ActionIcon radius={40} size={"lg"} color="primary" onClick={backToSelectDate}>
        <X size={20} />
      </ActionIcon>
    </Box>
  );

  if (classData?.status === "cancelled") {
    showErrorNotification("Error rescheduling class", "Your class was canceled");
    return <Navigate to="/parents/classes" />;
  }

  return (
    <Box
      className={styles.wrapper}
      sx={{
        minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
      }}
    >
      <Progress
        className={styles.progressBar}
        size="sm"
        color={marketplaceTheme.colors.dt_background_accent}
        value={rescheduleStep * 25}
      />
      <ActionIcon
        radius={40}
        size={"lg"}
        variant="light"
        onClick={() => push(`/parents/classes`)}
        className={styles.backButton}
      >
        <ArrowLeft size={24} color="#2C2A50" />
      </ActionIcon>

      <Box className={styles.content}>
        <Box mb={20}>
          <Text className={styles.title}>Reschedule class</Text>
          <Box mb={18}>
            <Text className={styles.contentText}>
              Pick a new date and time. Note: Your child may be matched with a different tutor when you reschedule
            </Text>
          </Box>

          {selectedDate ? renderSelectedDateCard() : renderDaysList()}

          {rescheduleStep > 1 && (
            // Select time
            <Box mb={24}>
              <SelectInput
                sx={{ marginBottom: "6px" } as any}
                onChange={handleSelectTime}
                inputStyles={
                  {
                    "&:hover": {
                      cursor: "pointer",
                    },
                  } as any
                }
                label="Select time"
                data={
                  availableDays && availableDays.findIndex((d) => d.day === selectedDate) !== -1
                    ? availableDays[availableDays.findIndex((d) => d.day === selectedDate)].slots
                    : []
                }
              />
              <Text mt={4} sx={{ color: "#7174A0", fontSize: "15px", lineHeight: "18px" } as any}>
                {sessionData?.user_details.timezone.replace("_", " ")} Time
              </Text>
            </Box>
          )}

          {rescheduleStep > 2 && (
            <Button
              loading={rescheduleClass.isLoading}
              fullWidth
              onClick={() => reschedule()}
              disabled={disableSubmitReschedule}
              sx={
                {
                  height: "60px",
                  fontSize: "18px",
                  padding: "19px 24px",
                  fontWeight: 700,
                } as any
              }
            >
              Reschedule
            </Button>
          )}
        </Box>
      </Box>
      <ErrorEnrollmentModal
        opened={errorModalOpen}
        title={errorMessage.title}
        message={errorMessage.message}
        onClose={() => {
          setErrorModalOpen(false);
        }}
        onClickButton={() => {
          push("/parents/classes");
        }}
      />
    </Box>
  );
};

export default ParentReschedule;
