import { Select, SelectProps } from "@mantine/core";
import React from "react";
import marketplaceTheme from "src/utils/marketplaceTheme";

const RawSelect: React.FC<SelectProps> = (props) => {
  return (
    // @ts-expect-error - styles error
    <Select
      {...props}
      styles={() => ({
        input: {
          height: "64px",
          padding: "20px 16px",
          border: "2px solid #D3D7EC",
          borderRadius: "16px",
          color: "#2C2A50",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "24px",
          paddingRight: "12px",
          cursor: "pointer",
          "::placeholder": {
            color: "#AAB0D8",
          },
          ":hover": {
            borderColor: "#7174A0",
          },
          ":active": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":focus": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
        },
        dropdown: {
          borderRadius: "24px",
        },
        item: {
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "24px",
        },
      })}
    />
  );
};

export default RawSelect;
