import { forwardRef } from "react";
import { IconSearchPlain } from "@web-monorepo/dds-icons";
import { useForwardedOrLocalRef, useUniqueIds } from "../../shared/hooks";
import { ClearButton } from "./components";
import type { BaseProps } from "./types";
import styles from "./DDSSearchField.module.css";

export const Sizes = ["small", "medium", "large"] as const;
export const Variant = "default";

export interface Props extends BaseProps {
  size: (typeof Sizes)[number];
  placeholder?: string;
  label?: string;
}

/**
 * Docs:
 * - {@link https://www.figma.com/design/erhFTAWNMugPVrJGCAWsse/%E2%9D%96-Dojo-Design-System-%E2%80%93-DDS?node-id=8511-18914&m=dev Figma}
 * - {@link https://components.classdojo.com/?path=/story/components-ddssearchfield--default-variant&args=size Storybook}
 */
export const DDSSearchField = forwardRef<HTMLInputElement, Props>(
  (
    { ["data-name"]: dataName, disabled, id, label = "Search", size = "medium", placeholder = "Search", ...rest },
    forwardedRef,
  ) => {
    const ref = useForwardedOrLocalRef(forwardedRef);
    const [iconId, inputId] = useUniqueIds(["DDSSearchField.icon", "DDSSearchField.input"]);

    const className = [styles.searchField, styles[size]].join(" ");

    return (
      <label className={className}>
        <IconSearchPlain size="m" aria-label={label} id={iconId} />
        <input
          aria-labelledby={iconId}
          className={styles.input}
          data-name={dataName}
          disabled={disabled}
          id={id ?? inputId}
          placeholder={placeholder}
          ref={ref}
          {...rest}
        />
        <ClearButton className={styles.clearButton} forwardedRef={ref} />
      </label>
    );
  },
);
