import { showNotification } from "@mantine/notifications";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import { useNavigate } from "react-router-dom";

export const isBrowser = () => typeof window !== "undefined";

export const showErrorNotification = (title?: string, message?: string) => {
  showNotification({
    title: title || "Something went wrong!",
    message: message || "An error has occured. Please try again in a few minutes or you may send us a message.",
    color: "red",
  });
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const urlToFile = (url: string, filename: string) => {
  const mimeType = (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const useSubscribe = () => {
  const [state, dispatch] = useGlobalStore();
  const push = useNavigate();

  const subscribeParent = (data: any) => {
    const subscription = {
      first_name_child: data?.childName,
      first_name_parent: data?.student?.first_name,
      grade: data?.grade,
      last_name_parent: data?.student?.last_name,
      subject: data?.subject,
      product: "",
      subscription: {},
      teacher: data?.teacher,
      type: "subscription",
      assessment: data?.assessment,
      subscriptionClassId: state?.booking?.subscriptionClassId,
    };

    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: subscription,
    });

    push(`/subscription/booking/class/${data.classId}/redirect-from-class`);
  };

  return { subscribeParent };
};

export const validatePasswordAndReturnErrorMessage = (password: string) => {
  if (!password) {
    return "Password is required.";
  }
  if (password.length < 8) {
    return "Password should have more than 8 characters.";
  }
  if (password.length > 32) {
    return "Password should not exceed 32 characters.";
  }
  return null;
};
