import { theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { useState } from "react";
import LoggedOutHeaderContents from "old/src/components/LoggedOutHeaderContents";
import MobileNav from "old/src/components/MobileNav";
import ParentHeaderContents from "old/src/components/ParentHeaderContents";
import PrivateHeaderContents from "old/src/components/PrivateHeaderContents";
import { HEADER_HEIGHT } from "src/constants";
import { useSessionQuery } from "src/hooks/useSession";

const Header = () => {
  const [navbarOpened, setNavbarOpened] = useState(false);
  const { sessionData } = useSessionQuery();

  const role = sessionData?.roles?.includes("teacher")
    ? "teacher"
    : sessionData?.roles?.includes("parent")
      ? "parent"
      : "none";

  return (
    <>
      <div sx={sxHeaderWrapper}>
        <div sx={sxHeaderContents}>
          {role === "teacher" && (
            <PrivateHeaderContents navbarOpened={navbarOpened} toggleNavbar={() => setNavbarOpened((o) => !o)} />
          )}
          {role === "parent" && (
            <ParentHeaderContents navbarOpened={navbarOpened} toggleNavbar={() => setNavbarOpened((o) => !o)} />
          )}
          {role === "none" && (
            <LoggedOutHeaderContents navbarOpened={navbarOpened} toggleNavbar={() => setNavbarOpened((o) => !o)} />
          )}
        </div>
      </div>
      <MobileNav opened={navbarOpened} onClose={() => setNavbarOpened(false)} />
    </>
  );
};

export default Header;

const sxHeaderWrapper: ThemeUIStyleObject = {
  height: HEADER_HEIGHT,
  zIndex: 99,
  backgroundColor: "dt_background_primary",
  borderBottom: "dt_divider",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const sxHeaderContents: ThemeUIStyleObject = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: theme.breakpoints.xl,
  position: "relative",
  padding: 0,
  paddingLeft: "dt_m",
  paddingRight: "dt_m",
  height: "100%",

  [`@media (max-width: ${theme.breakpoints.s}px)`]: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "dt_l",
    paddingRight: "dt_l",
  },
};
