// Copied from api repo 5/1/2024
const regex = /^(?=\S{3,128}$)[^\s\\;,:@]+@[^\s\\;,:.@][^\s\\;,:@]*\.[^\s\\;,:\d@]+$/;

const badChars = '`=<>()!/?#,[]|"\\ğı';
// some nice notes here:
// https://www.jochentopf.com/email/chars.html

const testLocal = (local: string): boolean => {
  if (local[0] === ".") {
    return false; // local can't start with a dot
  }
  if (local.endsWith(".")) {
    return false; // local can't end with a dot
  }
  // eslint-disable-next-line no-control-regex
  if (/[^\x00-\x7F]/.test(local)) {
    return false; // match non ascii chars (rejected by sendgrid, even if gmail and others accept diacritics)
  }

  return true;
};

const testDomain = (domain: string): boolean => {
  /*
  From RFC952:
  A "name" (Net, Host, Gateway, or Domain name)
  is a text string up to 24 characters drawn from
  the alphabet (A-Z), digits (0-9), minus sign (-),
  and period (.). Note that periods are only allowed
  when they serve to delimit components of "domain style names".
  */
  if (!/^[A-Z\d\-.]*$/i.test(domain)) {
    return false;
  }
  const lastChar = domain[domain.length - 1];
  if (lastChar === ".") {
    // last char can't be a dot
    return false;
  }
  if (domain.indexOf(".") === -1) {
    return false;
  }
  return true;
};

export const test = (address: string): boolean => {
  if (address.includes("@@")) {
    return false;
  }
  if (address.includes("..")) {
    return false;
  }
  for (const char of badChars) {
    if (address.includes(char)) {
      return false;
    }
  }
  if (!regex.test(address)) {
    return false;
  }
  const [local, domain] = address.split("@");
  if (!testLocal(local)) {
    return false;
  }
  if (!testDomain(domain)) {
    return false;
  }
  return true;
};
