import { endOfMonth, getWeeksInMonth } from "@internationalized/date";
import { useCalendarGrid } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { type RangeCalendarState } from "@react-stately/calendar";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { CalendarCell } from "./CalendarCell";
import css from "./CalendarGrid.module.css";

type CalendarGridProps = {
  offset?: { months: number };
  state: RangeCalendarState;
  onDateSelected: (date: Date) => void;
};

export function CalendarGrid({ state, offset = { months: 0 }, onDateSelected, ...props }: CalendarGridProps) {
  const { locale } = useLocale();
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      ...props,
      startDate,
      endDate,
      weekdayStyle: "short",
    },
    state,
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(startDate, locale);

  return (
    <table {...gridProps} cellPadding={0} sx={gridStyles} cellSpacing={0}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>
              <div className={css.weekday}>{day}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i: number) =>
                date ? (
                  <CalendarCell
                    onDateSelected={onDateSelected}
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const gridStyles: ThemeUIStyleObject = {
  width: "100%",
  marginTop: "12px",

  "tbody:before": {
    lineHeight: "12px",
    content: '"."',
    color: "dt_content_light",
    backgroundColor: "dt_background_primary",
    display: "block",
  },
};
