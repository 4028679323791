import { BodyText, Button, LabelText, HeadlineText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { FormEventHandler, useCallback, useState } from "react";
import { useParentMutation } from "old/src/hooks/useParent";
import { logTutorEvent } from "src/utils/log";

type Props = {
  childName: string;
  liked?: boolean;
  onSubmit: (liked: boolean) => void;
  token: string;
  tutorName: string;
  classId: string;
  teacherId: string;
};

const SurveyForm = ({ childName, liked, classId, teacherId, onSubmit, token, tutorName }: Props) => {
  const [likedOverall, setLikedOverall] = useState(liked);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { reviewClass } = useParentMutation();

  const onFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      setDisabled(true);
      if (likedOverall == null) return;
      reviewClass.mutateAsync({ feedbackComment, token, likedOverall }, { onSuccess: () => onSubmit(likedOverall) });
    },
    [feedbackComment, onSubmit, likedOverall, reviewClass, token],
  );

  const onLikedOverall = useCallback(
    (value: boolean) => {
      setLikedOverall(value);
      logTutorEvent({
        eventName: `tutor.review.${value ? "thumbs_up.pressed" : "thumbs_down.pressed"}`,
        eventValue: classId,
        metadata: {
          teacherId,
        },
      });
    },
    [setLikedOverall, classId, teacherId],
  );

  const hasEnteredRating = likedOverall != null;

  return (
    <form sx={sxWrapper} onSubmit={onFormSubmit}>
      <HeadlineText as="h2">
        Rate {childName}'s class with {tutorName}
      </HeadlineText>
      <BodyText>Your feedback helps us get even better!</BodyText>
      <div sx={sxRatingWrapper}>
        <button
          sx={sxRatingButton}
          type="button"
          onClick={() => onLikedOverall(true)}
          data-selected={likedOverall === true}
          data-unselected={likedOverall === false}
        >
          <img sx={sxThumbsUp} src="parents/review/thumbs-up.svg" alt="Thumbs up" />
        </button>
        <button
          sx={sxRatingButton}
          type="button"
          onClick={() => onLikedOverall(false)}
          data-selected={likedOverall === false}
          data-unselected={likedOverall === true}
        >
          <img sx={sxThumbsDown} src="parents/review/thumbs-up.svg" alt="Thumbs down" />
        </button>
      </div>
      {hasEnteredRating && (
        <>
          <LabelText>
            {likedOverall === true && <>What made this class special?</>}
            {likedOverall === false && <>How can we improve?</>}
          </LabelText>
          <textarea
            sx={sxTextarea}
            placeholder="Add your feedback"
            onChange={(e) => setFeedbackComment(e.target.value)}
          />
        </>
      )}
      <Button type="submit" disabled={!hasEnteredRating || disabled} data-name="survey_form:submit_review">
        Submit review
      </Button>
    </form>
  );
};

export default SurveyForm;

const sxWrapper: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  gap: "dt_l",
  textAlign: "center",
};

const sxRatingWrapper: ThemeUIStyleObject = {
  display: "flex",
  gap: "dt_l",
};

const sxRatingButton: ThemeUIStyleObject = {
  borderRadius: "dt_radius_s",
  border: "dt_card",
  width: 68,
  height: 68,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&[data-selected=true]": {
    borderColor: "dt_border_active",
    color: "dt_content_accent",
  },

  "&[data-unselected=true]": {
    opacity: 0.6,
  },
};

const sxTextarea: ThemeUIStyleObject = {
  borderRadius: "dt_radius_s",
  border: "dt_card",
  height: 180,
  width: 325,
  resize: "none",
  padding: "dt_l",

  "&::placeholder": {
    color: "dt_content_tertiary",
  },
};

const sxThumbsUp: ThemeUIStyleObject = { width: 32, display: "block" };

const sxThumbsDown: ThemeUIStyleObject = { ...sxThumbsUp, transform: "rotate(180deg)" };
