import { useSearchParams } from "react-router-dom";
import { LoadingMojo } from "@classdojo/web";
import useEventMutation from "src/hooks/useEventMutation";
import { logTutorEvent } from "src/utils/log";
import { fountainSources } from "src/utils/fountainSources";
import { useCallback } from "react";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";

const TutorApplicationRedirect = () => {
  const [searchParams] = useSearchParams();
  const eventMutation = useEventMutation();

  const sendBlueshiftEvent = useCallback(
    (email: string, utmCampaign: string, callback: () => void) => {
      eventMutation.mutate(
        {
          event: "dojo-tutoring-tutor-application-start",
          email,
          campaign: utmCampaign,
        },
        {
          onSuccess: callback,
        },
      );
    },
    [eventMutation],
  );

  useOnFirstRender(() => {
    {
      const utmCampaign = searchParams.get("utm_campaign") ?? "";
      const email = searchParams.get("email");
      const utmSource = searchParams.get("utm_source") ?? "";
      const entityId = searchParams.get("entity_id");
      const metadata = {
        utm_campaign: utmCampaign,
        utm_source: utmSource,
      };

      logTutorEvent({
        eventName: "redirect_to_tutor_application_page",
        ...(entityId ? { entityId } : undefined),
        metadata,
      });

      const fountainUrl = `https://us-2.fountain.com/classdojo/apply/dojo-tutor-application?utm_source=${
        fountainSources[utmSource] ?? encodeURIComponent(utmSource)
      }`;

      const redirectToApplicationForm = () => {
        window.location.href = fountainUrl;
      };

      if (email) {
        sendBlueshiftEvent(email, utmCampaign, redirectToApplicationForm);
      } else {
        redirectToApplicationForm();
      }
    }
  });

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40px",
      }}
    >
      <LoadingMojo border />
    </div>
  );
};

export default TutorApplicationRedirect;
