import { Text, TextProps } from "@mantine/core";
import { memo } from "react";
import { getDefaultTimezone } from "src/utils/dates";

const TimezoneText = (props: Omit<TextProps, "children">) => {
  const timezone = getDefaultTimezone().replace("_", " ");

  return (
    <Text sx={{ color: "dt_content_tertiary", fontSize: "15px", lineHeight: "18px" } as any} {...props}>
      {timezone}
    </Text>
  );
};

export default memo(TimezoneText);
