import { useParams } from "react-router-dom";
import { Loader } from "UILibrary";
import { useTutorMeetingQuery } from "old/src/hooks/useTeacher";

const TutorMeeting = () => {
  const query = useParams();
  const { zoomMeetingURL, isError } = useTutorMeetingQuery(query.token);

  if (zoomMeetingURL) {
    window.location.href = zoomMeetingURL;
    return null;
  }

  if (isError) return null;
  return <Loader size="md" />;
};

export default TutorMeeting;
