import { IconXCircleFilled } from "@web-monorepo/dds-icons";
import { type FC, type RefObject, useCallback } from "react";

// in lieu of a useState, this component reassigns the ref value and manually dispatches an onChange signal
const getPropertySetter = () => Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value")?.set;
const setValueOnInput = (input: HTMLInputElement, value: string) => {
  const setter = getPropertySetter();
  if (!setter) return;

  // assigning to input.value isn't enough. You have to manually call the setter for the events to properly dispatch
  setter.call(input, value);
  input.dispatchEvent(new Event("input", { bubbles: true }));
};

const useOnClick = (ref: RefObject<HTMLInputElement>) => {
  return useCallback(() => {
    if (ref && "current" in ref && ref.current && "value" in ref.current) {
      const input = ref.current;
      if (!input) return;
      setValueOnInput(input, "");
    }
  }, [ref]);
};

export const ClearButton: FC<{ className: string; forwardedRef: RefObject<HTMLInputElement> }> = ({
  className,
  forwardedRef,
}) => {
  const onClick = useOnClick(forwardedRef);

  // TODO: translate the aria-label
  return (
    <button className={className} aria-label="clear" onClick={onClick}>
      <IconXCircleFilled />
    </button>
  );
};
