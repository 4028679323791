import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/tutor/class/{classId}"]["delete"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200];

export default ({ classId, ...body }: { classId: string } & Body) => {
  return monolithApiClient.delete<Response>(`/tutor/class/${classId}`, { data: body });
};
