// fix-onclick-on-icon
import * as logClient from "@classdojo/log-client";
import { TabsGroup } from "@classdojo/web";
import { HeadlineText, Space, Portal } from "@classdojo/web/nessie";
import capitalize from "lodash/capitalize";
import { createElement, useState, useCallback, useRef, useEffect } from "react";
import Dock from "react-dock";
import NewWindow from "react-new-window";
import { useMediaQuery } from "react-responsive";
import { EventLogHistorySectionContainer } from "./EventLogHistorySection";
import { FeatureSwitchesSectionContainer } from "./FeatureSwitchesSection";
import { DevToolsSettings, PANEL_DISPLAY_SIDES } from "./devToolsSettings";
import {
  IconArrowLeft,
  IconArrowRight,
  IconChevronLeft,
  IconChevronRight,
  IconX,
  IconExpand,
  IconCollapse,
} from "@web-monorepo/dds-icons";

type Section = {
  title: string;
  component: () => React.ReactElement;
};

// put the button to open the dev tools on top of main app ui elements
const OPEN_BUTTON_Z_INDEX = 200;
// put the dock on top of the dev tools open button. Need to be careful not to
// hide nessie's popup menus
const DOCK_Z_INDEX = 201;

/**
 * Defines the dev tools sections that are available when the panel is open
 * `title` will be the text displayed on the section button and `component` is the
 * component used to display the section contents
 */
const DevToolbarSections = {
  EventLogHistory: { title: "Logged Events", component: EventLogHistorySectionContainer },
  FeatureSwitches: { title: "Feature Switches", component: FeatureSwitchesSectionContainer },
};

type DevToolsProps = {
  additionalSections?: Record<string, Section>;
};

/**
 * Renders a button to open the dev tools on the bottom right of the app
 * Dev tools are displayed by default in `dev` environment, but can always be
 * toggled using the query param `dev-tools=true` or `dev-tools=false`, even in
 * production environment.
 * Same thing can be done with localStorage, using an entry with `dev-tools=true`
 * or `dev-tools=false`
 */
export const DevTools = (props: DevToolsProps) => {
  // tracks if dev tools should be available or not
  const { isDevToolsEnabled: shouldShowDevTools } = DevToolsSettings.useIsDevToolsEnabled();

  return shouldShowDevTools ? <DevToolsInternal {...props} /> : null;
};

const DevToolsInternal = ({ additionalSections }: DevToolsProps) => {
  // We have no way of telling if the user closed the new window or just
  // pressed the exit from new window option, since both will end up unloading
  // the new window and calling `closeDevToolbar`. However, if the user selected
  // exit from new window, we don't actually want to close the dev toolbar,
  // we just want to show it docked in the main window again.
  // This ref helps us keep track of the call sequence of the callbacks, so we
  // can do the right thing.
  const isClosingNewWindowRef = useRef(false);

  const incrementDevToolsOpenedMetric = useIncrementDevToolsOpenedMetric();

  // tracks if dev toolbar is open or closed
  const [isOpen, setIsOpen] = useState(false);
  const openDevToolbar = useCallback(() => {
    setIsOpen(true);
    incrementDevToolsOpenedMetric();
  }, [incrementDevToolsOpenedMetric]);
  const closeDevToolbar = useCallback(() => {
    if (isClosingNewWindowRef.current) {
      // user selected exit new window, reset ref so we can close
      // the dev toolbar next time this is called
      isClosingNewWindowRef.current = false;
    } else {
      setIsOpen(false);
    }
  }, []);

  // tracks if dev toolbar should display in a separate window or a side panel
  const { isNewWindow, setIsNewWindow } = DevToolsSettings.useIsNewWindow();
  const openNewWindow = useCallback(() => {
    setIsNewWindow(true);
  }, [setIsNewWindow]);
  const exitNewWindow = useCallback(() => {
    setIsNewWindow(false);
    // set ref value, so we don't close the dev toolbar on the next
    // call to `closeDevToolbar`
    isClosingNewWindowRef.current = true;
  }, [setIsNewWindow]);

  // tracks on which side of the page the dev toolbar panel is displaying
  const { panelDisplaySide, setPanelDisplaySide } = DevToolsSettings.usePanelDisplaySide();

  // The dev tools window loses the connection with the app if the page
  // is reloaded, so we listen for page unload event and close it to
  // prevent confusion with multiple old open dev tools windows
  const [isUnloadingPage, setIsUnloadingPage] = useState(false);
  useWindowUnloadEffect(useCallback(() => setIsUnloadingPage(true), []));

  // TODO: add visual indication that custom values are being used, for example,
  // when a feature switch value is changed in the dev tools

  return (
    <Portal>
      {!isOpen && <OpenDevToolbarButton onClick={openDevToolbar} displaySide={panelDisplaySide} />}
      <DevToolbar
        isOpen={isOpen && !isUnloadingPage}
        closeDevToolbar={closeDevToolbar}
        isNewWindow={isNewWindow}
        openNewWindow={openNewWindow}
        exitNewWindow={exitNewWindow}
        panelDisplaySide={panelDisplaySide}
        setPanelDisplaySide={setPanelDisplaySide}
        additionalSections={additionalSections}
      />
    </Portal>
  );
};

type OpenDevToolbarButtonProps = {
  onClick: VoidFunction;
  displaySide: string;
};

const OpenDevToolbarButton = ({ onClick, displaySide }: OpenDevToolbarButtonProps) => {
  const positionProps = displaySide === PANEL_DISPLAY_SIDES.Left ? { left: "10px" } : { right: "10px" };
  return (
    <div
      sx={{
        position: "fixed",
        bottom: "10px",
        zIndex: OPEN_BUTTON_Z_INDEX,
        padding: "dt_xs",
        backgroundColor: "dt_watermelon60",
        ...positionProps,
      }}
    >
      {displaySide === PANEL_DISPLAY_SIDES.Left ? (
        <IconChevronRight size="m" sx={{ cursor: "pointer" }} onClick={onClick} />
      ) : (
        <IconChevronLeft size="m" sx={{ cursor: "pointer" }} onClick={onClick} />
      )}
    </div>
  );
};

type DevToolbarProps = {
  isOpen: boolean;
  closeDevToolbar: VoidFunction;
  isNewWindow: boolean;
  openNewWindow: VoidFunction;
  exitNewWindow: VoidFunction;
  panelDisplaySide: string;
  setPanelDisplaySide: (side: string) => void;
  additionalSections?: Record<string, Section>;
};

const DevToolbar = ({
  isOpen,
  closeDevToolbar,
  isNewWindow,
  openNewWindow,
  exitNewWindow,
  panelDisplaySide,
  setPanelDisplaySide,
  additionalSections,
}: DevToolbarProps) => {
  const allSections = { ...DevToolbarSections, ...additionalSections };
  const [selectedSection, setSelectedSection] = useState<keyof typeof allSections>("EventLogHistory");

  // TODO: for now, since we are using a `window.prompt()` to accept custom values for
  // feature switches, we are keeping track of a window ref to use when we open
  // the dev toolbar in a new window, so that the prompt dialog opens on top of
  // the correct window.
  // If we change it to use something else, we probably don't need this anymore.
  const newWindowRef = useRef(window);
  const setNewWindowRef = useCallback((newWindow: Window & typeof globalThis) => {
    newWindowRef.current = newWindow;
  }, []);

  const tabIds: Record<any, any> = Object.keys(allSections).reduce(
    (acc: Record<any, any>, sectionKey: keyof typeof allSections) => {
      acc[sectionKey] = `${sectionKey}Tab`;
      return acc;
    },
    {},
  );
  const tabPanelIds: Record<any, any> = Object.keys(allSections).reduce(
    (acc: Record<any, any>, sectionKey: keyof typeof allSections) => {
      acc[sectionKey] = `${sectionKey}TabPanel`;
      return acc;
    },
    {},
  );

  const selectorOptions = Object.keys(allSections).map((sectionKey: keyof typeof allSections) => ({
    text: capitalize(allSections[sectionKey].title),
    value: sectionKey,
    id: tabIds[sectionKey],
    tabPanelId: tabPanelIds[sectionKey],
  }));

  if (!isOpen) return null;

  return (
    <DevToolbarWrapper
      isNewWindow={isNewWindow}
      isOpen={isOpen}
      closeDevToolbar={closeDevToolbar}
      panelDisplaySide={panelDisplaySide}
      setNewWindowRef={setNewWindowRef}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          backgroundColor: "dt_white",
          "* button[aria-selected=true]": {
            color: "dt_taro0",
          },
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "dt_s",
            paddingLeft: "dt_m",
            paddingRight: "dt_m",
            backgroundColor: "dt_taro20",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <div sx={{ flex: 1, position: "relative", "> span": { color: "dt_taro90" } }}>
            <HeadlineText as="h2" level={2}>
              ClassDojo Dev Toolbar
            </HeadlineText>
          </div>
          <div sx={{ display: "flex", flexDirection: "row", alignItems: "center", position: "relative" }}>
            {!isNewWindow &&
              (panelDisplaySide === PANEL_DISPLAY_SIDES.Left ? (
                <IconArrowRight
                  size="m"
                  sx={{ cursor: "pointer", color: "dt_taro90" }}
                  onClick={() => setPanelDisplaySide(PANEL_DISPLAY_SIDES.Right)}
                />
              ) : (
                <IconArrowLeft
                  size="m"
                  sx={{ cursor: "pointer", color: "dt_taro90" }}
                  onClick={() => setPanelDisplaySide(PANEL_DISPLAY_SIDES.Left)}
                />
              ))}
            {isNewWindow ? (
              <IconCollapse sx={{ cursor: "pointer", color: "dt_taro90" }} onClick={exitNewWindow} size="l" />
            ) : (
              <IconExpand sx={{ cursor: "pointer", color: "dt_taro90" }} onClick={openNewWindow} size="l" />
            )}
            <Space kind="inline" size="xs" />
            <IconX size="m" cursor="pointer" color="dt_taro90" onClick={closeDevToolbar} />
          </div>
        </div>
        <TabsGroup
          options={selectorOptions}
          value={selectedSection}
          onChange={(value) => setSelectedSection(value)}
          sx={{ margin: "dt_m" }}
          data-name="dev_tools"
        />
        <div
          role="tabpanel"
          id={tabPanelIds[selectedSection]}
          tabIndex={0}
          aria-labelledby={tabIds[selectedSection]}
          sx={{
            display: "flex",
            flex: 1,
            padding: "dt_s",
            paddingLeft: "dt_m",
            paddingRight: "dt_m",
            position: "relative",
          }}
        >
          {createElement(allSections[selectedSection].component, {
            // pass down the window object to use to the section component as props
            windowObject: isNewWindow ? newWindowRef.current : window,
          })}
        </div>
      </div>
    </DevToolbarWrapper>
  );
};

type DevToolbarWrapperProps = {
  isNewWindow: boolean;
  isOpen: boolean;
  closeDevToolbar: VoidFunction;
  panelDisplaySide: string;
  setNewWindowRef: (window: Window) => void;
};

const DevToolbarWrapper = ({
  isNewWindow,
  isOpen,
  closeDevToolbar,
  panelDisplaySide,
  setNewWindowRef,
  children,
}: React.PropsWithChildren<DevToolbarWrapperProps>) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  // only render panel contents if the Dock is visible to avoid unnecessary rendering
  // when the panel is not being shown
  return isNewWindow && isOpen ? (
    <NewWindow
      onOpen={(newWindow: Window) => setNewWindowRef(newWindow)}
      onUnload={closeDevToolbar}
      title="ClassDojo Dev Toolbar"
    >
      {children}
    </NewWindow>
  ) : (
    <Dock
      position={panelDisplaySide === PANEL_DISPLAY_SIDES.Left ? "left" : "right"}
      defaultSize={isTabletOrMobile ? 1 : undefined}
      fluid
      isVisible={isOpen}
      dimMode="none"
      zIndex={DOCK_Z_INDEX}
    >
      {children}
    </Dock>
  );
};

// helper hook for calling an event handler when the page unloads
const useWindowUnloadEffect = (handler: VoidFunction) => {
  const cb = useRef(handler);

  useEffect(() => {
    const handler = () => cb.current();
    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [cb]);
};

//
// Dev Toolbar metrics
//
const DEV_TOOLS_OPENED_METRIC = "web.devtools.opened";
const useIncrementDevToolsOpenedMetric = () => {
  const [hasSentMetric, setHasSentMetric] = useState(false);

  const sendMetric = useCallback(() => {
    if (!hasSentMetric) {
      setHasSentMetric(true);
      logClient.sendMetrics([
        {
          type: "increment",
          value: 1,
          metricName: DEV_TOOLS_OPENED_METRIC,
        },
      ]);
    }
  }, [hasSentMetric]);

  return sendMetric;
};
