const cookies = {
  sourceParentRedirectEmail: {
    name: "dojo-tutoring-parent-source-redirect-email",
    options: {
      expires: 60 * 60 * 24 * 30, // 30 days
    },
  },
  sourceTutorRedirectEmail: {
    name: "dojo-tutoring-tutor-source-redirect-email",
    options: {
      expires: 60 * 60 * 24 * 30, // 30 days
    },
  },
  sourceUtmCampaigns: {
    name: "dojo-tutoring-utm-campaigns",
    options: {
      expires: 60 * 60 * 24 * 365, // 365 days
    },
  },
  parentDataForStepOneSkipping: {
    name: "dojo-tutoring-parent-data-for-step-one-skipping",
    options: {
      expires: 60 * 60 * 24 * 30, // 30 days
    },
  },
  promoCode: {
    name: "dojo-tutoring-promo-code",
    options: {
      expires: 60 * 60 * 24 * 30, // 30 days
    },
  },
} as const;

export default cookies;
