import useMutation from "src/hooks/useMutation";
import { paths } from "@classdojo/ts-api-types/api";
import { useNavigate } from "react-router-dom";
import { monolithApiClient } from "src/configs/axios.config";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import { clearSessionQueryData } from "src/hooks/useSession";
import { setDefaultTimezone } from "src/utils/dates";
import { metrics } from "src/utils/metrics";

type Endpoint = paths["/api/marketplace/logout"]["post"];
type Response = Endpoint["responses"][200];

const logout = async () => {
  const response = await monolithApiClient.post<Response>(`/logout`);
  clearSessionQueryData();
  return response;
};

const useLogoutMutation = () => {
  const push = useNavigate();
  const [, dispatch] = useGlobalStore();

  return useMutation(logout, {
    onSuccess: () => {
      setDefaultTimezone(null);
      dispatch({ type: actionTypes.CLEAR_STORAGE });
      push("/");
    },
    onError: (error) => {
      metrics.increment("marketplace.logout.failure", { message: JSON.stringify(error) });
    },
  });
};

export default useLogoutMutation;
