import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/teacher/profile/{teacher}"]["get"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export type Teacher = Response;

export default function getTeacherPublicProfile(id: string) {
  return monolithApiClient.get<Response>(`/teacher/profile/${id}`);
}
