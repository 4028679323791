import { showNotification } from "@mantine/notifications";
import { loadStripe } from "@stripe/stripe-js";

const isWindowDefined = typeof window !== "undefined";

if (!isWindowDefined) throw new Error("This code must only run in the browser");

const { host } = window.location;
export const IS_TEST_SERVER = host.startsWith("tutor-test") || host.startsWith("tutor3");

const STRIPE_KEY =
  IS_TEST_SERVER || Config.nodeEnv !== "production" ? Config.stripeTestKey : Config.stripeProductionKey;

if (!STRIPE_KEY) throw new Error("Missing stripe key");

export const STRIPE_PROMISE = loadStripe(STRIPE_KEY)
  .catch(() => loadStripe(STRIPE_KEY))
  .catch((e) => {
    // Stripe doesn't provide any granularity over what error happened during loadStripe
    // other than an error message. "Failed to load Stripe.js" indicates that loadStripe
    // couldn't download the Stripe script and is likely a network issue. Any other errors
    // that occur during loadStripe are likely to be a Stripe issue and should be rethrown.
    if (e instanceof Error && e.message !== "Failed to load Stripe.js") throw e;
    showNotification({
      title: "Unexpected Network Connection Issue",
      message:
        "Usually this indicates low cellular or WiFi signal. Please try again in a few minutes or you may send us a message.",
      color: "red",
    });
    return null;
  });
