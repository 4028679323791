type FeatureSwitchName = `web_marketplace_${string}` | `api_marketplace_${string}`;

type LoggedOutSwitchMap = Record<
  FeatureSwitchName,
  {
    entityType: "parent" | "teacher";
    values: readonly ["off", "control", ...string[]] | readonly ["off", "on"];
  }
>;

type LoggedInSwitchMap = Record<
  FeatureSwitchName,
  {
    values: readonly ["off", "control", ...string[]] | readonly ["off", "on"];
  }
>;

export const LOGGED_OUT_SWITCH_MAP = {
  web_marketplace_trial_parent_agency_nov2024_v5: {
    entityType: "parent",
    values: ["off", "control", "variant1", "variant2"],
  },
  web_marketplace_trial_parent_agency_holdout: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_algolia_search: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_trial_tutor_specializations_nov2024: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
} as const satisfies LoggedOutSwitchMap;

export const LOGGED_IN_SWITCH_MAP = {
  web_marketplace_render_decagon_widget_june: {
    values: ["off", "control", "test"],
  },
  web_marketplace_booking_flow_dashboard_redirect_sep2024_v2: {
    values: ["off", "control", "test"],
  },
  web_marketplace_post_trial_booking_info_oct2024: {
    values: ["off", "control", "test"],
  },
  web_marketplace_ooo_oct2024: {
    values: ["off", "control", "test"],
  },
  web_marketplace_sub_funnel_prices_oct2024_v2: {
    values: ["off", "control", "variant1", "variant2"],
  },
} as const satisfies LoggedInSwitchMap;
