import { Link } from "react-router-dom";
import useStyles from "./styles/Footer.styles";
import { Box, Text } from "UILibrary";

type FooterProps = {
  includeLinks?: boolean;
  className?: string;
};

const Footer = ({ includeLinks = true, className }: FooterProps) => {
  const { classes } = useStyles();
  return (
    <Box className={`${classes.footerContainer} ${className}`} component="footer">
      {includeLinks && (
        <Box className={classes.footerLinks}>
          <Box className={classes.footerLinkContainer}>
            <Link to="/termsofservice">
              <Text className={classes.footerLink}>Terms of service</Text>
            </Link>
          </Box>
          <Box className={classes.footerLinkContainer}>
            <Link to="/privacy">
              <Text className={classes.footerLink}>Privacy Policy</Text>
            </Link>
          </Box>
          <Box className={classes.footerLinkContainer}>
            <Link to="/transparency">
              <Text className={classes.footerLink}>Notice at Collection</Text>
            </Link>
          </Box>
          <Box className={classes.footerLinkContainer}>
            {/* OneTrust Cookies Settings button start */}
            <Text className={classes.footerLink}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="ot-sdk-show-settings">Cookie Settings</a>
            </Text>
            {/* OneTrust Cookies Settings button end */}
          </Box>
        </Box>
      )}
      <Box className={classes.footerMain}>
        <Text className={classes.copyrightText}>© {new Date().getFullYear()} ClassDojo. All Rights Reserved.</Text>
      </Box>
    </Box>
  );
};

export default Footer;
