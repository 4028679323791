import { RAW_cssValue } from "@classdojo/web/nessie/stylingLib";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Text, Button, PasswordInput, Image } from "UILibrary";
import Logo from "old/src/components/Logo";
import useStyles from "old/src/pageComponents/Login/styles/Login.styles";
import useTriggerResetPasswordMutation from "src/hooks/api/auth/useTriggerResetPasswordMutation";
import { validatePasswordAndReturnErrorMessage } from "old/src/utils";

const CreatePassword = () => {
  const push = useNavigate();
  const query = useParams();
  const triggerResetPassword = useTriggerResetPasswordMutation();
  const { classes } = useStyles();
  const [isSuccess, setIsSuccess] = useState(false);

  const form = useForm({
    initialValues: {
      password: "",
    },

    validate: {
      password: validatePasswordAndReturnErrorMessage,
    },
  });

  const onSubmit = async (values: any) => {
    const payload = { ...values, token: query?.token };

    triggerResetPassword.mutate(payload, {
      onSuccess: () => {
        setIsSuccess(true);
      },
      onError: (error: any) => {
        form.setFieldError("email", error?.response?.data?.error.detail);
      },
    });
  };

  if (isSuccess) {
    return (
      <Box className={classes.wrapper}>
        <Logo className={classes.logo} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "dt_card",
            height: "438px",
            borderRadius: "dt_radius_s",
            boxShadow: RAW_cssValue("0px 2px 0px rgba(45, 64, 150, 0.06)"),
            background: "#FFF",
            padding: RAW_cssValue("0 80px"),
          }}
        >
          <Box
            sx={{
              maxWidth: "600px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Image width={160} src="cd-monster-one.svg" alt="ClassDojo Application Status" mb={24} />
            <Text
              sx={
                {
                  fontSize: "24px",
                  lineHeight: "30px",
                  color: "#2C2A50",
                  marginBottom: "8px",
                  fontWeight: 700,
                } as any
              }
            >
              Password successfully changed
            </Text>
            <Text
              sx={
                {
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#7174A0",
                  marginBottom: "dt_xl",
                  fontWeight: 400,
                } as any
              }
            >
              You can now login with your new password!
            </Text>
            <Button fullWidth onClick={() => push("/login")}>
              Log in
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.wrapper}>
      <Logo className={classes.logo} />
      <Text
        sx={{
          fontWeight: 700,
          fontSize: "24px",
        }}
      >
        Set your password
      </Text>
      <Text
        sx={
          {
            fontWeight: 400,
            fontSize: "18px",
            color: "#7174A0",
            margin: "8px auto 30px",
          } as any
        }
      >
        You will be able to login with your email ({query?.email}) and the password you set below. Your password must be
        at least 8 characters long.
      </Text>

      <Box className={classes.loginForm}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <PasswordInput
            sx={{ width: "100%", marginBottom: "dt_xl" }}
            placeholder="Password"
            {...form.getInputProps("password")}
          />
          <Button
            sx={{
              width: "100%",
              marginTop: 0,
              marginBottom: "dt_l",
              marginX: "auto",
            }}
            type="submit"
            loading={triggerResetPassword.isLoading}
            disabled={triggerResetPassword.isLoading}
          >
            Set password
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default CreatePassword;
