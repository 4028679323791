import { monolithApiClient } from "src/configs/axios.config";
import { paths } from "@classdojo/ts-api-types/api";

type Endpoint = paths["/api/marketplace/session"]["get"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export default class SessionAPI {
  static getSessionData() {
    return monolithApiClient.get<Response>("/session");
  }
}
