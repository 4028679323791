import { createCalendar } from "@internationalized/date";
import { useRangeCalendar } from "@react-aria/calendar";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useEffect, useRef } from "react";
import CalendarContent, { getCalendarDate } from "./calendar/CalendarContent";

function getRangeCalendarDate(dateRange: { start: Date | null; end: Date | null }) {
  const { start, end } = dateRange;
  if (start == null || end == null) return undefined;

  return { start: getCalendarDate(start), end: getCalendarDate(end) };
}

type DatePickerProps = {
  className?: string;
  maxDate?: Date;
  minDate?: Date;
  months?: number;
  value: { start: Date | null; end: Date | null };
  onClickOutside: () => void;
  onDateSelected: (date: Date) => void;
  selectingStart: boolean;
};

/**
 * Date picker. Wrapper around @react-aria/calendar with our custom flavor
 * for Class Dojo.
 */
const RangeCalendar = ({
  value,
  minDate,
  maxDate,
  className,
  months = 1,
  onClickOutside,
  onDateSelected,
  selectingStart,
  ...props
}: DatePickerProps): JSX.Element => {
  const localeInfo = new Intl.DateTimeFormat().resolvedOptions();

  const state = useRangeCalendarState({
    ...props,
    minValue: minDate ? getCalendarDate(minDate) : undefined,
    maxValue: maxDate ? getCalendarDate(maxDate) : undefined,
    value: getRangeCalendarDate(value),
    visibleDuration: { months },
    locale: localeInfo.locale,
    createCalendar,
    pageBehavior: "single",
  });

  const { anchorDate, setAnchorDate } = state;
  const { start } = value;

  useEffect(() => {
    if (start == null || selectingStart) return;
    const calendarDate = getCalendarDate(start);
    if (calendarDate.toString() === anchorDate?.toString()) return;

    setAnchorDate(calendarDate);
  }, [selectingStart, start, anchorDate, setAnchorDate]);

  const ref = useRef<HTMLDivElement>(null);
  const calendarProps = useRangeCalendar(props, state, ref);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node | null;
      if (ref?.current && !ref.current.contains(target)) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside]);

  return (
    <CalendarContent
      state={state}
      months={months}
      onDateSelected={onDateSelected}
      {...props}
      {...calendarProps}
      className={className}
      containerRef={ref}
    />
  );
};

export default RangeCalendar;
