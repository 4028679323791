import { Button, Box, Modal, Text } from "UILibrary";
import React from "react";

const ErrorEnrollmentModal: React.FC<{
  opened: boolean;
  onClose: any;
  title: string;
  message: string;
  onClickButton: any;
}> = ({ opened, onClose, title, message, onClickButton }) => {
  return (
    <Modal
      styles={{
        content: { borderRadius: "56px" },
        body: {
          padding: "12px 12px 8px",
        },
      }}
      size={500}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      centered
    >
      <Box>
        <Text
          sx={
            {
              fontSize: "24px",
              lineHeight: "30px",
              color: "#2C2A50",
              fontWeight: 700,
              margin: 0,
              marginBottom: "16px",
              textAlign: "center",
            } as any
          }
        >
          {title}
        </Text>
        <Box mb={24}>
          <Text>{message}</Text>
        </Box>

        <Box>
          <Button mb={12} sx={{ width: "100%" }} type="submit" color="purple" onClick={onClickButton}>
            Return to dashboard
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorEnrollmentModal;
