import { BodyText, LabelText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEventHandler, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePublicTeacherProfileQuery, useStripePaymentMethods } from "old/src/hooks/useParent";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import NewSingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import NewBookingDetailsSimplicity from "src/pageComponents/NewSingleClass/BookingDetailsSimplicity";
import useValidateAndAssignTutorMutation from "src/hooks/useValidateAndAssignTutorMutation";
import { showNotification } from "@mantine/notifications";
import RadioGroup from "src/components/RadioGroup";
import RadioGroupOption from "src/components/RadioGroup/RadioGroupOption";
import useWatch from "@classdojo/web/hooks/useWatch";
import { ParentClassData } from "src/api/parent/getClassById";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { logTutorEvent } from "src/utils/log";

const CARD_ICONS = {
  visa: "icons/visa-card.svg",
  diners: "icons/diners-card.svg",
  amex: "icons/amex-card.svg",
  discover: "icons/discover-card.svg",
  jcb: "icons/jcb-card.svg",
  unionpay: "icons/unionpay-card.svg",
  mastercard: "icons/mastercard-card.svg",
};

type PaymentMethod = {
  id: string;
  brand: keyof typeof CARD_ICONS;
  last4: string;
  default: boolean;
};

type CCPaymentProps = {
  classData: ParentClassData;
  clientSecret: string;
};

const CCPayment = ({ clientSecret, classData }: CCPaymentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [state, dispatch] = useGlobalStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const isStripeLoaded = Boolean(stripe && elements);
  const { teacher, isInitialLoading: isTeacherLoading } = usePublicTeacherProfileQuery(classData.teacher.uid);
  const isLoading = isTeacherLoading || isSubmitting || !isStripeLoaded;
  const push = useNavigate();

  const validateAndAssignTutorMutation = useValidateAndAssignTutorMutation();

  const { paymentMethods, isSuccess: paymentMethodsSuccess } = useStripePaymentMethods();

  const paymentWrapperRef = useRef<HTMLDivElement>(null);
  const scrollToError = () => {
    paymentWrapperRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useOnFirstRender(() => {
    logTutorEvent("parent.marketplace.retrial_checkout_paid_class_viewed");
  });

  const confirmPayment = async () => {
    if (!stripe || !elements) {
      setIsSubmitting(false);
      return;
    }
    logTutorEvent("parent.marketplace.retrial_checkout_paid_class_completed");
    if (paymentMethodId === "newCard") {
      const baseURL = window.location.pathname.includes("index.html")
        ? `${window.location.origin}${window.location.pathname}`
        : `${window.location.origin}/`;
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${baseURL}#/additional-single-class/booking/${classData.classId}/success`,
        },
      });
      if (result.error) {
        setStripeError(result.error.message || "");
        scrollToError();
      }
    } else {
      const result = await stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethodId });
      if (result.error) {
        showNotification({
          message: result.error.message,
          color: "red",
        });
      } else {
        push(`/additional-single-class/booking/${classData.classId}/success`);
      }
    }
    setIsSubmitting(false);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsSubmitting(true);

    validateAndAssignTutorMutation.mutate(
      { sessionId: classData.session.sessionId },
      {
        onSuccess: () => {
          dispatch({
            type: actionTypes.UPDATE_BOOKING,
            payload: {
              ...state.booking,
              isNewPaymentCard: paymentMethodId === "newCard",
            },
          });
          confirmPayment();
        },
        onError: () => {
          setIsSubmitting(false);
          showNotification({
            message: "We no longer have tutors at the selected time. Please select another time.",
            color: "#F2FBE7",
          });
          push(`/additional-single-class/step-six`);
        },
      },
    );
  };

  const sortedPaymentMethods = useMemo(() => {
    const methods = ((paymentMethods ?? []) as PaymentMethod[])
      .sort((method1, method2) => +method2.default - +method1.default)
      .map((method) => {
        return {
          id: method.id,
          imgSrc: CARD_ICONS[method.brand] ?? "icons/generic-card.svg",
          last4: method.last4,
        };
      });
    if (methods.length > 0) setPaymentMethodId(methods[0].id);
    return methods;
  }, [paymentMethods]);

  const showElementForm = (paymentMethodsSuccess && sortedPaymentMethods.length == 0) || paymentMethodId == "newCard";
  useWatch([paymentMethodsSuccess, sortedPaymentMethods], () => {
    if (paymentMethodsSuccess && sortedPaymentMethods.length == 0) {
      setPaymentMethodId("newCard");
    }
  });

  return (
    <NewSingleClassForm
      disabled={isLoading || !paymentMethodsSuccess || isSubmitting}
      onSubmit={onSubmit}
      onBack={() => push("/additional-single-class/step-six")}
      contentMaxWidth={480}
      fixedSubmitButton={false}
      buttonText="Schedule class"
    >
      {teacher && (
        <NewBookingDetailsSimplicity
          title={classData ? `Book ${classData.childName}’s trial class` : "Book their trial class"}
          teacher={teacher}
          isFetchingPromoCode={false}
        />
      )}
      <div sx={sxPaymentDetailsTitle}>
        <LabelText>Payment Details</LabelText>
      </div>
      {paymentMethodsSuccess && sortedPaymentMethods.length > 0 && (
        <RadioGroup
          sx={sxPaymentMethods}
          value={paymentMethodId}
          onChange={(value) => {
            setPaymentMethodId(value);
          }}
          dotPosition="right"
          aria-disabled={isSubmitting}
        >
          {sortedPaymentMethods.map(({ id, last4, imgSrc }, index) => (
            <RadioGroupOption value={id} key={index}>
              <div sx={sxPaymentMethod}>
                <div sx={sxCardBrand}>
                  <img src={imgSrc} sx={sxCreditCardImg} alt="card icon" />
                </div>
                <div sx={sxCreditCardDigits}>{last4}</div>
              </div>
            </RadioGroupOption>
          ))}
          <RadioGroupOption value="newCard">Add new card</RadioGroupOption>
        </RadioGroup>
      )}
      <div sx={{ ...sxPaymentElementWrapper, display: showElementForm ? "flex" : "none" }} ref={paymentWrapperRef}>
        <PaymentElement />
        {stripeError && stripeError !== "Your card number is incomplete." && (
          <BodyText level={2} kind="warning">
            {stripeError}
          </BodyText>
        )}
      </div>
    </NewSingleClassForm>
  );
};

export default CCPayment;

const sxPaymentElementWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_s",
  paddingTop: "dt_xl",
  paddingBottom: "dt_xl",
  width: "100%",
};

const sxCreditCardImg: ThemeUIStyleObject = {
  height: "18px",
  objectFit: "cover",
};

const sxCreditCardDigits: ThemeUIStyleObject = {
  fontWeight: "600",
  fontSize: "18px",
  height: "100%",
  letterSpacing: "-0.25px",
  color: "dt_content_primary",
  verticalAlign: "text-top",
  lineHeight: "18px",
  display: "flex",
  alignItems: "center",
};

const sxPaymentMethods: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_s",
  width: "100%",
  marginTop: "dt_s",
};

const sxPaymentMethod: ThemeUIStyleObject = {
  height: "100%",
  display: "flex",
  alignItems: "center",
};

const sxCardBrand: ThemeUIStyleObject = {
  width: "52px",
};

const sxPaymentDetailsTitle: ThemeUIStyleObject = {
  marginTop: "dt_xl",
  width: "100%",
};
