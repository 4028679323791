import { logStartExperiment, setFeatureSetId } from "@classdojo/log-client";
import isEqual from "lodash/isEqual";
import partition from "lodash/partition";
import { EndpointQueryParameters } from "../api/apiTypesHelper";
import { makeMemberQuery, NOOP, WAITING_FOR_DEPENDENCIES } from "../reactQuery";
import { MemberQueryType } from "../reactQuery/memberQuery";
import env from "../utils/env";
import { localStorage } from "@web-monorepo/safe-browser-storage";
import { ReactElement } from "react";

// @fetcher-revisit:
// - clear local storage featureSetIdLocalStorageKey value on user logout

let FEATURE_SET_ID_LOCALSTORAGE_KEY: string | null = null;

//
// featureSetId holds some logic in API that seemed confusing at first, lets try brief how we use it here:
//
// Every time we call API it will generate a featureSetId, for analytics purposes.
// The featureSetId is generated based on the query string params (except for featureSetId itself) and
// the response values for the switches. API will return this featureSetId in the response so we can store
// it and use it in latest calls.
// So for instance whenever we call /featureSwtiches?parentLocale=en&parentSwitches=sw1&featureSetId=123
// API will create the hash again and if the hash matches with the one we sent (featureSetId)
// then it won’t add a new record in the DB, if it does not then API will add a new record for later usage on
// analytics (that’s why we set it in the log client in our side).
// API will never end in a wrong state, since it’s always generating the feature set id based on
// the params (hashing), but we do send the id on subsequent calls just to avoid going to the DB for
// saving the record over and over on each call for the same user and config.
//
// So basically from our side every time we get an answer back from our call to feature switches end
// point we do two important things, we set the featureSetId in the log client and we store it
// in local storage (for using it in the future calls to the end point, which happens when loading the page).
// For simplicity we read only once from the local storage, when loading the APP and then we use that
// featureSetId for our call for the life cycle of the app (until the user reloads).
// We do this because we actually never call /featureSwitches end point after we load the app, so it
// does not matter to try read the latest value from local storage (this allowed us to simplify the
// logic and avoid extra fetches, due to fetchers default functionality with the cache).
// If in the future we want to make extra calls to the feature switches endpoint during the usage of
// the app, we need to make sure we read from local storage always the latest value.
// Worth mentioning that if the user changes anything that affects the query string (like locale) in
// that case we do perform a new call since we actually changed something that will affect the
// result and the featureSetId.
let storedFeatureSetId: string | undefined = undefined;

// The static config for the switches.
//  - useBuildFeatureSwitchesQueryParamsHook: Check the docs below for more details
//  - useFeatureSwitchOverridesHook: hook to load any feature switch override values to use
let featureSwitchesConfiguration: {
  useBuildFeatureSwitchesQueryParamsHook: () => Record<string, unknown> | null;
  useFeatureSwitchOverridesHook: () => Record<string, unknown> | null | undefined;
  featureSwitchesFetcherQueryParams: string[];
} | null = null;

/**
 * Sets the configuration for loading and using feature switches.
 * It should be called during application startup, before any of the exported feature switch hooks are used.
 *
 * @param {Object} params
 * @param {string} params.featureSetIdLocalStorageKey
 *   The name of the key in the local storage for storing the featureSetId (not too relevant what key is picked here
 *
 * @param {Function} params.useBuildFeatureSwitchesQueryParamsHook
 *   Every frontend has different needs for fetching switches. Some will fetch by school or by teacher or by session
 *   (logged out). Only the consumer frontend knows what dependencies are required and how to load them. We expect
 *   consumers to provide a hook that will resolve its dependencies and return the query string params value to be
 *   used when calling the  feature switches API endpoint.
 *
 *   We expect the consumer to provide a hook that depending on the data found in the app will return the
 *   query string params for fetching the switches. For example:
 *
 *   - If the user has a session/parent then `useBuildFeatureSwitchesQueryParamsHook` should return:
 *
 *     {
 *       parentId: "12345",
 *       parentLocale: "en-US",
 *       parentSwitches: "Switch1,Switch2"
 *     }
 *
 *   - If the user is logged out `useBuildFeatureSwitchesQueryParamsHook` should return:
 *
 *     {
 *       sessionId: "12345",
 *       sessionSwitches: "Switch1"
 *     }
 *
 *   - If app is still waiting on some data dependencies to load, it should return `null`
 *
 *   - If there are no feature switches to fetch, it should return `""` (empty string)
 *
 * @param {Function} [params.useFeatureSwitchOverridesHook]
 *   Optional hook that returns a key/value object with any feature switch values that should override the
 *   API returned values. Any value returned from the API that is not overriden will be kept.
 *
 * @param {string[]} [params.featureSwitchesFetcherQueryParams]
 *   Name or the query string parameters to be used by the featureSwitchesFetcher
 */
export function setFeatureSwitchesConfig({
  featureSetIdLocalStorageKey,
  useBuildFeatureSwitchesQueryParamsHook,
  useFeatureSwitchOverridesHook,
  featureSwitchesFetcherQueryParams,
}: {
  featureSetIdLocalStorageKey: string;
  useBuildFeatureSwitchesQueryParamsHook: () => Record<string, unknown> | null;
  useFeatureSwitchOverridesHook?: () => Record<string, unknown> | null | undefined;
  featureSwitchesFetcherQueryParams: string[];
}) {
  if (!useBuildFeatureSwitchesQueryParamsHook) {
    throw new Error("You must specify a function for `useBuildFeatureSwitchesQueryParamsHook` configuration option");
  }

  featureSwitchesConfiguration = {
    useBuildFeatureSwitchesQueryParamsHook,
    useFeatureSwitchOverridesHook: useFeatureSwitchOverridesHook || (() => undefined),
    featureSwitchesFetcherQueryParams,
  };
  FEATURE_SET_ID_LOCALSTORAGE_KEY = featureSetIdLocalStorageKey;

  // Sending empty string so we still fetch even if we don't have one
  storedFeatureSetId = localStorage.getItem(FEATURE_SET_ID_LOCALSTORAGE_KEY) || "";
}

// fetcher to make API call - it uses a `query` parameter which is a string
// containing all the serialized query string key/value parameters we need to send,
// in addition to `featureSetId`
let _useApiFeatureSwitchesFetcher: MemberQueryType<
  "/api/featureSwitch",
  { featureSetId: string } & EndpointQueryParameters<"/api/featureSwitch">
>;

//
// Public API
//

/**
 * Ensures all the configured feature switch values are fetched from the API.
 * You can use this hook to pre-load the cache on application startup.
 * It returns an object with `{ ready: bool, data: object }`
 */
export const useEnsureFeatureSwitches = () => {
  if (!featureSwitchesConfiguration) {
    throw new Error("You must call `setFeatureSwitchesConfig` to initialize feature switches first");
  }

  if (!_useApiFeatureSwitchesFetcher) {
    _useApiFeatureSwitchesFetcher = makeMemberQuery({
      fetcherName: "featureSwitches",
      path: "/api/featureSwitch",
      queryParams: ["featureSetId"].concat(featureSwitchesConfiguration.featureSwitchesFetcherQueryParams),
    });
  }
  const { useBuildFeatureSwitchesQueryParamsHook } = featureSwitchesConfiguration;

  // get query string send to API
  const queryStringParams = useBuildFeatureSwitchesQueryParamsHook();
  if (queryStringParams !== null && !(typeof queryStringParams === "object")) {
    // invalid type returned from useBuildFeatureSwitchesQueryParamsHook
    throw new Error(
      `\`useBuildFeatureSwitchesQueryParamsHook\` returned an invalid value type. ` +
        `Make sure it returns an object or \`null\` if app is still waiting for dependencies. ` +
        `return=[${JSON.stringify(queryStringParams)}]`,
    );
  }

  const isWaitingForDependencies = queryStringParams === null;
  const hasNoFeatureSwitches = queryStringParams !== null && Object.keys(queryStringParams).length === 0;

  const params = isWaitingForDependencies
    ? WAITING_FOR_DEPENDENCIES
    : hasNoFeatureSwitches
      ? NOOP
      : {
          featureSetId: storedFeatureSetId,
          ...queryStringParams,
        };
  const featureSwitchesResult = _useApiFeatureSwitchesFetcher(params);

  // @fetchers-revisit: add some logging to let developers know that feature switches
  // are refetching, which will cause the application component tree to be re-mounted,
  // and can cause some undesired behavior

  // in case there are no feature switches to load, we return empty object
  const data = hasNoFeatureSwitches ? {} : featureSwitchesResult.data;

  if (featureSwitchesResult.data?.featureSetId) {
    setFeatureSetId(featureSwitchesResult.data.featureSetId);

    if (FEATURE_SET_ID_LOCALSTORAGE_KEY) {
      localStorage.setItem(FEATURE_SET_ID_LOCALSTORAGE_KEY, featureSwitchesResult.data.featureSetId);
    }
  }

  return {
    data,
    ready: params === NOOP || (!featureSwitchesResult.isInitialLoading && !(params === WAITING_FOR_DEPENDENCIES)),
    error: featureSwitchesResult.error,
    isWaitingForDependencies,
  };
};

/**
 * Gets the values for all the feature switches, including any overridden values.
 *
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unnecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 */
declare global {
  interface Window {
    localSwitchOverrides?: Record<string, string>;
  }
}

/* Temporary solution to allow local overrides of feature switches to be cached,
 * should likely be removed once we integrate with a state management library (zustand, valtio, etc).
 */

let globalDataReference: ReturnType<typeof useEnsureFeatureSwitches>["data"] | undefined;
let globalOverrideReference: Record<string, string> | undefined;
let globalResolvedFeatureSwitchesCache: Record<string, string> | undefined;

const CONFLICTING_FEATURE_SWITCH_VARIANT_OVERRIDE = "conflicting_feature_switch_variant_override" as const;

export const useFetchedFeatureSwitchesWithOverrides = <T extends string>(): Record<T, string> => {
  const { data, ready, isWaitingForDependencies } = useEnsureFeatureSwitches();
  const useFeatureSwitchOverridesHook = featureSwitchesConfiguration?.useFeatureSwitchOverridesHook;
  const featureSwitchOverrides = useFeatureSwitchOverridesHook?.() || {};

  if (
    globalDataReference === data &&
    isEqual(globalOverrideReference, featureSwitchOverrides) &&
    globalResolvedFeatureSwitchesCache
  ) {
    return globalResolvedFeatureSwitchesCache;
  }

  if (!env.isProd && !ready && !isWaitingForDependencies) {
    // console.warn this instead of throwing an error because we were seeing some issues
    // when reloading feature switches, for example, when we change the teacher language multiple times.
    // This shouldn't happen anyway, since it is part of the general one time app setup,
    // so it is only intended as a dev helper so we don't forget to do it.
    // eslint-disable-next-line no-console
    console.warn(
      `Feature Switches are not loaded yet! Please call \`useEnsureFeatureSwitches()\` in ApplicationContainer.`,
    );
  }

  const defaultValue = {} as Record<T, string>;

  if (data) {
    // flattens all the feature switches returned by api into an object with a single level
    // So, if API returns: { parent: { switch1: "on" }, session: { switch2: "off" } }
    // allSwitches will be { switch1: "on", switch2: "off" }. This prefers values that are not "off"
    const allSwitches = Object.values(data).reduce<Record<T, string>>((switches, group) => {
      if (typeof group !== "object") return switches;

      // We do this to ensure that "off" switches are always overwritten with a value other than "off", if there are duplicates.
      // This is necessary because some switches are both school and entity level, and we want to prefer the user being in a
      // switch vs out of a switch.
      const [offSwitchesEntries, otherSwitchesEntries] = partition(
        Object.entries(group),
        ([, value]) => value === "off",
      );
      // TODO: it looks like these are either strings or objects. The "as" here seems to be covering up a real danger.
      const offSwitches = Object.fromEntries(offSwitchesEntries) as Record<T, string>;
      const otherSwitches = Object.fromEntries(otherSwitchesEntries) as Record<T, string>;

      // If a user has multiple variants for the same feature switch, we don't want to randomly select one
      // Here we mark a conflict, and after the reduce, resolve all conflicting variants to "off"
      for (const nonOffSwitch in otherSwitches) {
        if (
          switches &&
          switches[nonOffSwitch] &&
          switches[nonOffSwitch] !== "off" &&
          switches[nonOffSwitch] !== otherSwitches[nonOffSwitch]
        ) {
          otherSwitches[nonOffSwitch] = CONFLICTING_FEATURE_SWITCH_VARIANT_OVERRIDE;
        }
      }

      return { ...offSwitches, ...switches, ...otherSwitches };
    }, defaultValue);

    const allSwitchesWithConflictsRemoved = Object.fromEntries(
      Object.entries(allSwitches).map(([k, v]) => [k, v === CONFLICTING_FEATURE_SWITCH_VARIANT_OVERRIDE ? "off" : v]),
    ) as Record<T, string>;

    // apply any configures feature switch override values
    const allSwitchesWithOverrides = featureSwitchOverrides
      ? { ...allSwitchesWithConflictsRemoved, ...featureSwitchOverrides }
      : allSwitchesWithConflictsRemoved;

    // fetchers-revisit: we need to look into how to provide better support for testing
    // allow initial overrides to be set on window for Cypress tests
    const allSwitchesWithTestOverrides = window.localSwitchOverrides
      ? { ...allSwitchesWithOverrides, ...window.localSwitchOverrides }
      : allSwitchesWithOverrides;

    return (globalResolvedFeatureSwitchesCache = allSwitchesWithTestOverrides);
  }

  return (globalResolvedFeatureSwitchesCache = defaultValue);
};

/**
 * Gets the value for the specified feature switch.
 *
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 *
 * @param {string} switchName
 * @returns {string}
 */
export const useFetchedFeatureSwitch = (switchName: string) => {
  const data = useFetchedFeatureSwitchesWithOverrides<string>();

  return data && data[switchName] ? data[switchName] : "off";
};

/**
 * Checks if the specified feature switch is currently `on`.
 * The value for `switchName` must be the same as one of the values
 * specified in `featureSwitches` when calling `setFeatureSwitchesConfig`
 *
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 *
 * @param {string} switchName
 * @returns {boolean}
 */
export const useFetchedIsFeatureSwitchOn = (switchName: string) => {
  const switchValue = useFetchedFeatureSwitch(switchName);

  if (switchValue !== "off" && switchValue !== "on") {
    if (!env.isProd) {
      // eslint-disable-next-line no-console
      console.warn(
        `Called \`useFetchedIsFeatureSwitchOn()\` with a Feature Switch that is not an on/off value! ` +
          `switchName=[${switchName}] switchValue=[${switchValue}]`,
      );
    }
  }

  return switchValue === "on";
};

/**
 * Checks if the feature switch value matches the specified variant value.
 * The value for `switchName` must be the same as one of the values
 * specified in `featureSwitches` when calling `setFeatureSwitchesConfig`
 *
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 *
 * @param {string} switchName
 * @param {string} variantValue
 * @returns {boolean}
 */
export const useFetchedIsFeatureSwitchVariant = (switchName: string, variantValue: string) => {
  const switchValue = useFetchedFeatureSwitch(switchName);
  return switchValue === variantValue;
};

/**
 * Checks if the feature switch value matches the specified variant value.
 * The value for `switchName` must be the same as one of the values
 * specified in `featureSwitches` when calling `setFeatureSwitchesConfig`
 *
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 *
 * @param {string} switchName
 * @param {Array<string>} variantsValue
 * @returns {boolean}
 */
export const useFetchedIsFeatureSwitchInAnyVariant = (switchName: string, variantsValue: string[]) => {
  const switchValue = useFetchedFeatureSwitch(switchName);
  return variantsValue.some((variantValue) => switchValue === variantValue);
};

/**
 * Checks if the feature switch value matches by regextp the specified variant value.
 * This is a new addition to support "sub-variants", that is multiple varianst with the
 * same name, but with some subname that we are using internally for analytics.
 * The value for `switchName` must be the same as one of the values
 * specified in `featureSwitches` when calling `setFeatureSwitchesConfig`
 *
 * We use the `useFetched*` convention here. This means that by convention ApplicationContainer
 * will guarantee that whatever information this fetcher relies on, will be initialized
 * before rendering any children. The reasoning behind is that some info (like session,
 * userConfig, teacher, etc) should always be present the moment after we load the app.
 * By loading this information at initialization time, before rendering any screen, we can now
 * consume it without needing to add null checkers.
 * Is not an ideal solution but avoids unecessary null checks on fetchers that we always want to
 * be present after login. We might rework this with suspense in the short term.
 *
 * @param {string} switchName
 * @param {string} variantValue
 * @returns {boolean}
 */
export const useFetchedIsFeatureSwitchVariantPrefix = (switchName: string, variantValue: string) => {
  const switchValue = useFetchedFeatureSwitch(switchName);

  const regexp = new RegExp(`^${variantValue}(-.+){0,1}$`, "g");
  return regexp.test(switchValue);
};

export const useFetchedIsFeatureSwitchVariantPrefixInAudience = (switchName: string, variants: string | string[]) => {
  if (!Array.isArray(variants)) {
    variants = [variants];
  }

  const switchValue = useFetchedFeatureSwitch(switchName);

  const ret = variants.some((variant) => {
    const regexp = new RegExp(`^${variant}(-.+){0,1}$`, "g");
    return regexp.test(switchValue);
  });

  return ret;
};

// ---------------------------
// HELPERS
//
export function _testOnlyResetFeatureSwitchesConfig() {
  if (!env.isTest) throw new Error("`_testOnlyResetFeatureSwitchesConfig` can only be called in tests");

  featureSwitchesConfiguration = null;
  storedFeatureSetId = undefined;

  if (FEATURE_SET_ID_LOCALSTORAGE_KEY) {
    localStorage.removeItem(FEATURE_SET_ID_LOCALSTORAGE_KEY);
  }
}

/** FeatureSwitch component selects from states hash based on the value of the switch name. Off & control are interchangeable. */
export const FeatureSwitch = ({
  states,
  name,
  startExperiment,
}: {
  states: {
    [key: string]: ReactElement | null;
  } & ({ off: ReactElement | null } | { control: ReactElement | null });
  name: string;
  startExperiment?: boolean;
}): ReactElement | null => {
  const value = useFetchedFeatureSwitch(name);
  if (value === "switch") throw new Error("switch feature switch value not supported");

  if (typeof states[value] === "undefined") {
    // eslint-disable-next-line no-console
    console.error("experiment variant does not match component", { name, value, keys: Object.keys(states) });
    return states.off || states.control;
  }

  if (startExperiment && value !== "off") {
    logStartExperiment(name);
  }
  return states[value];
};
