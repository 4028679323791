import { useNavigate, useParams } from "react-router-dom";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import { useClassByIdQuery } from "old/src/hooks/useParent";
import { LoadingMojo } from "@classdojo/web";
import useWatch from "@classdojo/web/hooks/useWatch";

const SubscriptionBookingRedirectFromClass = () => {
  const [state, dispatch] = useGlobalStore();
  const query = useParams();
  const push = useNavigate();
  const { classData, isInitialLoading: isClassLoading } = useClassByIdQuery(query.classId);

  useWatch(classData, () => {
    if (classData) {
      dispatch({
        type: actionTypes.UPDATE_BOOKING,
        payload: {
          ...(state.booking ?? undefined),
          first_name_child: classData.childName,
          grade: classData.grade,
          subject: classData.subject,
          teacher_uid: classData.teacher.uid,
          subscriptionClassId: classData.id,
        },
      });
      push("/subscription/booking/select-class-frequency", { replace: true });
    }
  });

  if (isClassLoading) {
    return (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40px",
        }}
      >
        <LoadingMojo border />
      </div>
    );
  }
  return null;
};

export default SubscriptionBookingRedirectFromClass;
