// this contains object property names whose values we want to omit
const OBJECT_PROPERTIES_TO_REPLACE: Record<string, boolean> = { password: true };

// this contains regex to match with string values
// the regex must contain 3 capture groups - a prefix, the value to be omitted and a suffix - where
// anything matching the second capture group will be omitted
const STRING_PATTERNS_TO_REPLACE = [/(\"password\":\")(.*?)(\")/];

export const removeSensitiveData = (data?: unknown) => {
  if (!data) return data;

  if (typeof data === "object") {
    return Object.entries(data).reduce((result: Record<string, unknown>, [key, value]) => {
      result[key] = OBJECT_PROPERTIES_TO_REPLACE[key]
        ? "[omitted]"
        : ["object", "string"].includes(typeof value)
          ? removeSensitiveData(value)
          : value;
      return result;
    }, {});
  }

  if (typeof data === "string") {
    return STRING_PATTERNS_TO_REPLACE.reduce((result, regex) => result.replace(regex, "$1[omitted]$3"), data);
  }

  return data;
};
