import React from "react";
import { PasswordInput as BasePasswordInput, PasswordInputProps } from "@mantine/core";
import marketplaceTheme from "src/utils/marketplaceTheme";

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  return (
    // @ts-expect-error - styles error
    <BasePasswordInput
      styles={() => ({
        rightSection: {
          width: "64px",
        },
        label: {
          fontSize: "16px",
          fontWeight: 600,
          color: marketplaceTheme.colors.dt_content_accent,
        },
        innerInput: {
          height: "64px",
          color: "#2C2A50",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          padding: "20px 16px",
          "::placeholder": {
            color: "#AAB0D8",
          },
          ":hover": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":active": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":focus": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":focus-within": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
        },
        input: {
          height: "64px",
          border: "2px solid #D3D7EC",
          borderRadius: "16px",
          ":hover": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":active": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":focus": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
          ":focus-within": {
            borderColor: `${marketplaceTheme.colors.dt_border_active} !important`,
          },
        },
      })}
      {...props}
    />
  );
};

export default PasswordInput;
