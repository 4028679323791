import { useMemo } from "react";

let idCounter = 0;
const uniqueId = (label: string) => `${label}${++idCounter}`;

type Identifiers<Labels extends string[]> = {
  [K in keyof Labels]: `${Labels[K]}${number}`;
};

export const useUniqueIds = <const Labels extends string[]>(labels: Labels): Identifiers<Labels> =>
  useMemo(() => labels.map(uniqueId) as Identifiers<Labels>, []);
