import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import startCase from "lodash/startCase";
import ReactHtmlParser from "react-html-parser";
import { useNavigate, useParams } from "react-router-dom";
import { HEADER_HEIGHT } from "src/constants";
import CoppaModal from "old/src/components/CoppaModal";
import useMatomoTracker from "old/src/hooks/useMatomoTracker";
import { useClassByIdQuery } from "old/src/hooks/useParent";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import ProfilePhoto from "src/components/ProfilePhoto";
import useIsIOS from "src/hooks/useIsIOS";
import { logTutorEvent } from "src/utils/log";
import { BodyText, Button, theme } from "@classdojo/web/nessie";
import { IconArrowLeft } from "@web-monorepo/dds-icons";
import { LoadingMojo } from "@classdojo/web";
import { formatWithPattern, parse } from "src/utils/dates";

const AssessmentParentPage = () => {
  const query = useParams();
  const push = useNavigate();
  const { classData, isFetching, isCOPPAError, refetch } = useClassByIdQuery(query?.assessmentId as string | undefined);
  const [, dispatch] = useGlobalStore();
  const subscribeEvent = useMatomoTracker("subscribe-flow");
  const isIOS = useIsIOS();

  const redirectToClassesDashboard = () => {
    push("/parents/classes");
  };

  const redirectToSubscriptionCheckout = () => {
    const subscription = {
      first_name_child: classData?.childName,
      first_name_parent: (classData as any)?.student?.first_name,
      grade: classData?.grade,
      last_name_parent: (classData as any)?.student?.last_name,
      subject: classData?.subject,
      product: "",
      subscription: {},
      teacher: classData?.teacher,
      classBookingId: classData?.classId,
      type: "subscription",
    };

    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: subscription,
    });
    subscribeEvent("button-click", "subscription-entered-flow-from-assessment");
    logTutorEvent("parent.marketplace.subscription.entered_flow_from_assessment");
    push(`/subscription/booking/class/${classData?.classId}/redirect-from-class`);
  };

  if (!classData) {
    return (
      <div sx={sxGeneralWrapper}>
        <div sx={sxPageWrapper}>
          {isFetching ? (
            <LoadingMojo border />
          ) : isCOPPAError ? (
            <CoppaModal onSubmit={() => refetch()} />
          ) : (
            <div sx={{ paddingTop: isIOS ? "dt_s" : 0 }}>
              {!isIOS && (
                <Button
                  sx={sxBackButton}
                  onClick={redirectToClassesDashboard}
                  data-name="parent-assessment-back-button"
                  kind="link"
                >
                  <IconArrowLeft sx={sxLeftArrowIcon} size="m" /> Back to classes
                </Button>
              )}
              <BodyText sx={sxAssessmentNotAvailable}>No Assessment Available</BodyText>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div sx={sxGeneralWrapper}>
      <div sx={{ ...sxPageWrapper, paddingTop: isIOS ? "dt_s" : 0 }}>
        {!isIOS && (
          <Button
            sx={sxBackButton}
            onClick={redirectToClassesDashboard}
            data-name="parent-assessment-back-button"
            kind="link"
          >
            <IconArrowLeft sx={sxLeftArrowIcon} size="m" /> Back to classes
          </Button>
        )}
        <div sx={sxCard}>
          <div sx={sxHeader}>
            <div>
              <BodyText sx={sxTitle}>Tutoring assessment</BodyText>
              <BodyText sx={sxSubtitle}>
                {startCase(classData?.subject)} • {startCase(classData?.grade)}
              </BodyText>
            </div>

            <div sx={sxTitleRowRightContent}>
              <BodyText sx={sxChildName}>{classData?.childName}</BodyText>
              <BodyText sx={sxSubtitle}>
                {formatWithPattern(parse(classData?.assessment?.date_created), "PPp")}
              </BodyText>
            </div>
          </div>
          <div sx={sxTutorInfo}>
            <div sx={sxCentered}>
              <ProfilePhoto src={classData?.teacher?.photoUrl} size={40} alt="tutor profile" />
              <div sx={sxTutorInfoColumn}>
                <BodyText sx={sxCompletedByTutor}>Completed by</BodyText>
                <BodyText sx={sxTutorName}>
                  {classData?.teacher?.firstName} {classData?.teacher?.lastName}
                </BodyText>
              </div>
            </div>

            <Button
              sx={sxSubscribeButton}
              onClick={redirectToSubscriptionCheckout}
              data-name="parent-assessment-subscribe-button"
              size="s"
              kind="primary"
            >
              Subscribe to weekly class
            </Button>
          </div>

          <div sx={sxAssessmentContent}>
            <div sx={sxAssessmentField}>
              <BodyText sx={sxAssessmentFieldLabel}>Class overview</BodyText>
              <div sx={sxAssessmentFieldContent}>{ReactHtmlParser(classData?.assessment?.class_overview ?? "")}</div>
            </div>

            <div sx={sxAssessmentField}>
              <BodyText sx={sxAssessmentFieldLabel}>Areas of strength</BodyText>
              <div sx={sxAssessmentFieldContent}>{ReactHtmlParser(classData?.assessment?.areas_of_strength ?? "")}</div>
            </div>

            <div sx={sxAssessmentField}>
              <BodyText sx={sxAssessmentFieldLabel}>Areas of improvement</BodyText>
              <div sx={sxAssessmentFieldContent}>
                {ReactHtmlParser(classData?.assessment?.areas_of_improvement ?? "")}
              </div>
            </div>

            <div sx={sxAssessmentField}>
              <BodyText sx={sxAssessmentFieldLabel}>Recommended activities and resources</BodyText>
              <div sx={sxAssessmentFieldContent}>{ReactHtmlParser(classData?.assessment?.recommended ?? "")}</div>
            </div>

            <div sx={sxAssessmentField}>
              <BodyText sx={sxAssessmentFieldLabel}>Work plan for the following classes</BodyText>
              <div sx={sxAssessmentFieldContent}>{ReactHtmlParser(classData?.assessment?.work_plan ?? "")}</div>
            </div>

            <div sx={{ ...sxRow, ...sxAssessmentField }}>
              <div>
                <BodyText sx={sxAssessmentFieldLabel}>Number of classes recommended per week</BodyText>
                <div>
                  At least {classData?.assessment?.frequency} session
                  {(classData?.assessment?.frequency ?? 0) > 1 && "s"} per week
                </div>
              </div>

              <Button
                sx={sxSubscribeButtonFrequency}
                onClick={redirectToSubscriptionCheckout}
                data-name="parent-assessment-subscribe-button"
                size="s"
              >
                Subscribe to weekly class
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentParentPage;

const sxGeneralWrapper: ThemeUIStyleObject = {
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
};

const sxPageWrapper: ThemeUIStyleObject = {
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "dt_xxl",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    width: "100%",
  },
};

const sxCard: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  border: "dt_card",
  boxShadow: "dt_shadow_shadezies_small",
  borderRadius: "dt_radius_s",
  width: "100%",
};

const sxWrapper: ThemeUIStyleObject = {
  padding: RAW_cssValue("24px 40px"),
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    padding: "dt_l",
  },
};

const sxHeader: ThemeUIStyleObject = {
  ...sxWrapper,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    padding: "dt_l",
    flexDirection: "column",
    textAlign: "left",
    alignItems: "flex-start",
  },
};

const sxTitleRowRightContent: ThemeUIStyleObject = {
  textAlign: "right",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: "dt_m",
    textAlign: "left",
  },
};

const sxTitle: ThemeUIStyleObject = {
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "32px",
  color: "dt_content_primary",
};

const sxRow: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    flexDirection: "column",
    textAlign: "left",
    alignItems: "flex-start",
  },
};

const sxSubscribeButton: ThemeUIStyleObject = {
  fontWeight: 600,
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: "dt_m",
    width: "100%",
  },
};

const sxSubscribeButtonFrequency: ThemeUIStyleObject = {
  ...sxSubscribeButton,
  [`@media (max-width: ${theme.breakpoints.m})`]: {
    display: "none",
  },
};

const sxSubtitle: ThemeUIStyleObject = {
  fontSize: "16px",
  lineHeight: "28px",
  color: "dt_content_primary",
  fontWeight: 500,
};

const sxChildName: ThemeUIStyleObject = {
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "28px",
  color: "dt_content_primary",
};

const sxBackButton: ThemeUIStyleObject = {
  marginBottom: "dt_s",
};

const sxLeftArrowIcon: ThemeUIStyleObject = {
  marginRight: "dt_s",
  fontSize: "14px",
};

const sxAssessmentNotAvailable: ThemeUIStyleObject = {
  fontSize: "20px",
  fontWeight: 600,
};

const sxTutorInfo: ThemeUIStyleObject = {
  ...sxWrapper,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderTop: "dt_divider",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    padding: "dt_l",
    flexDirection: "column",
    justifyContent: "center",
  },
};

const sxCompletedByTutor: ThemeUIStyleObject = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  color: "dt_content_tertiary",
};

const sxTutorName: ThemeUIStyleObject = {
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "28px",
  color: "dt_content_primary",
};

const sxAssessmentField: ThemeUIStyleObject = {
  borderTop: "dt_divider",
  marginBottom: "dt_m",
  paddingTop: "dt_l",
};

const sxAssessmentFieldLabel: ThemeUIStyleObject = {
  marginBottom: "dt_xs",
  fontSize: "18px",
  color: "dt_content_primary",
  fontWeight: 700,
};

const sxAssessmentFieldContent: ThemeUIStyleObject = {
  wordWrap: "break-word",
  overflowWrap: "anywhere",
};

const sxAssessmentContent: ThemeUIStyleObject = {
  ...sxWrapper,
  paddingTop: "0",
};

const sxCentered: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
};

const sxTutorInfoColumn: ThemeUIStyleObject = {
  marginLeft: "dt_s",
};
