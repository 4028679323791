import { Link } from "react-router-dom";
import useStyles from "./styles/CookiesPrivacy.styles";
import { Box, Divider, Text } from "UILibrary";

const CookiesPrivacy = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.body}>
      <Box className={classes.wrapper}>
        <Box className={classes.hero} sx={{ marginBottom: "dt_l" }}>
          <Box sx={{ width: "100%" }}>
            <Text component="h1">Cookies Policy</Text>
            <Box mb={16} />
            <Text className={classes.subHeader}>Last Updated: January 9, 2023</Text>
          </Box>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring offers a range of features that use technologies like cookies, pixel tags (“pixels”), device
            or other identifiers, and local storage (collectively, “Online Tracking Technologies”) to provide a seamless
            and safe experience on ClassDojo. Some of the technologies described below are used solely on our
            informational website located at https://www.tutoring.classdojo.com (the “Dojo Tutoring Website”) and others
            are used only in logged-in areas of the Service, such as through the Dojo Tutoring Platform or Dojo Tutoring
            App. Any terms not defined here shall have the meaning set forth in the Dojo Tutoring{" "}
            <Link to="/privacy">Privacy Policy</Link>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            First and foremost, we do not use or disclose information collected through Online Tracking Technology for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
              third-party advertising
            </a>{" "}
            purposes (including{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
              behaviorally targeting advertising
            </a>{" "}
            to{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>{" "}
            or children) on the Service. As described in more detail below, we use these technologies in many ways so
            that Dojo Tutoring works, like for security purposes, to provide certain helpful features, and also to
            understand and measure how Dojo Tutoring is used by tutors, parents, and learners. Third parties that you
            interact with as you use the Service may also use these technologies for a variety of purposes.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            For a full list of all the different tracking technologies we use, please see{" "}
            <a href="https://www.classdojo.com/cookies-policy/#FullListCookies">here</a>. Check back here from time to
            time to get the latest information about these technologies and how they are used on Dojo Tutoring.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Your browser or device may offer settings related to these technologies - visit your browser or device’s
            help material to learn about your options. We may, however, not recognize or respond to browser or device
            signals around tracking, and changing these settings may impact your ability to use some of the great
            features on Dojo Tutoring.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Below we provide more information about Online Tracking Technologies and how they work.
          </Text>
        </Box>
        <br />
        <Box>
          <h3 className={classes.paragraph}>Quick Reference:</h3>
        </Box>
        <ul>
          <li>
            <Link to="#what-are-some-of-these-online-tracking-technologies">
              What are some of these Online Tracking Technologies?
            </Link>
          </li>
          <li>
            <Link to="#how-do-we-use-online-tracking">
              How do we use Online Tracking Technologies when you use a mobile device?
            </Link>
          </li>
          <li>
            <Link to="#how-can-i-manage-these-online-tracking">
              How can I manage these Online Tracking Technologies?
            </Link>
          </li>
          <li>
            <Link to="#why-do-we-use-online-tracking">Why do we use Online Tracking Technologies?</Link>
          </li>
          <li>
            <Link to="#how-do-third-parties-use-online-tracking">
              How do third parties use Online Tracking Technologies on our Service and the Dojo Tutoring Website?
            </Link>
          </li>
          <li>
            <Link to="#how-can-you-control-dojo-tutoring-use-of-online-tracking">
              How can you control ClassDojo’s use of Online Tracking Technologies to show you ads?
            </Link>
          </li>
          <li>
            <Link to="#online-tracking-technologies-from-unregistered-users">
              Online Tracking Technologies from unregistered users
            </Link>
          </li>
          <li>
            <Link to="#detailed-list-of-online-tracking">Detailed list of Online Tracking Technologies</Link>
          </li>
        </ul>
        <br />
        <Divider />
        <br />
        <h3 id="what-are-some-of-these-online-tracking-technologies">
          What are some of these Online Tracking Technologies?
        </h3>
        <ul></ul>
        <li>
          <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_3cee3e5e-c072-4fc2-821d-84258d56c2c4">
            Cookies and similar technologies
          </a>
        </li>
        <li>
          <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_b865d3eb-6e41-467c-9b53-7f2c9bd73562">
            Pixel Tags
          </a>
        </li>
        <li>
          <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_efdb3ae6-8829-4e77-a8e0-b47c865fbe1b">
            Local Storage
          </a>
        </li>
        <br />
        <h3 id="how-do-we-use-online-tracking">
          How do we use Online Tracking Technologies when you use a mobile device?
        </h3>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Many mobile devices act just like computers, such as smartphones that support full-featured browsers. For
            those devices, we use Online Tracking Technologies in ways that are similar to when you are accessing the
            web from a computer. Other devices use different technologies such as local storage on a device, Software
            Development Kits (SDKs), or Advanced Programming Interfaces (APIs) to facilitate the same types of processes
            we use Cookies for, as described below. As on the web, we also may use similar technologies to store and
            retrieve an identifier or other information from or on your device. For example, with your permission, we
            may use an advertising identifier such as the Identifier Advertiser (“IDFA”) on iOS devices and the
            Advertising ID on Android devices where cookies are not available.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            With your permission, we may also obtain or receive information about your use of your mobile device and our
            Service. We describe how we use these technologies and the information we received from them in our{" "}
            <a href="https://tutoring.classdojo.com/privacy">Privacy Policy.</a>
          </Text>
        </Box>
        <br />
        <h3 id="how-can-i-manage-these-online-tracking">How can I manage these Online Tracking Technologies?</h3>
        <Box>
          <ul></ul>
          <li>
            <b>Browser Cookie Controls</b>
            <br />
            Your browser or device may offer settings related to these technologies - visit your browser or device’s
            help menu to learn about your options (browsers for mobile devices may not offer visibility). For example,
            you may set your browser to refuse all cookies or to indicate when a cookie is being sent. However, changing
            these settings may impact your use of some features or services on our Service or the Dojo Tutoring Website
            as they may not function properly without cookies. While some internet browsers offer a “do not track” or
            “DNT” option that lets you tell websites that you do not want to have your online activities tracked, these
            features are not yet uniform and there is no common standard adopted by industry groups, technology
            companies, or regulators. Therefore, we do not currently commit to responding to browsers’ DNT signals with
            respect to our websites. Dojo Tutoring takes privacy and meaningful choice seriously and will make efforts
            to continue to monitor developments around DNT browser technology and the implementation of a standard.
          </li>
          <br />
          <li>
            <b>Dojo Tutoring Cookie Preference Center</b>
            <br />
            You will also have certain options for management of Online Tracking Technologies as set forth in the
            applicable jurisdiction-specific Dojo Tutoring Cookie Settings Preference Center.
          </li>
          <br />
          <li>
            <b>Flash Cookies</b>
            <br />
            Cookie management tools provided by your browser will not remove Flash cookies. Learn how to{" "}
            <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
              manage privacy and storage settings for Flash cookies
            </a>
            . If you disable Flash cookies, you won’t have access to many features that make your guest experience more
            efficient and some of our Services or the Dojo Tutoring Website will not function properly.
          </li>
          <br />
          <li>
            <b>Mobile</b>
            <br />
            Please note that the choices described above regarding browser-based opt-outs may not apply to mobile
            applications. Your mobile device operating system may offer settings that enable you to make choices about
            the collection of mobile app information for targeted advertising (such as{" "}
            <a href="https://support.apple.com/en-us/HT202074">turning off personalized ads</a>). Mobile app users may
            also download through the applicable app store the AppChoices app to learn about how you can opt-out of the
            collection of cross-app data on this device for targeted advertising by participating companies. Since not
            all of the partners we work with will be available in AppChoices, you should exercise your choices using
            both mobile device settings and AppChoices. If you opt-out, you will continue to receive the same number of
            mobile ads, but they may be less relevant because they will not be based on your interests. You may still
            see ads related to the content on a web page or in an application or based on other non-personal
            information. Please see “How can you control Dojo Tutoring’s use of Online Tracking Technologies to show you
            ads?” for more information.
          </li>
        </Box>
        <br />
        <h3 id="why-do-we-use-online-tracking">Why do we use Online Tracking Technologies?</h3>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring uses cookies that are session-based (temporary) and persistent-based (permanent). Session
            cookies exist only during one session. They disappear from your computer when you close your browser
            software or turn off your computer. Persistent cookies remain on your computer or mobile device even after
            you close your browser or turn off your computer and make it possible to recognize you during subsequent
            visits to the website or application. Persistent cookies remain on your device until their end date is
            reached until a new cookie is installed or until you delete them in the settings of your browser,
            application, or mobile device.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We use these technologies for a variety of reasons, such as allowing us to show you content that’s most
            relevant to you, improving our products and services, and helping to keep our Service and the Dojo Tutoring
            Website secure. Please visit here to see the list of Online Tracking Technologies used on
            tutoring.classdojo.com. Please note that this list is not updated daily. While specific names of the Online
            Tracking Technologies that we use may change from time to time as we improve and update our Service or the
            Dojo Tutoring Website, they generally fall into the below categories of use:
          </Text>
        </Box>
        <br />

        <table>
          <thead>
            <tr>
              <th scope="col" className="ot-table-header ot-host">
                Categories of use
              </th>

              <th scope="col" className="ot-table-header ot-cookies">
                Descriptions
              </th>
              <th scope="col" className="ot-table-header ot-cookies-type">
                Manage Settings
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="ot-host-td" data-label="Cookie Subgroup">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">Strictly Necessary, Authentication</span>
              </td>
              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  These tell us when you’re logged in, so we can show you the appropriate experience and features. For
                  example, cookies help us remember your browser so you do not have to keep logging into our Site.
                </span>
              </td>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  Please see <Link to="#how-can-i-manage-these-online-tracking">above</Link>
                </span>
              </td>
            </tr>
            <tr>
              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">Strictly Necessary, Security and site integrity</span>
              </td>
              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  These help keep Dojo Tutoring safe and secure by supporting and enabling security features and help us
                  detect activity that violates our Terms of Service or otherwise degrades our ability to provide our
                  Site and services. For example: We use security cookies to authenticate users, prevent fraudulent use
                  of login credentials, protect user data from unauthorized parties, and help us fight spam and phishing
                  attacks.
                </span>
              </td>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  Please see <Link to="#how-can-i-manage-these-online-tracking">above</Link>
                </span>
              </td>
            </tr>

            <tr>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">Functional, Localization</span>
              </td>
              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-type-td-content">
                  These help us provide localized experiences. For example: We may store information in a cookie that is
                  placed on your browser or device so you will see the site in your preferred language.
                </span>
              </td>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  Please see <Link to="#how-can-i-manage-these-online-tracking">above</Link>
                </span>
              </td>
            </tr>
            <tr>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">Functional, Features and services</span>
              </td>

              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-type-td-content">
                  These provide functionality that helps us deliver products and services and change the way the site
                  behaves or looks. For example: Cookies help us store preferences, know when you’ve seen or interacted
                  with certain content, and provide you with customized content and experiences. For instance, cookies
                  allow us to make suggestions to you and others, and to customize content on our Services or the Dojo
                  Tutoring Website.
                </span>
                <br />
                <span className="ot-cookies-type-td-content">
                  Loss of the information stored in a preference cookie may make the Site experience less functional but
                  should not prevent it from working.
                </span>
              </td>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  Please see <Link to="#how-can-i-manage-these-online-tracking">above</Link>
                </span>
              </td>
            </tr>
            <tr>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">Performance</span>
              </td>

              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  These help us ensure Dojo Tutoring is operationally performing at a high level, such as by
                  understanding how quickly our Service or the Dojo Tutoring Website loads for different users. For
                  example: these cookies help us route traffic between servers and understand how quickly certain pages
                  load for different people and if they receive error messages from certain pages. These cookies do not
                  collect information that individually identifies a visitor. These are used to improve how the Service
                  and Dojo Tutoring Website functions and performs.
                </span>
              </td>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  Please see <Link to="#how-can-i-manage-these-online-tracking">above</Link>
                </span>
              </td>
            </tr>

            <tr>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">Performance, Analytics and research</span>
              </td>

              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  From time to time, Dojo Tutoring engages third parties to track and analyze usage and volume
                  statistical information from individuals who visit our Sites. Dojo Tutoring may also utilize Flash
                  cookies for these purposes. These are used to understand how our Services and Dojo Tutoring Website
                  visitors use and engage with Dojo Tutoring, and thus so we can continue to find ways to make Dojo
                  Tutoring a great experience. For example: We may use these technologies to know which features on Dojo
                  Tutoring are most popular - and which ones could be improved
                </span>{" "}
              </td>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  Please see <Link to="#how-can-i-manage-these-online-tracking">above</Link>
                </span>
              </td>
            </tr>

            <tr>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">Advertising</span>
              </td>

              <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  We use cookies to make advertising more engaging to visitors and to retarget Dojo Tutoring visitors on
                  other sites (such as Facebook). Some common applications of cookies are to select advertising based on
                  what’s relevant to a visitor; to improve reporting on campaign performance, and to avoid showing ads
                  the visitor has already seen. We will NOT use advertising cookies in areas where learners are logged
                  in to the Services.
                </span>
                <br />
                <span className="ot-cookies-td-content">
                  For example: Dojo Tutoring uses cookies delivered by third parties to show you ads for Dojo Tutoring
                  products and services that we think may interest you on this and other devices you may use, and to
                  track the performance of Dojo Tutoring advertisements. For example, these cookies remember which
                  browsers have visited the Dojo Tutoring Website.
                </span>
                <br />
                <span className="ot-cookies-td-content">
                  We may also work with third-party advertising companies that collect information about your visits to
                  non-affiliated mobile applications in order to show you advertisements that may interest you on
                  devices you may use. Please note that the choices described above regarding browser-based targeted
                  advertising may not apply to targeted advertising involving mobile applications. Your mobile device
                  operating system may offer settings that enable you to make choices about the collection of mobile app
                  information for targeted advertising.
                </span>
              </td>
              <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                  You can opt-out of seeing online interest-based (or “targeted”) ads from Dojo Tutoring and other
                  participating companies through the{" "}
                  <a href="https://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance</a> or the{" "}
                  <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative</a> in the US, the{" "}
                  <a href="https://youradchoices.ca/">Digital Advertising Alliance of Canada</a> in Canada, or the{" "}
                  <a href="http://www.youronlinechoices.eu/">European Interactive Digital Advertising Alliance</a> in
                  Europe or through the Dojo Tutoring Cookie Preference Center or your mobile device settings as
                  mentioned <Link to="#how-can-i-manage-these-online-tracking">above</Link>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <h3 id="how-do-third-parties-use-online-tracking">
          How do third parties use Online Tracking Technologies on our Service and the Dojo Tutoring Website?
        </h3>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            In addition to our own Online Tracking Technologies, we work with various companies to help us analyze how
            our Service and the Dojo Tutoring Website are used for functionality and serving content, for advertising,
            and to improve Dojo Tutoring and deliver the best possible experience for tutors, parents, and learners.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            These third parties may use web measurement and customization technologies (such as cookies) in conjunction
            with the provision of these services. Take a look at our{" "}
            <a href="https://tutoring.classdojo.com/privacy">third-party service providers</a> to learn more about them
            as well as to review their privacy policies. Additionally, please visit{" "}
            <a href="https://www.classdojo.com/cookies-policy/#FullListCookies">here</a> to see the list of Online
            Tracking Technologies used on tutoring.classdojo.com. Please note that this list is not updated daily.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Third parties may also use cookies on their own website and applications in connection with the Dojo
            Tutoring Website or Services, such as social media sharing or authentication features from Facebook and
            Twitter.
          </Text>
        </Box>
        <br />
        <h3 id="how-can-you-control-dojo-tutoring-use-of-online-tracking">
          How can you control Dojo Tutoring’s use of Online Tracking Technologies to show you ads?
        </h3>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We will NOT use advertising cookies in areas where learners are logged in to the Services. We may partner
            with a third party to either display advertising on our website or manage our advertising on other sites.
            Our third-party partner may use cookies or similar technologies in order to provide you with advertising
            based on your browsing activities and interests. You can opt-out of seeing online interest-based (or
            “targeted”) ads from Dojo Tutoring and other participating companies through the{" "}
            <a href="https://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance</a> or the{" "}
            <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative</a> in the US, the{" "}
            <a href="https://youradchoices.ca/">Digital Advertising Alliance of Canada</a> in Canada, or the{" "}
            <a href="http://www.youronlinechoices.eu/">European Interactive Digital Advertising Alliance</a> in Europe.
            Some of our advertising-related activities may involve the services of companies that do not participate in
            those programs. For example, we may use the Google AdWords remarketing service to advertise on third-party
            websites (including Google) to previous visitors to our Dojo Tutoring Website. You can set preferences for
            how Google advertises to you using the{" "}
            <a href="https://support.google.com/accounts/answer/2662856?hl=en&co=GENIE.Platform%3DDesktop">
              Google Ad Preferences
            </a>{" "}
            page. You will also have certain options for management of Online Tracking Technologies relating to
            advertising as set forth in the applicable jurisdiction-specific Dojo Tutoring Cookie Settings Preference
            Center.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            The opt-outs apply only to the browser in which you set them. For example, if you set the opt-out while
            using Firefox, but then use Chrome, the opt-out will not be active in Chrome. To opt-out in Chrome, you will
            need to repeat the opt-out process. Please note you may continue to receive generic ads even after opting
            out.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We may also work with third-party advertising companies that collect information about your visits to
            non-affiliated mobile applications in order to show you advertisements that may interest you on devices you
            may use. Please note that the choices described above regarding browser-based targeted advertising may not
            apply to targeted advertising involving mobile applications. Your mobile device operating system may offer
            settings that enable you to make choices about the collection of mobile app information for targeted
            advertising (for example, by re-setting your device’s advertising identifier and/or such as{" "}
            <a href="https://support.apple.com/en-us/HT202074">turning off personalized ads</a>). Mobile app users may
            also download through the applicable app store the AppChoices app to learn about how you can opt-out of the
            collection of cross-app data on this device for targeted advertising by participating companies. Since not
            all of the partners we work with will be available in AppChoices, you should exercise your choices using
            both mobile device settings and AppChoices.
          </Text>
        </Box>
        <br />
        <h3 id="online-tracking-technologies-from-unregistered-users">
          Online Tracking Technologies from unregistered users
        </h3>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We still use Online Tracking Technologies if you don’t have an account or have logged out of your account.
            For example, if you have logged out of your account we use cookies to, for example, help you log in faster
            the next time.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We also set cookies if you don’t have a Dojo Tutoring account, but have visited tutoring.classdojo.com, to
            help us protect the Dojo Tutoring Website and the Service, and the people who use it from malicious
            activity. For example, these cookies help us detect and prevent denial-of-service attacks and the mass
            creation of fake accounts. If you have cookies on your browser or device, we read that cookie when you visit
            a site with our app.
          </Text>
        </Box>
        <br />
        <h3 id="detailed-list-of-online-tracking">
          Detailed list of Online Tracking Technologies in the Services and ClassDojo Website
        </h3>
        <br />
        <Box className="collapse legal-body" id="FullListCookies">
          <Box id="ot-sdk-cookie-policy"></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CookiesPrivacy;
