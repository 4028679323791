import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { useSessionQuery } from "src/hooks/useSession";
import LoginPage from "src/pages/login";

type RequireLoggedInProps = {
  children: ReactElement;
};

const RequireLoggedIn = ({ children }: RequireLoggedInProps) => {
  const push = useNavigate();
  const { isLoading, sessionData } = useSessionQuery();

  useEffect(() => {
    // Wrap this in a self-calling function so that we can return early to make the logic simpler
    (() => {
      if (isLoading) return;

      if (!sessionData?.roles.includes("teacher")) return; // user is not a teacher

      const { onboarded, approved } = sessionData.user_details;
      if (!onboarded) return push("/apply/teacher/application");
      if (!approved) return push("/apply/status");
    })();
  }, [isLoading, push, sessionData]);

  if (isLoading) return null;

  if (sessionData == null) {
    return (
      <Layout header={false} layout="centered" wave>
        <LoginPage />
      </Layout>
    );
  }
  return children;
};

export default RequireLoggedIn;
