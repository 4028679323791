import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";
import { getDefaultTimezone } from "src/utils/dates";
import { sanitizeId } from "src/utils/sanitizeId";

type Endpoint = paths["/api/marketplace/teacher/subscription-availability/{teacherUidProxy}"]["get"];
type Response = Endpoint["responses"][200]["content"]["application/json"];
type Params = Endpoint["parameters"]["query"];

export type SubscriptionAvailability = Response;

export default (id: string) => {
  const timezone = getDefaultTimezone();
  const sanitizedId = sanitizeId(id);
  const params: Params = { timezone };
  return monolithApiClient.get<Response>(`/teacher/subscription-availability/${sanitizedId}`, {
    params,
  });
};
