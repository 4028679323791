import { useState } from "react";
import { useLoggerContext } from "@classdojo/web";
import { DevToolsSettings } from "src/utils/devTools";
import { useEnsureFeatureSwitches, useFetchedFeatureSwitchesWithOverrides } from "@web-monorepo/shared/featureSwitches";
import { useSessionQuery } from "src/hooks/useSession";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";

const EnsureSessionLoaded = ({ children }: { children: React.ReactElement }) => {
  const { isInitialLoading } = useSessionQuery();
  if (isInitialLoading) return null;

  return <EnsureFeatureSwitchesLoaded>{children}</EnsureFeatureSwitchesLoaded>;
};

// needs to fetch the feature switches after they've been ensured
const SetupLoggerContext = () => {
  const fetchedFeatureSwitches = useFetchedFeatureSwitchesWithOverrides();

  const { setPanelDisplaySide } = DevToolsSettings.usePanelDisplaySide();

  useOnFirstRender(() => {
    setPanelDisplaySide("left");
  });

  useLoggerContext({ prefix: "marketplace", fetchedFeatureSwitches });

  return null;
};

const EnsureFeatureSwitchesLoaded = ({ children }: { children: React.ReactElement }) => {
  const [featureSwitchesLoaded, setFeatureSwitchesLoaded] = useState(false);
  const { ready } = useEnsureFeatureSwitches();
  if (ready && featureSwitchesLoaded === false) {
    setFeatureSwitchesLoaded(true);
  }

  if (!featureSwitchesLoaded) return null;

  return (
    <>
      <SetupLoggerContext />
      {children}
    </>
  );
};

export default EnsureSessionLoaded;
