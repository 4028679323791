import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";
import { sanitizeId } from "src/utils/sanitizeId";

type Endpoint = paths["/api/marketplace/enrollment/{classId}/assessment"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200];

export default ({ id, ...body }: { id: string } & Body) => {
  const sanitizedId = sanitizeId(id);
  return monolithApiClient.post<Response>(`/enrollment/${sanitizedId}/assessment`, body);
};
