import Cookie from "js-cookie";
import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useParentSubscriptionsQuery from "src/hooks/api/parent/useParentSubscriptionsQuery";
import Logo from "./Logo";
import { Drawer, Box, Divider, Text } from "UILibrary";
import useMatomoTracker from "old/src/hooks/useMatomoTracker";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import useLogoutMutation from "src/hooks/api/auth/useLogoutMutation";
import useEventMutation from "src/hooks/useEventMutation";
import { useSessionQuery } from "src/hooks/useSession";
import cookies from "src/utils/cookies";
import { logTutorEvent } from "src/utils/log";
import { Button } from "@classdojo/web/nessie";
import useHelperWidgetAPI from "src/hooks/useHelperWidgetAPI";

interface MobileNavProps {
  opened: boolean;
  onClose(): void;
}

type Link = {
  path?: string;
  label: string;
  key: string;
};

const usePublicLinks = () => {
  const links: Link[] = [
    {
      path: "/",
      label: "Home",
      key: "home",
    },
    {
      path: "/login",
      label: "Login",
      key: "login",
    },
    {
      path: "/for-teachers",
      label: "Apply as a teacher",
      key: "for-teachers",
    },
  ];
  return links;
};

const useParentLinks = () => {
  const { subscriptions } = useParentSubscriptionsQuery();
  const shouldShowManageSubscription = Boolean(subscriptions?.length);
  const links: Link[] = [
    {
      path: "/parents/classes",
      label: "Upcoming",
      key: "classes",
    },
    {
      path: "/parents/pastClasses",
      label: "Past classes",
      key: "pastClasses",
    },
    shouldShowManageSubscription
      ? { path: "/subscription/manage", label: "Manage subscriptions", key: "manage-subscriptions" }
      : null,
    {
      path: "https://tutor-help.classdojo.com/hc/en-us",
      label: "Help",
      key: "help",
    },
    {
      label: "Contact Us",
      key: "contact-us",
    },
    {
      label: "Logout",
      key: "logout",
    },
  ].filter(Boolean);
  return links;
};

const useTeacherLinks = () => {
  const links: Link[] = [
    {
      path: "/teachers/calendar",
      label: "Classes Calendar",
      key: "classes-calendar",
    },
    {
      path: "/teachers/availability",
      label: "Availability",
      key: "availability",
    },
    {
      path: "/teachers/profile",
      label: "Profile",
      key: "profile",
    },
    {
      label: "Contact Us",
      key: "contact-us",
    },
    {
      label: "Logout",
      key: "logout",
    },
  ];
  return links;
};

const MobileNav: React.FC<MobileNavProps> = ({ onClose, opened }) => {
  const { pathname } = useLocation();
  const push = useNavigate();
  const [, dispatch] = useGlobalStore();
  const logoutMutation = useLogoutMutation();
  const { sessionData } = useSessionQuery();
  const checkoutEvent = useMatomoTracker("checkout-flow");
  const eventMutation = useEventMutation();
  const isBookingPage = useMemo(() => pathname.includes("booking"), [pathname]);
  const isSingleTrialPage = useMemo(() => pathname.includes("/single-class"), [pathname]);
  const role = sessionData?.roles?.includes("teacher")
    ? "teacher"
    : sessionData?.roles?.includes("parent")
      ? "parent"
      : "none";
  const shouldRenderAdditionalClassButton = !isBookingPage && !isSingleTrialPage;
  const helperWidgetAPI = useHelperWidgetAPI();

  const onLogout = () => {
    logoutMutation.mutateAsync();
    onClose();
  };

  const onContactUs = () => {
    helperWidgetAPI.openWidget({
      email: sessionData?.user_details.email,
      name: `${sessionData?.user_details.first_name || ""} ${sessionData?.user_details.last_name || ""}`,
    });
  };

  const getAction = (key: string) => {
    switch (key) {
      case "logout":
        return onLogout();
      case "contact-us":
        return onContactUs();
      default:
        break;
    }
  };

  const redirectToBooking = async () => {
    checkoutEvent("button-click", "entered-booking-flow");
    logTutorEvent("parent.marketplace.entered_booking_flow");

    const sourceEmailData = Cookie.get(cookies.sourceParentRedirectEmail.name);
    if (sourceEmailData) {
      const { email, campaign } = JSON.parse(sourceEmailData as string) as any;
      await eventMutation.mutateAsync({
        event: "dojo-tutoring-trial-class-booking-started",
        email,
        campaign,
      });
    }

    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: null,
    });
    push("/single-class");
  };

  const redirectToAdditionalTrialBooking = async () => {
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: null,
    });
    push("/additional-single-class/step-one");
  };

  const publicLinks = usePublicLinks();
  const parentLinks = useParentLinks();
  const teacherLinks = useTeacherLinks();

  const renderLinks = () => {
    let links: Link[] = [];
    if (!sessionData) {
      links = publicLinks;
    }

    if (sessionData?.roles?.includes("parent")) {
      links = parentLinks;
    }

    if (sessionData?.roles?.includes("teacher")) {
      links = teacherLinks;
    }

    return links?.map((value) => (
      <Link
        key={value.key}
        sx={{
          display: "block",
          textDecoration: "none",
          fontWeight: 600,
          color: pathname === value.path ? "dt_content_accent" : "dt_content_primary",
          fontSize: "28px",
        }}
        to={value.path ?? ""}
        onClick={value.path ? () => onClose() : () => getAction(value.key)}
        data-testid={value.key}
      >
        {value.label}
      </Link>
    ));
  };

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={<Logo sx={{ width: "180px" }} onClick={onClose} />}
      size="xl"
      padding="xl"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 64px)",
          justifyContent: "space-between",
        }}
      >
        <Box mt={24}>
          {renderLinks()}
          <Divider my={16} mb={24} />
          {role === "parent"
            ? shouldRenderAdditionalClassButton && (
                <Button
                  data-name="parentHeader:bookTrialClass"
                  sx={{ width: "100%" }}
                  onClick={redirectToAdditionalTrialBooking}
                >
                  Book a trial class
                </Button>
              )
            : role === "none" && (
                <Button data-name="parentHeader:getStarted" sx={{ width: "100%" }} onClick={redirectToBooking}>
                  Get started
                </Button>
              )}
        </Box>
        <Box>
          <Divider my={16} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text mt={12} sx={{ fontWeight: 600, fontSize: "14px", lineHeight: "100%" }}>
              © {new Date().getFullYear()} ClassDojo. All Rights Reserved.
            </Text>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileNav;
