import { monolithApiClient } from "src/configs/axios.config";
import useMutation from "src/hooks/useMutation";
import { paths } from "@classdojo/ts-api-types";

type Endpoint = paths["/api/marketplace/parent/session/{sessionId}/free-trial"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200];

const bookFreeTrialSession = async ({ sessionId, ...body }: Body & { sessionId: string }) =>
  await monolithApiClient.post<Response>(`/parent/session/${sessionId}/free-trial`, body);

const useBookFreeTrialSessionMutation = () => {
  return useMutation(bookFreeTrialSession);
};

export default useBookFreeTrialSessionMutation;
