import { setEntityId, setEntityType } from "@classdojo/log-client";
import { paths } from "@classdojo/ts-api-types/api";
import { AxiosError } from "axios";
import useMutation from "src/hooks/useMutation";
import { monolithApiClient } from "src/configs/axios.config";
import { setSessionQueryData } from "src/hooks/useSession";
import { logTutorEvent } from "src/utils/log";
import { datadogRum } from "@datadog/browser-rum";
import useHelperWidgetAPI from "src/hooks/useHelperWidgetAPI";
import { setDefaultTimezone } from "src/utils/dates";

type Endpoint = paths["/api/marketplace/login"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export type SessionData = Response;

export const getEntityType = (roles: string[]) => (roles.includes("teacher") ? "teacher" : "parent");

const login = async (body: Body) => {
  const response = await monolithApiClient.post<Response>(`/login`, body);
  setSessionQueryData(response.data);
  return response;
};
const useLoginMutation = () => {
  const helperWidgetAPI = useHelperWidgetAPI();

  return useMutation(login, {
    onSuccess: (data: { data: Response }) => {
      const uid = data.data.user_details.uid;
      const userType = getEntityType(data.data.roles);
      setEntityId(uid);
      setEntityType(userType);
      helperWidgetAPI.setWidgetUserData({
        userType,
        email: data.data.user_details.email,
        fullname: `${data.data.user_details.first_name ?? ""} ${data.data.user_details.last_name ?? ""}`,
        caller: "useLoginMutation",
      });
      datadogRum.setUser({
        id: uid,
        userType,
      });
      setDefaultTimezone(data.data.user_details.timezone);
      logTutorEvent({ eventName: "marketplace.login.success" });
    },
    onError: (error: AxiosError) => {
      logTutorEvent({ eventName: "marketplace.login.failure", metadata: { code: error.code, message: error.message } });
    },
  });
};

export default useLoginMutation;
