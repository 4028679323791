import { Button, BodyText, Space, LabelText, HeadlineText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { Link } from "react-router-dom";
import Notification from "./Notification";
import useMatomoTracker from "old/src/hooks/useMatomoTracker";
import { logTutorEvent } from "src/utils/log";

type Props = {
  classId: string;
  subjects: string;
  tutorName: string;
  tutorPhoto?: string;
  yearsExperience?: number;
};

const PostSurveyPositive = ({ classId, subjects, tutorName, tutorPhoto, yearsExperience = 0 }: Props) => {
  const subscribeEvent = useMatomoTracker("subscribe-flow");
  return (
    <div sx={sxWrapper}>
      <Notification message="Review submitted" />
      <HeadlineText as="h2">Book their weekly class with {tutorName}</HeadlineText>
      <div sx={sxCard}>
        {tutorPhoto ? (
          <img sx={sxPhoto} src={tutorPhoto} alt="ClassDojo teacher profile" />
        ) : (
          <img
            sx={sxPhoto}
            src="images/logo/logo-100px.png"
            srcSet="images/logo/logo-100px@2x.png 2x"
            alt="ClassDojo teacher profile"
          />
        )}
        <LabelText>{tutorName}</LabelText>
        <div sx={sxStat}>
          <img src="icons/cd-star.svg" alt="Experienced Educator" />
          <BodyText level={2}>
            {yearsExperience > 1 ? <>{yearsExperience} years experience</> : <>Experienced educator</>}
          </BodyText>
        </div>
        <div sx={sxStat}>
          <img src="icons/cd-ribbon.svg" alt="Qualified Tutor" />
          <BodyText level={2}>{subjects}</BodyText>
        </div>
        <div sx={sxStat}>
          <img src="icons/cd-verified.svg" alt="ClassDojo verified teacher" />
          <BodyText level={2}>ClassDojo-certified</BodyText>
        </div>
        <Space size="dt_s" />
        <Link to={`/parents/classes?classId=${classId}`}>
          <Button
            data-name="post_survey_positive:book_weekly"
            onClick={() => {
              subscribeEvent("button-click", "subscription-entered-flow-from-review");
              logTutorEvent("parent.marketplace.subscription.entered_flow_from_review");
            }}
          >
            Book weekly
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default PostSurveyPositive;

const sxWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_l",
  alignItems: "center",
  padding: "dt_xl",

  a: { textDecoration: "none" },
};

const sxCard: ThemeUIStyleObject = {
  border: "dt_card",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "dt_radius_s",
  gap: "dt_xs",
  width: ["100%", null, null, null, "auto"],
  minWidth: [null, null, null, null, 320],
  padding: "dt_xl",
  boxSizing: "border-box",
};

const sxPhoto: ThemeUIStyleObject = {
  display: "block",
  width: 100,
  height: 100,
};

const sxStat: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  gap: "dt_xs",
};
