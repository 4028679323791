import useMutation from "src/hooks/useMutation";
import EventAPI from "src/api/event";

export default () => {
  return useMutation(EventAPI.sendBlueshiftEvent, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });
};
