import useWatch from "@classdojo/web/hooks/useWatch";
import { useRef, useState } from "react";
import { useLocation } from "react-router";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { getEntityType } from "src/hooks/api/auth/useLoginMutation";
import { useManualFeatureSwitch } from "src/hooks/useFeatureSwitches";
import useHelperWidgetAPI, { WindowWithHelperAPIMethods, callDecagonMethod } from "src/hooks/useHelperWidgetAPI";
import { useSessionQuery } from "src/hooks/useSession";
import { logTutorEvent } from "src/utils/log";

const zendeskSettings = {
  contactForm: {
    selectTicketForm: {
      "*": "I need help as a ...",
    },
  },
};

type ZendesScriptProps = {
  setIsLoaded: (_isLoaded: boolean) => void;
};

const ZendeskScript = ({ setIsLoaded }: ZendesScriptProps) => {
  return (
    <Zendesk
      zendeskKey={Config.zendeskWebWidgetKey}
      {...zendeskSettings}
      onLoaded={() => {
        ZendeskAPI("webWidget", "hide");
        setIsLoaded(true);
      }}
    />
  );
};

type DecagonScriptProps = {
  setIsLoaded: (_isLoaded: boolean) => void;
};

const DecagonScript = ({ setIsLoaded }: DecagonScriptProps) => {
  const scriptWrapperRef = useRef<HTMLDivElement>(null);
  const HelperWidgetAPI = useHelperWidgetAPI();

  useWatch([scriptWrapperRef.current], () => {
    if (scriptWrapperRef.current) {
      const script = document.createElement("script");
      script.src = "https://decagon.ai/loaders/classdojo.js";
      scriptWrapperRef.current.appendChild(script);
      script.addEventListener("load", () => {
        callDecagonMethod(() => (window as WindowWithHelperAPIMethods).duet.hide());
        setIsLoaded(true);
        HelperWidgetAPI.setDecagonEventListener();
      });
    }
  });
  return <div ref={scriptWrapperRef}></div>;
};

const HelperWidget = () => {
  const { data: webWidgetExperimentData } = useManualFeatureSwitch("web_marketplace_render_decagon_widget_june");
  const [isZendeskLoaded, setIsZendeskLoaded] = useState(false);
  const [isDecagonLoaded, setIsDecagonLoaded] = useState(false);
  const helperWidgetAPI = useHelperWidgetAPI();
  const { pathname } = useLocation();
  const { sessionData, isFetching } = useSessionQuery();

  useWatch(
    [isZendeskLoaded, isDecagonLoaded, pathname, helperWidgetAPI, webWidgetExperimentData, sessionData, isFetching],
    () => {
      const isOnBookingPage =
        pathname.includes("/single-class/") ||
        pathname.includes("/additional-single-class/") ||
        pathname.includes("/booking/");
      if (isZendeskLoaded && isDecagonLoaded && webWidgetExperimentData && !isOnBookingPage && !isFetching) {
        if (sessionData) {
          const userType = getEntityType(sessionData.roles);
          helperWidgetAPI.setWidgetUserData({
            email: sessionData.user_details.email || "",
            userType,
            fullname: `${sessionData.user_details.first_name || ""} ${sessionData.user_details.last_name || ""}`,
            caller: "helperWidgetWatcher",
          });
          if (webWidgetExperimentData === "test" && userType === "parent") {
            logTutorEvent({
              eventName: "parent.marketplace.decagon_widget_shown",
              metadata: { email: sessionData.user_details.email },
            });
          }
        }

        helperWidgetAPI.showWidget();
      }
    },
  );

  return (
    <>
      <DecagonScript setIsLoaded={setIsDecagonLoaded} />
      <ZendeskScript setIsLoaded={setIsZendeskLoaded} />
    </>
  );
};

interface HelperProviderProps {
  children: React.ReactNode;
}

export default ({ children }: HelperProviderProps) => {
  return (
    <>
      <HelperWidget />
      {children}
    </>
  );
};
