import { Link } from "react-router-dom";
import useStyles from "./styles/Privacy.styles";
import { Box, Text, Image, Divider } from "UILibrary";
import useQueryParams from "src/hooks/useQueryParams";
import { useEffect } from "react";

export const personalInformationLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
    personal information
  </a>
);
export const learnersLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
    learners
  </a>
);

export const serviceProvidersLink = () => (
  <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a>
);

export const deIdentifiedLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
    de-identified
  </a>
);

export const FAQLink = () => <a href="https://classdojo.zendesk.com/hc/articles/12016402266125">FAQ</a>;

export const inactiveLink = () => <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">inactive</a>;

const Privacy = () => {
  const { classes } = useStyles();
  const query = useQueryParams();

  // useWatch too early in render cycle, element may not exist yet
  // cannot use simple # because of hash based routing, urls have two #

  useEffect(() => {
    if (query.ref) {
      const destination = document.getElementById(query.ref);
      if (destination) {
        destination.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [query]);

  return (
    <Box className={classes.forTeachers}>
      <Box className={classes.wrapper}>
        <Box className={classes.hero} sx={{ marginBottom: "dt_l" }}>
          <Box>
            <Text component="h1">Privacy Policy Highlights</Text>
            <Box mb={16} />
            <Text className={classes.subHeader} sx={{ color: "#7174A0" } as any}>
              Last Updated: April 15, 2024
            </Text>
            <Text>
              You can view a previous version of our Privacy Policy here.{" "}
              <Link to="/privacy-jan-2023">https://tutor.classdojo.com/#/privacy-jan-2023</Link>
            </Text>
          </Box>
        </Box>

        <Box mb={8}>
          <Text className={classes.paragraph}>
            We believe you should always know what data we collect from you, how we use it, and that you should have
            meaningful control over both. We want to empower users to make the best decisions about the information they
            share with us. In fact, we made a{" "}
            <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
              short video
            </a>{" "}
            for children that highlights the most important details in our Privacy Policy that they should know about.
          </Text>
        </Box>

        <Box mb={8}>
          <Text className={classes.paragraph}>
            Our privacy policy is based on several core principles. These principles should not be considered a legal
            document — the full <Link to="#dojo-tutoring-privacy-policy">privacy policy</Link> below is — but they are
            what underlie the privacy policy.
          </Text>
        </Box>

        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Transparency and Choice:</b> We tell you what data we collect, why we collect it, and how it’s used,
              retained, and shared with others. We are transparent about our practices so that you can make meaningful
              choices about how your{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              is used. If we make a material change, we will provide prominent notice by posting a notice on our service
              or this website page and/or we will notify you by email (if you have provided an email address to us). See{" "}
              <Link to="#providing-transparency-rights">here</Link> for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Personal Information We Collect:</b> Please see <Link to="/transparency">this page</Link> where we
              detail the{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              we collect from each user type, including the sources from which the information was collected, the
              business purpose for which the information was collected, and the third parties with whom we share or
              disclose personal information for a business purpose. You can also find more details{" "}
              <Link to="#what-information-does-dojo-tutor-collect">below</Link>.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>How We Use the Information We Collect:</b> We use the{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              we collect from {learnersLink()} (or about learners from tutors or their parents) to provide and improve
              (as allowed by law) the Service, for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_0f871510-2886-46eb-9bb4-e7ae17080447">
                {" "}
                educational purposes
              </a>{" "}
              and as allowed by law, for security and safety purposes, including enforcing our Terms, or to comply with
              legal process. We will not require learners to provide more personal information than is reasonably
              necessary to participate in the Service. For additional information regarding our use of information
              collected from tutors and other users, see <Link to="#how-does-dojo-use-info">here</Link>.
            </Text>
          </li>

          <li>
            <Text className={classes.listText}>
              <b>We Never Sell Personal Information:</b> We will never{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
                sell
              </a>{" "}
              or rent your{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              or non-personal information. We will only share or disclose personal information as set forth in the
              Privacy Policy, such as with a limited set of{" "}
              <Link to="/third-party-service-providers/">third-party</Link> service providers necessary to provide or
              develop our Services (such as database hosting) or as required by law, and we will be transparent about
              who these service providers are. We will contractually require that these service providers process
              personal information in accordance with our instructions and consistent with this Privacy Policy. We
              ensure that these service providers employ reasonable and comprehensive data protection and security
              protocols. See <Link to="#how-we-share-you-personal-information">here</Link> for more details.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>We Never Use Learner Information for Behaviorally Targeted or Third-Party Advertising:</b> Information
              collected from learners (including personal information and information collected automatically) is never
              used or disclosed for
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
                {" "}
                third-party advertising
              </a>
              . We also do not place any{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
                third-party advertisements
              </a>{" "}
              in learner logged-in areas of the Service. Additionally, personal information collected from learners is
              never used for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
                behaviorally-targeted advertising
              </a>{" "}
              to learners by us or any third-party. We may present{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
                contextually relevant
              </a>{" "}
              educational content (not using learner personal information) for either our own products or third-party
              products and services ourselves that we believe may be of interest to a learner (e.g., our showing a child
              additional tutoring sessions for other school subjects they may be interested in).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Data Retention:</b> We will not retain learner{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              for any longer than is reasonably necessary to fulfill the purpose for which the information was
              collected, including, to provide and maintain the Service for which we receive or collect the learner
              personal information, keep our platform safe and secure, or to comply with legal obligations. We store
              non-learner user personal information for as long as it is necessary to provide products and Services to
              you and others. For non-learner users, personal information associated with your account will be kept
              until your account is deleted or until we no longer need the data to provide the Service, whichever occurs
              first. Please see <Link to="#how-long-does-dojo-keep-information">here</Link> for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>No Learner’s Information is Shown Publicly:</b> No{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learner
              </a>
              ’s account or profile is made available or visible to the general public through the Service. For a list
              of what logged-in learner users’ information can be viewed by other logged-in Dojo Tutor Service users,
              please see our {FAQLink()}.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>You Own Your Data:</b> We don’t own any content or information you provide or we receive — you (
              {learnersLink()}, tutors, and/or parents) will own your content and information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Security and Privacy by Design and Default:</b> We use security industry best practices to protect
              personal information, including using encryption and other security safeguards to protect personal
              information. We design products with security and privacy in mind from day one and also consult with
              outside experts to help us test and improve our controls. See{" "}
              <Link to="#how-does-tutor-protect-information">here</Link> for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Right to Access, Correction, and Deletion of Data:</b> You can access, correct, and delete your
              personal information. See <Link to="#providing-transparency-rights">here</Link> for more information on
              all user’s rights, including about your additional rights of data portability and right to object or
              withdraw consent.
            </Text>
          </li>
        </ul>
        <Box
          className={classes.hero}
          sx={{ marginBottom: "dt_l", marginTop: "dt_l", scrollMarginTop: "130px" }}
          id="dojo-tutoring-privacy-policy"
        >
          <Box>
            <Text component="h1">Dojo Tutor Privacy Policy</Text>
            <Box mb={16} />
            <Text className={classes.subHeader} sx={{ color: "#7174A0" } as any}>
              Last Updated: April 15, 2024
            </Text>
            <Text>
              You can view a previous version of our Privacy Policy here.{" "}
              <Link to="/privacy-jan-2023">https://tutor.classdojo.com/#/privacy-jan-2023</Link>
            </Text>
          </Box>
        </Box>
        <Box>
          <h3>Introduction and Scope</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutor (<b>“Dojo Tutor”</b>, <b>“us”</b>, <b>“we”</b>, or <b>“our”</b>) is a platform that helps
            children to further their learning by providing online live tutoring for children ages 4-16 and operates the
            website located at <a href="https://tutoring.classdojo.com/">https://tutoring.classdojo.com</a> (
            <b>“Website”</b>) as well as the areas of the Website where users login to use the Dojo Tutor platform (the{" "}
            <b>“Tutoring Platform”</b>), our mobile applications (the <b>“Dojo Tutor Apps”</b>), or any of our other
            products or services that link to this Privacy Policy that Dojo Tutor may provide now or in the future
            (collectively, the <b>“Service(s)”</b>). Dojo Tutor may be acting as both a Controller and a Processor as
            those terms are defined under the European Union’s General Data Protection Regulation, including as
            implemented or adopted under the laws of the United Kingdom (“GDPR”) or as defined under any other
            applicable state privacy law in the United States. For more information, please see{" "}
            <Link to="#is-dojo-tutoring-a-controller">below</Link>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We respect the privacy of our website visitors, learners, and tutors (referred to as <b>“you”</b> or{" "}
            <b>“your”</b>) and the personal information you share with us. This privacy policy (as posted on our Website
            and as amended from time to time (<b>“Privacy Policy”</b>)) explains:
          </Text>
          <ul>
            <li>
              <Text className={classes.listText}>
                What information Dojo Tutor collects from you (and why we collect it),
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>How we use and share that information,</Text>
            </li>
            <li>
              <Text className={classes.listText}>
                The choices and rights you have, including how to access, update, delete your information, and take your
                information out of Dojo Tutor.
              </Text>
            </li>
          </ul>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We will only collect, use, and share your personal information in accordance with this Privacy Policy. This
            Privacy Policy applies solely to the Dojo Tutor Services and the data collected by us, or those working on
            our behalf. This Privacy Policy also covers Dojo Tutor’s treatment of any personal information about our
            users that our partners or other services share with us, for example information shared from Zoom, or data
            imported from authorized and approved sources.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph} sx={{ fontWeight: "bold" }}>
            This Privacy Policy also does not apply to the ClassDojo product available at the logged in section of{" "}
            <a href="https://www.classdojo.com">https://www.classdojo.com</a> (the “ClassDojo Platform”), or the
            associated mobile applications (the “ClassDojo Apps”) (ClassDojo Platform and ClassDojo Apps collectively,
            the “ClassDojo Main Services”) or the informational website located at{" "}
            <a href="https://www.classdojo.com">https://www.classdojo.com</a> (the “ClassDojo Website”). The ClassDojo
            Main Services Privacy Policy is located <a href="https://www.classdojo.com/privacy/">here</a> and the
            ClassDojo Website Privacy Policy is located <a href="http://www.classdojo.com/website-privacy">here</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            This Privacy Policy also does not apply to data collected through other websites, products, or services that
            Dojo Tutor does not own or control such as third-party services you might access through links or other
            features (e.g., social media buttons, email campaigns, push or in-app notifications, or YouTube videos) on
            the Service. These other services have their own privacy policies, and we encourage you to review them
            before providing them with personal information.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Our Privacy Policy is designed to provide transparency into our privacy practices and principles. We’ve done
            our best to write this policy in simple, clear terms, but if you’re not familiar with terms like personal
            information, cookies, IP address, pixel tags, and browsers, read about these{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms">
              key terms
            </a>
            .
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            For learners and children, we have a{" "}
            <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
              short video
            </a>{" "}
            that highlights the most important details in our Privacy Policy that they should know about. We’ve also
            added summaries below each section that provide short explanations in plain English (they start with
            “Basically …’) to aid in understanding, but aren't legally binding.
          </Text>
        </Box>
        <br />
        <Box sx={{ display: "grid", gridTemplateColumns: "60px 60px", columnGap: "dt_s" }}>
          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/spp_signatory+square_lg.jpg"
            alt="ClassDojo teacher profile"
            width="60px"
          />

          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/ikeepsafe_coppabadge.png"
            alt="ClassDojo teacher profile"
            width="60px"
          />
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We hope you find this clear and friendly. If you have any questions, we’re here to help. You can visit the{" "}
            <a href="http://www.classdojo.com/privacycenter">ClassDojo Privacy Center</a> to learn more about how we
            protect your privacy, or you can contact us as set forth in the{" "}
            <Link to="#contact-information">Contacting Dojo Tutor</Link> section below.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Protecting your privacy is incredibly important to us. This Privacy Policy is here to help you understand
            how we treat any{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            that you share with us when you use the Dojo Tutor Services. This Privacy Policy applies to every Dojo Tutor
            product - other companies that Dojo Tutor doesn’t own or control will have their own privacy policies, and
            you should read them, too! This Privacy Policy applies solely to the Dojo Tutor Services and does not apply
            to the ClassDojo Main Services or the informational ClassDojo Website - you can find the ClassDojo Main
            Services Privacy Policy <a href="https://www.classdojo.com/privacy/">here</a> and ClassDojo Website Privacy
            Policy <a href="https://www.classdojo.com/website-privacy/">here</a>.
          </Text>
        </Box>
        <br />
        <Divider />
        <br />
        <Box>
          <Text weight="bold" underline>
            Quick Reference
          </Text>
          <ul>
            <li>
              <Text className={classes.listText}>
                <Link to="#acknowledgment-and-consent">Acknowledgment and Consent</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#dojo-tutor-privacy-certifications">Dojo Tutor Privacy Certifications</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-does-dojo-tutoring-protect-children">How Does Dojo Tutor Protect Children?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#what-information-does-dojo-tutor-collect">What Information Does Dojo Tutor Collect?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#online-analytics-advertising-and-do-not-track">
                  Online Analytics, Advertising, and Do-Not Track
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-does-dojo-use-info">How Does Dojo Tutor Use the Information It Collects?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-we-share-you-personal-information">How We Share Your Personal Information</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-does-tutor-protect-information">
                  How Does Dojo Tutor Protect and Secure My Information?
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-long-does-dojo-keep-information">
                  How Long Does Dojo Tutor Keep Information About Me?
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#providing-transparency-rights">
                  Dojo Tutor Commitments to Providing Transparency and Your Rights
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#additional-article-27-GDPR">
                  Additional Information and Assistant and Article 27 GDPR Local Representative
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#communications-from-dojo-tutor">What Communications Will I Receive from Dojo Tutor?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#third-party-authentication-services">Third-party Authentication Services</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#third-party-service-providers">Third-Party Services</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#what-if-i-am-not-in-the-united-states">What if I am not in the United States?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#us-state-privacy-disclosures">U.S. State Data Privacy Disclosures</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#changes-to-our-privacy-policy">Changes to our Privacy Policy</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#contact-information">How Can I Contact Dojo Tutor with Questions?</Link>
              </Text>
            </li>
          </ul>
        </Box>
        <Box sx={{ scrollMarginTop: "130px" }} id="acknowledgment-and-consent">
          <h3>Acknowledgment and Consent</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            By visiting our Website or Tutoring Platform, attending a tutoring session, applying to become a tutor, or
            using our Services in any manner, you acknowledge that you accept the terms, practices and policies
            described in this Privacy Policy (and as updated from time to time), and you hereby agree that we may
            collect, use, and share your information as described herein. If you do not agree with our policies and
            practices, you should not use our Website or our Services. Your use of the Website and our Services is at
            all times subject to our <a href="termsofservice">Terms of Use</a> (as updated from time to time (the “
            <b>Terms</b>”)), which incorporates this Privacy Policy. Any capitalized terms we use in this Privacy Policy
            without defining them have the definitions given to them in the Terms.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            By visiting our Website, using our Services or applying to become a tutor, you agree that we may collect,
            use and share your information as described in our Privacy Policy.
          </Text>
        </Box>
        <Box>
          <br />

          <Box sx={{ scrollMarginTop: "130px" }} id="dojo-tutor-privacy-certifications">
            <h3 className={classes.paragraph}>Dojo Tutor Privacy Certifications</h3>
          </Box>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutor participates in the iKeepSafe Safe Harbor program. iKeepSafe has granted Dojo Tutor the COPPA (as
            defined below) Safe Harbor seal signifying its Website and Tutoring Platform have been reviewed and approved
            for having policies and practices surrounding the collection, use, maintenance and disclosure of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            from children are consistent with the iKeepSafe COPPA Safe Harbor program guidelines.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutor is also a signatory to the Student Privacy Pledge. We agree to a set of principles intended to
            safeguard student privacy, including responsible stewardship, protection, and transparent handling of
            student personal information. Read more about the Student Privacy Pledge{" "}
            <a href="https://studentprivacypledge.org/signatories/">here</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            For more information about COPPA and generally protecting children's online privacy, please visit{" "}
            <a href="http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online">
              OnGuard Online
            </a>
            .
          </Text>
        </Box>
        <br />
        <Box sx={{ display: "grid", gridTemplateColumns: "60px 60px", columnGap: "dt_s" }}>
          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/spp_signatory+square_lg.jpg"
            alt="ClassDojo teacher profile"
            width="60px"
          />

          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/ikeepsafe_coppabadge.png"
            alt="ClassDojo teacher profile"
            width="60px"
          />
        </Box>
        <br />

        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Dojo Tutor is certified <a href="http://ikeepsafe.org/privacy/coppa/">COPPA-compliant</a>, and has also
            signed the <a href="https://studentprivacypledge.org/signatories/">Student Privacy Pledge</a>.
          </Text>
        </Box>
        <br />
        <Box id="how-does-dojo-tutoring-protect-children">
          <h3 className={classes.paragraph}>How Does Dojo Tutor Protect Children?</h3>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please see our <Link to="/child-privacy-terms">Children's Privacy Policy here.</Link>
          </Text>
        </Box>
        <br />
        <Box sx={{ scrollMarginTop: "130px" }} id="what-information-tutoring-collect">
          <h3 className={classes.paragraph}>What Information Does Dojo Tutor Collect from Children?</h3>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please see our{" "}
            <Link to="/child-privacy-terms?ref=what-information-tutoring-collect">Children's Privacy Policy here.</Link>
          </Text>
        </Box>
        <Box mb={8}>
          <Text weight="bold" id="information-children-provide-to-us">
            Information Children Provide to Us
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please see our{" "}
            <Link to="/child-privacy-terms?ref=information-children-provide-to-us">
              Children's Privacy Policy here.
            </Link>
          </Text>
        </Box>
        <Box>
          <Text weight="bold" id="information-collected-about-the-child-from-parents-and-tutors-combined">
            Information Collected About the Child from Parents and Tutors Combined with Children’s Data
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Please see our{" "}
            <Link to="/child-privacy-terms?ref=information-collected-about-the-child-from-parents-and-tutors-combined">
              Children's Privacy Policy here.
            </Link>
          </Text>
        </Box>
        <br />

        <Box>
          <h3>How Does Dojo Tutor Use the Information it collects from Children?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Please see our{" "}
            <Link to="/child-privacy-terms?ref=how-does-dojo-tutor-use-the-information">
              Children's Privacy Policy here.
            </Link>
          </Text>
        </Box>
        <br />

        <Box sx={{ scrollMarginTop: "130px" }} id="how-long-keeps-children-information">
          <h3>How Long Does ClassDojo Keep Children’s Information?</h3>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please see our{" "}
            <Link to="/child-privacy-terms?ref=how-long-keeps-children-information">
              Children's Privacy Policy here.
            </Link>
          </Text>
        </Box>
        <br />

        <Box sx={{ scrollMarginTop: "130px" }} id="parental-choices">
          <h3>Parental Choices and Controls</h3>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please see our <Link to="/child-privacy-terms?ref=parental-choices">Children's Privacy Policy here.</Link>
          </Text>
        </Box>
        <Box>
          <Text weight="bold">How Does ClassDojo Obtain Consent?</Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please see our{" "}
            <Link to="/child-privacy-terms?ref=how-does-classdojo-obtain-consent">Children's Privacy Policy here.</Link>
          </Text>
        </Box>
        <br />
        <Box sx={{ scrollMarginTop: "130px" }} id="what-information-does-dojo-tutor-collect">
          <h3>What Information Does Dojo Tutor Collect About You?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We collect two types of information about you: (1) information that you voluntarily provide us by using the
            Service or Website (described below under{" "}
            <Link to="#what-information-does-dojo-tutor-collect">“Information You Provide to Us”</Link>) and (2)
            information collected automatically as result of your use of the Service (described below under{" "}
            <Link to="#information-we-collect-through-automatic-data-collection">
              “Information We Collect Through Automatic Data Collection Technologies”
            </Link>
            ). We may also receive information about you from third-party sources (as described below under{" "}
            <Link to="#information-received-from-third-party-sources">
              “Information Received from Third-Party Sources”
            </Link>
            ). The types and amounts of information collected will vary depending on whether the user is a learner,
            tutor, parent, or general website visitor and how they use Dojo Tutor (e.g., if a tutor wants to apply to
            become a tutor, we may need to collect certain employment related information). We have also created{" "}
            <a href="https://tutoring.classdojo.com/transparency">this chart</a> with the detailed categories of{" "}
            {personalInformationLink()} we collect from each user type, including the sources from which the information
            was collected, the business purpose for which the information was collected, and the third parties with whom
            we disclose personal information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Dojo Tutor collects information from you directly (such as account information) and also collects some
            information automatically (such as crash reports when errors occur) in order to provide you with the best
            possible Dojo Tutor experience. Please see{" "}
            <a href="https://tutoring.classdojo.com/transparency">this chart</a> for the detailed categories of personal
            information we collect from each user type, including the sources from which the information was collected,
            the business purpose for which the information was collected, and the third parties with whom we disclose
            personal information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <Text
            weight="bold"
            underline
            sx={{ fontSize: "20px", scrollMarginTop: "130px" }}
            id="what-information-does-dojo-tutor-collect"
          >
            Information You Provide to Us
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We ask for and collect the following {personalInformationLink()} about you when you use the Service or
            Website. This information is necessary for the adequate performance of the contract between you and us, for
            our legitimate interest in being able to provide and improve the Service and Website, and to allow us to
            comply with our legal obligations. Without it, we may not be able to provide you with all the requested
            services. Our use of the information set forth below is described in the section{" "}
            <Link to="#how-does-dojo-use-info">“How Does Dojo Tutor Use the Information it Collects.”</Link>
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            There are currently three categories of users on our Service:{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>
            , tutors and parents and/or legal guardians. Additionally, as a non-logged-in visitor to the Dojo Tutor
            Website, you may provide personal information to us as set forth below. We describe the information
            collected from learners in the{" "}
            <Link to="#how-does-dojo-tutoring-protect-children">“How Does Dojo Tutor Protect Children”</Link> section
            above; for other users, we collect and store the following types of information from each type of user:
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Account Sign-up and User Profile Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Information you provide when registering for a tutoring session as a parent (or registering your child as
              a learner) or becoming a tutor, such as first and last name (and the name of your child if a parent),
              email address, password, unique username, zip code, country and phone number (e.g, if you want to receive
              SMS reminders). We may also ask you (as a parent) for the date of birth or age of your child to help us
              both comply with laws (such as COPPA and Age-Appropriate Design Codes) and assist with providing the
              appropriate Services to your child.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Any additional optional profile information you may provide during registration or related to your
              profile, such as a photo (or avatar), more detailed profile information (e.g., hobbies), responses to
              questions such as “how you heard about Dojo Tutor”, other ClassDojo profile (e.g., your ClassDojo Main
              Services parent profile), and other login credentials you might decide to link your Dojo Tutor account
              (e.g.,login credentials from your ClassDojo Main Services account for parents).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              As a parent, you may choose to associate your account with an existing ClassDojo account you have for your
              child’s school on the ClassDojo Main Services. For parents, Dojo Tutor may also pre-populate your Dojo
              Tutor sign-up and profile information as well as your child’s name with your ClassDojo Main Services login
              credentials and child’s name if you elect to sign-up to Dojo Tutor through the ClassDojo Main Services.
              Please note that the personal information and content (such as tutoring assessments) contained in two
              parent accounts will be linked and accessed by both accounts, but any information related to your child’s{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/4413231512205-What-are-Student-Accounts-and-Outside-School-Child-Accounts">
                student account
              </a>{" "}
              on the ClassDojo Main Services will be stored separately and only viewable through your ClassDojo Main
              Services login. While parent and learner profiles may be viewed by other parents, tutors, or teachers; no
              parent or learner profile is made available or visible to the general public through the functionality of
              our Dojo Tutor Service.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold" id="tutor-application-and-certification-info">
            Tutor Application and Certification Information
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Information you may provide when applying to become a certified tutor, such as relevant employment or
              professional related experience (e.g., number of years as a teacher and which grades you taught, how long
              you have been a tutor and what subjects you’ve provided tutoring on, whether you have experience with
              tutoring online, etc.). Tutors may also provide the information in the below “
              <Link to="#edudcation-or-other-demographic-info">Education or other Demographic Information</Link>”
              category.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Videos a tutor submits of themselves responding to certain interview questions. These videos are used to
              help select qualified tutors for the Service.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Contract Signing information. We use a{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers/">third party service providers</a>{" "}
              to help facilitate tutors signing consulting agreements with Dojo Tutor and for supporting tutors on
              creating their tax filings. Tutors may share with these third parties their full name, email, phone
              number, physical address and signature. Through our partners’ third party platforms, we can access the
              personal information the tutor shared when signing the contract, including their full name, email, phone
              number, physical address and signature.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Background check information</b>. With the consent of the tutor, Dojo Tutor performs background checks
              (which includes Sex Offender Search (sources include but are not limited to National Sex Offender Public
              Website and national and state sources), Global Watchlist Search (sources include but are not limited to
              Office Of Inspector General, European Union Consolidated List, Drug Enforcement Agency Fugitives,
              Government Sanction Databases, US Terrorist List), National Search (sources include but are not limited to
              Warrant sources, Department of Corrections sources, Arrest sources, Court sources), and County Searches
              (sources include but are not limited to County Courthouse records) on all tutor applications using a{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers/">third party service provider</a>.{" "}
              Applicants will share with this service provider their full name, phone number, email address, date of
              birth, social security number, zip code and electronic signature. Dojo Tutor then has access to the
              applicant’s background check information (through accessing the third party’s portal) and the last four
              digits of the Social Security Number.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold" id="edudcation-or-other-demographic-info">
            Education or other Demographic Information
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Education information you may provide as a tutor include, but are not limited to, school name and
              location; whether you were home schooled or went to a private or public school; graduation year; grades or
              transcripts; and additional academic records pertaining to tutor applications and honorary certifications
              (e.g. assessments, standardized test scores). Tutors may submit their grades or transcripts with their
              personal information removed. Once the tutor’s certification is established, the tutor may request Dojo
              Tutor to delete their grades, transcripts or other academic records.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Education information you may provide as a parent about your child: school name and location; whether your
              child was home schooled or went to a private or public school; graduation year; grade your child is in;
              grades or transcripts (for tutors, for learners by parents); and additional academic records).{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              High-level details about schooling (e.g., stage of learner — K-12 ) that parents are required to provide
              about their child during registration for a tutoring session.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Demographic information you may optionally provide as a tutor (or a parent about your child) such as your
              (or your child’s) name, date of birth, gender pronouns, and languages spoken, extra support received or
              your child’s IEP needs.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Qualitative description of how your child is doing and where they need the most tutoring help.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>Interests of your child (e.g., art, basketball, dance, swimming)</Text>
          </li>
          <li>
            <Text className={classes.listText}>Tutoring subject areas </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Tutoring Session Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Information collected from both{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learners
              </a>{" "}
              and tutors such as tutoring subject areas and tutoring attendance and how long participants stayed in Zoom
              (reported from Zoom). The attendance data is shown to both the tutor and learner (to confirm they are
              following the attendance policy) and may also be shown to parents.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Information collected when hosting a tutoring session, such as tutoring description from the tutor.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Zoom Recordings</b>: Certain information is captured through your participation as a tutor in a
              tutoring session such as your image, voice, movements, and other audio information as well as chat logs or
              other information provided during the tutoring session. Dojo Tutor currently uses{" "}
              <a href="https://explore.zoom.us/en/isv/">Zoom ISV Program</a> to host its tutoring sessionssessions, but
              has a Data Protection Addendum and other contracts in place with Zoom that mandate that Zoom apply its
              Children’s Educational Privacy Statement found{" "}
              <a href="https://explore.zoom.us/en/schools-privacy-statement/">here</a> to Dojo Tutor’s use of Zoom. The
              tutoring sessions (including transcripts and Zoom chat logs) are recorded from both {learnersLink()} (your
              child) and tutors to ensure the safety and quality of tutoring on the Services as well as to provide and
              maintain the service (such as to create transcripts of the tutoring sessions as set forth below). These
              recordings are automatically deleted by Zoom after 14 days (unless we are required by law to retain it,
              need it to ensure the security of our community or our Service, or to enforce our Terms). To learn more,
              read the <a href="https://explore.zoom.us/en/privacy/">Zoom Retention Policy</a>. Separately, within the
              14-day period in which Zoom retains these recordings, Dojo Tutor may either download, store and retain the
              recordings (or elect to have Zoom retain these) for up to an additional 30 days to provide and maintain
              the service, conduct internal analysis, and keep our platform safe and secure.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Tutoring Session Transcripts</b>: See “
              <Link to="/child-privacy-terms?ref=tutoring-session-info">Tutoring Session Transcripts</Link>” section in
              the Children’s Privacy Policy.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Messaging, Assessments and Other Communications</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Parent Reviews and Feedback on Sessions</b>: Parents may choose to provide tutors and Dojo Tutor with
              feedback or provide “reviews” on their child’s tutoring sessions. The reviews may be publicly posted.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Tutors’ assessments of learners</b>: Tutors may provide learners with both positive and constructive
              feedback that can further their learning (e.g., areas of strength or improvement) as well as recommended
              activities, resources and a work plan. The feedback is shared with the learner and their parents, and may
              be shared by the parent to other family members or teachers, but is not made available to the general
              public. Tutors may also edit and provide further human review of various AI Output generated in connection
              with assessments and the Tutoring Session Transcripts. A tutor’s assessment may also be shared
              automatically by Dojo Tutor (unless a parent opts-out) with new tutors assigned to a learner.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Direct messages and group chats on the Services</b>: A user may choose to direct message other users
              (either another individual or in a group associated with a tutoring series) such as for logistics
              corresponding to a tutoring session. These messages are shared with the other users in the messaging group
              as well as safety monitors that ensure the communications on our platform remain safe.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Collaboration Features</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Sharing Content with Teachers</b>. Tutoring session feedback, tutoring session information (such as
              session recordings), or assessments shared at the direction of the parent to their child’s teacher will
              occur at the parent’s initiation and are to be shared for the teacher’s information purposes only. While
              Dojo Tutor may match the teacher’s first and last name, school information, and email address from an
              existing in-school ClassDojo Main Services account to enable the feedback to be shared, Dojo Tutor will
              not make this feedback or assessment downloadable to the teacher or accessible in any in-school ClassDojo
              account, nor will it treat such feedback or assessments as an{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_63d91d2f-19b7-44bd-8373-649884e75fca">
                education record
              </a>{" "}
              or{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_63d91d2f-19b7-44bd-8373-649884e75fca">
                student data
              </a>{" "}
              under the{" "}
              <a href="https://www.govinfo.gov/content/pkg/USCODE-2011-title20/pdf/USCODE-2011-title20-chap31-subchapIII-part4-sec1232g.pdf">
                Family Educational Rights and Privacy Act of 1974
              </a>{" "}
              (“<b>FERPA</b>”) until such time as Dojo Tutor has entered into a contractual relationship to provide such
              Dojo Tutor Services to the school or district.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Sharing Content with others</b>. Tutoring session feedback or assessments shared at the direction of
              the parent to other users (e.g. other family members) may occur at the parent’s or family member’s
              initiation. An exception to this is the tutor assessments that Dojo Tutor will share automatically (unless
              the parent opts out) with a newly assigned tutor. While Dojo Tutor may collect the user's first and last
              name and email address to enable the feedback or assesment to be shared, Dojo Tutor will not otherwise use
              this information provided by the parent to contact the individual (unless they separately have an account
              on Dojo Tutor).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Inviting Others</b>: As a tutor or parent, you can invite other family members or tutors to join the
              Service, for example by providing their email address or phone number (to invite them via SMS) to Dojo
              Tutor. When a tutor joins the Service via an invite from a parent, they can see the learner’s first and
              last names, their parents’ first and last name and email address.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Please keep in mind that any information (including {personalInformationLink()}) or content that you
              voluntarily disclose to others (including non-users) can be viewed, copied, stored, and used by the
              individuals you share it with. Dojo Tutor cannot control the actions of people with whom you choose to
              share information and we are not responsible for the collection, use, or disclosure of such information or
              content by others.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Content (photos, videos, photographs, documents or audio files)</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Please see the “
              <Link to="#tutor-application-and-certification-info">
                Tutor Application and Certification Information
              </Link>
              ” category, and the “<Link to="#tutoring-session-info">Tutoring Session</Link>” category listed above.{" "}
              Note that tutors and parents may also upload photos to their profiles.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Geolocation</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Dojo Tutor does not collect precise geolocation information without user permission. Dojo Tutor collects
              non-precise geolocation information such as{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f2f016f2-e461-4443-8c29-6bb597063447">
                IP address
              </a>{" "}
              information and zip code and may collect certain automatically collected location information as set forth{" "}
              <Link to="#information-we-collect-through-automatic-data-collection">below.</Link>
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Billing and Payment Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              When you purchase a tutoring session as a parent or if you are a tutor and Dojo Tutor is paying you for
              your tutoring session, we may collect certain {personalInformationLink()} to process these transactions.
              We do not collect any credit card information directly from parents to purchase tutoring sessions, however
              we may receive, from our payment processor, the last four digits of your credit card to search for
              specific transactions in case we want to issue a refund or other related customer support assistance.
              Additionally, we do not collect any bank account information from tutors to process their payments for
              conducting the sessions, nor is any bank account information passed back to us. Please see{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers">here</a> for a list of what
              information we receive from third-parties in connection with your payment method as well as what purchase
              events and analytics we track through our service providers in connection with your purchases. These
              purchase events and analytics are collected for internal analysis on product usage and purchase. We may
              also use it to track purchases that you did not make, and for sending push and email notifications to a
              parent’s email or device to encourage them to take actions within the Service (such as finalizing a
              purchase).
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Survey Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Your responses to surveys that we or our{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a> might ask you
              to complete for internal analytics purposes, marketing, and product improvement. These surveys may also
              ask for your name and email as well as other free form and multiple-choice fields. We may also ask parents
              for information about important upcoming dates (e.g., parent-teacher conference dates) as well as survey
              questions related to how the school year has been going for their child to get a sense of how their child
              is doing in school and to personalize their experience as needed. Dojo Tutor will not collect survey
              information from learners under the age of 13.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Conduct and Safety Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              If a user reports another user on the Service (e.g., such as through a “report abuse” button) for conduct
              or other safety issues, Dojo Tutor collects this information to investigate the report.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Contact Information and Correspondence with Dojo Tutor</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              As a user of the Service or Website, you may choose to provide us with your {personalInformationLink()},
              such as a name, email address, and telephone number. Some examples include when you send us an email
              asking a question, communicate with customer support, participate in a video testimonial about our
              Service, or choose to participate in any research efforts, including a customer feedback session, with
              Dojo Tutor to improve the Service. We collect records and copies of your correspondence (including email
              address and chat messages) when contacting us for support. When you send us a message using the "Contact
              Us" page or via email, the email addresses and phone numbers collected are not further used to market to
              the individual beyond providing the services requested or responding to the requests.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Testimonials</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              We may collect certain {personalInformationLink()} (such as your name, photo, and/or video) if you choose
              to give us a testimonial. We post testimonials on our Service which may contain this personal information
              in the testimonial. We obtain the individual’s consent in advance to ensure we have permission to post
              this content publicly. To request the removal of your personal information from our testimonials, please
              contact us at <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com.</a>
            </Text>
          </li>
        </ul>
        <br />
        <Box>
          <Text weight="bold">Detailed Personal Information collected by Dojo Tutor</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              We have also prepared <Link to="/transparency">this chart</Link> which describes in detail the categories
              of {personalInformationLink()} we collect from each user type, including the sources from which the
              information was collected, the business purpose for which the information was collected, and the third
              parties with whom we disclose personal information for a business purpose.
            </Text>
          </li>
        </ul>
        <br />
        <Box>
          <Text weight="bold">Mobile App Permissions</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              If you are using the Dojo Tutor App, we may ask you for certain permissions – please see{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/204422195">here</a> for more detail about those
              permissions.
            </Text>
          </li>
        </ul>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We ask you for different types of information based on who you are (i.e., tutor, parent, or learner) and
            which Dojo Tutor features you use (e.g., sending a message between a tutor and a parent, or conducting a
            tutoring session via Zoom). We have also prepared{" "}
            <a href="https://tutoring.classdojo.com/transparency">this chart</a> of the personal information we collect
            from each user type, including details on the sources from which the information was collected, the business
            purpose for which the information was collected, and the third parties with whom we disclose personal
            information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <h3 id="information-we-collect-through-automatic-data-collection">
            Information We Collect Through Automatic Data Collection Technologies
          </h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            When you use the Service or Website, we (or our {serviceProvidersLink()}) may use various technologies that
            automatically record or collect certain information, including {personalInformationLink()}, from your
            browser or your device about the services you use and how you use them. This information is necessary for
            the adequate performance of the contract between you and us, to enable us to comply with legal obligations
            and given our legitimate interest in being able to provide and improve the Service and Website. For example,
            this could include the frequency and duration of your visits to Dojo Tutor or the Website (similar to TV
            ratings that indicate how many people watched a particular show). If you use Dojo Tutor on different
            devices, we may link the information we collect from those different devices to help us provide a consistent
            Service across your different devices. If we do combine any automatically collected information with
            personal information, we will treat the combined information as personal information, and it will be
            protected as per this Privacy Policy.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>The technologies and information we automatically collect include:</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Cookies and other similar technologies:</b> We (or our {serviceProvidersLink()}) may use{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_3cee3e5e-c072-4fc2-821d-84258d56c2c4">
                cookies or similar technologies
              </a>{" "}
              to identify your browser or device. We (or our service providers) may also use these technologies (never
              in {learnersLink()}' logged-in areas of our Service) in connection with advertising of our Service that
              may appear on other sites or in connection with advertising their products outside of our Service (e.g.,
              if you view an embedded YouTube video player on our Service, YouTube may place cookies or similar
              technologies on your browser when you play the video off our site). We don’t allow these third-parties to
              advertise directly on our Service (i.e., such as when an advertiser would bid to place an advertisement
              directly on a platform such as Facebook), but we may serve{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
                contextually relevant
              </a>{" "}
              advertising for third-party products and services ourselves that we believe may be of interest to you
              (e.g., tutoring for other subjects that your kid might benefit from). Please read our{" "}
              <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a> for
              more details, including how to modify your cookie settings and a list of the tracking technologies we use.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_efdb3ae6-8829-4e77-a8e0-b47c865fbe1b">
                Local storage:
              </a>{" "}
              We may also use, collect, and store information and preferences locally on your device using mechanisms
              such as browser-based web storage (including HTTP cookies and HTML5) and application data caches. Like
              many services, Dojo Tutor (or our service providers) uses both local storage and cookies and other similar
              technologies to analyze trends, gather demographic information about our users, understand your engagement
              with the Service and Website, administer the Service and Website, tailor the Service and Website for you,
              and to help the Service and Website work better for you - for example, by remembering your language
              preferences or other information so that you will not have to re-enter it during the next time you visit
              the Website or Services. Please read our{" "}
              <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a> for
              more details.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Device information:</b> We collect device-specific information such as your device type, device brand,
              operating system, hardware version and settings, device settings, file and software names and types,
              battery and signal strength, and device identifiers (including unique device IDs). We may also collect
              device event information such as crashes, system activity, browser type, browser language, the date and
              time of your request and referral URL. This helps us measure how the Website and Service is performing,
              improve Dojo Tutor for you on your particular device, and send you push notifications if you’ve opted in
              to receive them. Zoom may also collect the following from the device: information about the speakers,
              microphone, camera, OS version, hard disk ID, PC name, MAC address, WiFi information, IP address (which
              may be used to infer general location at a city or country level) and other device information (like
              Bluetooth signals). Please see the <a href="https://explore.zoom.us/en/privacy/">Zoom Privacy Policy</a>{" "}
              for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Mobile application information:</b> Certain mobile applications or “apps” include a unique application
              number. This number and information about your installation (for example, the operating system type and
              application version number) may be sent to Dojo Tutor when you install or uninstall that application, such
              as when you install the Dojo Tutor App, when you open the application, or when that application
              periodically contacts our servers, such as for automatic updates. Additionally, we may receive application
              state and crash log information which will help us debug and improve the Dojo Tutor App.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Server log information:</b> Like most online services, when you use our Service or Website we
              automatically collect and store certain information in our{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_b35dfe89-b21e-4bb2-8616-856d62ad6237">
                server logs
              </a>
              . Examples include things like:
              <ul>
                <li>
                  <Text className={classes.listText}>
                    Telephone log information like your phone number and SMS routing information
                  </Text>
                </li>
                <li>
                  <Text className={classes.listText}>
                    <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f2f016f2-e461-4443-8c29-6bb597063447">
                      IP address
                    </a>
                  </Text>
                </li>
                <li>
                  <Text className={classes.listText}>
                    Details of how you used our Service or Website, such as your activity on the Service or Website
                    (including product event data used to track progress or user activity), and the frequency and
                    duration of your visits to the Service or Website (similar to TV ratings that indicate how many
                    people watched a particular show)
                  </Text>
                </li>
              </ul>
              This information helps us make decisions about what we should work on next - for example, by showing which
              features are most popular and to send notifications to a device (such as push notifications if the user
              opted in) to encourage the user to finish an activity or start the next activity. We may use your IP
              address and share it with third parties to help diagnose problems with our servers, for security purposes,
              to manage the Service or Website, to enhance the Services or Website based on usage pattern data and, for
              analytics and for advertising. Dojo Tutor will not share IP address information of learners with third
              parties for analytics and advertising purposes.{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Location information:</b> When you use our Service or Website, we may collect and process information
              about your geographic location, for example, based on your IP address. We collect both coarse (i.e.,
              city-level) location data and may collect precise location data. If we do request precise geolocation
              information (“precise” meaning sufficient to identify street name and name of city or town) from you (such
              as when we want to send merchandise to specific parents), we ask for your explicit opt-in permission. We
              **<b>never</b>** request precise geolocation data from learners. From all users, if they upload photos or
              videos from their computer, or from their phone&apos;s camera roll directly, as opposed to choosing to use
              Dojo Tutoring ’s own application camera, we collect what the device sends us. If the device sends us
              Exchangeable Image File Format (EXIF data) (metadata contained within the image file - e.g., camera model,
              lighting conditions, and location (if enabled)) this can contain location data (and may contain precise
              location data). We strip all EXIF data, including the location information, from the photo before it is
              viewed by other users (e.g., the parent in Messaging). If a user accesses and uses our mobile camera
              within the application itself, the camera has been configured so as to not collect any EXIF data,
              therefore the EXIF information is not stored (as opposed to if you use your device’s own camera roll).
            </Text>
          </li>
          <br />
          We will not store or track your precise device location on an ongoing basis or without your permission. We do
          not share precise geolocation data with third-parties, other than our {serviceProvidersLink()} as necessary to
          provide the Service. If you no longer wish to allow us to track your location information, you may opt out at
          any time by turning it off at the device level.
          <li>
            <Text className={classes.listText}>
              <b>Cross-device collection:</b> To provide users with a seamless online experience, we may link your
              identifiers on different browsers and environments you are using. We may also work with third-party
              partners to employ technologies, including the application of statistical modeling tools, which permit us
              to recognize and contact you across multiple devices.
            </Text>
          </li>
        </ul>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We collect some information from you automatically (and additionally use cookies and other similar
            technologies to collect information) so that we know when things go wrong, or to help us understand what
            parts of Dojo Tutor need some improvement. If we need to request street-level (“precise”) geolocation data
            from you as a tutor or parent, we’ll ask for your explicit, opt-in consent. We never request precise
            geolocation data from learners.
          </Text>
        </Box>
        <br />
        <Box>
          <h3 id="information-received-from-third-party-sources">Information Received from Third Party Sources</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We may also obtain information, including {personalInformationLink()}, from third-party sources to update or
            supplement the information you provided, or we collected automatically. This may include aggregated
            anonymous information or certain personal information that may be provided to us (e.g., background check
            information). If we receive personal information from third-parties, we will handle it in accordance with
            this Privacy Policy. If we directly combine information we receive from other third-parties with personal
            information that we collect through the Service or Website, we will treat the combined information as
            {personalInformationLink()} and handle it in accordance with this Privacy Policy. Additionally, we may use
            any aggregated anonymous information received by third-parties as set forth below under the heading{" "}
            <Link to="#aggregated-information-and-non-identifying">
              “Aggregated Information and Non-Identifying Information”
            </Link>
            . Local laws may require you to authorize the third-party to share your information with us before we can
            acquire it. We do not control, supervise, or respond to how third parties providing your information process
            your personal information, and any information request regarding the disclosure of your personal information
            to us should be directed to such third-parties. Please see this{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">chart</a> with the detailed
            categories of personal information we collect from each user type, including the sources from which the
            information was collected, the business purpose for which the information was collected, and the third
            parties with whom we disclose personal information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We may collect some information from third-party sources, such as Checkr when we do background checks on
            tutors. That information will be handled under this Privacy Policy. Please see{" "}
            <Link to="/transparency">this chart</Link> with the detailed categories of personal information we collect
            from each user type, including the sources from which the information was collected, the business purpose
            for which the information was collected, and the third parties with whom we share or disclose personal
            information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <h3 id="online-analytics-advertising-and-do-not-track">Online Analytics, Advertising and Do-Not-Track</h3>
        </Box>
        <Box>
          <Text weight="bold">Online Analytics Providers</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We may collect analytics data, or use third-party analytics tools, to help us measure traffic and usage
            trends for the Website (including ads targeting, retargeting, optimization, and conversion events), and to
            understand more about the demographics of our users. These third parties use the sorts of technologies
            described in the{" "}
            <Link to="#automatically-collected-information">“Automatically Collected Information”</Link> section above.
            The information collected by this technology will be disclosed to or collected directly by these service
            providers. A current list of analytics providers that we use is located{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">here</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Advertising Technology Providers</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutor does not display any{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
              behaviorally targeted advertising
            </a>{" "}
            on our Service to learners (i.e., in areas of our Website where learners are logged in). We may serve{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
              contextually relevant advertising
            </a>{" "}
            for third-party products and services that we believe may be of interest to you (e.g., a partnership with an
            online education platform that can help your child practice math at home).
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            However, data about your activities on non-logged-in areas of our Website (outside of our Service) may be
            collected by us or by third-party advertising technology providers for use in delivering online advertising
            tailored to your individual characteristics, activities, interests and other factors. These ad services may
            track your online activities over time and across the web by collecting information through automated means,
            including through the use of the various technologies described above, and they may use this information,
            and other information they receive from us or other sources, to deliver advertisements to you on our
            website, our emails, and other websites. We and our third-party vendors may use first-party cookies and
            third-party cookies together, and other automated technologies, and in some cases additional information,
            (i) to inform, optimize, and serve ads based on past visits to our Websites or other factors and (ii) to
            report how ad impressions, other uses of ad services, and interactions with these ad impressions and ad
            services are related to visits to our Websites or other factors.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            You may visit the{" "}
            <a href="http://www.networkadvertising.org/choices/">
              Network Advertising Initiative’s Consumer Opt-Out page
            </a>{" "}
            or the <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance’s Consumer Opt-Out page</a>{" "}
            to opt out of receiving tailored advertising based on your Internet browsing activities from companies that
            participate in those programs. You may also manage certain advertising cookies by visiting the{" "}
            <a href="http://www.youronlinechoices.eu/">EU-based Your Online Choices.</a> You may also be able to limit
            interest-based advertising through the settings on your mobile device by selecting “limit ad tracking” (iOS)
            or “opt-out of interest based ads” (Android). You may also be able to opt out of some – but not all –
            interest-based ads served by mobile ad networks by visiting the{" "}
            <a href="http://youradchoices.com/appchoices">AppChoices website</a> and downloading the mobile AppChoices
            app. Some of our advertising-related activities may involve the services of companies that do not
            participate in those programs. For example, we may use the Google AdWords remarketing service to advertise
            on third party websites (including Google) to previous visitors to our Website. You can set preferences for
            how Google advertises to you using the{" "}
            <a href="http://www.google.com/settings/ads/onweb/">Google Ad Preferences page.</a> The opt-outs apply only
            to the browser in which you set them. For example, if you set the opt-out while using Firefox, but then use
            Chrome, the opt-out will not be active in Chrome. To opt out in Chrome, you will need to repeat the opt-out
            process.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            The opt-outs described in this Privacy Policy do not block or delete cookies, or prevent the use of cookies
            or other technologies for purposes other than selecting ads based on your interests as inferred by your
            online behavior. If you opt out, data may still be collected about your web browsing activities and you will
            still see advertising. Ads may be delivered based on other factors, such as the content of the web page in
            which they are shown, or demographic information obtained through other means.
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Please read our{" "}
            <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a> for more
            details, including how to remove or modify your cookie settings and a list of the advertising tracking
            technologies we use.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">YouTube</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Tutoring uses YouTube to display video content on the Website and may use on the Services. YouTube
            incorporates tracking technologies, which may be present in the YouTube videos embedded on the Service,
            which may collect information from your browser when you view the video on the Service, including device
            identifiers and/or cookie IDs. This information is collected directly and automatically by YouTube and its
            partners, and Dojo Tutor does not participate in these data transmissions. Dojo Tutor does not provide any
            personal information, such as usernames, to YouTube.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutor has taken steps to minimize this tracking activity, to the extent we have control over a
            third-party’s practices. We have turned on “Privacy-Enhanced Mode” for YouTube videos displayed on the
            Service and Website. We also utilize Google’s “child-directed treatment” tags to alert Google that these
            YouTube videos may be embedded on webpages visited by children or learners. You can learn more about
            Google’s practices at{" "}
            <a href="http://www.google.com/policies/privacy/partners">
              http://www.google.com/policies/privacy/partners
            </a>
            . You may also be able to control ad tracking through YouTube by visiting the Google Ad Settings page,
            currently available at: <a href="https://adssettings.google.com/">https://adssettings.google.com</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Do Not Track Disclosure</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Do Not Track (&quot;DNT&quot;) is a privacy preference that users can set in certain web browsers to inform
            websites and services that they do not want to be“tracked”. For more information on “do not track”, please
            visit <a href="http://www.allaboutdnt.org">www.allaboutdnt.org</a>. The industry working group (W3C Tracking
            Protection) to develop the DNT standard is now{" "}
            <a href="https://www.w3.org/2011/tracking-protection/">closed</a>, so Dojo Tutor does not respond to these
            DNT signals. We will honor Global Privacy Control Signals or Opt-Out Preference Signals for certain users as
            set forth in our <Link to="#california-privacy-disclosures">“California Privacy Disclosures”</Link> section
            below (both as defined in the California Privacy Disclosures section).
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Dojo Tutor does not recognize or respond to browser-initiated DNT signals given the lack of consensus on
            determining how to comply. However, if you are located in certain jurisdictions (such as California), we
            will honor Global Privacy Control Signals or Opt-Out Preference Signals as required by law. For more
            information, see our <Link to="#california-privacy-disclosures">California Privacy Disclosures</Link>{" "}
            section.
          </Text>
        </Box>

        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="how-does-dojo-use-info"
        >
          How Does Dojo Tutor Use the Information it Collects?
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            First and foremost, you should know that Dojo Tutor does not{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent any of your, or your child’s,{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            to any third-party for any purpose - including for advertising or marketing purposes.{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
              Third-Party Advertising
            </a>{" "}
            is not permitted on areas where users are required to log in to Dojo Tutor and personal information
            collected from {learnersLink()} and children is never used for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
              behaviorally-targeted advertising
            </a>{" "}
            to learners or children (by us or third-parties). We may serve{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
              contextually relevant advertising
            </a>{" "}
            for third-party products and services that we believe may be of interest to you (e.g., we may suggest using
            external learning platforms to help your child practice the skills they are learning during tutoring). Dojo
            Tutor commits that its collection, use, retention and sharing of personal information shall be reasonably
            necessary and proportionate to achieve the purposes for which the personal information was collected or
            processed and for the purposes disclosed in this Privacy Policy and{" "}
            <a href="https://tutoring.classdojo.com/transparency">Information Transparency Chart</a> and that the
            personal information will not be further processed in a manner incompatible with these purposes.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We use your personal data only when we have a valid legal basis to do so. Our use of information collected
            from children and {learnersLink()} using the Service is set forth above in the Section “
            <Link to="#how-does-dojo-tutoring-protect-children">How Does Dojo Tutor Protect Children?</Link>” More
            specifically, this information collected (from non-learners and children) is used to:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Power Our Services.</b> This includes, operating, providing, enhancing and improving the Service and
              Website, for example, by developing new products and features, and personalizing or customizing the
              Service and Website for you or providing access to restricted parts of our Website or Services.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Provide Internal Analytics and Reviews.</b> This includes using for internal audits and review, for
              analyzing how the Website and Services are used, providing statistics about the usage levels of the
              Website and Services, and diagnosing service or technical problems.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Process Your Transactions.</b> To fulfill your requests and provide any information you request, such
              as responding to your emails, submissions, questions, comments, requests for information or customer
              support or for event registration, scheduling or attendance; or for any other purpose for which the
              information was collected (e.g., to process your tutor application, fulfilling tutoring online session
              orders), or for which you provide your consent (if required).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Communicate with You.</b> To send you information about features on our Service and Website or changes
              to our policies and Service or Website or communicate with you about your use of the Service and Website,
              your account, or transactions with us. We may also send you security alerts, and support and
              administrative messages and otherwise facilitate your use of, and our administration and operation of the
              Service and Website (including notifying you of data privacy incidents). Additionally, we may provide
              tutors and parents information about events, announcements, offers, promotions, products, including
              third-party products, and services we think will be of interest to you and notify and contact contest and
              sweepstakes entrants.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Determine Eligibility or Evaluate Your Performance.</b> To determine eligibility for becoming a tutor,
              or other membership, credentials, designations, or volunteer opportunities or to evaluate your performance
              on continued learning courses and assist you in the tracking of your progress. For tutor applications and
              tutoring sessions, we may record, save, and share video and audio recordings for review by peer reviewers,
              a review board, and/or a team of internal reviewers. All such reviewers will be restricted from using or
              disclosing such videos other than for the purposes related to providing or improving the Services or our
              business.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Request Your Participation in Surveys or Focus Groups.</b> To request your participation in ratings,
              reviews, surveys, focus groups, or other initiatives which help us to gather information used to develop
              and enhance our Website, products and Services, and may be used to provide additional ClassDojo or Dojo
              Tutor product recommendations to you (as a parent or tutor).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Provide AI-Generated Insights</b>. To leverage AI features aimed at providing, improving, and
              personalizing our Website, products, and Services (as described in “Artificial Intelligence (AI)-Generated
              Insights on Tutoring Session” section).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Ensure Security and Prevent Fraud.</b> Most crucially, to protect our community by making sure the
              Service and Website remains safe and secure, such as by detecting, investigating and preventing activities
              that may violate our policies or be illegal.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Comply with Law.</b> To comply with applicable law - for example, to satisfy tax or reporting
              obligations, to comply with a lawful governmental request or notify you of data privacy incidents when
              legally required.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We use automatically collected information (described in the{" "}
            <Link to="#automatically-collected-information">&quot;Information collected automatically&quot;</Link>{" "}
            section above) to provide and support our Service and the Website, and for the additional uses described in
            that section of our Privacy Policy. To learn more about how we use your information for personalization and
            tracking, please see the{" "}
            <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We do not use algorithms for profiling or to otherwise make any decision based solely on automated
            processing that would significantly affect you without the opportunity for human review (“Automated Decision
            Making”).
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            When acting as a <Link to="#is-dojo-tutoring-a-controller">Controller</Link>, We process this information
            given our legitimate interest in improving and protecting the Service and our users’ experience with it,
            taking into consideration your interests, rights, and expectations. Depending on the circumstances, we may
            also rely on your consent or the fact that the processing is necessary for the adequate performance of the
            contract with you, to protect your vital interest or those of other persons or the Service, and to comply
            with applicable laws.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Additionally, when acting as a <Link to="#is-dojo-tutoring-a-controller">Controller</Link>, we will process
            your personal information for the purposes listed in this section related to marketing given our legitimate
            interests in undertaking marketing activities to offer you products and services that may be of your
            interest. You can opt out of receiving marketing communications from us by following the unsubscribe
            instructions included in our marketing communications.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please note that we may anonymize and/or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identify
            </a>{" "}
            information collected through the Services or via other means so that the information no longer relates to
            you. Subject to applicable laws, where we have anonymized and/or {deIdentifiedLink()} information so that it
            no longer identifies you personally, our use and disclosure of such information is not subject to this
            Privacy Policy.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutor doesn’t{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your information to third-parties, we don’t permit{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_6fb13f0c-b840-412f-9956-2a519005384d">
              third-party advertising
            </a>{" "}
            on Dojo Tutoring, and personal information collected from {learnersLink()} is never used for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
              behaviorally-targeted advertising
            </a>{" "}
            to learners. power our services, provide internal analytics and reviews, process your transactions,
            communicate with you, determine eligibility or evaluate performance, request your participation in surveys
            or focus groups, provide AI-generated insights, prevent security and fraud.
            <br />
            We may also use the information provided by parents and tutors to provide parents and tutors information
            about events, announcements, offers, promotions, products, including third-party products, and services we
            think will be of interest to you.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="how-we-share-you-personal-information"
        >
          How We Share Your Personal Information
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutor does not{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your (or your child’s) {personalInformationLink()} to any third-party for any purpose - including
            for advertising or marketing purposes. Additionally, personal information collected from children is never
            used for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
              behaviorally-targeted advertising
            </a>{" "}
            to learners by us or any third-party, and children’s personal information is never{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sold
            </a>{" "}
            or rented to anyone, including marketers and advertisers. Furthermore, we do not disclose personal
            information to any third-parties except in the limited circumstances described in this Privacy Policy and as
            set forth below:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Other Users You Share and Communicate with on Dojo Tutor:</b> No parent, or{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learner
              </a>{" "}
              profiles are made available to the general public through our Service, but as a tutor, you may choose to
              share your profile publicly. If you are a learner, tutor, or parent, you may choose to share information
              or content through the Service with other Dojo Tutor learners, tutors, and parents - for example, things
              like your account information, more detailed profile information or other information you share with
              tutors, learners, or parents you are communicating with through Dojo Tutor Messaging or our other
              collaboration features.
              <br />
              <br />
              Please keep in mind that information (including personal Information or children&apos;s personal
              information) or content that you voluntarily disclose to others - including to other Dojo Tutor users you
              interact with through the Service (such as messages you might send other users or other learners, tutors,
              and parents you collaborate with) - can be viewed, copied, stored, and used by the people you share it
              with. We cannot control the actions of people with whom you choose to share information and we are not
              responsible for the collection, use, or disclosure of such information or content by others.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Peer Review, Session Review and Mentoring:</b> We disclose personal information to approved reviewers
              for peer review, session review, and other operations of the Dojo Tutor platform.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Third-Party Integrations on our Service:</b> When, as a learner, tutor, or parent, you use third-party
              apps, websites, or other services that use, or are integrated with, our Website or Service, they may
              receive information about what you post or share. For example, when you share feedback or your tutoring
              session summary on Twitter or Facebook, or as a tutor you provide third party links or integrations for
              your learners during a tutoring session, these services receive the information that you share through
              this functionality and information that you are sharing from Dojo Tutor. Please see{" "}
              <Link to="#third-party-service-providers">here</Link> for more information on third-party services. Please
              see <Link to="/third-party-service-providers/">here</Link> for a list of third-party app integrations on
              our Service and the Website.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Service Providers:</b> We do work with vendors, service providers, and other partners to help us
              provide the Service and Website by performing tasks on our behalf. These service providers may be located
              inside or outside of the European Economic Area (“EEA”). We may need to provide information (including
              personal information) to them to help them perform these business functions, for example sending emails on
              our behalf, database management services, database hosting, video hosting and storage, providing customer
              support software, and security. We may also authorize these third-party companies to collect information
              on our behalf. These providers have limited access to your personal information to perform these tasks on
              our behalf and are contractually bound to protect and use it only for the purpose for which it was
              disclosed. Additionally, these partners and service providers must adhere to confidentiality and security
              obligations in a way that is consistent with this Privacy Policy. Please see{" "}
              <Link to="/third-party-service-providers/">here</Link> for a list of the third parties we work with to
              provide the Service and the Website.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Social Media Platforms:</b> Where permissible according to applicable law, we may share certain limited
              personal information with social media platforms (such as Google or Facebook) and other websites,
              applications or partners, to generate leads, drive traffic to our websites or otherwise market and
              advertise our products or Services on those websites or applications (this will never include personal
              information of learners). We may also share certain personal information (never of learners or children)
              with social media platforms and other websites to ensure that these individuals are not marketed to on
              these platforms. These processing activities are based on our legitimate interest in undertaking marketing
              activities to offer you products and services that may be of your interest. These social media platforms
              with which we may share your personal information are not controlled or supervised by Dojo Tutor.
              Therefore, any questions regarding how your social media platform processes your personal information
              should be directed to such provider. You may also <Link to="#contact-information">contact us here</Link>{" "}
              if you would like to opt out of any such sharing.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Analytics Services:</b> We use analytics services, including mobile analytics software, to help us
              understand and improve how the Service and Website are being used. These services may collect, store and
              use information in order to help us understand things like how often you use the Service or Website, the
              events that occur within the application, usage, performance data, and from where the application was
              downloaded. A current list of providers we use for analytics is located{" "}
              <Link to="/third-party-service-providers/">here</Link>.
            </Text>
          </li>
          <li>
            <Text
              className={classes.listText}
              sx={{ scrollMarginTop: "130px" }}
              id="aggregated-information-and-non-identifying"
            >
              <b>Aggregated Information and Non-Identifying Information:</b> We may share aggregate or{" "}
              {deIdentifiedLink()}
              information (which cannot reasonably be used to identify your child) with third parties for various
              purposes, including compliance with various reporting obligations; to demonstrate how the Service is used;
              for business purposes; or to assist third parties in understanding our users’ interest, habits, and usage
              patterns for certain programs.
            </Text>
          </li>
          <li>
            <Text className={classes.listText} sx={{ scrollMarginTop: "130px" }} id="legal-requirements">
              <b>Legal Requirements:</b> We may disclose information, including {personalInformationLink()}, if we have
              a good faith belief that doing so is necessary to comply with the law, such as complying with a subpoena
              or other legal process. We may also need to disclose personal information where, in good faith, we think
              it is necessary to protect the rights, property, or safety of Dojo Tutor, our employees, our community, or
              others, or to prevent violations of our <Link to="/termsofservice">Terms of Service</Link> or other
              agreements. This includes, without limitation, responding to law enforcement and government requests and
              investigating and defending ourselves against third-party claims and allegations. Where appropriate, we
              may notify users about the legal requests, unless (i) providing notice is prohibited by the legal process
              itself, by court order we receive, or by applicable law; (ii) we believe that providing notice would be
              futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or
              increase a risk of fraud upon Dojo Tutor, or its users, the Service or Website. In instances where we
              comply with legal requests without notice for these reasons, we will attempt to notify that user about the
              request after the fact where appropriate and where we determine in good faith that we are no longer
              legally prevented from doing so.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Fraud and Security Protection:</b> We may share information to assist with security operations for
              scheduled penetration testing, auditing, and continuous improvement of our security practices, including
              without limitation, exchanging information with other companies and organizations for fraud protection.
              Authorized parties of the information would comply with our current privacy and security policies.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Sharing with Dojo Tutor Companies:</b> Over time, Dojo Tutor may grow and reorganize. We may share your
              personal information with affiliates such as a parent company, subsidiaries, joint venture partners, or
              other companies that we control or that are under common control with us, in which case we will require
              those companies to agree to use your personal information in a way that is consistent with this Privacy
              Policy. We will not share your child’s personal information with affiliates without first obtaining your
              explicit consent.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Change of control:</b> The{" "}
              <a href="https://studentprivacypledge.org/signatories/">Student Privacy Pledge</a> requires us (and all
              pledgers) to commit to how we handle data in the event of a change to our organizations such that all or a
              portion of Dojo Tutor or its assets are acquired by or merged with a third-party, or in any other
              situation where{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              that we have collected from users would be one of the assets transferred to or acquired by that
              third-party. Consistent with the Student Privacy Pledge and applicable state and federal laws, in
              connection with such a change to our organization, if any, this Privacy Policy will continue to apply to
              your information, and any acquirer would only be able to handle your personal information as per this
              policy (unless you give consent to a new policy). We will provide you with notice of an acquisition within
              thirty (30) days following the completion of such a transaction, by posting on our homepage, or by email
              to your email address that you provided to us. If you do not consent to the use of your personal
              information by such a successor company, subject to applicable law, you may request its deletion from the
              company. We may also make information, including personal information, available to a potential investor
              or purchaser (or their advisors) in advance of any such transaction completion. <br />
              <br /> In the unlikely event that Dojo Tutor goes out of business, or files for bankruptcy, we will
              protect your personal information, and will not{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
                sell
              </a>{" "}
              it to any third-party. For more information on our practices regarding your data if we go out of business,
              please see our <Link to="https://classdojo.zendesk.com/hc/articles/115004809286">FAQ</Link>.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>With your consent:</b> Other than the cases above, we won’t disclose your{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              for any purpose unless you consent to it.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutor doesn’t{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            to any third-parties. We believe your information is yours, and you should own it - we think that’s the
            right way to operate. We share some information with{" "}
            <Link to="/third-party-service-providers">service providers</Link> who help us provide you with the Service
            and Website – like companies that help us send emails, for example, or if we have to comply with the law.
            Additionally, you may choose to share information with other users of the Service or to third-parties that
            are integrated on our Service, or to provide us consent for other specific sharing of your personal
            information. We may also share aggregated information or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identified
            </a>{" "}
            information (such as statistics about visitors, traffic and usage patterns) with third parties for any
            purpose (information shared this way will not contain any personal information). We may also share your
            personal information with other Dojo Tutor companies (such as a parent or affiliate).
            <br />
            And, if Dojo Tutor is ever acquired or goes out of business, our commitments don’t change: we still won’t{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            to anyone. Your personal information will continue to be protected by this policy, and any company that
            acquires Dojo Tutor will have to abide by this policy unless you give consent to a new policy.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          mb={24}
          mt={48}
          id="how-does-tutor-protect-information"
        >
          How Does Dojo Tutoring Protect and Secure My Information?
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            The security of your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            is important to us. The safety and security of your information also depends on you. You should maintain
            good internet security practices. Where you have password-protected access to an account or certain parts of
            the Website or Services, you are responsible for keeping this password secure at all times. You should not
            share your password with anyone. You must prevent unauthorized access to your account and{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal
            </a>{" "}
            information by selecting and protecting your password appropriately and limiting access to your computer or
            mobile device and browser by signing off after you have finished accessing your account. If you think that
            any of your accounts have been compromised you should change your account credentials with us, and in
            particular make sure any compromised account does not allow access to your account with us. We will never
            email you to ask for your password or other account login information. If you receive such an email, please
            send it to us so we can investigate.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We work hard to protect our community, and we maintain reasonable administrative, technical, and physical
            security procedures and practices appropriate to the nature of the personal information designed to protect
            personal information from unauthorized or illegal access, destruction, use, modification or disclosure.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>In particular:</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Our engineering team is dedicated to keeping your personal information secure.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We perform application security testing; penetration testing; conduct risk assessments; and monitor
              compliance with security policies
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We periodically review our information collection, storage, and processing practices, including physical
              security measures, to guard against unauthorized access to systems
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Whenever we develop new features, we do in a manner to keep your personal information safe
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              When you enter any personal information anywhere on the Service, we encrypt the transmission of that
              information using secure socket layer technology (SSL/TLS) by default
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Dojo Tutor’s databases where we store your personal information is encrypted at rest, which converts all
              personal information into a form that unauthorized users can’t translate.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We ensure passwords are stored and transferred securely using encryption and salted hashing
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              The Service and Website is hosted on servers at a{" "}
              <Link to="/third-party-service-providers">third-party facility</Link>, with whom we have a contract
              providing for enhanced security measures. For example, personal information is stored on a server equipped
              with industry-standard firewalls. In addition, the hosting facility provides a 24x7 security system, video
              surveillance, intrusion detection systems, and locked cage areas
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We automatically delete inactive Learner Accounts after a specific period of time, as per our retention
              policy, described in the "How Long Does Dojo Tutor Keep Children's Information?" section.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We also operate a ‘bug bounty’ security program to encourage an active community of third-party security
              researchers to report any security bugs to us. More information on this is available by contacting us at{" "}
              <a href="mailto:tutoringsecurity@classdojo.com">tutoringsecurity@classdojo.com</a>
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We restrict access to personal information to authorized Dojo Tutor employees, agents or independent
              contractors who need to know that information in order to process it for us, and who are subject to strict
              confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            For additional information on our security practices, please see our{" "}
            <a href="https://www.classdojo.com/security/">Security Whitepaper</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Although we make concerted good faith efforts to maintain the security of personal information, and we work
            hard to ensure the integrity and security of our systems, no practices are 100% immune, and we can’t
            guarantee the security of information. Outages, attacks, human error, system failure, unauthorized use, or
            other factors may compromise the security of user information at any time. If we learn of a security breach,
            we will attempt to notify you electronically (subject to any applicable laws and school reporting
            requirements) so that you can take appropriate protective steps; for example, we may post a notice on our
            homepage <Link to="/">www.tutoring.classdojo.com</Link>) or elsewhere on the Service or Website, and may
            send email to you at the email address you have provided to us. Depending on where you live, you may have a
            legal right to receive notice of a security breach in writing.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            The security of your information is important to us, and we take it very seriously. We maintain and always
            seek to add strong safeguards to ensure the safety and security of Dojo Tutor and our community of tutors,
            learners and parents. You can help us out by keeping your password secret! When you enter information
            anywhere on the Service or the Website, we encrypt the transmission of that information using SSL by default
            and the databases where we store your personal information is encrypted at rest. Please see our{" "}
            <a href="https://www.classdojo.com/security/">Security Whitepaper</a> for more details. You can help us keep
            your information secure by keeping your password secret and notifying us if you suspect your account has
            been compromised.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          mb={24}
          mt={48}
          id="how-long-does-dojo-keep-information"
        >
          How long does Dojo Tutor Keep Information About me?
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We retain the data we collect for different periods of time depending on what it is, how it is used, and how
            you configure your settings. You can read more about Dojo Tutor’s retention periods, including how long it
            takes us to delete your information{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12706732718349">here</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            <i>Non-Learner Users:</i>
            <br />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            We store your <a>personal information</a> for as long as it is necessary to provide the Service or Website
            to you and others, including those described above. We determine the appropriate retention period for
            personal information on the basis of the amount, nature and sensitivity of the personal information being
            processed, the potential risk of harm from unauthorized use or disclosure of the personal information,
            whether we can achieve the purposes of the processing through other means, and on the basis of applicable
            legal requirements (such as applicable statutes of limitations). The time periods for which we retain your
            personal information depend on the purposes for which we use it. Personal information associated with your
            account will be kept until your account is deleted, unless we no longer need the data to provide the
            Website, products and Services, in which case we will delete or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identify
            </a>{" "}
            the information prior to your deleting your account. We may retain{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identified information
            </a>{" "}
            for as long as we deem appropriate or as otherwise required by law. Please see our{" "}
            <Link to="/transparency">Information Transparency chart</Link> for more specific information on retention
            periods of specific personal information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            You can, of course, <Link to="#delete-your-account">delete your account</Link> at any time. Please note that
            we may have to retain some information after your account is deleted to comply with legal obligations,
            protect the safety and security of our community or our Website or Service, or prevent abuse of our{" "}
            <Link to="/termsofservice">Terms</Link>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Additional examples of when we may need to retain personal information after your account is deleted:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>Our contractual and business relationships with you.</Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Financial recordkeeping – when you make a payment to us (or we make a payment to you (e.g. for your
              tutoring sessions) we often required to retain this information for long period of time for purposes of
              accounting, dispute investigation and resolution, and compliance with tax, anti-money laundering, and
              other financial regulations.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Direct communications with us – if you have directly communicated with us, through a customer support
              channel, feedback form, or a bug report, we may retain reasonable records of those communications.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            For more details, please read “What happens when I delete my account?” in our{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQs</Link>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Even if you delete your account, keep in mind that the deletion of your related account information by our{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a> may not be
            immediate and that the deleted information may persist in backup copies for a reasonable period of time. We
            may retain {deIdentifiedLink()} information for as long as we deem appropriate or as otherwise required by
            law.
          </Text>
        </Box>
        <br />

        <Box mb={8}>
          <Text className={classes.paragraph}>
            <i>Learners and Children:</i>
            <br />
            Please see the{" "}
            <Link to="#how-long-keeps-children-information">
              “How Long Does ClassDojo Keep Children’s Information?”
            </Link>{" "}
            for details on how long we retain children’s or learner’s data.
          </Text>
        </Box>
        <br />
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            We keep tutor and parent personal information until your account is deleted, or until we no longer need it
            to provide you with the Service, products or Website. Please see the{" "}
            <Link to="#how-long-keeps-children-information">
              “How Long Does ClassDojo Keep Children’s Information?”
            </Link>{" "}
            for details on how long we retain children’s or learner’s data.
          </Text>

          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Please see our <Link to="/transparency">Information Transparency Chart</Link> for more specific information
            on retention periods. You can, of course, <Link to="#delete-your-account">delete your account</Link> at any
            time. For more details, please read “What happens when I delete my account?” in our{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQs</Link>. Even if you delete your
            account, keep in mind that the deletion by our{" "}
            <Link to="/third-party-service-providers">service providers</Link> may not be immediate and that the deleted
            information may persist in backup copies for a reasonable period of time. We may retain {deIdentifiedLink()}{" "}
            information for as long as we deem appropriate, or as otherwise required by law.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          id="providing-transparency-rights"
          mb={24}
          mt={48}
        >
          Dojo Tutor’s Commitments to Providing Transparency and Your Rights
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We pride ourselves on being as transparent as possible about what information we collect from you so that
            you can make meaningful choices about how your information is used. You control the{" "}
            {personalInformationLink()} you share with us. You may have certain rights relating to your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>
            , subject to local data protection laws. Please review local data protection laws for more information.
            Depending on the applicable local laws, these rights may include the rights set forth in this Section. You
            also have a right not to be discriminated against for exercising any of your rights set forth in this
            Section.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                marginLeft: "20px",
              } as any
            }
          >
            <ul>
              <li>
                <Text className={classes.listText}>
                  <Link to="#access-correct-personal-information">Access or Correct Your Personal Information</Link>
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  <a href="https://classdojo.zendesk.com/hc/articles/12016402266125-What-Learner-Information-Can-be-Viewed-by-Other-Logged-in-Dojo-Tutoring-Users">
                    Control Who You Share Information With
                  </a>
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  <Link to="#delete-your-account">Delete Your Personal Information or Account from Dojo Tutor</Link>
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  <Link to="#object-restrict-withdraw">Object or Restrict Processing</Link>
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  <Link to="#object-restrict-withdraw">Withdraw Consent</Link>
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  <a href="https://classdojo.zendesk.com/hc/articles/12038504162701">
                    Take Your Information Out of Dojo Tutor
                  </a>
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  <Link to="#additional-article-27-GDPR">
                    Additional Information or Assistance and Article 27 GDPR Local Representative
                  </Link>
                </Text>
              </li>
            </ul>
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you are a California resident, you may have additional rights as set forth under the California Consumer
            Privacy Act as amended by the California Privacy Rights Act and its implementing regulations (collectively
            the “CCPA” ). Please see the Section “
            <Link to="#california-privacy-disclosures">California Privacy Disclosures</Link>” for more information if
            you are a California resident. Additionally, if you are a resident of certain other states within the United
            States, you may have additional rights as set forth in the U.S. State Law Requirements Section{" "}
            <Link to="#us-state-privacy-disclosures">below</Link>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            At Dojo Tutor, we believe that more transparency is better. We try to give you control, with easy settings
            and options, so that you can make good choices when it comes to your information and how it is used. We also
            set forth the ways in which you can exercise your rights to access, correct, and delete your personal
            information as well as your right to take information out of Dojo Tutor and object or withdraw consent. If
            you are a California resident, you may have additional rights as set forth under the CCPA. Please see the
            Section “California Privacy Disclosures” Section for more information if you are a California resident.
            Additionally, if you are a resident of certain other states within the United States, you may have
            additional rights as set forth in the U.S. State Law Requirements Section below.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="how-to-exercise-rights"
        >
          How to Exercise Your Rights
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            You may exercise any of these rights described in this Section by sending an email to{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email used
            to create your Dojo Tutor account or as otherwise set forth in the{" "}
            <Link to="#contact-information">“Contacting Dojo Tutor”</Link> section below. Please note that we may ask
            you to verify your identity before taking further action on your request, for example by requiring you to
            provide acceptable forms of personal identification as set forth{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016488970381">here</a>. Your personal information may
            be processed in responding to these rights. We try to respond to all legitimate requests within one month
            unless otherwise allowed or required by law, and will contact you if we need additional information from you
            in order to honor your request or verify your identity. Occasionally it may take us longer than a month,
            taking into account the complexity and number of requests we receive. If you are an employee of a Dojo Tutor
            customer, we recommend you contact your employer’s system administrator for assistance in correcting or
            updating your information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We may reject requests for access, change, or deletion that are unreasonably repetitive (more than two times
            per year for access rights), risk the privacy of others, require disproportionate technical effort (for
            example, developing a new system or fundamentally changing an existing practice), or would be extremely
            impractical (for instance, requests concerning information residing on backup systems). Where we can provide
            information access and correction, we will do so for free, except where it would require a disproportionate
            effort or if more than two times a year for access rights.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            To exercise your rights, please send us an email to{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email used
            to create your Dojo Tutor account. We will respond to all legitimate requests within one month unless
            otherwise allowed or required by law, and will contact you if we need additional information.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="access-correct-personal-information"
        >
          Access or Correct Your Personal Information
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutor aims to provide you with easy access to any{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            we have collected about you and give you easy ways to update it or delete it, unless we have to keep that
            information for legitimate business purposes (e.g., we need at least an email address for your account if
            you maintain an account with us) or legal purposes. You have the right to correct inaccurate or incomplete
            personal information concerning you (and which you cannot update yourself within your Dojo Tutor account).
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            <u>Managing Your Information:</u> If you have registered for an account on Dojo Tutor, you may update,
            correct, or delete some of your profile information or your preferences at any time by logging into your
            account on Dojo Tutor and accessing your account settings page. You may have to verify your identity before
            you can do that. You may also, at any time, update or correct, certain personal information that you have
            provided as set forth in the “<Link to="#how-to-exercise-rights">How to Exercise Your Rights section</Link>.
            ”
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            <u>Accessing Your Information:</u> Upon request, Dojo Tutor will provide you with information about whether
            we hold any of your personal information, and, if you are a user of Dojo Tutor, you may request access to
            all your personal information we have on file by contacting us as set forth in the “
            <Link to="#how-to-exercise-rights">How to Exercise Your Rights section</Link>.” In some cases, we won’t be
            able to guarantee complete access due to legal restrictions - for example, you will not be allowed to access
            files that contain information about other users or information that is confidential to us.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            <u>Accessing Your Child’s Information:</u> Visit our <Link to="#parental-choices">“Parental Choices”</Link>{" "}
            section to see how you can obtain copies of your child’s personal information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            You have certain rights to access, update and correct your personal information. You can always access and
            manage your personal information through your Dojo Tutor account. Please see{" "}
            <Link to="#parental-choices">here</Link> for more information on accessing your child’s information.
            Additionally, you can exercise your rights to access, update and correct as set forth in the{" "}
            <Link to="#how-to-exercise-rights">“How to Exercise Your Rights section”</Link>.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="delete-your-account"
        >
          Delete Your Personal Information or Account from Dojo Tutor
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If for some reason you ever want to delete your account (or your child’s account, if you are his or her
            parent), you can do that at any time by contacting us as set forth{" "}
            <Link to="#how-to-exercise-rights">above</Link>. If you are a parent or tutor using the Service, you may
            also delete your account by logging into your account and accessing your account settings page.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            When you delete your account, we delete your profile information and any other content you provide in your
            profile (such as your name, username, password, email address, and profile photos) and depending on the
            category of user you are (i.e., tutor, parent, or learner), the additional content or personal information
            uploaded for use will be deleted as set forth in our{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQ</Link>. Information that you have
            shared with others, others have shared about you, or content other users may have copied and stored, is not
            part of your account and may not be deleted when you delete your account.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We aim to maintain our Services and Website in a manner that protects {personalInformationLink()} from
            accidental or malicious destruction. Because of this, even after you update or delete personal information
            you have provided us from our Service, your personal information may be retained in our backup files and
            archives for a reasonable period of time as necessary for our legitimate business interests, such as fraud
            detection and prevention and enhancing safety. For example, if we suspend a Dojo Tutor account for fraud or
            safety reasons, we may retain certain information from that account to prevent that user from opening a new
            account in the future. Also, we may retain and use your personal information to the extent necessary to
            comply with our legal obligations.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you are a California resident, we may also retain personal information as set forth under the CCPA.
            Please see our {FAQLink()} for more information if you are a California resident.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            You can always delete your account by visiting your account settings, or simply by contacting us at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the email used to
            create your Dojo Tutor account. For more details on what happens when you delete your account, click{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">here</Link>.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="object-restrict-withdraw"
        >
          Object, Restrict, or Withdraw Consent
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Where you have provided your consent to the processing of your personal information by Dojo Tutor you may
            withdraw your consent at any time by changing your account settings or by sending a communication to Dojo
            Tutoring as set forth <Link to="#how-to-exercise-rights">above</Link> specifying which consent you are
            withdrawing. Please note that the withdrawal of your consent does not affect the lawfulness of any
            processing activities based on such consent before its withdrawal. Dojo Tutor provides parents and tutors
            with the opportunity to withdraw consent or ‘opt-out’ of receiving any future marketing communications from
            Dojo Tutor and its partners at any time. Please see the{" "}
            <Link to="#communications-from-dojo-tutor">“What communications will I receive from Dojo Tutor?”</Link>{" "}
            section below for more information. Additionally, you can always decline to share personal information with
            us, or even block all cookies. However, it’s important to remember that many of Dojo Tutor’s features may
            not be accessible, or may not function properly if you do that - for example, we may not be able to remember
            your language preferences for you.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            In some jurisdictions, applicable law may entitle you to require Dojo Tutor not to process your{" "}
            {personalInformationLink()} for certain specific purposes (including profiling) where such processing is
            based on legitimate interest. If you object to such processing Dojo Tutor will no longer process your
            personal information for these purposes unless we can demonstrate compelling legitimate grounds for such
            processing or such processing is required for the establishment, exercise or defence of legal claims.
            Additionally, in some jurisdictions, applicable law may give you the right to limit the ways in which we use
            your personal information, in particular where (i) you contest the accuracy of your personal information;
            (ii) the processing is unlawful and you oppose the erasure of your personal information; (iii) we no longer
            need your personal information for the purposes of the processing, but you require the information for the
            establishment, exercise or defence of legal claims; or (iv) you have objected to the processing as set forth
            above and pending the verification whether the legitimate grounds of Dojo Tutor override your own.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            You have the right to withdraw consent for the collection of your personal information, and also opt out of
            marketing communications from us. You may also be able to limit the ways in which Dojo Tutor uses your
            personal information. Email us at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the email used to
            create your Dojo Tutor account and we’ll help you right away.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          id="additional-article-27-GDPR"
          mb={24}
          mt={48}
        >
          <u>Additional Information or Assistance and Article 27 GDPR Local Representative</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you are located in the European Union or the EEA, you have a right to lodge complaints about the data
            processing activities carried about by Dojo Tutor with the supervisory authority in your country.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            <b>European Data Protection Officer</b>
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            <i>General Data Protection Regulation (GDPR) – European Representative</i>
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Pursuant to Article 27 of the GDPR, Dojo Tutor has appointed European Data Protection Office (EDPO) as its
            GDPR representative in the EU. You can contact EDPO regarding matters pertaining to the GDPR:
          </Text>
          <ul>
            <li>
              <Text className={classes.listText}>
                by using EDPO's online request form:{" "}
                <a href="https://edpo.com/gdpr-data-request">https://edpo.com/gdpr-data-request</a>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                by writing to EDPO at Avenue Huart Hamoir 71, 1030 Brussels, Belgium
              </Text>
            </li>
          </ul>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            <i>UK General Data Protection Regulation (GDPR) - UK Representative</i>
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Pursuant to Article 27 of the UK GDPR, Dojo Tutor has appointed EDPO UK Ltd as its GDPR representative in
            the UK. You can contact EDPO UK regarding matters pertaining to the UK GDPR:
          </Text>
          <ul>
            <li>
              <Text className={classes.listText}>
                by using EDPO online request form:{" "}
                <a href="https://edpo.com/uk-gdpr-data-request/">https://edpo.com/uk-gdpr-data-request/</a>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                by writing to EDPO UK at 8 Northumberland Avenue, London WC2N 5BY, United Kingdom
              </Text>
            </li>
          </ul>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            If you&apos;re located in the EU you can contact ClassDojo&apos;s appointed local representative in the EU,
            European Data Protection Office (EDPO) using their{" "}
            <a href="https://edpo.com/gdpr-data-request/">online request form</a>. If you are located in the UK, you can
            contact ClassDojo’s appointed local representative in the UK, EDPO using their{" "}
            <a href="https://edpo.com/uk-gdpr-data-request/">online request form</a>.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="communications-from-dojo-tutor"
        >
          What Communications Will I Receive from Dojo Tutor
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you registered on Dojo Tutor, provided an email address or phone number to us, or otherwise opted-in to
            receive communications from us, you consent to us sending you messages and updates regarding your account,
            the Website and Services, including privacy and security notices, updates regarding the Website and Service,
            and information regarding products, features or services from Dojo Tutor (or third-parties we believe you
            may be interested in). We do not send communications about third-party products, features or services to
            learners or children. These communications may include, but are not limited to, social media updates,
            SMS/MMS messages, push and in-app notifications, email, and postal mail. If you have an account with us,
            we’ll also use your email address to contact you for customer service purposes, or for any legal matters
            that arise in the course of business. We may receive a confirmation when you open an email from us if your
            device supports it. We use this confirmation to help us understand which emails are most interesting and
            helpful.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you invite another person to join you on Dojo Tutor by providing their email address or phone number,
            Dojo Tutor may send an invitation on your behalf to the individual via the email or phone number you
            provided. Prior to providing Dojo Tutor with the email address or phone numbers of another person, you must
            ensure you have obtained consent from that person. The invitation (and any invitation reminders) may be sent
            via email or SMS/Text message. If they would prefer not to receive our communications, they may opt-out
            using the “Unsubscribe” or “STOP” instructions contained in those communications. You are consenting to Dojo
            Tutor (on your behalf) sending these informational text messages.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            You can always unsubscribe from receiving any of our 1) marketing emails or other marketing communications
            whenever you’d like by clicking the “Unsubscribe” link at the bottom of the email; 2) marketing SMS texts by
            replying or texting ‘STOP’; or 3) marketing push notifications by turning off push notifications on your
            device. You can further indicate your preferences by contacting us using the information in the{" "}
            <Link to="#contact-information">“How Can I Contact Dojo Tutor with Questions?”</Link> section below. Please
            note that opting out of marketing communications does not opt you out of receiving important business
            communications related to your current relationship with us, such as communications about your subscriptions
            or event registrations, service announcements or security information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you reside in Canada or other jurisdictions that require opt-in consent, you may have also opted in to
            receive future promotional electronic communications from us. If you opt-in to receive such communications,
            we may use the information to: communicate with you regarding our products, services and promotions; provide
            you with other information that you request; and/or improve our product and service offerings. You will
            always have the opportunity to "unsubscribe" from us at any time by clicking the "unsubscribe" link at the
            bottom of the email. We provide an ongoing opportunity to unsubscribe or opt out of contact by us as set out
            on our website or by contacting us by e-mail at tutoring-privacy@classdojo.com, or as otherwise described in
            our messages to you.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you decide to unsubscribe, we will only contact you thereafter: (i) for the purposes allowed under
            applicable law; and (ii) to receive service related messages. For example, if you unsubscribe from marketing
            communications, it will not limit our ability to send transactional communications to you regarding products
            or services you have requested.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            From time to time, we may send you useful messages about updates or new features. You can always opt-out of
            these messages if you’d rather we didn’t contact you.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="third-party-authentication-services"
        >
          Third-Party Authentication Services
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you decide to register for a Dojo Tutor account through an authentication service, such as Google Login
            (“Authentication Service”), Dojo Tutor may collect{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            that is already associated with your account connected to the Authentication Service. If you choose to
            provide such information during registration, you are giving Dojo Tutor the permission to store and use such
            information already associated with your Authentication Service in a manner consistent with this Privacy
            Policy. The current list of Authentication Services that we use is listed{" "}
            <Link to="/third-party-service-providers">here</Link>. Please note, that when using an Authentication
            Service for registering a{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learner
            </a>{" "}
            (or if a learner is directly using their own Authentication Service), Dojo Tutor will not request more
            information from the Authentication Service than name and email address unless specifically requested to be
            passed to us by the parent. We will only use the email collected from learners for the purposes of login and
            account management. The Authentication Service may collect certain other information from you in your use of
            that particular service (such as G-Suite for Education). You may revoke Dojo Tutor’s access to your account
            on any Authentication Service at any time by updating the appropriate settings in the account preferences of
            the respective Authentication Service. You should check your privacy settings on each Authentication Service
            to understand and change the information sent to us through each Authentication Service. Please review each
            Authentication Service’s terms of use and privacy policies carefully before using their services and
            connecting to our Service. Your use of Authentication Service is subject to the applicable third-party terms
            and privacy policies. Please see <Link to="/third-party-service-providers">here</Link> for more information
            on what we collect through the use of Authentication Services and how we use that information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            We may allow users to register on our Service or Website through an authentication service (e.g. with Google
            Login). If you choose to do so, we may collect personal information that is already associated with your
            connected authentication service, and you give us permission to use and store this information consistent
            with this Privacy Policy. You can revoke our access to your account on this authentication service at any
            time by updating the appropriate settings in the account settings of the authentication service. Please
            note, that when using an authentication service for registering a learner (or if a learner is directly using
            their own authentication service), Dojo Tutor will not request more information from the authentication
            service than name and email address, unless specifically requested to be passed to us by the parent.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          mb={24}
          mt={48}
          id="third-party-service-providers"
        >
          Third-Party Services
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            The Service and Website may contain links to websites, applications, services, or other content provided by
            third-parties (e.g., video players). In addition, tutors may choose to provide or place links to third-party
            materials for outside resources for {learnersLink()} (“Tutor Third Party Links”) . Dojo Tutor does not
            control or monitor such Tutor Third Party Links and does not encourage learners to click on such Tutor Third
            Party links through the Service. Your use of these features may result in the collection, processing and
            sharing of information about you, depending on the features. Any information, including{" "}
            {personalInformationLink()}, you choose to provide or that is collected by these third-parties is subject to
            that third-party’s policies governing privacy and security and is not covered by our Privacy Policy.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            These other websites may place their own cookies or other files on your computer, collect data or solicit
            personal information from you, including if you view a video through an embedded video player which is
            played off our Service or Website, but may appear to still be playing on our Service or Website. The fact
            that we link to a website or other third-party content is not an endorsement, authorization or
            representation that we are affiliated with that third-party, nor is it an endorsement of their privacy or
            information security policies or practices. We are not responsible for the content, features, privacy and
            security practices and policies of any third-parties. We encourage you to learn about third-parties’ privacy
            and security policies before providing them with personal information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Links to other websites and services as well as embedded video players may be found within the Service —
            this Privacy Policy does not apply to those.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="is-dojo-tutoring-a-controller"
        >
          Is Dojo Tutoring a Controller?
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutoring processes {personalInformationLink()} as a Controller, as defined in the GDPR and/or used
            under the CPRA.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="what-if-i-am-not-in-the-united-states"
        >
          What if I am not in the United States?
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutor is hosted and operated in the United States. If you use the Service or Website from the European
            Union or the United Kingdom, or any other region with laws governing data collection, protection and use
            that may differ from United States law, please note that you may be transferring your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            outside of those jurisdictions to the United States. The personal information we or our service providers
            collect may be stored and processed in servers within or outside of the United States and wherever we and
            our service providers have facilities around the globe, and certain information may be accessible by persons
            or companies outside of the United States who provide services for us. Prior to transferring your{" "}
            {personalInformationLink()} to the United States, ClassDojo will take appropriate steps to ensure that the
            personal information will be safeguarded and used only for authorized purposes set out in this Privacy
            Policy or in accordance with applicable laws.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Where we transfer, store and process your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            outside of the United Kingdom, European Union, EEA, or Switzerland we have ensured that appropriate
            safeguards are in place to ensure an adequate level of protection for the rights of data subjects based on
            the adequacy of the receiving country’s data protection laws, or an alternative mechanism for the transfer
            of data as approved by the European Commission (Art. 46 GDPR) or other applicable regulator. For more
            information on these safeguards, please see our{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12038310314509">FAQ</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            By using the Service, you consent to the transfer of your personal information outside your home
            jurisdiction, including to the United States, and to the storage of your personal information in the United
            States, for the purpose of hosting, using and processing the personal information in accordance with this
            Privacy Policy. You further acknowledge that these countries may not have the same data protection laws as
            the country from which you provided your personal information, and that Dojo Tutor may be subject to access
            requests and be compelled to disclose your personal information to government authorities, courts and law
            enforcement officials in countries (including the United States) outside of your home country. You have the
            right to withdraw your consent at any time by contacting us as described in the{" "}
            <Link to="#contact-information">“Contacting Dojo Tutor”</Link> section below.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Our servers are located in the U.S., so if you are using the Service from any other country, your data might
            be transferred to the U.S.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="eu-us-data-privacy-framework"
        >
          EU – U.S. Data Privacy Framework (EU-U.S. DPF), Swiss – U.S. Data Privacy Framework And UK Extension To The
          EU-U.S. DPF
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutor complies with the EU-U.S. Data Privacy Framework (EU-U.S. DPF), the UK Extension to the EU-U.S.
            DPF, and the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF) as set forth by the U.S. Department of
            Commerce (collectively the "DPF Programs"). Dojo Tutor has certified to the U.S. Department of Commerce that
            it adheres to the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF Principles) with regard to the
            processing of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal data
            </a>{" "}
            received from the European Union in reliance on the EU-U.S. DPF and from the United Kingdom (and Gibraltar)
            in reliance on the UK Extension to the EU-U.S. DPF. Dojo Tutor has certified to the U.S. Department of
            Commerce that it adheres to the Swiss-U.S. Data Privacy Framework Principles (Swiss-U.S. DPF Principles)
            with regard to the processing of personal data received from Switzerland in reliance on the Swiss-U.S. DPF.
            ClassDojo will rely on the UK Extension to the EU-U.S. DPF and the Swiss-U.S. DPF from the date that they
            become recognized in the United Kingdom and Switzerland respectively as providing appropriate safeguards for
            the transfer of personal data. If there is any conflict between the terms in this Privacy Policy and the
            EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles (collectively, the "DPF Principles"), the DPF
            Principles shall govern. To learn more about the DPF Programs, and to view our certification, please visit
            <a href="https://www.dataprivacyframework.gov/">https://www.dataprivacyframework.gov/</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutor is responsible for the processing of personal data it receives, under the DPF Programs , and
            subsequently transfers to third-parties acting as an agent on its behalf. Dojo Tutor complies with the DPF
            Principles for all onward transfers of personal information from the EU, UK and Switzerland, including the
            onward transfer liability provisions.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            With respect to personal data received or transferred pursuant to the DPF Programs, Dojo Tutor is subject to
            the jurisdiction and regulatory enforcement powers of the U.S. Federal Trade Commission and other authorized
            statutory bodies. In certain situations, Dojo Tutor may be required to disclose personal data in response to
            lawful requests by public authorities, including to meet national security or law enforcement requirements.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutor commits to resolve DPF Principles-related complaints about the collection and use of your
            personal data. EU, UK and Swiss individuals with inquiries or complaints regarding our handling of personal
            data received in reliance on the DPF Programs should contact us using the details set out in the "
            <Link to="#how-to-exercise-rights">How to Exercise Your Rights</Link>" Section. In compliance with the
            EU-U.S. DPF, the UK Extension to the EU-U.S. DPF and the Swiss-U.S. DPF, Dojo Tutor commits to refer
            unresolved complaints concerning our handling of personal data received in reliance on the DPF Programs to
            Truste, an alternative dispute resolution provider based in the United States. If you do not receive timely
            acknowledgment of your DPF Principles-related complaint from us, or if we have not addressed your DPF
            Principles-related complaint to your satisfaction, please visit{" "}
            <a href="https://feedback-form.truste.com/watchdog/request">here</a> for more information or to file a
            complaint. The services of Truste are provided at no cost to you.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Under certain conditions, more fully described on the{" "}
            <a href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2">
              DPF Principles website
            </a>
            , you may invoke binding arbitration when other dispute resolution procedures outlined above have been
            exhausted.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutor has certified to the Department of Commerce that it adheres to the EU- U.S. Data Privacy
            Framework (DPF) Principles regarding the processing of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal data
            </a>{" "}
            transferred from the European Union and from the UK in reliance on the UK Extension to the EU- US DPF. Dojo
            Tutor has also certified to the U.S. Department of Commerce that it adheres to the Swiss-U.S. DPF Principles
            (Swiss-U.S. DPF Principles) with regard to the processing of personal data received from Switzerland in
            reliance on the Swiss-U.S. DPF. To learn more about the DPF program, and to view our certification, please
            visit <a href="https://www.dataprivacyframework.gov/">https://www.dataprivacyframework.gov/</a>.
          </Text>
        </Box>

        <Text className={classes.subHeader} mb={24} mt={48} sx={{ scrollMarginTop: "130px" }} id="canada">
          Canada
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We endeavor to provide privacy protection that is consistent with the applicable privacy laws in Canada,
            including the Personal Information Protection and Electronic Documents Act (“PIPEDA”) and the provincial
            private sector privacy laws in British Columbia, Alberta and Quebec. For any questions regarding how we
            comply with PIPEDA, please contact us at tutoring-privacy@classdojo.com.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutor works hard to provide privacy protections consistent with applicable privacy laws in Canada.
          </Text>
        </Box>

        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="us-state-privacy-disclosures"
        >
          U.S. State Data Privacy Disclosures
        </Text>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Overview</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you are a U.S. resident, we process your {personalInformationLink()} in accordance with applicable U.S.
            state data privacy laws. Some U.S. state privacy laws, like the CCPA, Virginia Consumer Data Protection Act
            (VCDPA), Colorado Privacy Act (CPA), Connecticut Act Concerning Personal Data Privacy and Online Monitoring
            (CTDPA); and Utah Consumer Privacy Act (UCPA) require specific disclosures for state residents. This section
            of our Dojo Tutor Privacy Policy contains information required by the CCPA and other U.S. state data privacy
            laws.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please note that state data privacy laws are constantly in flux, and the rules implementing some of these
            laws have not yet been finalized. We are continuously working to ensure we stay abreast of and remain
            compliant with these laws, and we will update our processes and disclosures as these laws evolve.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Transparency</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            You have the right to know what kinds of {personalInformationLink()} Dojo Tutor collects and the sources of
            that information, how we use that information, and our business purpose for that collection. Please see this
            <Link to="/transparency">chart</Link> for the detailed categories of personal information we collect from
            each user type, including the sources from which the information was collected, the business purpose for
            which the information was collected, the third parties with whom we disclose personal information for a
            business purpose, and information on retention periods for the categories of personal information ("Notice
            at Collection").
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Please see the "
            <Link to="#what-information-does-dojo-tutor-collect">What Information Does Dojo Tutor Collect?</Link>" and
            the "<Link to="#how-does-dojo-use-info">How Does Dojo Tutor Use the Information It Collects?</Link>"
            sections of our Privacy Policy for more information on the personal information we collect, the sources of
            that information and how we use this information. Please also see the "
            <Link to="#how-we-share-you-personal-information">How We Share Your Personal Information</Link>" and "
            <Link to="#how-long-does-dojo-keep-information">How Long Does Dojo Tutor Keep Information About Me?</Link>"
            section of our Privacy Policy for more information on when Dojo Tutor may disclose personal information and
            how Dojo Tutor retains personal information.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Your Rights and Choices</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you are a California resident or resident of any other state with an applicable U.S. state data privacy
            law, you have the right to request that Dojo Tutor disclose certain information to you about our collection,
            use, and disclosure of your {personalInformationLink()}. Please see this{" "}
            <Link to="/transparency">chart</Link> for the detailed categories of personal information we collect from
            each user type, including the sources from which the information was collected, the business purpose for
            which the information was collected, the third parties with whom we disclose personal information for a
            business purpose, and information on retention periods for the categories of personal information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            You also have specific rights of access, deletion, correction and data portability regarding your{" "}
            {personalInformationLink()} under the CCPA, and other applicable U.S. state data privacy laws. You have the
            right to request that Dojo Tutor provide the specific pieces of information that we have collected about
            you. We provide these same rights to all users regardless of location. Please see the “
            <Link to="#providing-transparency-rights">
              Dojo Tutor's commitments to providing Transparency and Your Rights
            </Link>
            ” Section for more detail on how to exercise these rights, including how we will verify requests. The VCDPA,
            CPA and other applicable U.S. state data privacy laws also provide the right to opt out of certain forms of
            profiling and targeted advertising. You may also designate a natural person or business entity registered
            with the California Secretary of State (or your applicable state) to act on your behalf to exercise these
            rights. For more information please see our {FAQLink()}.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We will not discriminate against you for exercising any of your rights under CCPA or any other applicable
            U.S. state data privacy law with a similar right. Unless permitted by the CCPA or any other applicable U.S.
            state data privacy law, we will not:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>Deny you goods or services.</Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Charge you different prices or rates for goods or services, including granting discounts or other
              benefits, or imposing penalties.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Suggest that you may receive a different price or rate for goods or services or a different level or
              quality of goods or services.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            However, we may offer you certain financial incentives permitted by the CCPA, or any other applicable U.S.
            state data privacy law, that can result in different prices, rates, or quality levels. Any CCPA-permitted
            (or other U.S. state data privacy law) financial incentive we offer will be reasonably related to your
            personal information's value and contain written terms that describe the program's material aspects.
            Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at
            any time.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            If you are a U.S. resident with an applicable state specific privacy law, such as the CCPA, VCDPA, CPA,
            CTDPA or UCPA, these laws afford you certain rights of access, deletion and data portability - which we also
            extend to all users. Please see the{" "}
            <Link to="#providing-transparency-rights">
              Dojo Tutor’s commitments to providing Transparency and Your Rights
            </Link>{" "}
            Section for more detail on how to exercise these rights, including how we will verify requests. We will not
            discriminate against you for exercising any of your rights under the applicable state privacy laws.
            <br />
            Additionally, if you are a California resident you are entitled to certain disclosures regarding our
            collection and use of your personal information over the past twelve (12) months - Please see{" "}
            <Link to="/transparency">this chart</Link> for the detailed categories of personal information we collect
            from each user type, including the sources from which the information was collected, the business purpose
            for which the information was collected and the third parties with whom we share or disclose personal
            information for a business purpose.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Sensitive Personal Information</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            In certain jurisdictions, you have the right to limit the use or disclosure of your sensitive{" "}
            {personalInformationLink()} to certain allowed activities under applicable U.S. state data privacy laws.
            Sensitive personal information is a subset of personal information and is defined differently under
            applicable U.S. state data privacy laws. Please see our{" "}
            <a href="https://tutor.classdojo.com/#/transparency">Information Transparency Chart</a> for a list of any
            sensitive personal information that we may collect, use and disclose based on the applicable U.S. state data
            privacy laws. If you are a California resident, we do not use or disclose your{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ae)">
              sensitive personal information
            </a>{" "}
            for purposes other than those allowed under the{" "}
            <a href="https://cppa.ca.gov/regulations/pdf/20230329_final_regs_text.pdf">CCPA, Section 7027(m)</a>.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>We Do Not Sell Your Personal Information</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            As stated throughout this Privacy Policy, Dojo Tutor does not{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              sell personal information
            </a>{" "}
            of any Dojo Tutor Services user to any third-party for any purpose - including for advertising or marketing
            purposes. Please note that a sale does not include when we disclose your personal information at your
            direction, or when otherwise permitted under applicable U.S. state data privacy laws.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px" }}
          id="california-privacy-disclosures"
        >
          California Privacy Disclosures
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            In addition to the disclosures listed above in this U.S. State Data Privacy Section, please see the
            following California specific disclosures.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            The CCPA requires Dojo Tutor to describe our data practices using specific categories and to provide
            specific types of required disclosures. Please see our <Link to="/transparency">Notice of Collection</Link>{" "}
            which organizes this information. At or before the time of collection, you have a right to receive notice of
            our practices, including the categories of personal information, the purposes for which such information is
            collected or used, whether such information is sold or shared, and how long such information is retained.
            You can find those details by clicking on the <Link to="/transparency">Notice of Collection</Link>.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Do Not Share My Personal Information</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Dojo Tutor does not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> its
            users’ personal information to a{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ai)">third party</a> for
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(k)">
              cross-context behavioral advertising
            </a>
            , as those terms are defined under the CCPA. Furthermore, we do not share or disclose personal information
            with any third-parties except in the limited circumstances described in this Privacy Policy and as set forth{" "}
            <Link to="#third-party-service-providers">here</Link>.
          </Text>
        </Box>

        <Box mb={8}>
          <Text className={classes.paragraph}>
            If Dojo Tutor in the future does{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            any personal information of a Dojo Tutor Services user located in California, we will provide the CCPA
            required "Do Not Sell or Share my Personal Information" link in the footer of our website as well as
            honoring an Opt-Out Preference Signal as set forth below. Dojo will not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            the personal information of consumers under 16 years of age.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Currently, Dojo Tutor does not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> its
            users personal information to a{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ai)">third party</a> for{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(k)">
              cross-context behavioral advertising
            </a>
            . If Dojo Tutor in the future does{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            any personal information of a Dojo Tutor Services user located in California, we will provide the CCPA
            required "Do Not Sell or Share my Personal Information" link in the footer of our website as well as
            honoring an Opt-Out Preference Signal as set forth below. Dojo Tutor will not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            the personal information of consumers under 16 years of age. For a list of third-party service providers we
            disclose information to, please see{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">here</a>.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Do Not Track or Opt-Out Preference Signal</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers to inform websites
            and services that they do not want certain information to be "tracked". For more information on “do not
            track,” please visit <a href="http://www.allaboutdnt.org">www.allaboutdnt.org</a>. The industry working
            group (W3C Tracking Protection) to develop the DNT standard is now{" "}
            <a href="https://www.w3.org/2011/tracking-protection/">closed</a>, so Dojo Tutor does not respond to these
            DNT signals. There are different (from DNT) opt-out preference signals set forth under the CCPA ("Opt-Out
            Preference Signals") also called a Global Privacy Control (GPC) Signal. Dojo Tutor will respond to Opt-out
            Preference or Global Privacy Control Signals. This signal will apply to the browser. A consumer can use an
            Opt-Out Preference Signal by following the instructions <a href="https://globalprivacycontrol.org/">here</a>
            .
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Third-parties that have content embedded on the Service, such as a social feature, or an embedded video
            player, may set cookies on a user's browser and/or obtain information about the fact that a web browser
            visited the Service from a certain IP address. Third-parties cannot collect any other personally
            identifiable information from the Service unless you provide it to them directly.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutor does not respond to DNT signals as there is lack of industry standard and the working group to
            resolve these issues is also now <a href="https://www.w3.org/2011/tracking-protection/">closed</a>. However,
            if you are a user based in California, Dojo Tutor will respond to Opt-Out Preference or GPC Signals as those
            terms and signals are defined under the CCPA, as amended. This signal will apply to the browser and a
            consumer can use an opt-out preference signal by following the instructions{" "}
            <a href="https://globalprivacycontrol.org/">here</a>.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Notice for Minors (Users under 18)</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you are under the age of 18, or the parent of a child using Dojo Tutor under the age of 18 residing in
            California, you are entitled to request removal of content or information you (the minor) have publicly
            posted on our Service. Currently, we do not allow minors to post content to share publicly, but if in the
            future this is a feature, you have this right to request removal.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Minors (or the minor’s parents if under 13) have the option to delete {personalInformationLink()} associated
            with their user accounts. If you are a minor, or the parent of a minor under 13, and would like to delete
            personal information associated with your Dojo Tutor account, please follow the directions set forth in the
            "
            <Link to="#providing-transparency-rights">
              Dojo Tutor's commitments to providing Transparency and Your Rights
            </Link>
            " section. Although we offer deletion capabilities on our Service, you should be aware that the removal of
            content may not ensure complete or comprehensive removal of that content or information posted through the
            Service, as there may be {deIdentifiedLink()} or recoverable elements of your content or information on our
            servers in some form. Additionally, we will not remove content or information that we may be required to
            retain at the direction of the school or under applicable laws.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            If you are a minor or parent of a minor, you may delete personal information per the "
            <a href="https://classdojo.zendesk.com/hc/articles/12706732718349">
              What happens when I delete my account (Tutoring)?
            </a>
            " section. While Dojo Tutor currently does not allow minors to post content to share publicly, if Dojo Tutor
            provides this feature in the future, you have the right to request removal.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>“Shine the Light” Law</u>
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            Pursuant to California Civil Code Section 1798.83(c)(2), Dojo Tutor does not share{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            with third parties for their own direct marketing purposes unless you affirmatively agree to such
            disclosure. California customers can receive more information about our compliance with this law or to make
            a request under California Civil Code Section 1798.83 by sending us an email at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com ">tutoring-privacy@classdojo.com</a> or by writing to us as
            set forth in the <Link to="#contact-information">“How Can I Contact Dojo Tutor with Questions”</Link>{" "}
            section.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="changes-to-our-privacy-policy"
        >
          How Will Dojo Tutor Notify Me of Changes to this Policy?
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We may occasionally update this Privacy Policy as our business practices and legal requirements evolve or to
            further protect our users. You can see when the last update was by looking at the “Last Updated” date at the
            top of this page. Any updates will be effective upon posting. We won’t reduce your rights under this Privacy
            Policy without your explicit consent. If we make any significant changes, we’ll provide prominent notice by
            posting a notice on the Service or Website and/or notifying you by email (using the email address you
            provided), so you can review and make sure you know about them. In addition, if we ever make material
            changes to the types of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            we collect from children, or how we use it, we will notify parents in order to obtain{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016320121101-What-consent-must-parents-give-for-their-child-to-use-Dojo-Tutoring">
              parental consent
            </a>{" "}
            for those new practices.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We encourage you to review this Privacy Policy from time to time, to stay informed about our collection,
            use, and disclosure of personal information through the Service and Website. If you don’t agree with any
            changes to the Privacy Policy, you may terminate your account. By continuing to use the Service or Website
            after the revised Privacy Policy has become effective, you acknowledge that you accept and agree to the
            current version of the Privacy Policy.
          </Text>
        </Box>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            We reserve the right to notify you of updates to our Privacy Policy as part of communications, e.g., by
            email, and/or by some other means. Please note that if you have opted not to receive legal notice emails
            from us (or you have not provided us with your email address), those legal notices will still govern your
            use of the Services, and you are still responsible for reading and understanding them.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            We will let you know by email and/or on our website when we make significant changes to our Privacy Policy.
            If we make material changes to the types of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            we collect from children, or how we use it, we will notify parents, or ask for their{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">consent</a>. You can see when the last
            update was by looking at the “Last Updated” date at the top of this page and any updates will be effective
            upon posting.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={24} mt={48} sx={{ scrollMarginTop: "130px" }} id="contact-information">
          Contact Information
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            If you have any questions about this Privacy Policy, our privacy practices, or for any other purposes,
            please contact us by email at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a>. You may also write to us
            at:
            <br />
            Dojo Tutor
            <br />
            735 Tehama Street
            <br />
            San Francisco, California, 94103. United States of America.
            <br />
            Atten: Chief Privacy Officer
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={24} mt={48} sx={{ scrollMarginTop: "130px" }}>
          Further Privacy and Security Resources
        </Text>
        <Box mb={8}>
          <Text className={classes.paragraph}>
            For parents and/or legal guardians, learners, and tutors seeking more information on how we provide safety
            on Dojo Tutor, we provide privacy and security related materials on our{" "}
            <a href="https://www.classdojo.com/privacycenter/">Privacy Center</a> as well as on our{" "}
            <a href="https://www.classdojo.com/security/">Security Whitepaper</a>. In addition:
          </Text>
          <ul>
            <li>
              <Text className={classes.listText}>
                For Key Terms that are used in this Privacy Policy and our Terms of Service, please visit this{" "}
                <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms">
                  Key Terms FAQ.
                </a>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                For our Online Tracking Technologies Policy, please see{" "}
                <a href="https://tutor.classdojo.com/#/cookies-policy">here</a>.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                For our short video for students that highlights the most important details in our Privacy Policy that
                they should know about, please see{" "}
                <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
                  here
                </a>
                .
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                For our chart that details the personal information we collect, how we use it, and where it is stored,
                please see <a href="https://tutor.classdojo.com/#/third-party-service-providers/">here</a>.
              </Text>
            </li>
          </ul>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            If you’d like some more safety and privacy resources for your school, please visit our{" "}
            <a href="https://www.classdojo.com/privacycenter/">Privacy Center</a> or our{" "}
            <a href="https://www.classdojo.com/security/">Security Whitepaper</a>. For definitions of Key Terms used in
            this policy and our Terms of Service,{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703">visit here</a>.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Privacy;
