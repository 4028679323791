import { RAW_cssValue } from "@classdojo/web/nessie/stylingLib";
import useStyles from "./styles/ContentPolicy.styles";
import { Box, Text } from "UILibrary";

const ContentPolicy = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.forTeachers}>
      <Box className={classes.wrapper}>
        <Box className={classes.hero} sx={{ marginBottom: RAW_cssValue(36) }}>
          <Box>
            <Text component="h1" mb={1}>
              Session Content Policy
            </Text>
            <Text className={classes.subHeader}>Last Updated: November 20, 2022</Text>
          </Box>
        </Box>

        <Box mb={40}>
          <Box mb={8}>
            <Text component="h3" mb={8}>
              Overview
            </Text>
          </Box>

          <Box mb={8}>
            <Text
              component="p"
              sx={
                {
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "32px",
                  letterSpacing: -0.5,
                  color: "#2C2A50",
                  margin: 0,
                } as any
              }
            >
              In addition to our Terms of Service, we have specific content policies that tutors must follow when using
              Dojo Tutoring. These policies are intended to support Dojo Tutoring’s mission to give every kid on earth
              an education they love.
            </Text>
          </Box>

          <Box mb={8}>
            <Text
              component="p"
              sx={
                {
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "32px",
                  letterSpacing: -0.5,
                  color: "#2C2A50",
                  margin: 0,
                } as any
              }
            >
              At Dojo Tutoring, we aspire to help each learner in a personalized way. We believe that our tutors are in
              the best position to assess and prepare for sessions that meet the individual needs of each learner. We
              have created a set of policies to help guide and support our Tutors as they work with learners of
              different ages and backgrounds.
            </Text>
          </Box>

          <Box mb={8}>
            <Text
              component="p"
              sx={
                {
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "32px",
                  letterSpacing: -0.5,
                  color: "#2C2A50",
                  margin: 0,
                } as any
              }
            >
              If a Tutor violates these policies or otherwise causes harm, the Tutor will be removed from our platform
              and will not be allowed to use Dojo Tutoring.
            </Text>
          </Box>

          <Box mb={8}>
            <Text
              component="p"
              sx={
                {
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "32px",
                  letterSpacing: -0.5,
                  color: "#2C2A50",
                  margin: 0,
                } as any
              }
            >
              As Dojo Tutoring grows, these policies will continue to evolve. We encourage Tutors to frequently review
              our policies and always use their best judgment.
            </Text>
          </Box>
        </Box>

        <Box mb={40}>
          <Box mb={8}>
            <Text component="h3">Policy</Text>
          </Box>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <Text>
              <b>Sessions must not endanger or compromise the well-being of learners, intentionally or otherwise.</b>{" "}
              This includes both physical and mental harm, as well as financial outcomes.
            </Text>

            <ul>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not ask learners to read violent passages as an exercise to practice their
                  phonics. Even if they do not actively read a violent passage in class, teachers should avoid using
                  violent books/stories as examples that the learner could then search for as a result of the session.
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not use the calculation of medication dosage as an example during a math
                  session. Even though the example might seem harmless, it has the potential to be misinterpreted as
                  medical advice, which could result in harm to the learner.
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not use investment in a specific stock as an example during a math session.
                  Although it might just be an example, it could be misinterpreted as financial advice and result in the
                  learner making a poor financial decision.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Sessions must not make learners feel excluded due to their beliefs.</b>
            </Text>
            <ul>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not use reading passages that explain why some religions might be more
                  appealing than others, or why one political stance makes more sense than another. Students may confuse
                  the passage’s subjective perspective for recommendations.
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor cannot request to stop tutoring a learner based on their beliefs or cultural
                  background.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Tutors must not share information whose accuracy is disputed by experts.</b> While there may exist
              different opinions around specific facts, our Quality Assurance experts will decide if there is sufficient
              uncertainty surrounding a topic to merit an exception to these guidelines.
            </Text>
            <ul>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not read passages that suggest that the moon landing may have been faked.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Sessions should be appropriate for children as young as 5.</b> This means no profanity, nudity,
              violence, scariness, etc.
            </Text>
            <ul>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not read passages that include profanity in a reading session.
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not use the number of casualties in WW2 as part of a math problem.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Tutors must be professional, respectful, and kind at all times.</b> Learners look up to Tutors as role
              models—Tutors must behave impeccably at all times.
            </Text>
            <ul>
              <li>
                <Text className={classes.listText}>Example: A tutor should never harass or bully a learner.</Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor’s methods should never make a student feel that they are not safe.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Tutors must not advertise other services.</b>
            </Text>
            <ul>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not recommend the purchase of any good or service that would imply a benefit
                  to themselves.
                </Text>
              </li>
              <li>
                <Text className={classes.listText}>
                  Example: A tutor should not ask learners to subscribe to other educational platforms.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Tutors should not violate copyright laws during their sessions.</b> Tutors are expected to use their
              own materials during classes. External resources must be copyright-free or be used with the explicit
              consent of the resource’s owner.
            </Text>
            <ul>
              <li>
                <Text className={classes.listText}>
                  Example: A Tutor may include a short excerpt from an audiobook as an example in a reading session, but
                  should not play it in its entirety.
                </Text>
              </li>
            </ul>
          </Text>
        </Box>

        <Box mb={64}>
          <Box mb={8}>
            <Text component="h3">Questions</Text>
          </Box>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Questions about these policies, or appeals of moderation decisions, can be emailed to{" "}
            <a href="mailto:tutoring-support@classdojo.com">tutoring-support@classdojo.com</a>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentPolicy;
