import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/tutor/class/meeting"]["get"];
type Params = Endpoint["parameters"]["query"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export default (token: string) => {
  const params: Params = { token };
  return monolithApiClient.get<Response>(`/tutor/class/meeting`, { params });
};
