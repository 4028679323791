import { useState } from "react";
import TooCloseDayOffWarning from "./TooCloseDayOffWarning";
import ConfirmDayOffAndReason from "./ConfirmDayOffAndReason";
import { useDayOffFormStateContext } from "old/src/pageComponents/Teachers/Availability/useOOOForm";
import { logTutorEvent } from "src/utils/log";
import useWatch from "@classdojo/web/hooks/useWatch";
import { isDayOffTooClose } from "src/pageComponents/TutorDayOff/utils";

export default function () {
  const { selectedDayOffs } = useDayOffFormStateContext();
  const [showTooCloseWarning, setShowTooCloseWarning] = useState(isDayOffTooClose(selectedDayOffs));

  useWatch([showTooCloseWarning], () => {
    if (showTooCloseWarning) {
      logTutorEvent("marketplace.tutor.day_off_creation_too_soon");
    }
  });

  if (showTooCloseWarning) {
    return (
      <TooCloseDayOffWarning
        onContinue={() => {
          logTutorEvent("marketplace.tutor.day_off_emergency_clicked");
          setShowTooCloseWarning(false);
        }}
      />
    );
  }

  return <ConfirmDayOffAndReason />;
}
