import { BodyText, TextField, theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import Field from "src/components/form/Field";
import NewSingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { TranslatedString } from "@classdojo/web/pods/i18n/translate";
import { usePublicTeacherProfileQuery } from "old/src/hooks/useParent";
import { useMemo } from "react";
import { logTutorEvent } from "src/utils/log";
import { TutorGrade, newOfferedGradesAndLabels } from "src/utils/constants";
import NewGradeSelect from "src/components/NewGradeSelect";

const FormSchema = z.object({
  childName: z.string().min(2).max(50).default(""),
  grade: z.string().min(1).default(""),
});
type FormData = z.infer<typeof FormSchema>;

const DirectBookingChildInformation = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
  });
  const tutorId = state.booking?.teacher_uid;
  const { teacher: tutor } = usePublicTeacherProfileQuery(tutorId);

  useOnFirstRender(() => {
    if (state.booking) {
      if (!tutorId) {
        push("/direct-booking-subscription/tutor-introduction");
      } else {
        logTutorEvent({
          eventName: "parent.marketplace.directbooking.viewed.child_info",
          metadata: { tutor_id: tutorId },
        });
        const { first_name_child, grade } = state.booking;
        if (first_name_child) form.setValue("childName", first_name_child, { shouldValidate: true });
        if (grade) form.setValue("grade", grade, { shouldValidate: true });
      }
    }
  });

  const onNextStep: SubmitHandler<FormData> = async (values) => {
    logTutorEvent({
      eventName: "parent.marketplace.directbooking.completed.child_info",
      metadata: { tutor_id: tutorId },
    });
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state.booking,
        first_name_child: values.childName,
        grade: values.grade,
      },
    });
    push("/direct-booking-subscription/subject-selection");
  };

  const tutorGrades = useMemo(() => {
    if (!tutor) return [];
    const totalGrades = [
      ...tutor.tutoring_information.math,
      ...tutor.tutoring_information.reading,
      ...tutor.tutoring_information.writing,
    ];
    return newOfferedGradesAndLabels.filter(({ grade }) => totalGrades.includes(grade));
  }, [tutor]);

  return (
    <NewSingleClassForm
      disabled={!form.formState.isValid}
      title="Tell us a bit about your child."
      onSubmit={form.handleSubmit(onNextStep)}
      contentMaxWidth={430}
      buttonText="Continue"
      sxTitle={sxTitle}
      progressBarPercentage={20}
    >
      <div sx={sxFieldsWrapper}>
        <Field
          form={form}
          name="childName"
          render={({ error, onBlur, onChange, value }) => (
            <div sx={sxField}>
              <TextField
                sx={sxInput}
                autoComplete="off"
                defaultValue={value}
                maxLength={100}
                type="text"
                aria-label={"childName" as TranslatedString}
                label="Your child’s name"
                onBlur={onBlur}
                onChange={onChange}
              />
              {error?.message && (
                <BodyText level={2} kind="danger">
                  {error.message}
                </BodyText>
              )}
            </div>
          )}
        />
        <Field
          form={form}
          name="grade"
          render={({ value, onChange }) => (
            <div sx={sxField}>
              <NewGradeSelect grade={value as TutorGrade} onChange={onChange} offeredGradesAndLabels={tutorGrades} />
            </div>
          )}
        />
      </div>
    </NewSingleClassForm>
  );
};

export default DirectBookingChildInformation;

const sxFieldsWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  marginTop: "dt_xl",
  marginBottom: "dt_xl",
  width: "100%",
  gap: "dt_xl",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: "0",
    gap: "dt_l",
  },
};

const sxField: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_xs",
};

const sxInput: ThemeUIStyleObject = {
  width: "100%",
};

const sxTitle: ThemeUIStyleObject = {
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    textAlign: "left",
    fontSize: "23px",
    fontWeight: 800,
    marginBottom: "dt_xs",
    lineHeight: "28px",
    letterSpacing: "-0.1px",
  },
};
