import { Box, Select, TextInputProps, CSSObject, Text, Image } from "@mantine/core";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import CustomTextInput from "./TextInput";
import countryCodes from "country-codes-list";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import PhoneInput from "react-phone-number-input";
import { findFlagUrlByIso2Code } from "country-flags-svg";
// eslint-disable-next-line @web-monorepo/no-css-file-imports
import "react-phone-number-input/style.css";

interface MobileInputProps extends TextInputProps {
  form: any;
  onInputFocus?: any;
  onCountrySelectFocus?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  errorStyles?: CSSObject;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const countryCodesObject = countryCodes.customList("countryCode", "+{countryCallingCode}");

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const countryCodesNamesObject = countryCodes.customList("countryCode", "{countryNameEn}");

const CustomInput = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <CustomTextInput
      errorStyles={{ display: "none", margin: "0 !important" }}
      inputstyles={{ borderRadius: "0 16px 16px 0" }}
      sx={{ width: "100%" }}
      {...props}
      forwardedRef={ref}
    />
  );
});

CustomInput.displayName = "inputComponent";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  json: any;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ json, ...others }: ItemProps, ref) => {
  const parsed = JSON.parse(json) as any;
  return (
    <div ref={ref} {...others}>
      {parsed?.flag} {parsed?.name}
    </div>
  );
});

SelectItem.displayName = "SelectItem";

const DEFAULT = JSON.stringify({
  areaCode: "+1",
  code: "US",
  flag: "🇺🇸",
  name: "United States of America",
  flagSvg: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg",
});

const MobileInput: React.FC<MobileInputProps> = ({ form, onInputFocus, onCountrySelectFocus }) => {
  const [selectedCountry, setSelectedCountry] = useState(DEFAULT);
  const phoneInputProps = form.getInputProps("mobile_number");
  const { value, error } = phoneInputProps;
  const [phoneValue, setPhoneValue] = useState<any>(value);
  const [defaultCountry, setDefaultCountry] = useState<any>("US");
  const [flagSvg, setFlagSvg] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg",
  );

  const countries = useMemo(() => {
    return Object.keys(countryCodesObject).map((countryCode: string) => {
      const flagSvg = findFlagUrlByIso2Code(countryCode);
      const value = JSON.stringify({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        areaCode: countryCodesObject[countryCode],
        code: countryCode,
        flag: getUnicodeFlagIcon(countryCode),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name: countryCodesNamesObject[countryCode],
        flagSvg,
      });

      return {
        label: getUnicodeFlagIcon(countryCode),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name: countryCodesNamesObject[countryCode],
        code: countryCode,
        value,
        json: value,
        flagSvg,
      };
    });
  }, []);

  useEffect(() => {
    const parsed = JSON.parse(selectedCountry) as any;

    if (form.values.mobile_number) {
      form.setFieldValue("mobile_number", form.values.mobile_number?.replace(/[^\d+]/g, ""));
    } else {
      form.setFieldValue("mobile_number", parsed?.areaCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.mobile_number]);

  useEffect(() => {
    if (phoneValue) {
      form.setFieldValue("mobile_number", phoneValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneValue]);

  const getIcon = () => {
    const countryObject = JSON.parse(selectedCountry) as any;

    return /\p{Emoji}/u.test(countryObject?.flag) ? (
      <Text
        style={{
          color: "#212529",
          fontSize: "14px",
          marginTop: "2px",
          marginLeft: "4px",
          fontWeight: 600,
        }}
      >
        {countryObject?.code}
      </Text>
    ) : (
      <Image width={20} src={flagSvg} alt={selectedCountry} />
    );
  };

  const selectCountry = () => {
    return (
      <Select
        error={error}
        styles={{
          input: {
            height: "64px",
            padding: "20px 16px",
            border: "2px solid #D3D7EC",
            borderRadius: "16px 0 0 16px",
            color: "#2C2A50",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            borderRight: "none",
            width: "100px",
          },
          error: {
            display: "none",
            borderColor: "red !important",
            marginBotton: "5px !important",
          },
          dropdown: {
            width: "auto !important",
            left: "0 !important",
          },
        }}
        searchable
        data={countries}
        filter={(value, item) =>
          item.code.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.name.toLowerCase().includes(value.toLowerCase().trim())
        }
        value={selectedCountry}
        itemComponent={SelectItem}
        onChange={(value: any) => {
          const country = JSON.parse(value) as any;
          setSelectedCountry(value);
          setDefaultCountry(country.code);
          form.setFieldValue("mobile_number", country?.areaCode);
          setFlagSvg(country?.flagSvg);
          setPhoneValue("");
        }}
        onFocus={onCountrySelectFocus}
        iconWidth={48}
        icon={getIcon()}
      />
    );
  };

  return (
    <Box sx={{ display: "grid", position: "relative" }}>
      <Text size={16} weight={600} color="#212529">
        Mobile number
      </Text>
      <PhoneInput
        error={error}
        value={phoneValue}
        onChange={setPhoneValue}
        international={false}
        defaultCountry={defaultCountry}
        inputComponent={CustomInput}
        countrySelectComponent={selectCountry}
        limitMaxLength={true}
        onFocus={onInputFocus}
      />
      {error && (
        <Text
          sx={
            {
              fontSize: "12px",
              lineHeight: "24px",
              color: "#f03e3e",
            } as any
          }
        >
          {error}
        </Text>
      )}
    </Box>
  );
};

export default MobileInput;
