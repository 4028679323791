import { monolithApiClient } from "src/configs/axios.config";
import { useQuery } from "@tanstack/react-query";

import { paths } from "@classdojo/ts-api-types";
type Endpoint = paths["/api/marketplace/tutor/dayOffSessionConflicts"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

const getDayOffSessionConflicts = async (payload: Body) =>
  await monolithApiClient.post<Response>(`/tutor/dayOffSessionConflicts`, payload);

export default (
  daysOff: {
    startTs: Date;
    endTs: Date;
  }[],
) => {
  return useQuery(
    ["getDayOffSessionConflicts"],
    () =>
      getDayOffSessionConflicts({
        intervals: daysOff.map(({ startTs, endTs }) => ({
          startTs: startTs.toISOString(),
          endTs: endTs.toISOString(),
        })),
      }),
    {
      enabled: daysOff.length > 0,
      refetchOnWindowFocus: false,
    },
  );
};
