import { useMemo } from "react";
import { usePublicTeacherProfileQuery } from "old/src/hooks/useParent";
import { useParams, useNavigate } from "react-router-dom";
import { Avatar, Image } from "UILibrary";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import NewSingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import { actionTypes } from "old/src/store/actionTypes";
import { useGlobalStore } from "old/src/store";
import { logTutorEvent } from "src/utils/log";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";

const DirectBookingTutorIntroduction = () => {
  const { tutorId } = useParams();
  const { teacher: tutor, isError: tutorProfileError } = usePublicTeacherProfileQuery(tutorId);
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();

  useOnFirstRender(() => {
    if (tutorId) {
      logTutorEvent({
        eventName: "parent.marketplace.directbooking.viewed_tutor_booking_page",
        metadata: { tutor_id: tutorId },
      });
    }
  });

  const subjects = useMemo(() => {
    if (!tutor) return "";
    const _subjects: string[] = [];
    const tutoringInformation = tutor.tutoring_information;
    if (tutoringInformation.math.length > 0) {
      _subjects.push("Math");
    }
    if (tutoringInformation.reading.length > 0) {
      _subjects.push("Reading");
    }
    if (tutoringInformation.writing.length > 0) {
      _subjects.push("Writing");
    }
    return _subjects.join(" | ");
  }, [tutor]);

  const onNextStep = () => {
    logTutorEvent({
      eventName: "parent.marketplace.directbooking.completed_tutor_booking_page",
      metadata: { tutor_id: tutorId },
    });
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state?.booking,
        teacher_uid: tutorId,
      },
    });
    push("/direct-booking-subscription/child-information");
  };

  if (tutorProfileError) {
    push("/");
    return null;
  }

  if (!tutor) return null;

  return (
    <NewSingleClassForm
      disabled={!tutor}
      onSubmit={onNextStep}
      contentMaxWidth={480}
      showSubmitButton={true}
      buttonText="Book weekly classes"
    >
      <div sx={sxGeneralWrapper}>
        <div sx={sxTutorWrapper}>
          <div sx={sxCenter}>
            {tutor.photo_url ? (
              <Image
                styles={{
                  image: {
                    background: "dt_backgroung_secondary",
                    objectFit: "cover",
                  },
                }}
                width={90}
                height={90}
                radius={160}
                mb={12}
                src={tutor.photo_url}
                alt="ClassDojo teacher profile"
              />
            ) : (
              <Avatar size={90} radius={160} />
            )}
          </div>
          <div sx={sxColumn}>
            <div sx={sxSubtitle}>
              {tutor.first_name} {tutor.last_name}
            </div>
            <div sx={sxCenterText}>Certified teacher</div>
          </div>
          <div sx={sxCenterText}>{subjects}</div>
          <div sx={sxCenterText}>
            {tutor.teaching_experience_years === 1 ? "1 year" : `${tutor.teaching_experience_years} years`} teaching
          </div>
        </div>
        <div sx={sxTutorBio}>
          <div sx={sxTutorBioTitle}>about me</div>
          <div sx={sxTutorBioContent}>{tutor.teaching_experience_description}</div>
        </div>
      </div>
    </NewSingleClassForm>
  );
};

export default DirectBookingTutorIntroduction;

const sxTutorWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_xxs",
  width: "100%",
  paddingTop: "dt_l",
  paddingBottom: "dt_l",
  color: "dt_content_primary",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
};

const sxSubtitle: ThemeUIStyleObject = {
  color: "dt_content_primary",
  textAlign: "center",
  fontSize: "28px",
  fontWeight: 800,
  lineHeight: "34px",
  letterSpacing: "-0.1px",
};
const sxCenterText: ThemeUIStyleObject = {
  textAlign: "center",
};

const sxColumn: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const sxCenter: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
};

const sxTutorBio: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  gap: "dt_s",
  fontSize: "15px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0.2px",
  color: "dt_content_primary",
  border: "dt_card",
  padding: "dt_m",
  borderRadius: "dt_radius_s",
};

const sxTutorBioTitle: ThemeUIStyleObject = {
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
  color: "dt_content_accent",
};

const sxGeneralWrapper: ThemeUIStyleObject = {
  marginBottom: "dt_xl",
};

const sxTutorBioContent: ThemeUIStyleObject = {
  whiteSpace: "pre-line",
  wordWrap: "break-word",
  overflowWrap: "break-word",
};
