import { Table } from "@mantine/core";
import { Link } from "react-router-dom";
import useStyles from "./styles/ThirdPartyServiceProviders.styles";
import { Box, Text } from "UILibrary";

const Privacy = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.forTeachers}>
      <Box className={classes.wrapper}>
        <Box className={classes.hero} sx={{ marginBottom: "dt_l" }}>
          <Box>
            <Text component="h1">Third Party Service Providers</Text>
          </Box>
        </Box>

        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            It's important to us that we keep your information safe and secure. In order to help Dojo Tutor (“Dojo
            Tutor”) provide, maintain, protect and improve our services (the “Dojo Tutor Services”), Dojo Tutor shares
            information with a few other partners, vendors and trusted organizations (“Service Providers”) to process it
            on our behalf in accordance with our instructions, Privacy Policy and any other appropriate confidentiality,
            security or other requirements. These Service Providers will only have access to the information they need
            to provide the Dojo Tutor Services.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            You can find information on these Service Providers we work with below, including what data we share with
            them, or they provide to us, the service they provide for Dojo Tutor and links to their respective privacy
            policies or other documents that govern how they handle the information they process on our behalf.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Like any other organization, we also use “IT” business services or internal tools such as Gmail, Google
            Drive, and Slack to operate our organization (“Internal Tools”). These Internal Tool services may
            incidentally contain personal information (e.g., email address or contact handle) and we apply the Service
            Provider restrictions described above and have included these in a separate chart below.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            There are a few Service Providers that we also utilize in providing (or in connection with) the Dojo Tutor
            corporate website (“Dojo Tutor Website), but which are not utilized in logged-in areas in the Dojo Tutor
            Services, we have listed those Service Providers below the chart for the Dojo Tutor Services.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            This list may change over time, and we’ll work hard to keep it up-to-date. If you have any questions, please
            get in touch <a href="mailto:tutoringprivacy@classdojo.com">here</a>.
          </Text>
        </Box>
        <Box mb={24}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
              } as any
            }
          >
            <Link to="/privacy">Dojo Tutoring Services Privacy Policy</Link>.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={24}>
          Technical Service Providers
        </Text>
        <Table>
          <thead>
            <tr>
              <th>Name of Service Provider with Link to Privacy Policy or relevant privacy documentation</th>
              <th>How Dojo Tutoring uses the Service Provider</th>
              <th>
                What user information is shared or collected by this Service Provider and/or passed back to us from the
                Service Provider?
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="https://aws.amazon.com/agreement/">Amazon Web Services (AWS)</a>
              </td>
              <td>
                Amazon Web Services (AWS) provides Dojo Tutor with servers, databases, analytics, network infrastructure
                and email services.
              </td>
              <td>
                All user personal information and uploaded content, device ID, request data, and all logs are stored on
                AWS. Passwords are stored as one-way hashes. All personal information is{" "}
                <a href="https://aws.amazon.com/security/?nc=sn&loc=0">encrypted</a> at rest.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.assemblyai.com/security">AssemblyAI</a> and{" "}
                <a href="https://trust.anthropic.com/">Anthropic</a> integration*
                <br />
                <br />
                *Data use is governed by custom contractual
              </td>
              <td>
                We use AssemblyAI to create text-only transcriptions of tutoring sessions recorded over Zoom.
                <br />
                <br />
                Separately, AssemblyAI integrates with a large language model (“LLM”) subprocessor,{" "}
                <a href="https://trust.anthropic.com/">Anthropic</a>, and at Dojo Tutor’s instructions and directions
                generates certain artificial intelligence (“AI”) insights based on these text only transcripts as set
                forth <Link to="/ai-transparency-note">here</Link>. (“AI Output”). Dojo Tutor uses the AI Output
                generated from the transcripts to provide and improve our service, perform internal analysis on our
                product offerings, and keep our platform safe as further set forth{" "}
                <Link to="/ai-transparency-note">here</Link>.
              </td>
              <td>
                Dojo Tutor shares with AssemblyAI audio only recordings from tutoring sessions that occur on Zoom (no
                videos are sent). These audio only recordings are retained only transiently and will be deleted
                immediately once Assembly has produced the transcripts.
                <br />
                <br />
                AssemblyAI in turn (at Dojo Tutor’s specific direction and instruction) shares these text only
                transcripts with the LLM (Anthropic) to perform certain AI related tasks as set forth{" "}
                <Link to="/ai-transparency-note">here</Link>. No audio or video of any tutoring session is sent to
                Anthropic. In addition, a pseudonymized transaction ID created by Assembly AI is sent by Assembly AI to
                Anthropic in order to link the text transcript to the AI Output. No direct identifiers of any user are
                sent to the LLM (Anthropic) - except to the extent the identifiers are included within the text of the
                tutor transcript. This information is retained by Assembly and Anthropic as set forth{" "}
                <Link to="/ai-transparency-note">here</Link>.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://blueshift.com/privacy-policy/">Blueshift</a>
              </td>
              <td>
                Sending automated and manual emails to tutors and parents like class reminders or requests for reviews.
                In addition, we utilize to send emails on behalf of users (e.g. parents and tutors).
              </td>
              <td>
                Email addresses and other associated user-level data (e.g., Name) for tutor and parent users (not
                learners) as well as the content of the email.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://calendly.com/dpa">Calendly</a>
              </td>
              <td>
                Through Calendly, applicants and tutors can schedule group or 1:1 meetings for their training, meet-ups
                and feedback sessions.
              </td>
              <td>
                To schedule a meeting through Calendly, the applicant or tutor needs to share with Calendly their name
                and email address. Also, as an optional field, they can share their phone number to receive SMS
                reminders before the meeting time.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://checkr.com/legal/privacy-policy">Checkr</a>
              </td>
              <td>
                We use Checkr to perform background checks to all of the tutors we onboard to our platform (with the
                consent of the tutor). Background checks are necessary to ensure that our learners will be safe around
                any tutor in Dojo Tutor.
              </td>
              <td>
                To perform the background check, applicants share with Checkr their full name, phone number, e-mail
                address, date of birth, social security number, zip code and electronic signature.
                <br />
                <br />
                Through the Checkr portal, we have access to this personal information (except social security numbers,
                we can only access the last 4 numbers) and the background check report information. This includes Sex
                Offender Search (sources include but are not limited to National Sex Offender Public Website and
                national and state sources), Global Watchlist Search (sources include but are not limited to Office Of
                Inspector General, European Union Consolidated List, Drug Enforcement Agency Fugitives, Government
                Sanction Databases, US Terrorist List), National Search (sources include but are not limited to Warrant
                sources, Department of Corrections sources, Arrest sources, Court sources), and County Searches (sources
                include but are not limited to County Courthouse records).
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://hireflix.com/en/privacy-policy-users">Hireflix</a>
              </td>
              <td>
                We use Hireflix to share the video questions to tutors of our asynchronous interview step in the
                application process with potential tutor applicants. Also, we use Hireflix to collect video responses
                from tutor applicants in that same step.
              </td>
              <td>
                In the interview step of our tutor application process, we share with Hireflix the first name, last
                name, email and phone number of each applicant.
                <br />
                <br />
                Hirelix additionally captures videos of the tutor applicant. Applicants aren’t requested to submit any
                other information, we only receive their video responses to the interview questions. Those videos are
                stored by Hireflix and not stored on Dojo Tutor.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.openphone.com/privacy">OpenPhone</a>
              </td>
              <td>Making and receiving calls from parents and tutors that may need assistance.</td>
              <td>
                Dojo Tutor shares with OpenPhone the phone number and names of users that receive or make calls. Dojo
                Tutor receives information on the duration of the call back from OpenPhone.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://remote.com/privacy-policy">Remote</a>
              </td>
              <td>
                We use Remote to facilitate tutors signing consulting agreements with Dojo Tutor as independent
                contractors, paying tutors twice a month, and supporting tutors in creating their tax filings.
              </td>
              <td>
                Dojo Tutor shares with Remote the tutors’ personal email and country of residence so Remote can send
                tutors an invitation to create their accounts. Also, for payment purposes, we share with Remote the
                total amount that we’ll pay them every two weeks based on the classes taught and subscribers converted
                each payable period.
                <br />
                <br />
                To create their personal accounts in Remote, tutors share with Remote their legal name, home address,
                date of birth and phone number. The legal name, home address, and personal email are used in the
                creation of the consulting agreement. Also, tutors give additional information for payment purposes:
                account ownership type (personal/business), account holder name, bank name, account number, ACH routing
                number, account type (checking/savings), and the BIC/SWIFT. Finally, tutors share personal information
                of an emergency contact of their preference, including their full name, email and phone number.
                <br />
                <br />
                Dojo Tutor can access all the tutors’ information stored in Remote except from their banking info. That
                includes their legal name, home address, date of birth, phone number, and emergency contact’s full name,
                email address and phone number.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://sendgrid.com/policies/security/">SendGrid</a>
              </td>
              <td>
                Sending automated and manual emails to tutors and parents like tutoring reminders or requests for
                reviews. In addition, we utilize to send emails on behalf of users (e.g. parents and tutors).
              </td>
              <td>
                Email addresses and other associated user-level data (e.g., Name) for tutor and parent users (not
                learners) as well as the content of the email.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://stripe.com/privacy">Stripe</a>
              </td>
              <td>Payment transaction process for parent’s subscriptions and tutor’s payments.</td>
              <td>
                Stripe shares a Transaction ID, email, phone number, geolocalization, name and last 4 digits of the
                subscriber’s credit card with Dojo Tutor to acknowledge an active subscription as well as the payment
                status (paid or canceled). We use that information to process the transaction, for financial reporting,
                and for customer support (e.g. searching for a subscription). We do not receive complete credit card or
                other financial information from Stripe.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.twilio.com/legal/privacy">Twilio</a>
              </td>
              <td>
                Send automated and manual SMS and Text-based reminders for Dojo Tutor sessions to parents and tutors. In
                addition, if a parent has opted in, marketing SMS and texts may be sent (e.g. such as reminding a parent
                to purchase more tutoring sessions).
              </td>
              <td>
                Dojo Tutor shares parents’ and tutors’ names, phone numbers, and the content of SMS messages with
                Twilio.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://admin.typeform.com/to/dwk6gt?typeform-source=www.google.com">Typeform</a>
              </td>
              <td>
                We use Typeform to receive and process all tutor applications, and to collect parent information for
                specific product experiments. We also use Typeform occasionally to send out surveys to parents and
                tutors to gather feedback that can be used to improve our product. We do not send out surveys directly
                to{" "}
                <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                  learners
                </a>
                .
              </td>
              <td>
                When we use Typeform for Tutor applications, Typerform may collect on our behalf: first name and last
                name, email address, phone number, number of hours a week in which they are available to teach, previous
                experience teaching, previous experience using technological resources, and any other relevant
                information that can inform us on their qualification to teach with us.
                <br />
                <br />
                For surveys, depending on the nature of the survey, we (through Typeform) might collect emails, phone
                numbers, names and other information that the parent or tutor is willing to provide. We do not collect
                this information from{" "}
                <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                  learners
                </a>
                .
                <br />
                <br />
                When we use Typeform for product experiments for parents, Typeform may collect on our behalf: the
                parent’s first name and last name, email address, phone number, first name of the child they want to
                enroll in tutoring, date and time of the tutoring session they want to book, and credit card details
                using a Stripe integration as explained above.
                <br />
                <br />
                When linking a user to a Typeform url from the website, Dojo Tutor may include a unique identifier
                associated with the user in the url query parameters so as to match the Typeform campaigns with the
                user.
                <br />
                <br />
                Typeform itself also collects and stores all the responses to Typeform surveys which are then passed to
                our own servers.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.google.com/intl/en/policies/privacy/">Youtube</a> embedded player
              </td>
              <td>
                For embedding video content. Videos are included using “
                <a href="https://support.google.com/youtube/answer/171780?expand=PrivacyEnhancedMode#privacy&zippy=%2Cturn-on-privacy-enhanced-mode">
                  Privacy-Enhanced Mode.
                </a>
                ” (not used in student logged in areas)
              </td>
              <td>
                Dojo Tutor receives video analytics (e.g., number of views and generalized demographic information).
                Dojo Tutor uses YouTube’s{" "}
                <a href="https://support.google.com/youtube/answer/171780?expand=PrivacyEnhancedMode#privacy&zippy=%2Cturn-on-privacy-enhanced-mode">
                  Privacy Enhanced Mode
                </a>{" "}
                to limit tracking of viewing behavior and Dojo Tutor is marked for{" "}
                <a href="https://support.google.com/policies/answer/9664901">Child-Directed treatment</a>. If the viewer
                clicks or taps out of the embed video, and is redirected to another website or app, that website or app
                may track the viewer’s behavior as per that website’s or app’s policies and terms.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://explore.zoom.us/en/isv/">Zoom ISV Program</a>*
                <br />
                <br />
                *Parties entered into custom contractual agreements to allow for users under 13 and under which Zoom has
                also agreed to apply its{" "}
                <a href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0060951">
                  Education Account Settings
                </a>{" "}
                to Dojo Tutor’s use of Zoom. This includes treating Dojo Tutor’s use as a Zoom Education Account to
                apply additional privacy, security and safety features and settings offered to schools. See{" "}
                <a href="https://explore.zoom.us/en/schools-privacy-statement/">
                  Zoom’s Children’s Education Privacy Statement
                </a>{" "}
                here.
              </td>
              <td>Video conferencing software used for live tutoring sessions.</td>
              <td>
                Dojo Tutor shares user registration details needed for the Zoom meetings (e.g. first and last name of
                parent and learner, email alias of the parent, preferred pronouns, date and time of the tutoring
                session). From Zoom's API, Dojo Tutor receives metadata on the session (e.g. attendance data and how
                long participants stayed in the Zoom, start URL, registrant URL and, meeting ID).
                <br />
                <br />
                These recordings are automatically deleted after 14 days by Zoom (unless we are required by law to
                retain it, need it to ensure the security of our community or our Service, or to enforce our Terms).
                Within the 14-day period in which Zoom retains tutoring session recordings, Dojo Tutor may retain the
                recordings for up to an additional 30 days to provide and maintain the service, conduct internal
                analysis, and keep our platform safe and secure.
                <br />
                <br />
                Zoom may also collect certain device and other data from the learner and tutors as set forth in their{" "}
                <a href="https://explore.zoom.us/en/schools-privacy-statement/">
                  Children’s Education Privacy Statement
                </a>
                . Dojo Tutor may also indirectly share the subject of the tutoring class and the grade of the learner
                with Zoom during the creation of a Dojo Tutor session.
              </td>
            </tr>
          </tbody>
        </Table>
        <Text className={classes.subHeader} mb={24} mt={48}>
          Internal Tools that may have incidental disclosure of personal information
        </Text>
        <Table>
          <thead>
            <tr>
              <th>Name of Service Provider with Link to Privacy Policy or relevant privacy documentation</th>
              <th>How Dojo Tutor uses the Service Provider</th>
              <th>
                What user information is shared or collected by this Service Provider and/or passed back to us from the
                Service Provider?
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="https://cloud.google.com/privacy">Google Workspace</a>
              </td>
              <td>Utilized for Dojo Tutor internal emails, docs, slides, spreadsheets, etc.</td>
              <td>
                Dojo Tutor stores its own emails and files using Google services. Dojo Tutor may share personal
                information with Google in the course of utilizing Google services, for example, if a user emails
                employees for support.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.maestroqa.com/security#:~:text=We%20take%20data%20security%20and,that%20their%20information%20is%20safe.">
                  MaestroQA
                </a>
              </td>
              <td>
                Automatically pulls tickets from Zendesk to perform quality audits of the customer support we are
                providing to parents and tutors.
              </td>
              <td>
                To grade customer tickets and generate historical reports, Maestro stores all information within the
                Zendesk ticket including the customer’s email address, phone number, first and last name, and the
                learner’s enrollment information. Maestro is integrated into Zendesk and has access to similar customer
                information as Zendesk. All graded tickets information are purged after 1 year.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://slack.com/trust/privacy/privacy-policy">Slack</a>
              </td>
              <td>Internal team communication tool.</td>
              <td>
                Dojo Tutor may incidentally share personal information with Slack, in the course of communications
                between team members, including engineers and support teams (e.g., debugging an issue for a user).
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.zendesk.com/company/privacy">Zendesk</a>
              </td>
              <td>Organizing and responding to support requests along with Helpdesk integration.</td>
              <td>
                In the course of responding to a support request, Dojo Tutor shares with Zendesk the email address of
                individuals submitting requests, and the content of the requests itself (e.g., bug reports, any other
                help center requests, and data pull requests from tutors and parents). Users may also provide
                information directly to Zendesk in the course of responding to a support request and communicating with
                a Success Agent.
              </td>
            </tr>
          </tbody>
        </Table>
        <Text className={classes.subHeader} mb={24} mt={48}>
          Service Providers used in providing (on or in connection with) the Dojo Tutor Website
        </Text>
        <Table>
          <thead>
            <tr>
              <th>Name of Service Provider with Link to Privacy Policy or relevant privacy documentation</th>
              <th>How ClassDojo Tutor uses the Service Provider</th>
              <th>
                What user information is shared or collected by this Service Provider and/or passed back to us from the
                Service Provider?
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="https://blueshift.com/privacy-policy/">Blueshift</a>
              </td>
              <td>
                This service helps us send relevant communications (email) to Dojo Tutor Website visitors and analyze
                key metrics about those communications.
              </td>
              <td>
                Email addresses and other associated user-level data (e.g., Name) as well as product events at a user
                level for Dojo Tutor Website users.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.facebook.com/ads/about">Facebook Ads</a> (Pixel, Custom Audiences)
              </td>
              <td>
                Analytics and retargeting for the Dojo Tutor Website (and in areas of the Dojo Tutor Service for parents
                when completing payment (never logged in areas for learners or children)) when users come from a
                Facebook link, and when they load the Dojo Tutor Website page as a logged in Dojo Tutor parent or
                teacher user (to be able to omit these users from any Facebook campaigns).
              </td>
              <td>
                Anonymous site usage data and ad conversion events.
                <br />
                <br />
                For Custom Audiences we send{" "}
                <a href="https://www.facebook.com/business/help/112061095610075?id=2469097953376494">hashed</a> emails
                of parents and teachers.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://policies.google.com/privacy">Google Ads</a>
              </td>
              <td>
                Analytics, conversion tracking, and optimization for the Dojo Tutor Website (and in areas of the Dojo
                Tutor Service for parents when completing payment (never logged in areas for learners or children)) when
                users come from a Google link, and when they load the Dojo Tutor Website page as a logged in Dojo Tutor
                parent or teacher user (to be able to omit these users from any Google ads campaigns).
              </td>
              <td>Anonymous site usage data and ads targeting, conversion tracking, and optimization.</td>
            </tr>
          </tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Privacy;
