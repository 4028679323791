import { UnstyledButton } from "@classdojo/web";
import { BodyText, Button, theme } from "@classdojo/web/nessie";
import { IconCalendarDelete, IconPlus, IconTrash } from "@web-monorepo/dds-icons";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import capitalize from "lodash/capitalize";
import {
  ActionIcon,
  Check,
  BaseCheckbox as Checkbox,
  Loader,
  Modal,
  RawSelect,
  SimpleGrid,
  Text,
  TimezoneText,
} from "UILibrary";

import cloneDeep from "lodash/cloneDeep";
import {
  useAvailabilityQuery,
  useDaysOffQuery,
  useTeacherMutation,
  useTutorSubscriptionConflictsQuery,
} from "old/src/hooks/useTeacher";
import useTimeSelectIntervals from "src/hooks/useTimeSelectIntervals";
import ConflictConfirmationModal from "old/src/pageComponents/Teachers/Availability/ConflictConfirmationModal";
import DayConflictsModal from "old/src/pageComponents/Teachers/Availability/DayConflictsModal";
import { useEffect, useMemo, useState } from "react";
import queryClient from "src/queryClient";
import { DAYS } from "src/settings/availability/constants";
import { DAYS_NAMES } from "src/settings/subscription/constants";
import { DAY_KEYS, DAY_VALUE } from "src/types/availability";
import { logTutorEvent } from "src/utils/log";
import { useFeatureSwitchAndStartExperiment } from "src/hooks/useFeatureSwitches";
import {
  addSeconds,
  isBefore,
  addDays,
  parse,
  parseWithPattern,
  toDate,
  formatDayOffRange,
  getDefaultTimezone,
  isValid,
} from "src/utils/dates";
import OutOfOfficeButton from "./OutOfOfficeButton";

type DayModal = {
  day: DAY_KEYS;
  conflicts: Array<{
    name: string;
    conflicts: string[];
  }>;
};

function padded(input: string | number | undefined, length: number): string | undefined {
  return input?.toString()?.padStart(length, "0");
}

function deviceToUserDefaultTime(date?: Date | null) {
  if (!date || !isValid(date)) return date;

  return toDate(`${date.getFullYear()}-${padded(date.getMonth() + 1, 2)}-${padded(date.getDate(), 2)}T00:00:00`, {
    timeZone: getDefaultTimezone(),
  });
}

// eslint-disable-next-line complexity
const Availability = () => {
  const [dateModal, setDateModal] = useState(false);
  const [datesOff, setDatesOff] = useState<[Date | null, Date | null]>([null, null]);
  const [selectedDates, setSelectedDates] = useState<[string, string, number][]>([]);
  const [days, setDays] = useState<Record<DAY_KEYS, DAY_VALUE> | null>(null);
  const times = useTimeSelectIntervals();
  const { updateAvailability, updateDaysOff, deleteDaysOff } = useTeacherMutation();
  const { availability, isLoading } = useAvailabilityQuery();
  const { daysOff, refetch } = useDaysOffQuery();
  const [dayModal, setDayModal] = useState<DayModal | null>(null);
  const { data: newOutofOfficeUI } = useFeatureSwitchAndStartExperiment("web_marketplace_ooo_oct2024");
  const isNewOutOfOfficeEnabled = newOutofOfficeUI === "test";

  const updatedAvailability = useMemo(() => {
    if (days == null) return;

    const updated = cloneDeep(days);

    Object.keys(updated).forEach((day: DAY_KEYS) => {
      delete updated[day].maxClasses;
      if (updated[day].ranges.length === 1 && updated[day].ranges[0].length === 0) {
        updated[day].ranges = [];
        updated[day].isAvailable = false;
      } else {
        updated[day].ranges = updated[day].ranges.filter((range) => range.filter(Boolean).length === 2);
      }
    });

    return updated;
  }, [days]);

  const { conflicts, isLoading: isSubscriptionConflictsLoading } = useTutorSubscriptionConflictsQuery(
    updatedAvailability && {
      availability: updatedAvailability,
      timezone: getDefaultTimezone(),
    },
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const transformed = daysOff?.map(
      (dates) =>
        [
          dates.ts,
          addSeconds(parse(dates?.ts), dates.duration).toISOString(),
          dates.id,
        ] as (typeof selectedDates)[number],
    );

    if (transformed) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setSelectedDates(transformed);
    }
  }, [daysOff]);

  useEffect(() => {
    if (availability?.availability) {
      const data = (Object.keys(availability?.availability) as DAY_KEYS[])
        .sort((a, b) => DAYS_NAMES.indexOf(a) - DAYS_NAMES.indexOf(b))
        .reduce(
          (obj, key) => {
            const day = availability.availability[key];
            if (day == null) return obj;

            // there could be a case when day is not available and it doesn't have ranges
            // in this case we should use default ranges
            const ranges =
              day.ranges.length === 0 && !day.isAvailable
                ? DAYS[key].ranges
                : day.ranges.map((range) => {
                    if (range.length) {
                      const startHour = range[0];
                      const endHour = range[1];
                      return [startHour, endHour];
                    } else {
                      return [];
                    }
                  });

            obj[key] = { ...day, ranges };

            return obj;
          },
          {} as typeof DAYS,
        );

      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setDays(data);
    } else {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setDays(DAYS);
    }
  }, [availability, availability?.availability, availability?.timezone]);

  const onDeleteDayOff = async (id: number) => {
    await deleteDaysOff.mutateAsync({ id }, {
      onSuccess: () => {
        refetch();
        showNotification({
          title: "Day off removed",
          message: (
            <Text>
              We just updated your days off schedule.
              <br />
              Make sure to let your students know!
            </Text>
          ),
          color: "teal",
          icon: <Check />,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
    } as any);
  };

  const onUpdateAvailability = async () => {
    if (updatedAvailability == null) return;
    for (const day in updatedAvailability) {
      for (const range of updatedAvailability[day as DAY_KEYS].ranges) {
        if (
          range.length &&
          isBefore(parseWithPattern(range[1], ["h:mm a"]), parseWithPattern(range[0], ["h:mm a"]), { inclusive: true })
        ) {
          showNotification({
            title: "Invalid time range selected",
            message: "Please verify all availability end times are after start times",
          });
          return;
        }
      }
    }

    const body = {
      availability: updatedAvailability,
      timezone: getDefaultTimezone(),
    };

    await updateAvailability.mutateAsync(body, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["availability"] });
        showNotification({
          title: "Availability updated",
          message: (
            <Text>
              We just updated your availability time.
              <br />
              Make sure to let your students know!
            </Text>
          ),
          color: "teal",
          icon: <Check />,
        });
        setShowConfirmationModal(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
    } as any);
  };

  const updateOrShowConfirmationModal = () => {
    if (conflicts == null || isSubscriptionConflictsLoading) return;

    if (Object.keys(conflicts).length > 0) setShowConfirmationModal(true);
    else onUpdateAvailability();
  };

  const renderSelectedDates = () => {
    return selectedDates?.map((date, index) => {
      return (
        <div
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: RAW_cssValue(RAW_cssValue("2px solid #EAECF5")),
            paddingTop: "dt_m",
            paddingBottom: "dt_m",
          }}
        >
          <Text
            sx={{
              color: "dt_content_primary",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "28px",
            }}
          >
            {formatDayOffRange(date[0], date[1])}
          </Text>
          <ActionIcon onClick={() => onDeleteDayOff(date[2])}>
            <IconTrash size="m" sx={{ color: "dt_content_primary" }} />
          </ActionIcon>
        </div>
      );
    });
  };

  const onRemoveTimeList = (index: number, day: DAY_KEYS) => {
    if (days) {
      const newRanges = [...days[day].ranges];

      newRanges.splice(index, 1);

      setDays({
        ...days,
        [day]: {
          ...days[day],
          ranges: newRanges,
        },
      });
    }
  };

  const onAddTimeList = (day: DAY_KEYS) => {
    if (days) {
      const newRanges = [...days[day].ranges];
      newRanges.push([]);

      setDays({
        ...days,
        [day]: {
          ...days[day],
          ranges: newRanges,
        },
      });
    }
  };

  const getEndTimeOptions = (index: number, day: DAY_KEYS) => {
    if (days) {
      const newRanges = [...days[day].ranges];

      const startTime = newRanges[index][0];

      if (startTime) {
        const startTimeIndex = times.findIndex((value: string) => startTime === value);
        return [...times].slice(startTimeIndex + 1);
      }
    }

    return times;
  };

  const renderTimeList = (ranges: string[][], day: DAY_KEYS) => {
    if (days) {
      return (
        <div sx={{ display: "flex", flexDirection: "column", gap: "dt_m" }}>
          {ranges.map((range: string[], index: number) => (
            <div
              key={index}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "dt_m",
                ":last-child": {
                  marginBottom: 0,
                },
              }}
            >
              <div
                sx={{
                  display: "grid",
                  gridTemplateColumns: "102px auto 102px",
                  alignItems: "center",
                }}
              >
                <RawSelect
                  onChange={(value: string) => {
                    const newRanges = [...days[day].ranges];
                    if (
                      isBefore(parseWithPattern(newRanges[index][1], ["h:mm a"]), parseWithPattern(value, ["h:mm a"]), {
                        inclusive: true,
                      })
                    ) {
                      showNotification({
                        title: "Invalid time range selected",
                        message: "Please verify availability end time is after start time",
                      });
                      newRanges[index][1] = value;
                    }
                    newRanges[index] = [value, newRanges[index][1]];

                    setDays({
                      ...days,
                      [day]: { ...days[day], ranges: newRanges },
                    });
                  }}
                  value={range[0]}
                  data={times}
                  searchable
                />
                <Text mx={8}>-</Text>
                <RawSelect
                  onChange={(value: string) => {
                    const newRanges = [...days[day].ranges];

                    newRanges[index] = [newRanges[index][0], value];

                    setDays({
                      ...days,
                      [day]: { ...days[day], ranges: newRanges },
                    });
                  }}
                  value={range[1]}
                  data={getEndTimeOptions(index, day)}
                  searchable
                />
              </div>
              {ranges.length > 1 && (
                <ActionIcon
                  onClick={() =>
                    days[day].ranges.length <= 1
                      ? setDays({
                          ...days,
                          [day]: {
                            ...days[day],
                            ranges: [],
                            isAvailable: false,
                          },
                        })
                      : onRemoveTimeList(index, day)
                  }
                  size={56}
                  sx={{ backgroundColor: "dt_background_secondary", borderRadius: "dt_radius_s" }}
                >
                  <IconTrash size="m" sx={{ color: "dt_content_primary" }} />
                </ActionIcon>
              )}

              {!isNewOutOfOfficeEnabled && index === ranges.length - 1 && (
                <ActionIcon
                  onClick={() => onAddTimeList(day)}
                  size={56}
                  sx={{
                    backgroundColor: "dt_background_secondary",
                    borderRadius: "dt_radius_s",
                    [`@media (max-width: ${theme.breakpoints.m})`]: {
                      display: "none",
                    },
                  }}
                >
                  <IconPlus size="s" />
                </ActionIcon>
              )}

              {isNewOutOfOfficeEnabled && index === ranges.length - 1 && (
                <ActionIcon
                  onClick={() => onAddTimeList(day)}
                  size={56}
                  sx={{
                    borderRadius: "dt_radius_s",
                    color: "dt_content_accent",
                    fontWeight: 700,
                    minWidth: "110px",
                    [`@media (max-width: ${theme.breakpoints.m})`]: {
                      display: "none",
                    },
                  }}
                >
                  + Time Range
                </ActionIcon>
              )}
            </div>
          ))}
          <ActionIcon
            onClick={() => onAddTimeList(day)}
            size={56}
            sx={{
              backgroundColor: "dt_background_secondary",
              borderRadius: "dt_radius_s",
              display: "none",
              [`@media (max-width: ${theme.breakpoints.m})`]: {
                display: "inherit",
              },
            }}
          >
            <IconPlus size="s" />
          </ActionIcon>
        </div>
      );
    }
  };

  const onAddDayOff = async () => {
    const dateStart = deviceToUserDefaultTime(datesOff[0])?.toISOString();
    const dateEnd = deviceToUserDefaultTime(datesOff[1])?.toISOString();
    const transformed = [
      {
        start_ts: dateStart ?? "",
        end_ts: dateEnd ?? null,
      },
    ];

    await updateDaysOff.mutateAsync(transformed, {
      onSuccess: () => {
        refetch();
        setDateModal(false);
        setDatesOff([null, null]);
        showNotification({
          title: "Days off updated",
          message: (
            <Text>
              We just updated your days off.
              <br />
              Make sure to let your students know!
            </Text>
          ),
          color: "teal",
          icon: <Check />,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
    } as any);
  };

  const renderDaysList = () => {
    if (days) {
      return (Object.keys(days) as DAY_KEYS[])?.map((day: DAY_KEYS) => (
        <div
          key={day}
          sx={{
            paddingTop: "dt_m",
            paddingBottom: "dt_m",
            paddingLeft: isNewOutOfOfficeEnabled ? "dt_m" : "0",
            borderRadius: isNewOutOfOfficeEnabled ? "dt_radius_s" : "0",
            display: "flex",
            width: "100%",
            marginTop: isNewOutOfOfficeEnabled ? "dt_s" : "0",
            marginBottom: isNewOutOfOfficeEnabled ? "dt_s" : "0",
            borderBottom:
              isNewOutOfOfficeEnabled || day === "saturday" ? "none" : RAW_cssValue(RAW_cssValue("2px solid #EAECF5")),
            border: isNewOutOfOfficeEnabled ? "dt_card" : "none",
            justifyContent: "space-between",
            borderColor: "#e5e6ef",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              [`@media (max-width: ${theme.breakpoints.m})`]: {
                alignItems: "flex-start",
                flexDirection: "column",
              },
            }}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                width: 104,
                height: 64,
                alignSelf: "flex-start",
                marginRight: isNewOutOfOfficeEnabled ? 70 : "dt_xl",
              }}
            >
              <Checkbox
                sx={{
                  marginRight: "dt_l",
                  [`@media (max-width: ${theme.breakpoints.m})`]: {
                    marginRight: "dt_s",
                  },
                }}
                size="lg"
                checked={days[day].isAvailable}
                onChange={(event) =>
                  setDays({
                    ...days,
                    [day]: {
                      ...days[day],
                      isAvailable: event.currentTarget.checked,
                    },
                  })
                }
              />
              <Text
                sx={{
                  color: "dt_content_primary",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 700,
                }}
              >
                {!isNewOutOfOfficeEnabled && day?.slice(0, 3).toUpperCase()}
                {isNewOutOfOfficeEnabled && capitalize(day)}
              </Text>
            </div>

            <div sx={sxTimeListWrapper}>
              {days[day].isAvailable ? (
                <div
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {renderTimeList(days[day].ranges, day)}
                </div>
              ) : (
                <Text
                  sx={{
                    color: "dt_content_secondary",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 600,
                  }}
                >
                  Unavailable
                </Text>
              )}
              {conflicts?.[day] && (
                <BodyText level={2}>
                  {conflicts?.[day]?.length} {conflicts?.[day]?.length === 1 ? "subscriber" : "subscribers"} affected{" "}
                  <UnstyledButton
                    data-name=""
                    type="button"
                    sx={{ color: "dt_content_accent", cursor: "pointer" }}
                    onClick={() => {
                      logTutorEvent("tutor.marketplace.edit_availability.click_on_affected_subscribers");
                      setDayModal({
                        day,
                        conflicts:
                          conflicts?.[day]?.map((conflict) => ({
                            name: conflict.studentName,
                            conflicts: conflict.conflicts,
                          })) ?? [],
                      });
                    }}
                  >
                    View
                  </UnstyledButton>
                </BodyText>
              )}
            </div>
          </div>
        </div>
      ));
    }
  };

  return (
    <div>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "dt_l",
          flexWrap: "wrap",
          gap: "dt_m",
          maxWidth: isNewOutOfOfficeEnabled ? "800px" : undefined,
          marginLeft: isNewOutOfOfficeEnabled ? "auto" : undefined,
          marginRight: isNewOutOfOfficeEnabled ? "auto" : undefined,
        }}
      >
        <Text
          sx={{
            fontSize: "28px",
            fontWeight: 700,
            lineHeight: "32px",
            color: "dt_content_primary",
          }}
        >
          Set your availability for classes
        </Text>
        <div
          sx={{
            display: "flex",
            gap: "dt_m",
            flex: 1,
            minWidth: "240px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <OutOfOfficeButton />
          <Button
            data-name=""
            onClick={() => {
              logTutorEvent("tutor.marketplace.edit_availability.click_on_save");
              updateOrShowConfirmationModal();
            }}
            disabled={updateAvailability.isLoading || isSubscriptionConflictsLoading}
          >
            Save
          </Button>
        </div>
      </div>
      <div
        sx={{
          border: isNewOutOfOfficeEnabled ? "none" : "dt_card",
          boxShadow: isNewOutOfOfficeEnabled ? "none" : RAW_cssValue("0px 2px 0px rgba(45, 64, 150, 0.06)"),
          borderRadius: "dt_radius_m",
          height: "100%",
          marginBottom: RAW_cssValue("64px"),
          margin: isNewOutOfOfficeEnabled ? "auto" : undefined,
          maxWidth: isNewOutOfOfficeEnabled ? "800px" : undefined,
        }}
      >
        {isNewOutOfOfficeEnabled && (
          <div
            sx={{
              display: "flex",
              gap: "dt_xs",
              padding: "dt_m",
              borderRadius: "dt_radius_xs",
              backgroundColor: "dt_background_accent",
              flexWrap: "wrap",
            }}
          >
            <IconCalendarDelete size="m" />
            <Text>
              <strong>Looking to take some time off?</strong>
            </Text>
            <Text>Add some out-of-office time instead.</Text>
            <Text>
              <a
                sx={{ textDecoration: "none", fontWeight: 700, color: "dt_content_accent" }}
                target="_blank"
                href="https://tutor-help.classdojo.com/hc/en-us/articles/31637318756493-For-Tutors-Out-Of-Office-Hours-FAQs"
              >
                Learn how
              </a>
            </Text>
          </div>
        )}
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              paddingTop: "dt_l",
              paddingBottom: isNewOutOfOfficeEnabled ? "0" : "dt_l",
              paddingLeft: "dt_xl",
              paddingRight: "dt_xl",
              borderBottom: isNewOutOfOfficeEnabled ? "none" : RAW_cssValue("2px solid #EAECF5"),
              width: "100%",
              justifyContent: "space-between",

              [`@media screen and (max-width: ${theme.breakpoints.m})`]: {
                flexDirection: "column",
                padding: "dt_s",
              },
            }}
          >
            <div sx={{ display: "flex", alignItems: "center" }}>
              <Text sx={{ fontSize: "18px", lineHeight: "28px" }}>Class duration:</Text>
              <Text
                sx={{
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "dt_content_secondary",
                  paddingLeft: "dt_m",
                  paddingRight: "dt_m",
                }}
              >
                25 min
              </Text>
            </div>
            <div sx={{ display: "flex", alignItems: "center" }}>
              <Text sx={{ fontSize: "18px", lineHeight: "28px" }}>Timezone:</Text>
              <TimezoneText
                sx={{
                  marginLeft: "8px",
                  color: "dt_content_secondary",
                  fontSize: "18px",
                  lineHeight: "28px",
                }}
              />
            </div>
          </div>
        </div>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            [`@media(max-width: ${theme.breakpoints.xl})`]: {
              display: "block",
            },
          }}
        >
          <div
            sx={{
              alignItems: "center",
              padding: "dt_l",
              paddingLeft: "dt_xl",
              paddingRight: "dt_xl",
              width: "100%",
              borderRight: isNewOutOfOfficeEnabled ? "none" : RAW_cssValue(RAW_cssValue("2px solid #EAECF5")),

              [`@media screen and (max-width: ${theme.breakpoints.m})`]: {
                padding: "dt_s",
              },
            }}
          >
            {!isNewOutOfOfficeEnabled && (
              <Text sx={{ fontWeight: 700, fontSize: "18px", lineHeight: "28px" }} mb={24}>
                Set your weekly hours {isLoading && <Loader size="xs" />}
              </Text>
            )}

            {!isLoading && <div>{renderDaysList()}</div>}
            <div sx={{ display: "flex", justifyContent: "center", paddingTop: "dt_l" }}>
              <Button
                data-name=""
                onClick={() => {
                  logTutorEvent("tutor.marketplace.edit_availability.click_on_save");
                  updateOrShowConfirmationModal();
                }}
                disabled={updateAvailability.isLoading || isSubscriptionConflictsLoading}
              >
                Save
              </Button>
            </div>
          </div>
          {!isNewOutOfOfficeEnabled && (
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: RAW_cssValue("28px 24px"),
                width: "524px",

                [`@media screen and (max-width: ${theme.breakpoints.m})`]: {
                  width: "auto",
                  padding: "dt_s",
                },
              }}
            >
              <Text sx={{ fontWeight: 700, fontSize: "18px", lineHeight: "28px" }} mb={8}>
                Out of office dates
              </Text>
              <Text
                sx={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: RAW_cssValue("#7174A0"),
                }}
                mb={32}
              >
                Add individual dates when you are not available.
              </Text>
              <Button
                data-name=""
                onClick={() => {
                  logTutorEvent("tutor.marketplace.edit_availability.add_dates");
                  setDatesOff([null, null]);
                  setDateModal(true);
                }}
              >
                Add dates
              </Button>

              <div>{renderSelectedDates()}</div>
            </div>
          )}
        </div>
      </div>

      <Modal
        styles={{
          content: {
            borderRadius: "56px",
            border: "2px solid #D3D7EC",
            boxShadow: "0px 6px 0px rgba(45, 64, 150, 0.06)",
          },
          body: {
            paddingTop: "32px",
            paddingBottom: "32px",
          },
        }}
        opened={dateModal}
        onClose={() => setDateModal(false)}
        centered
        withCloseButton={false}
      >
        <Text
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "30px",
            textAlign: "center",
          }}
          mb={24}
        >
          Select your out of office date(s)
        </Text>

        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "dt_l",
          }}
        >
          <DatePicker
            minDate={addDays(new Date(), 3)}
            maxDate={addDays(new Date(), 90)}
            value={datesOff}
            onChange={setDatesOff}
            type="range"
            size="lg"
          />
        </div>

        <SimpleGrid cols={2}>
          <Button
            type="button"
            kind="secondary"
            size="l"
            data-name=""
            disabled={updateDaysOff.isLoading}
            onClick={() => {
              logTutorEvent("tutor.marketplace.edit_days_off.click_on_cancel");
              setDateModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            kind="primary"
            size="l"
            data-name=""
            disabled={updateDaysOff.isLoading || !datesOff[0]}
            onClick={() => {
              logTutorEvent("tutor.marketplace.edit_days_off.click_on_save");
              onAddDayOff();
            }}
          >
            Apply
          </Button>
        </SimpleGrid>
      </Modal>
      {dayModal != null && (
        <DayConflictsModal day={dayModal.day} conflicts={dayModal.conflicts} onClose={() => setDayModal(null)} />
      )}
      {showConfirmationModal && conflicts != null && (
        <ConflictConfirmationModal
          conflicts={conflicts}
          onClose={() => setShowConfirmationModal(false)}
          onSave={onUpdateAvailability}
        />
      )}
    </div>
  );
};

export default Availability;

const sxTimeListWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_s",
};
