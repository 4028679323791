import { BodyText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import PostSurveyNegative from "./PostSurveyNegative";
import PostSurveyPositive from "./PostSurveyPositive";
import SurveyForm from "./SurveyForm";
import { usePublicTeacherProfileQuery } from "old/src/hooks/useParent";
import useQueryParams from "src/hooks/useQueryParams";
import { logTutorEvent } from "src/utils/log";

const QuerySchema = z.object({
  classId: z.string(),
  teacherId: z.string(),
  childName: z.string(),
  token: z.string(),
  isTrial: z.enum(["true", "false"]),
  liked: z.enum(["true", "false"]).optional(),
});

type QueryType = z.infer<typeof QuerySchema>;

const PostSessionSurvey = () => {
  const [liked, setLiked] = useState<boolean>();
  const query = useQueryParams() as QueryType;

  const { teacher, isInitialLoading } = usePublicTeacherProfileQuery(query.teacherId);

  useEffect(() => {
    if (!isInitialLoading && teacher !== null) {
      logTutorEvent({
        eventName: "tutor.review.page_load",
        eventValue: query.classId,
        metadata: {
          teacherId: query.teacherId,
        },
      });
    }
  }, [isInitialLoading, teacher, query]);

  const onSubmit = useCallback(
    (rating: boolean) => {
      setLiked(rating);
      logTutorEvent({
        eventName: "tutor.review.sent",
        eventValue: query.classId,
        metadata: {
          liked: rating,
          teacherId: query.teacherId,
        },
      });
    },
    [setLiked, query],
  );

  if (isInitialLoading || teacher == null) {
    return null;
  }

  QuerySchema.parse(query);

  return (
    <div sx={sxWrapper}>
      {liked === true && (
        <PostSurveyPositive
          classId={query.classId}
          subjects={teacher.tutoring_experience_subjects}
          tutorName={teacher.first_name}
          yearsExperience={teacher.teaching_experience_years}
        />
      )}
      {liked === false && <PostSurveyNegative />}
      {liked == null && (
        <SurveyForm
          childName={query.childName}
          token={query.token}
          tutorName={teacher.first_name}
          teacherId={query.teacherId}
          classId={query.classId}
          onSubmit={onSubmit}
          liked={query.liked === "true" ? true : query.liked === "false" ? false : undefined}
        />
      )}
      <BodyText level={2} kind="tertiary" sx={sxCopyright}>
        &copy; 2022 ClassDojo. All Rights Reserved.
      </BodyText>
    </div>
  );
};

export default PostSessionSurvey;

const sxWrapper: ThemeUIStyleObject = {
  height: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  gap: "dt_l",
};

const sxCopyright: ThemeUIStyleObject = {
  marginTop: "dt_xl",
};
