import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/checkout/subscription/{subscription}/next-cycle"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export type Subscription = Body;

export default ({ subscriptionId, ...body }: Body & { subscriptionId: number }) =>
  monolithApiClient.post<Response>(`/checkout/subscription/${subscriptionId}/next-cycle`, body);
