import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";
import useMutation from "src/hooks/useMutation";

type Endpoint = paths["/api/marketplace/parent/session/{sessionId}/validateAndAssignTutor"]["put"];
type Parameters = Endpoint["parameters"]["path"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

const putValidateAndAssignTutor = async ({ sessionId }: Parameters) => {
  return await monolithApiClient.put<Response>(`/parent/session/${sessionId}/validateAndAssignTutor`);
};

const useValidateAndAssignTutorMutation = () => {
  return useMutation(putValidateAndAssignTutor);
};

export default useValidateAndAssignTutorMutation;
