import { create } from "zustand";

type SlideSheetStoreType = {
  assessmentOpened: boolean;
  selectOpened: boolean;
  setAssessmentOpened: (opened: boolean) => void;
  setSelectOpened: (opened: boolean) => void;
};

export const useSlideSheetStore = create<SlideSheetStoreType>((set) => ({
  assessmentOpened: false,
  selectOpened: false,
  setAssessmentOpened: (opened) => set({ assessmentOpened: opened }),
  setSelectOpened: (opened) => set({ selectOpened: opened }),
}));
