import { FunctionComponent, useEffect } from "react";
import "../../global.css";

export const DesignSystemVariables: FunctionComponent<React.PropsWithChildren<{ theme: "light" | "dark" }>> = ({
  theme,
  children,
}) => {
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    return () => {
      document.documentElement.removeAttribute("data-theme");
    };
  }, [theme]);

  return <>{children}</>;
};
