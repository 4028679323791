import { Button, HeadlineText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { Link } from "react-router-dom";
import Notification from "./Notification";

const PostSurveyPositive = () => {
  return (
    <div sx={sxWrapper}>
      <Notification message="Review submitted" />
      <img sx={sxImg} src="parents/review/feedback.svg" alt="Feedback" />
      <HeadlineText as="h2">Thank you for sharing your feedback.</HeadlineText>
      <Link to="/">
        <Button data-name="post_survey_negative:go_to_homepage">Go to homepage</Button>
      </Link>
    </div>
  );
};
export default PostSurveyPositive;

const sxWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_l",
  alignItems: "center",
  padding: "dt_xl",
  a: { textDecoration: "none" },
};

const sxImg: ThemeUIStyleObject = {
  width: 158,
  height: 188,
};
