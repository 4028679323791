import { DisplayText, HeadlineText } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { Link } from "react-router-dom";

const NotFound = () => (
  <main sx={sxMain}>
    <img sx={sxBg} src="404-bg.png" alt="" />
    <section sx={sxBox}>
      <DisplayText level={1}>Lost?</DisplayText>
      <HeadlineText as="h1">
        <Link to="/">Let's find our way home</Link>
      </HeadlineText>
    </section>
  </main>
);

export default NotFound;

const sxMain: ThemeUIStyleObject = {
  minWidth: "100vw",
  minHeight: "100vh",
  backgroundColor: RAW_cssValue("#ADF3FF"),
  position: "relative",
};

const sxBg: ThemeUIStyleObject = {
  width: "100vw",
  maxWidth: "100vw",
  position: "absolute",
  left: 0,
  bottom: 0,
};

const sxBox: ThemeUIStyleObject = {
  position: "absolute",
  top: "45%",
  left: "50%",
  translate: "-50% -50%",
  fontWeight: 800,
  backgroundColor: RAW_cssValue("rgba(255,255,255,0.7)"),
  paddingTop: "dt_xl",
  paddingBottom: "dt_xl",
  paddingLeft: "dt_xxl",
  paddingRight: "dt_xxl",
  borderRadius: "dt_radius_m",
  display: "flex",
  flexDirection: "column",
  gap: "dt_m",
  alignItems: "center",
};
