import { createStyles } from "@mantine/core";
import { NAVBAR_BREAKPOINT } from "old/src/UILibrary/constants";
import marketplaceTheme from "src/utils/marketplaceTheme";

export default createStyles((_theme) => ({
  footerContainer: {
    background: marketplaceTheme.colors.dt_background_accent,
  },
  footerMain: {
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "120px",
    paddingRight: "120px",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: "22px",
    color: "#7174A0",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      height: "100%",
      padding: "19px 24px",
      marginBottom: "59px",
      flexDirection: "column",
    },
  },
  footerLinks: {
    maxWidth: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "47px",
    marginBottom: "50px",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "28px",
      marginBottom: "0px",
    },
  },
  footerLinkContainer: {
    marginLeft: "4px",
    marginRight: "4px",
    "> a": {
      textDecoration: "none",
    },
  },
  footerLink: {
    height: "26px",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "18px",
    textAlign: "center",
    color: "#7174A0",
    cursor: "pointer",
    textDecoration: "none",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      height: "18px",
      marginBottom: "32px",
    },
  },
  copyrightText: { fontWeight: 600, fontSize: "15px", lineHeight: "24px", color: "#7174A0" },
}));
