import { showNotification } from "@mantine/notifications";
import { HEADER_HEIGHT } from "src/constants";
import { Check } from "UILibrary";

export const showSuccessNotification = (message: string) => {
  return showNotification({
    radius: 18,
    message,
    color: "green",
    icon: <Check />,
    autoClose: false,
    style: {
      color: "#83DC1F",
      backgroundColor: "#F2FBE7",
      position: "fixed",
      top: HEADER_HEIGHT + 6,
      left: "50%",
      zIndex: 10000,
      transform: "translateX(-50%)",
      whiteSpace: "nowrap",
    },
  });
};
