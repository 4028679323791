import { UnstyledButton } from "@classdojo/web";
import orderBy from "lodash/orderBy";
import { useCallback, useMemo, useState } from "react";
import { useSlideSheetStore } from "src/components/SlideSheet/slideSheetStore";
import useDayOffSessionConflicts from "src/hooks/useDayOffSessionConflicts";
import { SelectInput } from "UILibrary";
import css from "./ConfirmDayOffAndReason.module.css";
import cn from "src/utils/cn";
import { DDSButton } from "@web-monorepo/dds";
import { IconTriangleDown } from "@web-monorepo/dds-icons";
import { logTutorEvent } from "src/utils/log";
import useWatch from "@classdojo/web/hooks/useWatch";
import {
  formatDateTzAgnostic,
  formatFullDate,
  formatHour,
  getDayOffType,
  isDayOffTooClose,
} from "src/pageComponents/TutorDayOff/utils";
import { useDayOffFormStateContext } from "old/src/pageComponents/Teachers/Availability/useOOOForm";

const dayOffReasonsOptions = [
  {
    label: "Medical appointment",
    value: "medical_appointment",
  },
  {
    label: "Health-related issues (e.g. sick, etc)",
    value: "health_related_issues",
  },
  {
    label: "Mental health break",
    value: "mental_health_break",
  },
  {
    label: "Professional obligations",
    value: "professional_obligations",
  },
  {
    label: "Technical issues (e.g. no power, etc)",
    value: "technical_issues",
  },
  {
    label: "Bereavement (death of a loved one)",
    value: "bereavement",
  },
  {
    label: "Natural disaster",
    value: "natural_disaster",
  },
  {
    label: "Vacation or rest",
    value: "vacation_or_rest",
  },
  {
    label: "Caregiver or family obligations",
    value: "caregiver_or_family_obligations",
  },
  {
    label: "Legal obligations",
    value: "legal_obligations",
  },
  {
    label: "Other",
    value: "other",
  },
];

export default function () {
  const {
    goBack,
    selectedDayOffs,
    createDayOff,
    updateDaysOff,
    calendarRangeState: { startDate, endDate },
  } = useDayOffFormStateContext();
  const onConfirmation = useCallback(
    (reason: string) => {
      logTutorEvent({
        eventName: "marketplace.tutor.day_off_creation_confirmed",
        metadata: {
          dayOffCategory: reason,
          dayOffType: getDayOffType(selectedDayOffs),
          dayOffIsTooClose: isDayOffTooClose(selectedDayOffs),
        },
      });
      createDayOff(reason);
    },
    [createDayOff, selectedDayOffs],
  );
  const disabled = updateDaysOff.isLoading;
  const [showAllConflicts, setShowAllConflicts] = useState(false);
  const { setSelectOpened } = useSlideSheetStore((store) => ({
    setSelectOpened: store.setSelectOpened,
  }));
  const [reason, setReason] = useState<string>();

  const dayOffDates = useMemo(() => {
    const daysOff = orderBy(selectedDayOffs, ({ startTs }) => startTs.getTime());
    const dayOff = daysOff[0];
    const startDateFormatted = formatDateTzAgnostic(startDate);
    const endDateFormatted = formatDateTzAgnostic(endDate);
    const isPartialDay = startDateFormatted === endDateFormatted;

    if (daysOff.length === 1) {
      const duration = Math.floor((dayOff.endTs.getTime() - dayOff.startTs.getTime()) / 1000);
      const isSingleDay = formatHour(dayOff.startTs) === "12:00 AM" && duration === 24 * 60 * 60;
      if (isSingleDay) {
        return startDateFormatted;
      }
      if (isPartialDay) {
        return `${startDateFormatted} • ${formatHour(dayOff.startTs)} - ${formatHour(dayOff.endTs)}`;
      }
      return `${startDateFormatted} ➞ ${endDateFormatted}`;
    }
    const formattedHours = daysOff
      .map(({ startTs, endTs }) => `${formatHour(startTs)} - ${formatHour(endTs)}`)
      .join(", ");
    return `${startDateFormatted} • ${formattedHours}`;
  }, [selectedDayOffs, startDate, endDate]);

  const { data: dayOffSessionConflictsData, isFetching: conflictsLoading } = useDayOffSessionConflicts(selectedDayOffs);
  const emptyConflicts = useMemo(() => [], []);
  const allSessionConflicts = dayOffSessionConflictsData?.data?.conflictsWithDayOff ?? emptyConflicts;
  const sessionConflicts = useMemo(
    () => allSessionConflicts.filter((conflict) => new Date(conflict.interval.startTs) > new Date()),
    [allSessionConflicts],
  );

  const conflictsToShow = showAllConflicts ? sessionConflicts : sessionConflicts.slice(0, 5);
  const numberOfHiddenConflicts = sessionConflicts.length - conflictsToShow.length;

  useWatch([conflictsToShow, conflictsLoading], () => {
    if (!conflictsLoading && conflictsToShow.length > 0) {
      logTutorEvent("marketplace.tutor.day_off_creation_with_conflicts");
    }
  });

  return (
    <div className={css.wrapper}>
      <div className={css.title}>Are you sure?</div>
      <div className={css.dayOffDateWrapper}>
        <div className={css.subtitle}>Adding OOO:</div>
        <div className={css.dayOff}>{dayOffDates}</div>
      </div>
      {conflictsToShow.length > 0 && (
        <div>
          <div className={cn(css.subtitle, css.conflictSessionsHeader)}>Affected classes:</div>
          <div className={css.sessionConflicts}>
            {conflictsToShow.map((conflict, idx) => (
              <div key={idx} className={css.conflict}>
                <div>{conflict.childName}</div>
                <div className={css.conflictDate}>
                  {formatFullDate(new Date(conflict.interval.startTs))} at{" "}
                  {formatHour(new Date(conflict.interval.startTs))}
                </div>
              </div>
            ))}
          </div>
          {numberOfHiddenConflicts > 0 && (
            <UnstyledButton
              data-name="expand-dayoff-conflict-sessions"
              onClick={() => setShowAllConflicts(true)}
              className={css.showAllConflicts}
            >
              And {numberOfHiddenConflicts} more
            </UnstyledButton>
          )}
        </div>
      )}
      <div className={css.dayOffReasonWrapper}>
        <div className={css.reasonSelectLabel}>OOO category</div>
        <SelectInput
          onChange={(val) => {
            setSelectOpened(true);
            if (val) {
              setReason(val);
            }
            setTimeout(() => {
              setSelectOpened(false);
            }, 300);
          }}
          data={dayOffReasonsOptions}
          placeholder="Select"
          className={css.select}
          rightSection={<IconTriangleDown size="s" color="dt_content_tertiary" />}
        />
      </div>
      <div className={css.buttonsWrapper}>
        <DDSButton onClick={goBack} variant="secondary" data-name="backButtonConfirmDayOffCreation" width="fill">
          Go back
        </DDSButton>
        <DDSButton
          onClick={() => {
            if (reason) {
              onConfirmation(reason);
            }
          }}
          data-name="confirmDayOffCreation"
          width="fill"
          disabled={!reason || disabled}
        >
          Confirm OOO
        </DDSButton>
      </div>
    </div>
  );
}
