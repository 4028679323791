export const actionTypes = {
  UPDATE_BOOKING: "UPDATE_BOOKING",
  REMOVE_BOOKING: "REMOVE_BOOKING",
  TOGGLE_BOOK_MODAL: "TOGGLE_BOOK_MODAL",
  SET_CHECKOUT_FUNNEL: "SET_CHECKOUT_FUNNEL",
  CLEAR_STORAGE: "CLEAR_STORAGE",
  DISABLE_EXPERIMENTS: "DISABLE_EXPERIMENTS",
  ENABLE_EXPERIMENTS: "ENABLE_EXPERIMENTS",
  SET_SUMMER_BOOST: "SET_SUMMER_BOOST",
};
