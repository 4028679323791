import { CalendarDate } from "@internationalized/date";
import { AriaButtonProps, useButton } from "@react-aria/button";
import { I18nProvider } from "@react-aria/i18n";
import { type RangeCalendarState } from "@react-stately/calendar";
import { DOMAttributes, useRef } from "react";
import { IconChevronLeft, IconChevronRight } from "@web-monorepo/dds-icons";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { UnstyledButton } from "@classdojo/web";
import { CalendarGrid } from "./CalendarGrid";
import css from "./CalendarContent.module.css";
import capitalize from "lodash/capitalize";
import { formatWithPattern, getDefaultTimezone } from "src/utils/dates";

type CalendarContentProps = {
  calendarProps: DOMAttributes<HTMLDivElement>;
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  months?: number;
  nextButtonProps: AriaButtonProps<"button">;
  prevButtonProps: AriaButtonProps<"button">;
  state: RangeCalendarState;
  onDateSelected: (date: Date) => void;
};

const ChangeMonthButton = ({
  buttonProps,
  label,
  ButtonIcon,
  dataName,
  hidden,
}: {
  buttonProps: AriaButtonProps<"button">;
  label: string;
  ButtonIcon: typeof IconChevronLeft | typeof IconChevronRight;
  dataName: string;
  hidden: boolean;
}) => {
  const nextRef = useRef(null);
  const { buttonProps: nextProps } = useButton(buttonProps, nextRef);

  if (hidden) {
    return (
      <UnstyledButton data-name={""} className={`${css.iconWrapper} ${css.hiddenIcon}`}>
        <ButtonIcon size="s" />
      </UnstyledButton>
    );
  }

  return (
    <UnstyledButton data-name={dataName} ref={nextRef} {...nextProps} aria-label={label} className={css.iconWrapper}>
      <ButtonIcon color={nextProps.disabled ? "dt_content_disabled" : "dt_content_primary"} size="s" />
    </UnstyledButton>
  );
};

function CalendarContent({
  calendarProps,
  className,
  containerRef,
  months = 1,
  nextButtonProps,
  prevButtonProps,
  state,
  onDateSelected,
}: CalendarContentProps) {
  const { calendarLocale } = getLocaleInfo();

  return (
    <I18nProvider locale={calendarLocale}>
      <div {...calendarProps} sx={calendarStyles} className={className} ref={containerRef}>
        {Array.from({ length: months }).map((_, index) => (
          <div key={index}>
            <div className={css.header}>
              <ChangeMonthButton
                buttonProps={prevButtonProps}
                dataName="calendar:chevron_left_icon"
                label="Previous month"
                ButtonIcon={IconChevronLeft}
                hidden={index !== 0}
              />

              <div
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h3 className={css.monthName}>
                  {capitalize(
                    formatWithPattern(
                      state.visibleRange.start.add({ months: index }).toDate(getDefaultTimezone()),
                      "MMMM yyyy",
                    ),
                  )}
                </h3>
              </div>

              <ChangeMonthButton
                buttonProps={nextButtonProps}
                dataName="calendar:chevron_right_icon"
                label="Next month"
                ButtonIcon={IconChevronRight}
                hidden={index !== months - 1}
              />
            </div>
            <CalendarGrid onDateSelected={onDateSelected} state={state} offset={{ months: index }} />
          </div>
        ))}
      </div>
    </I18nProvider>
  );
}

export default CalendarContent;

// eslint-disable-next-line react-refresh/only-export-components
export function getCalendarDate(date: Date): CalendarDate {
  return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

const getLocaleInfo = () => {
  const localeInfo = new Intl.DateTimeFormat().resolvedOptions();

  //   the as any was necessary because the type definition for Intl.Locale is wrong
  const IntlLocale = new Intl.Locale(localeInfo.locale) as any;

  const userCalendar: string =
    IntlLocale.calendar || IntlLocale.calendars?.[1] || IntlLocale.calendars?.[0] || localeInfo.calendar || "gregory";

  const calendarLocale = new Intl.Locale(localeInfo.locale, { calendar: userCalendar }).toString();

  return { localeInfo, userCalendar, calendarLocale };
};

const calendarStyles: ThemeUIStyleObject = {
  border: "dt_card",
  borderRadius: "dt_radius_s",
  backgroundColor: "dt_background_primary",
  width: "fit-content",
  padding: "dt_m",
  display: "flex",
  flexDirection: "column",
  gap: "dt_m",
};
