import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useMediaQuery } from "@mantine/hooks";
import { useCallback } from "react";
import { NAVBAR_BREAKPOINT } from "old/src/UILibrary/constants";
import cookies from "src/utils/cookies";
import Cookie from "js-cookie";

const useMatomoTracker = (category: string) => {
  const isMobile = useMediaQuery(`(max-width: ${NAVBAR_BREAKPOINT}px)`);
  const { trackEvent } = useMatomo();

  const checkoutEvent = useCallback(
    (action: string, eventName: string) => {
      const eventPayload: {
        category: string;
        action: string;
        name: string;
        customDimensions?: Array<{ id: number; value: string }>;
      } = {
        category,
        action,
        name: isMobile ? `mobile-${eventName}` : eventName,
      };
      const sourceEmailData = Cookie.get(cookies.sourceParentRedirectEmail.name);
      if (sourceEmailData) {
        const { campaign } = JSON.parse(sourceEmailData as string) as any;
        eventPayload.customDimensions = [{ id: 1, value: campaign }]; // set campaign as matomo custom dimension
      }
      return trackEvent(eventPayload);
    },

    [trackEvent, isMobile, category],
  );

  return checkoutEvent;
};

export default useMatomoTracker;
