import { Link } from "react-router-dom";
import useStyles from "./styles/Privacy.styles";
import { Box, Text } from "UILibrary";
import {
  personalInformationLink,
  learnersLink,
  serviceProvidersLink,
  deIdentifiedLink,
  FAQLink,
  inactiveLink,
} from "./";
import { useEffect } from "react";
import useQueryParams from "src/hooks/useQueryParams";

const ChildPrivacyTerms = () => {
  const { classes } = useStyles();
  const query = useQueryParams();

  // useWatch too early in render cycle, element may not exist yet
  // cannot use simple # because of hash based routing, urls have two #

  useEffect(() => {
    if (query.ref) {
      const destination = document.getElementById(query.ref);
      if (destination) {
        destination.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [query]);

  return (
    <Box className={classes.forTeachers}>
      <Box className={classes.wrapper}>
        <Box className={classes.hero} sx={{ marginBottom: "dt_l" }}>
          <Box>
            <Text component="h1">Dojo Tutor Child Privacy Terms</Text>
            <Box mb={16} />
            <Text className={classes.subHeader} sx={{ color: "#7174A0" } as any}>
              Last Updated: April 15, 2024
            </Text>
          </Box>
        </Box>
        <Box>
          <Box sx={{ scrollMarginTop: "130px" }} id="how-does-dojo-tutoring-protect-children">
            <h3 className={classes.paragraph}>How Does Dojo Tutor Protect Children?</h3>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              Protecting children’s privacy is especially important to us—we’re educators and parents ourselves, after
              all. This is why we’ve signed the Student Privacy Pledge and received iKeepSafe’s COPPA Safe Harbor seal,
              signifying that this Privacy Policy and our practices with respect to the operation of the Service have
              been reviewed and approved for compliance with iKeepSafe’s COPPA Safe Harbor program. COPPA protects the
              online privacy of children under the age of 13 (“child” or “children”); for more information about COPPA
              and generally protecting children’s online privacy, please visit{" "}
              <a href="http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online">
                OnGuard Online
              </a>
              .
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              We’ve also made a{" "}
              <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
                short video
              </a>{" "}
              for children and learners that highlights the most important details in our Privacy Policy that they
              should know about.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              This Children’s section explains our information, collection, disclosure, and parental consent practices
              with respect to information provided by children. This policy is in accordance with the U.S. Children’s
              Online Privacy Protection Act and its implementing regulations under the Children's Online Privacy
              Protection Rule (collectively, "COPPA"). ClassDojo extends the U.S-based COPPA consent protections in all
              jurisdictions, even if not required. If we rely on other legally applicable grounds for processing
              children's personal information (e.g. if we rely on legitimate interest in the European Union and the
              United Kingdom), we will still use the FTC COPPA consent methods described to provide further transparency
              for parents.
            </Text>
          </Box>
          <br />
          <Box sx={{ scrollMarginTop: "130px" }} id="what-information-tutoring-collect">
            <h3 className={classes.paragraph}>What Information Does Dojo Tutor Collect from Children?</h3>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              We collect two types of information from children: (1) information that children voluntarily provide us by
              using the Services (described below under “
              <Link to="#information-children-provide-to-us">Information Children Provide to Us</Link>”) and (2)
              information collected automatically as result of children’s use of the Services (described below under “
              <Link to="#automatically-collected-information">Information We Collect Automatically</Link>”). We also
              collect certain information about children from their tutor or parent as described below under the “
              <Link to="#information-collected-about-the-child-from-parents-and-tutors-combined">
                Information Collected About the Child from Parents and Tutors Combined with Children’s Data
              </Link>
              ”.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              The statements we make regarding the information we collect from or about {learnersLink()} and children
              using Dojo Tutor and how we use this information applies equally to all children and learners regardless
              of their age. Accordingly, where this Privacy Policy references learners or any information collected from
              or about learners, our Privacy Policy applies to learners under 13 years old as well as learners 13 years
              old and above.
            </Text>
          </Box>
          <br />
          <Box>
            <Text weight="bold" id="information-children-provide-to-us">
              Information Children Provide to Us
            </Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              Dojo Tutoring collects the minimal amount of information from children and {learnersLink()} necessary to
              register for an account on or otherwise use the Service. Additionally, Dojo Tutor will not condition a
              child or learner’s participation in an online activity on the Service on a child providing more{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              than is reasonably necessary in order to participate in the Service. If we discover that we have collected
              information from a child in a manner inconsistent with COPPA, we will take appropriate steps to either
              delete the information or immediately seek the{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">parent&apos;s consent</a> for that
              collection. This information is necessary to enable us to comply with legal obligations and given our
              legitimate interest in being able to provide and improve the Service and for security and safety purposes.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              For more detail on the categories of{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              collected from learners, as well as the sources from which the information was collected, the purposes for
              which the information was collected, and the categories of third parties with whom we disclose such
              personal information for a business purpose please see <Link to="/transparency">here</Link>.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>We collect information from your child in the following ways:</Text>
          </Box>
          <br />
          <Box>
            <Text weight="bold">Learner Account Creation</Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              Currently, learners access the Services through a parent setting up the learner’s account and/or giving
              their child access to the Services through the parent's device (“Learner Account”). When a parent sets up
              a Learner Account for their child, they will first need to{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12035564046093">create a parent account</a> and then
              they must provide certain information about their child which is necessary to allow their child to
              participate in the Services (e.g., child’s name, grade level, and tutoring subject, languages spoken).
              Parents with an existing ClassDojo Main Services account may alternatively create a new Dojo Tutor parent
              account using their ClassDojo Main Services account login credentials. The Dojo Tutor parent account will
              then be accessible through their existing ClassDojo Main Services account portal as well. In accordance
              with COPPA, we may ask the parent to provide the date of birth or age of their child, and if the child is
              under 13, we will also then seek to obtain verifiable{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">parental consent</a> in order to
              proceed with creating their child’s account or otherwise allowing their child to use the Service. The
              parent may choose to provide additional information - such as their child’s school name, a qualitative
              description of how their child is doing and where they need the most help, their child’s interests,
              whether their child has received any extra support, their child’s IEP needs, and languages spoken.
              Although not required under COPPA, we may at our discretion choose to impose parental consent requirements
              on learners ages 13 and above.
            </Text>
          </Box>
          <br />
          <Box>
            <Text weight="bold" id="tutoring-session-info">
              Tutoring Session Information
            </Text>
          </Box>
          <ul>
            <li>
              <Text className={classes.listText}>
                <b>Session Details</b>: Information collected from both {learnersLink()} and tutors such as tutoring
                subject areas, tutoring attendance and how long participants stayed in Zoom (as reported from Zoom). The
                attendance data is shown to both the tutor and learner (to confirm they are following the attendance
                policy) and may also be shown to parents and other designated third parties with the learner’s or
                parent’s permission.
              </Text>
            </li>

            <li>
              <Text className={classes.listText}>
                <b>Zoom recordings</b>: Certain information is captured by Zoom through your child’s participation in a
                tutoring session such as your child’s image, voice, movements, and other audio information as well as
                chat logs or other information provided during the tutoring session. We obtain this information through
                accessing recordings or reports from Zoom. Dojo Tutor currently uses the{" "}
                <a href="https://explore.zoom.us/en/isv/">Zoom ISV Program</a> to host its tutoring sessions, but has a
                Data Protection Addendum and other contracts in place with Zoom that allow for users under 13 and also
                mandate that Zoom apply its{" "}
                <a href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0060951">
                  Education Account Settings
                </a>{" "}
                to Dojo Tutor’s use of Zoom. This includes treating Dojo Tutor’s use of Zoom as an Education Account to
                apply the additional privacy, security and safety features and settings offered to schools. See Zoom’s
                Children’s Educational Privacy Statement{" "}
                <a href="https://explore.zoom.us/en/schools-privacy-statement/">here</a>. The tutoring sessions
                (including transcripts and Zoom chat logs) are recorded from both {learnersLink()} (your child) and
                tutors to ensure the safety and quality of tutoring on the Services as well as to provide and maintain
                the service (such as to create transcripts of the tutoring sessions as set forth{" "}
                <Link to="#tutoring-session-transcript">below</Link>). These recordings are automatically deleted by
                Zoom after 14 days (unless we are required by law to retain it, need it to ensure the security of our
                community or our Service, or to enforce our Terms). To learn more, read the{" "}
                <a href="https://explore.zoom.us/en/privacy/">Zoom Retention Policy</a>. Separately, within the 14-day
                period in which Zoom retains these recordings, Dojo Tutor may either download, store and retain the
                recordings (or elect to have Zoom retain these) for up to an additional 30 days to provide and maintain
                the service, conduct internal analysis, and keep our platform safe and secure..
              </Text>
            </li>
            <li>
              <Text id="tutoring-session-transcript" className={classes.listText}>
                <b>Tutoring Session Transcripts</b>: Dojo Tutor uses a{" "}
                <a href="https://tutor.classdojo.com/#/third-party-service-providers/">service provider</a> to create
                transcripts from the recorded tutoring sessions (“Tutoring Session Transcripts”). Dojo Tutor shares only
                the audio portion of the Zoom recordings of tutoring sessions between the learner and tutor with the
                service provider and ensures that the audio recordings are retained only transiently and deleted
                immediately upon creating the Tutoring Session Transcripts. Additionally, we ensure that the audio
                recordings can not be used by the service provider for any other purpose. Dojo Tutor also ensures that
                the service provider can’t retain the Tutoring Session Transcripts for more than thirty (30) days,
                unless deleted earlier by Dojo Tutor. Dojo Tutor uses these Tutoring Session Transcripts (if a parent
                consents) to perform certain automated intelligence (“AI”) related tasks as is further described below
                and in our <Link to="/ai-transparency-note">AI Transparency Note</Link>.
              </Text>
            </li>
          </ul>
          <Box>
            <Text weight="bold">Messaging and Other Communications</Text>
          </Box>
          <ul>
            <li>
              <Text className={classes.listText}>
                Contents of communications between {learnersLink()} and their tutors - such as messaging within the
                Services or information the child may communicate with their tutor during a recorded tutoring session.
                If your child participates in a group tutoring session, the content of their communications may be
                exposed to and shared with the other children in their tutoring class.
              </Text>
            </li>
          </ul>
          <Box>
            <Text weight="bold" id="information-collected-about-the-child-from-parents-and-tutors-combined">
              Information Collected About the Child from Parents and Tutors Combined with Children’s Data
            </Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              While not collected directly from your child, we also collect and receive from the child’s parent and
              tutor certain {personalInformationLink()} about the child that they choose to provide as set forth below
              and link this information to the child’s Learner Account. For additional information, please also see the
              Section{" "}
              <Link to="/privacy?ref=what-information-does-dojo-tutor-collect">
                “What Information Does Dojo Tutor Collect?”
              </Link>{" "}
              <Link to="/transparency">This chart</Link> details the personal information we collect, how we use it, and
              where it is stored.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              <i>Parent About Their Child:</i>
            </Text>
          </Box>
          <ul>
            <li>
              <Text className={classes.listText}>
                Name, which may be displayed to the tutor either on its own, or in a list with other {learnersLink()}{" "}
                assigned to the same tutor.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>Date of Birth</Text>
            </li>
            <li>
              <Text className={classes.listText}>A unique username and password</Text>
            </li>
            <li>
              <Text className={classes.listText}>
                Education information you provide about your child, such as what grade level your child is in.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                You may optionally provide your child’s school name and location; whether your child was home schooled
                or went to a private or public school; and graduation year.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                Qualitative description of how your child is doing and where they need the most help. This information
                is used by the tutor to plan their tutoring session.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                Interests of your child (e.g., art, basketball, dance, swimming). Dojo Tutor uses interests you provide
                about your child to personalize their tutoring experience and provide a more customized service
                (including, but not limited to tailoring lesson plans to their interests).
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>Languages spoken</Text>
            </li>
            <li>
              <Text className={classes.listText}>Gender pronouns</Text>
            </li>
            <li>
              <Text className={classes.listText}>
                Extra support received or student IEP needs. This information is used by the tutor to tailor the lesson
                plan according to your child’s needs.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>Tutoring subject areas</Text>
            </li>
          </ul>
          <Box>
            <Text className={classes.paragraph}>
              <i>Tutors About Learners (i.e., your child):</i>
            </Text>
          </Box>
          <ul>
            <li>
              <Text className={classes.listText}>
                Tutor’s’ assessments of the learner’s performance during the first tutoring session and over the course
                of additional sessions: Tutors will provide learners with both positive and constructive feedback that
                can further their learning (e.g., areas of strength or improvement) as well as recommended activities,
                resources and a work plan. In making these assessments, tutors may leverage the help of artificial
                intelligence generated from the Tutoring Session Transcripts, see “
                <Link to="#ai-generated-insights">
                  Artificial Intelligence (AI)-Generated Insights on Tutoring Session
                </Link>
                ” section for additional information. The feedback is shared with the learner and their parents, and may
                be shared by the parent to other individuals of the parent’s choosing, but is not made available to the
                general public.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                Contents of communications between your child and their tutors – such as messaging within the Services
                or information your child may communicate with their tutor during a recorded tutoring session.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                Tutoring attendance and how long participants stayed in Zoom (reported from Zoom).
              </Text>
            </li>
          </ul>
          <Box>
            <Text weight="bold">Location Information</Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              We never request or collect precise location data from learners and no learner location data is available
              publicly. From learners, the primary form of location data is based on IP Addresses as well as the
              non-precise location collected by Zoom as set forth{" "}
              <a href="https://explore.zoom.us/en/schools-privacy-statement/">here</a>.
            </Text>
          </Box>
          <br />
          <Box>
            <Text weight="bold" sx={{ scrollMarginTop: "130px" }} id="automatically-collected-information">
              Information We Collect Automatically
            </Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              In addition to the information listed above, we automatically collect some information from any use of our
              Service as set forth in the{" "}
              <Link to="#automatically-collected-information">&quot;Information collected automatically&quot;</Link>{" "}
              section. This includes device-specific information, such as device ID, unique device ID, device type and
              device attributes (e.g., operating system and battery level); and a device token (to send push
              notifications). Zoom may also collect the following from the device: information about the speakers,
              microphone, camera, OS version, hard disk ID, PC name, MAC address, WiFi information, and other device
              information (like Bluetooth signals). Please see{" "}
              <a href="https://explore.zoom.us/en/schools-privacy-statement/">
                Zoom’s Children’s Education Privacy Statement
              </a>{" "}
              for more information. We may also collect{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_b35dfe89-b21e-4bb2-8616-856d62ad6237">
                server log
              </a>{" "}
              information and product event data (e.g., if a child accesses a tutoring session, which we use to track
              progress, for internal analysis, on product usage and quality, or to send push or other notifications to
              encourage your child to take certain actions in the Dojo Tutor Service)
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              The device and application-related data is collected from the device and application being utilized to
              give the child access to Dojo Tutor accounts (e.g., the parent's device if the child is utilizing the
              parent’s device, or the child’s device if they are logging in separately on their own device). These are
              collected for internal analysis on product usage and quality, and we may also use it to track progress in
              the Service, and for sending{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/360050778411-Notifications-and-Your-Parent-Account#web">
                push
              </a>{" "}
              or email notifications to the child (as set forth below) to encourage them to take certain actions in the
              Dojo Tutor Service.
            </Text>
          </Box>
          <br />
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Basically,
            </Text>
          </Box>
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Dojo Tutor has been certified by iKeepSafe, an FTC-approved COPPA Safe Harbor, for compliance with their
              COPPA Safe Harbor program. We don’t ask for or require children to provide {personalInformationLink()}{" "}
              beyond that which is reasonably necessary to use Dojo Tutor. Please see{" "}
              <Link to="/transparency">this chart</Link> for the detailed categories of information we collect from each
              user type and how we use the information, as well as the sources from which the information was collected,
              the business purpose for which the information was collected and the third parties with whom we disclose
              personal information for a business purpose.
            </Text>
          </Box>
          <br />
          <Box id="how-does-dojo-tutor-use-the-information">
            <h3>How Does Dojo Tutor Use the Information it collects from Children?</h3>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              Information collected from children and {learnersLink()} (including {personalInformationLink()} and
              information collected automatically) is never used or disclosed for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_6fb13f0c-b840-412f-9956-2a519005384d">
                third-party advertising
              </a>
              . Additionally, personal information collected from children is never used for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
                behaviorally-targeted advertising
              </a>{" "}
              to learners by us or any third-party, and children’s personal information is never{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
                sold
              </a>{" "}
              or rented to anyone, including marketers and advertisers. We may present{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
                contextually relevant
              </a>{" "}
              educational content (not using learner personal information) for either our own products (e.g. showing a
              reading course while taking a math course) or third-party products and services ourselves that we believe
              may be of interest to a learner (e.g. an online platform that may help your kid practice math).
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              We use the information we collect from children and learners as follows:
            </Text>
          </Box>
          <br />
          <Box>
            <h3 id="ai-generated-insights">
              Artificial Intelligence (AI)-Generated Insights on Tutoring Session Transcripts
            </h3>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              Dojo Tutor uses Tutoring Session Transcripts (text only) as “inputs” to generate AI insights through the
              use of a <Link to="/third-party-service-providers/">service provider</Link> that integrates a Large
              Language Model (“LLM”) AI subprocessor to provide this AI service. At the specific direction and
              instruction of Dojo Tutor, this LLM will process these Tutoring Session Transcripts in order to obtain
              AI-generated insights on tutoring sessions between our {learnersLink()} and tutors (“AI Outputs”). This
              processing performed on the transcripts is done through the use of Dojo Tutor directed instructions known
              as “prompts” to the LLM (e.g. “summarize the tutoring session”). To protect the privacy of users, Dojo
              Tutor does not share any direct identifiers of tutors or learners (except to the extent that any direct
              identifiers are included within the Tutoring Session Transcripts).
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              Data obtained from the AI Outputs and Tutoring Session Transcripts may be accessed, processed and used by
              Dojo Tutor to provide and maintain the Service, personalize user experiences, perform internal analysis,
              and keep our platform safe and secure. For example, the Tutoring Session Transcripts will be sent to the
              LLM to create automated tutoring assessments, lesson summaries, and learning plans. To understand more
              about the specific “prompts” used to generate these AI insights, the information shared with this LLM, and
              Dojo Tutor’s use of AI, please see our <Link to="/ai-transparency-note">AI Transparency Note</Link>.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              <b>
                Dojo Tutor does not use any {personalInformationLink()} contained in any Tutoring Session Transcripts to
                train any Dojo Tutor’s artificial intelligence models. Dojo Tutor will only train our models with data
                that has been {deIdentifiedLink()} in such a way that it is not traceable to any Dojo Tutor user.
                Additionally, Dojo Tutor does not allow any LLM{" "}
                <Link to="/third-party-service-providers/">service provider</Link> or other AI third-party to use any
                information (personal information or {deIdentifiedLink()}) to train, improve or develop their artificial
                intelligence models.
              </b>{" "}
              This commitment includes the information contained in the charts detailed in this{" "}
              <Link to="/ai-transparency-note">AI Transparency Note</Link>, such as the Tutoring Session Transcripts,
              used to provide the AI Outputs.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              Your child's data (or you if you are a tutor) used to provide the “inputs” (e.g., the Tutoring Session
              Transcripts) may be temporarily retained by the LLM and related{" "}
              <Link to="/third-party-service-providers/">service providers</Link>, but in no event longer than thirty
              (30) days. Additionally, any AI Outputs will also be deleted within thirty (30) days of performing the
              processing on the “inputs” (e.g,. the Tutoring Session Transcripts).
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              If you, as the parent or guardian that has previously provided consent for the use of your child’s
              personal information in connection with Dojo Tutor’s AI use, would like to prevent further use and delete
              this information, please see the section “
              <Link to="#parental-choices">Parental Choices and Controls</Link>” below.
            </Text>
          </Box>
          <br />
          <Box>
            <h3 id="ai-generated-insights">Non-AI Uses</h3>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              In addition to the uses mentioned <Link to="#ai-generated-insights">above</Link>, we use the information
              we collect from a children and learners:
            </Text>
          </Box>
          <ul>
            <li>
              <Text className={classes.listText}>
                To provide and improve (as allowed by law) the Service to the child, including administering our
                tutoring sessions, to send push notifications or other in-app notifications to your child (e.g., to
                encourage them to take certain actions in the Service){" "}
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>For internal analytics on product usage</Text>
            </li>
            <li>
              <Text className={classes.listText}>
                For{" "}
                <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_0f871510-2886-46eb-9bb4-e7ae17080447">
                  educational
                </a>{" "}
                purposes and as authorized by law
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>To identify, fix and troubleshoot bugs and Service errors</Text>
            </li>
            <li>
              <Text className={classes.listText}>For security and safety purposes, including enforcing our Terms</Text>
            </li>
            <li>
              <Text className={classes.listText}>To comply with legal process or other laws</Text>
            </li>
          </ul>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              If we discover that we have collected information from a child in a manner inconsistent with COPPA, we
              will take appropriate steps to either delete the information or immediately seek the{" "}
              <a href="g">parent’s consent</a> for that collection. Dojo Tutor commits that its collection, use,
              retention, and sharing of children’s
              {personalInformationLink()} shall be reasonably necessary and proportionate to achieve the purposes for
              which the personal information was collected or processed and for the purposes disclosed in the this
              children’s section and{" "}
              <Link to="https://tutor.classdojo.com/#/transparency">Information Transparency Chart</Link> and that the
              personal information will not be further processed in a manner incompatible with these purposes.
            </Text>
          </Box>
          <br />
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Basically,
            </Text>
          </Box>
          <Box mb={"8px"}>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              We use the {personalInformationLink()} we collect from {learnersLink()} (or about learners from tutors or
              their parents) to provide and improve the Service, for internal analytics on product usage, for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_0f871510-2886-46eb-9bb4-e7ae17080447">
                educational purposes
              </a>
              , security and safety purposes, or as allowed by and to comply with the law. In addition, we work with
              service providers to derive AI-generated insights from Tutoring Session Transcripts. We will not require
              learners to provide more personal information than is reasonably necessary to participate in the Service.
            </Text>
          </Box>
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Personal information collected from children is never used or disclosed for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_6fb13f0c-b840-412f-9956-2a519005384d">
                third party advertising
              </a>
              . We do not place any third-party advertisements in learner logged-in areas of the Service. Personal
              information collected from learners is never used for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
                behaviorally-targeted advertising
              </a>{" "}
              to learners by us or any third-party. We may present contextually relevant educational content (not using
              learner personal information) for either our own products or third-party products and services ourselves
              that we believe may be of interest to a learner (e.g., our showing a child additional tutoring sessions
              for other school subjects they may be interested in). Children’s personal information is never{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
                sold
              </a>{" "}
              or rented to anyone, including marketers and advertisers. We may send push notifications to learners, but
              require children to provide a parent’s email address before receiving those notifications.
            </Text>
          </Box>
          <br />
          <Box>
            <h3>Push Notifications</h3>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              Dojo Tutor may send push notifications to learners. We require children to provide a parent’s email
              address before they can receive push notifications from Dojo Tutor (or have a Learner Account previously
              set up on ClassDojo by their parent with any necessary parental consent obtained). We will then provide
              the parent with notice (if the account was set up by them or their child) that the push notifications are
              being sent to their child. The device identifier for a child’s account will only be associated with other
              personal information once parental consent is provided. These push notifications may contain information
              about taking an action in the Service, such as the child completing a task or other product feature or
              informing the child their learning assessment has improved. If you are a learner or a parent of a child
              and no longer want these communications to be sent, please either turn off the push notifications on your
              device or contact us at tutoring-privacy@classdojo.com.
            </Text>
          </Box>
          <br />
          <Box>
            <Text weight="bold" sx={{ scrollMarginTop: "130px" }} id="automatically-collected-information">
              Does Dojo Tutor Disclose Any Information it Collects From Children?
            </Text>
          </Box>

          <Box>
            <Text className={classes.paragraph}>
              We do not disclose any {personalInformationLink()} about children to third-parties, except to{" "}
              {serviceProvidersLink()} necessary to provide the Service, other users as part of the Service (such as
              tutors), as directed or approved the child’s parents, as required by{" "}
              <Link to="/privacy?ref=legal-requirements">law</Link>, or to protect the security of the Service or other
              users. You can visit our {serviceProvidersLink()} page to see the list of third-party operators collecting
              or maintaining personal information from children through the Service. Please contact us as set forth in
              the section “
              <Link to="/privacy?ref=contact-information">How Can I Contact Dojo Tutor with Questions</Link>” with
              questions about the operators’ privacy policies and collection and use practices. Additionally, at any
              time, you may also agree to the collection and use of your child’s personal information, but choose not to
              allow disclosure to certain third parties (unless disclosure is integral to the Service).
            </Text>
          </Box>
          <br />
          <Box id="what-childrens-information-is-visible">
            <h3>What Children’s Information is Visible to Others?</h3>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              No Learner Account, profile or {personalInformationLink()} of a child is made available or visible to the
              general public through Dojo Tutor and no child can use Dojo Tutor without parental consent. Any content
              uploaded by you, your child, or your child’s tutor (including tutor and learner comments, assessments, or
              Zoom video recordings of tutoring sessions) through the Service, can only be viewed by you, those you
              choose to share assessments, feedback, reviews or any other tutoring information with, and your child’s
              tutor. While you may choose to share your tutoring information with others, please keep in mind that
              information (including your or your child’s {personalInformationLink()}) or content that you voluntarily
              disclose to others - including to other Dojo Tutor or ClassDojo users you interact with through and
              outside of the Service (such as messages you might send other users or individuals you collaborate with
              (such as tutors, teachers, friends or family members) - can be viewed, copied, stored, and used by the
              individuals you share it with. Dojo Tutor cannot control the actions of people with whom you choose to
              share information and we are not responsible for the collection, use, or disclosure of such information or
              content by others.
            </Text>
          </Box>

          <br />
          <Box>
            <Text className={classes.paragraph}>
              If you choose to enroll your child in a group tutoring session, any other child or learner in that
              tutoring session will also be able to see your child’s first name as well as any information another
              learner in that group tutoring session may share during the session (including through the chat feature).
              For group tutoring sessions, please note that other children will not be able to view your child’s
              assessment or other tutoring information. Information generated by AI systems or models will only be
              shared with you, your child, and your child’s tutor. For a complete list of what logged-in learner user’s
              information can be viewed by other logged in Dojo Tutor users, please see our {FAQLink()}.
            </Text>
          </Box>
          <br />
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Basically,
            </Text>
          </Box>
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              No Learner Account, profile or {personalInformationLink()} of a child is made available or visible to the
              general public through Dojo Tutor. If you choose to enroll in a group tutoring session, your child’s first
              name might be visible to the other children enrolled in the group session. For group sessions, other
              children will not be able to view your child’s assessment or other tutoring information. Information
              generated by AI systems or models will only be shared with you, your child, and your child’s tutor. For a
              list of what logged in learner user’s information can be viewed by other logged in Dojo Tutor users,
              please see our {FAQLink()}.
            </Text>
          </Box>
          <br />
          <Box sx={{ scrollMarginTop: "130px" }} id="how-long-keeps-children-information">
            <h3>How Long Does ClassDojo Keep Children’s Information?</h3>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              Dojo Tutor will not retain a child’s {personalInformationLink()} for any longer than is reasonably
              necessary to fulfill the purpose for which the information was collected, including, to provide and
              maintain the Service, for which we collect the child’s personal information, perform internal analysis,
              keep our platform safe and secure, or comply with legal obligations. Additionally, we only keep a child’s
              personal information for as long as their Learner Account is{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>, unless we are required by
              law to retain it, need it to ensure the security of our community or our Service, or to enforce our Terms.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>Specifically, we implement the following policies:</Text>
          </Box>
          <ul>
            <li>
              <Text className={classes.listText}>
                <b>Deleting inactive Learner Accounts</b>: If a child’s Learner Account is {inactiveLink()} for twelve
                months or more (meaning no learner (or their parent on behalf of their child) has logged into the
                Learner Account within the last 365 days), Dojo Tutor will delete the Learner Account. Learn more about
                how we delete inactive Learner Accounts{" "}
                <a href="https://classdojo.zendesk.com/hc/articles/12016429302157-What-happens-when-a-learner-account-becomes-inactive">
                  here
                </a>
                . For more information on the deletion of content related to Learner Accounts, please see our{" "}
                {FAQLink()}.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <b>14-day deletion policy for tutoring session Recordings:</b> ITo protect learners, Dojo Tutor sets
                limits on how long the Zoom recordings of tutoring sessions are maintained. These recordings are
                automatically deleted after 14 days by Zoom, unless we are required by law to retain it, need it to
                ensure the security of our community or our Service, or to enforce our Terms. The Zoom video recordings
                are stored on Zoom’s hosting provider’s servers. To learn more, read the{" "}
                <a href="https://explore.zoom.us/en/privacy/">Zoom Retention Policy</a>.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <b>Retention policy for tutoring session recordings in the Service:</b> Separately, within the 14-day
                period in which Zoom retains these recordings, Dojo Tutor may either download, store and retain the
                recordings (or elect to have Zoom retain these) for up to an additional 30 days to provide and maintain
                the service, conduct internal analysis, and keep our platform safe and secure.
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <b>Deleting inactive Learner Accounts:</b> If a child’s Learner Account is {inactiveLink()} for twelve
                months or more (meaning no learner (or their parent on behalf of their child) has logged into the
                Learner Account within the last 365 days), Dojo Tutor will delete the Learner Account. Learn more about
                how we delete inactive Learner Accounts here.
              </Text>
            </li>
          </ul>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              Please see our <Link to="/transparency">Information Transparency chart</Link> and{" "}
              <Link to="/ai-transparency-note?ref=what-information-is-used-table">AI Transparency chart</Link> for more
              details on specific retention periods. Additionally, for more information on the deletion of content
              related to Learner Accounts, please see our {FAQLink()}.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              We may retain {deIdentifiedLink()} information for as long as we deem appropriate or as otherwise required
              by law.
            </Text>
          </Box>
          <br />
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Basically,
            </Text>
          </Box>
          <Box mb={"8px"}>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              We will not retain a child’s {personalInformationLink()} for any longer than is reasonably necessary for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_0f871510-2886-46eb-9bb4-e7ae17080447">
                educational purposes
              </a>{" "}
              or legal obligations, or to provide the Service for which we receive or collect the child’s personal
              information. We only keep a child’s {personalInformationLink()} for as long as the account is{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>, unless we are required by
              law to retain it, need it to enforce our policies or to ensure the security of our community.
            </Text>
          </Box>
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Additionally, we protect children and learners with the additional policies: (1) we require Zoom to
              automatically delete tutoring session Zoom recordings after 14 days (unless noted above), (2) within this
              14-day retention period, Dojo Tutor may either download, store and retain the recordings (or elect to have
              Zoom retain these) for up to an additional 30 days to provide and maintain the service, conduct internal
              analysis, and keep our platform safe and secure, and (3) Dojo Tutor deletes Learners’ Accounts if they’re{" "}
              {inactiveLink()} for more than twelve months.
            </Text>
          </Box>
          <br />
          <Box sx={{ scrollMarginTop: "130px" }} id="parental-choices">
            <h3>Parental Choices and Controls</h3>
          </Box>
          <Box>
            <Text weight="bold">Access, Correction, and Deletion Rights under COPPA</Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              We support access to, correction, and deletion of your children’s {personalInformationLink()} by directly
              responding to requests from parents or legal guardians. You may access, review, correct, or delete any of
              your child’s personal information in the Service by emailing tutoring-privacy@classdojo.com with the same
              email used to create your parent account. In addition to the choices provided to you as a parent under
              COPPA, you may also have certain rights relating to your personal information, subject to local data
              protection laws and the jurisdiction you reside in. See the “
              <Link to="/privacy?ref=providing-transparency-rights">
                Dojo Tutor’s Commitments to Providing Transparency and Your Rights?
              </Link>
              ” Section below.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              We will use commercially reasonable efforts to process such requests in a timely manner consistent with
              applicable law. We will need to verify your identity, for example by requiring that you provide acceptable
              forms of personal identification as set forth in our {FAQLink()}. Please keep in mind that a request to
              delete records may lead to a termination of an account, or the use of the Services.
            </Text>
          </Box>
          <br />
          <Box>
            <Text className={classes.paragraph}>
              When you delete your child's account, we delete the content or personal information uploaded as set forth
              in our {FAQLink()}. For additional information on what happens when an account is deleted, please see our
              section “
              <a href="https://classdojo.zendesk.com/hc/articles/12706732718349-What-happens-when-I-delete-my-account-Tutoring">
                What happens when I delete my account (Tutoring).
              </a>
              ”
            </Text>
          </Box>
          <br />
          <Box>
            <Text weight="bold">Stop Collecting Further Personal Information</Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              Additionally, at any time, for Learner Accounts, you can refuse to permit us to collect further{" "}
              {personalInformationLink()} from your child by emailing{" "}
              <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email
              used to create your parent account. Please keep in mind that deleting records may require us to terminate
              the account in question.
            </Text>
          </Box>
          <br />
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              Basically,
            </Text>
          </Box>
          <Box>
            <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
              We believe parents who have set up their child’s Learner Account, or otherwise allow their child access to
              the Service, should always be able to review their child's account, request for us to delete their child’s
              personal information, obtain a copy of their child's {personalInformationLink()}, or stop us from further
              collecting their personal information. Just email us at{" "}
              <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email
              used to create your parent account and we’ll be happy to help. In addition to the choices provided to you
              as a parent under COPPA, you may also have certain other rights relating to your personal information,
              subject to local data protection laws and the jurisdiction you reside in. See our{" "}
              <Link to="/privacy?ref=providing-transparency-rights">
                Dojo Tutor’s Commitments to Providing Transparency and Your Rights
              </Link>{" "}
              Section for more information on your rights.
            </Text>
          </Box>
          <br />
          <Box>
            <Text weight="bold">What Controls do Parents Have?</Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              For more information on the controls available to Dojo Tutor parents, please review our {FAQLink()}.
            </Text>
          </Box>
          <br />
          <Box id="how-does-classdojo-obtain-consent">
            <Text weight="bold">How Does ClassDojo Obtain Consent?</Text>
          </Box>
          <Box>
            <Text className={classes.paragraph}>
              Dojo Tutor follows the U.S. Federal Trade Commission’s (FTC) COPPA rules for parental consent. For more
              information on how we obtain parental consent, please see our {FAQLink()}. Although not required under
              COPPA, we may choose to impose parental consent requirements on Learner Accounts 13 and above at our
              discretion. ClassDojo extends the U.S.-based COPPA consent protections in all jurisdictions, even if not
              required. If we rely on other legally applicable grounds for processing children’s{" "}
              {personalInformationLink()} (e.g. if we rely on legitimate interest in the European Union and the United
              Kingdom), we will still use the FTC COPPA consent methods described above to provide further transparency
              for parents.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChildPrivacyTerms;
