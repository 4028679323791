import { setEntityId, setEntityType } from "@classdojo/log-client";
import { paths } from "@classdojo/ts-api-types/api";
import { AxiosError } from "axios";
import useMutation from "src/hooks/useMutation";
import { monolithApiClient } from "src/configs/axios.config";
import { setSessionQueryData } from "src/hooks/useSession";
import { handleError } from "src/queryClient";
import { logTutorEvent } from "src/utils/log";
import { datadogRum } from "@datadog/browser-rum";
import useHelperWidgetAPI from "src/hooks/useHelperWidgetAPI";
import omit from "lodash/omit";
import { setDefaultTimezone } from "src/utils/dates";

type Endpoint = paths["/api/marketplace/signup/parent"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

const signUpParent = async (body: Body) => await monolithApiClient.post<Response>(`/signup/parent`, body);

const useSignUpParentMutation = () => {
  const helperWidgetAPI = useHelperWidgetAPI();

  const mutation = useMutation(signUpParent, {
    onSuccess: ({ data }) => {
      setEntityId(data.user_details.uid);
      setEntityType("parent");
      helperWidgetAPI.setWidgetUserData({
        userType: "parent",
        email: data.user_details.email,
        fullname: `${data.user_details.first_name ?? ""} ${data.user_details.last_name ?? ""}`,
        caller: "useSignUpParentMutation",
      });
      datadogRum.setUser({
        id: data.user_details.uid,
        userType: "parent",
      });
      // Set default rendering time zone in src/utils/dates?
      logTutorEvent({ eventName: "marketplace.login.success" });
      setDefaultTimezone(data.user_details.timezone);
      setSessionQueryData(data);
    },
    onError: (error: AxiosError, { email }) => {
      logTutorEvent({
        eventName: "marketplace.signup.failure",
        metadata: { code: error.code, message: error.message, email },
      });
      handleError(false, {
        title: "Signup failed",
        message: "Contact our support team for assistance or try with alternative credentials.",
      });
    },
  });

  // on signup, we'll refetch the session data, reload component, and mutation will be unmounted so isSuccess will not be true
  return omit(mutation, "isSuccess");
};

export default useSignUpParentMutation;
