import { useEffect, useLayoutEffect, useRef } from "react";

export function useInterval(callback: () => void, interval: number | null) {
  const savedCallback = useRef(callback);

  // Memoize the callback, so it doesn't trigger changes of the interval:
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (interval === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, interval);

    return () => {
      clearInterval(id);
    };
  }, [interval]);
}
