import css from "./CalendarWithNumber.module.css";

export default function ({ dateOfMonth, multipleDays }: { dateOfMonth: number; multipleDays: boolean }) {
  return (
    <div className={css.calendarWithNumberContainer}>
      <div className={css.calendarWithNumber}>
        <span>{dateOfMonth}</span>
      </div>
      {multipleDays && <div className={css.calendarWithNumberShadow} />}
    </div>
  );
}
