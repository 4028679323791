import * as logClient from "@classdojo/log-client";

export function sanitizeId(id: string): string {
  // check if the id has 32 characters, if so slice it to 24 and track it with a metric
  if (id.length === 32) {
    // track the metric
    logClient.sendMetrics([
      {
        type: "increment",
        value: 1,
        metricName: "marketplace.invalidMongoId",
      },
    ]);
    // slice the id to 24 characters
    return id.slice(0, 24);
  }
  return id;
}
