import { MantineThemeOverride } from "@mantine/core";
import Button from "./Button";
import { colors } from "./colors";

// https://mantine.dev/theming/extend-theme/#adding-custom-colors
const theme: MantineThemeOverride = {
  colors,
  primaryColor: "grape",
  components: {
    Button: {
      styles: () => Button(),
    },
    Image: {
      styles: {
        root: {
          width: "auto",
        },
      },
    },
  },
};

export { theme };
