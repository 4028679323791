import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Users sometimes navigate to paths like `!/signup`. We used to send emails with URLs like this.
 * react-router v6 isn't able to match paths like this, so we write the redirect manually.
 */
export default function FixBadPaths() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // If the path starts with `!/`, remove the `!` and navigate to the new path. Sometimes it comes in as /!/path instead, support both :shrug:
    const match = /^\/?!\/(.*)/.exec(location.pathname);
    if (match) {
      navigate({
        ...location,
        pathname: `/${match[1]}`,
      });
    }
  }, [location, navigate]);

  return null;
}
