import { paths } from "@classdojo/ts-api-types/api";
import { useQuery } from "@tanstack/react-query";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/parent/decagon"]["get"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];
export type DecagonMetadata = Response;

const getDecagonMetadata = async () => await monolithApiClient.get<Response>("/parent/decagon");

export default (enabled: boolean) => {
  const query = useQuery(["decagonMetadata"], getDecagonMetadata, {
    enabled,
  });

  return { ...query, data: query.data?.data };
};
