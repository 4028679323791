import Cookie from "js-cookie";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useEventMutation from "./useEventMutation";
import useMatomoTracker from "old/src/hooks/useMatomoTracker";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import cookies from "src/utils/cookies";
import { logTutorEvent } from "src/utils/log";

const useRedirectToBooking = () => {
  const [, dispatch] = useGlobalStore();
  const push = useNavigate();
  const checkoutEvent = useMatomoTracker("checkout-flow");
  const eventMutation = useEventMutation();

  return useCallback(async () => {
    checkoutEvent("button-click", "entered-booking-flow");
    logTutorEvent("parent.marketplace.entered_booking_flow");

    const sourceEmailData = Cookie.get(cookies.sourceParentRedirectEmail.name);
    if (sourceEmailData) {
      const { email, campaign } = JSON.parse(sourceEmailData as string) as any;
      await eventMutation.mutateAsync({
        event: "dojo-tutoring-trial-class-booking-started",
        email,
        campaign,
      });
    }
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: null,
    });
    push("/single-class");
  }, [checkoutEvent, dispatch, eventMutation, push]);
};

export default useRedirectToBooking;
