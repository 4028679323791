import { createStyles } from "@mantine/core";
import { NAVBAR_BREAKPOINT } from "old/src/UILibrary/constants";

export default createStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "900px",
    margin: "auto",
    padding: "80px 0",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      padding: "24px",
    },
  },

  hero: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "64px",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      flexDirection: "column",
      marginBottom: "40px",
    },
  },

  getStarted: {
    display: "flex",
    alignItems: "center",
    justifyConten: "space-between",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      flexDirection: "column",
    },
  },

  getStartedButton: {
    marginLeft: "40px",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      width: "100%",
      margin: 0,
      marginTop: "16px",
    },
  },

  heroImage: {
    width: "420px",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      marginTop: "40px",
      width: "60%",
    },
  },

  body: {
    h1: {
      fontSize: "50px",
      fontWeight: 800,
      lineHeight: "100%",
      letterSpacing: -0.5,
      color: "#2C2A50",
      margin: 0,
      [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
        fontSize: "42px",
      },
    },
    h3: {
      fontSize: "30px",
      fontWeight: 800,
      lineHeight: "36px",
      letterSpacing: -0.35,
      color: "#2C2A50",
      margin: 0,
      scrollMargin: "112px",
    },
    h2: {
      fontWeight: 800,
      fontSize: "30px",
      lineHeight: "36px",
      letterSpacing: -0.35,
      color: "#2C2A50",
      textAlign: "center",
    },
    h4: {
      fontSize: "26px",
      fontWeight: 800,
      lineHeight: "36px",
      letterSpacing: -0.35,
      color: "#2C2A50",
      margin: 0,
    },
    h5: {
      fontSize: "20px",
      fontWeight: 800,
      lineHeight: "36px",
      letterSpacing: -0.35,
      color: "#2C2A50",
      margin: 0,
    },
    li: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "32px",
      letterSpacing: -0.5,
      color: "#2C2A50",
    },

    table: {
      margin: "auto",
      border: "1px solid #d7d7d7",
      borderRadius: "5px",
      borderSpacing: "initial",
      width: "100%",
      fontSize: "14px",

      thead: {
        backgroundColor: "#f6f6f4",
        fontWeight: "bold",

        tr: {
          textAlign: "left",
          th: {
            border: "1px solid #696969",
            padding: "12px",
          },
        },
      },

      tbody: {
        verticalAlign: "top",

        tr: {
          td: {
            borderBottom: "1px solid #d7d7d7",
            borderRight: "1px solid #d7d7d7",
            padding: "12px",
          },
        },
      },
    },
  },

  paragraph: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24.8px",
    letterSpacing: -0.5,
    color: "#2C2A50",
    margin: 0,
  },

  subHeader: {
    fontSize: "30px",
    fontWeight: 800,
    lineHeight: "30px",
    letterSpacing: -0.5,
    color: "#7174A0",
    margin: 0,
    width: "70%",
    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      fontSize: "26px",
      width: "100%",
    },
  },

  listText: {
    fontSize: "16px !important",
  },
}));
