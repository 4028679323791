import { DDSButton } from "@web-monorepo/dds";
import css from "./TooCloseDayOffWarning.module.css";
import { useLogTutorEventOnFirstRender } from "src/utils/log";
import { useDayOffFormStateContext } from "old/src/pageComponents/Teachers/Availability/useOOOForm";

export default function ({ onContinue }: { onContinue: () => void }) {
  const { goBack } = useDayOffFormStateContext();
  useLogTutorEventOnFirstRender("marketplace.tutor.day_off_emergency_warning_viewed");

  return (
    <div className={css.wrapper}>
      <div className={css.title}>Is this an emergency?</div>
      <div>
        <div className={css.warning}>You are about to schedule a last minute OOO.</div>
        <div className={css.warningExplanation}>
          Families are counting on you to minimize last-minute schedule changes. As such, please avoid scheduling OOO
          less than 48 hours in advance unless it’s an emergency.
        </div>
      </div>
      <div className={css.buttonsWrapper}>
        <DDSButton onClick={goBack} variant="secondary" data-name="backButtonTooCloseDayOffWarning" width="fill">
          Go back
        </DDSButton>
        <DDSButton onClick={onContinue} data-name="continueButtonTooCloseDayOffWarning" width="fill">
          Continue
        </DDSButton>
      </div>
    </div>
  );
}
