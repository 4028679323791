import { useCallback, useState } from "react";

const useBoolean = (initialState: boolean) => {
  const [isTrue, setIsTrue] = useState(initialState);
  const setTrue = useCallback(() => setIsTrue(true), []);
  const setFalse = useCallback(() => setIsTrue(false), []);
  const toggle = useCallback(() => setIsTrue((prev) => !prev), []);

  return { isTrue, isFalse: !isTrue, setTrue, setFalse, toggle };
};

export default useBoolean;
