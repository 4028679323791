import { Tuple } from "@mantine/core";
import marketplaceTheme from "src/utils/marketplaceTheme";

export const colors: Record<string, Tuple<string, 10>> = {
  taro: ["#FFFFFF", "#F7F8FF", "#EAECF5", "#D3D7EC", "#AAB0D8", "#AAB0D8", "#545382", "#2C2A50", "#1E1D36", "#1E1D36"],
  grape: [
    marketplaceTheme.colors.dt_background_accent,
    marketplaceTheme.colors.dt_background_accent,
    marketplaceTheme.colors.dt_background_accent,
    marketplaceTheme.colors.dt_background_accent,
    marketplaceTheme.colors.dt_background_accent,
    marketplaceTheme.colors.dt_content_accent,
    marketplaceTheme.colors.dt_content_accent,
    marketplaceTheme.colors.dt_content_accent,
    marketplaceTheme.colors.dt_content_accent,
    marketplaceTheme.colors.dt_content_accent,
  ],
  blueberry: [
    "#F5FBFF",
    "#EFF8FF",
    "#E4F3FF",
    "#ADDAFF",
    "#61B5FF",
    "#3A74FF",
    "#194BE0",
    "#113398",
    "#0C2367",
    "#07153E",
  ],
  aqua: ["#FCFEFF", "#F7FCFF", "#F2FAFF", "#E1F8FF", "#B2EEFF", "#70DBFF", "#00B2F7", "#0092E5", "#00639C", "#00436A"],
  kiwi: ["#FAFEF7", "#F7FDF1", "#F2FBE7", "#DFFFBA", "#B4F575", "#83DC1F", "#58BC00", "#3C8000", "#295700", "#193400"],
  mango: ["#FFFEF5", "#FFFEEF", "#FFFEE5", "#FFF8AB", "#FDF267", "#FFDC00", "#F7B100", "#A87800", "#725200", "#443100"],
  tangerine: [
    "#FFFBF4",
    "#FFF8ED",
    "#FFF3E1",
    "#FFDFB3",
    "#FFBC5F",
    "#FF9517",
    "#E95E13",
    "#9E400D",
    "#6B2C09",
    "#401A05",
  ],
  watermelon: [
    "#FFF8F9",
    "#FFF4F5",
    "#FFECEE",
    "#FFC9D0",
    "#FFA2A3",
    "#FF5854",
    "#D72A2B",
    "#921D1D",
    "#631414",
    "#3B0C0C",
  ],
  rock: ["#FEFEFE", "#FCFCFF", "#FCFCFE", "#F9FAFE", "#F5F6FC", "#F0F2F9", "#EAECF5", "#757EB0", "#4A538E", "#2C3475"],
};

type ClassDojoCustomColors = keyof typeof colors;

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ClassDojoCustomColors, Tuple<string, 10>>;
  }
}
