import { CSSObject, TextInput, TextInputProps } from "@mantine/core";
import React from "react";
import marketplaceTheme from "src/utils/marketplaceTheme";

interface CustomTextInputProps extends TextInputProps {
  inputstyles?: CSSObject;
  errorStyles?: CSSObject;
  forwardedRef?: any;
  ref?: any;
}

const CustomTextInput: React.FC<CustomTextInputProps> = (props) => {
  const newProps = { ...props, ref: props.forwardedRef || props.ref };
  return (
    // @ts-expect-error - styles error
    <TextInput
      styles={() => ({
        input: {
          height: "64px",
          padding: "20px 16px",
          border: "2px solid #D3D7EC",
          borderRadius: "16px",
          color: "#2C2A50",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          ...props?.inputstyles,
          "::placeholder": {
            color: "#AAB0D8",
          },
          ":hover": {
            border: `${marketplaceTheme.borders.dt_active}`,
          },
          ":active": {
            border: `${marketplaceTheme.borders.dt_active}`,
          },
          ":focus": {
            border: `${marketplaceTheme.borders.dt_active}`,
          },
        },
        label: {
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
        },
        error: {
          ...props.errorStyles,
        },
      })}
      {...newProps}
    />
  );
};

export default CustomTextInput;
