import { BodyText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { HEADER_HEIGHT } from "src/constants";
import { IconCheckCircleFilled } from "@web-monorepo/dds-icons";

type Props = {
  message: string;
};
const Notification = ({ message }: Props) => {
  return (
    <div sx={sxWrapper}>
      <IconCheckCircleFilled sx={sxIcon} size="m" />
      <BodyText level={2}>{message}</BodyText>
    </div>
  );
};

export default Notification;

const sxWrapper: ThemeUIStyleObject = {
  backgroundColor: "dt_background_success",
  padding: "dt_m",
  borderRadius: "dt_radius_s",
  display: "flex",
  alignItems: "center",
  gap: "dt_xs",
  flexGrow: 0,
  position: [null, null, null, "absolute"],
  right: [null, null, null, 32],
  top: [null, null, null, HEADER_HEIGHT + 16],
  width: ["100%", null, null, "auto"],
  boxSizing: "border-box",
};

const sxIcon: ThemeUIStyleObject = {
  color: "dt_content_success",
};
