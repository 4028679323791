import { paths } from "@classdojo/ts-api-types/api";
import { AxiosResponse } from "axios";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/blueshift/events"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200];

export default class EventAPI {
  static sendBlueshiftEvent(body: Body): Promise<AxiosResponse<Response>> {
    return monolithApiClient.post("/blueshift/events", body);
  }
}
