import * as logClient from "@classdojo/log-client";
import { useParams } from "react-router-dom";
import { Box, Loader, Button } from "UILibrary";
import CoppaModal from "old/src/components/CoppaModal";
import { useClassMeetingQuery } from "old/src/hooks/useParent";
import { logTutorEvent } from "src/utils/log";

const Meeting = () => {
  const query = useParams();
  const { meeting, isCOPPAError, isFetching, refetch } = useClassMeetingQuery(query.token);

  const redirectToMeeting = () => {
    // payload should include source (sms, email, dashboard) and is_subscription (true, false)

    const logPayload = {
      source: query?.source?.toString() ?? "unknown",
      isSubscription: String(meeting?.is_subscription),
    };

    logTutorEvent({
      eventName: "parent.marketplace.meeting.zoom_open",
      metadata: logPayload,
    });

    logClient.sendMetrics([
      {
        type: "increment",
        value: 1,
        metricName: "marketplace.meeting.zoom_open",
        tags: logPayload,
      },
    ]);
    if (meeting?.zoom_student_url != null) window.location.href = meeting.zoom_student_url;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(90vh - 124px)",
      }}
    >
      {isFetching ? (
        <Loader size="md" />
      ) : isCOPPAError ? (
        <CoppaModal token={query?.token as string} onSubmit={() => refetch()} />
      ) : (
        meeting?.zoom_student_url && (
          <Button sx={{ width: "240px" }} onClick={redirectToMeeting} mb={24}>
            Enter class
          </Button>
        )
      )}
    </Box>
  );
};

export default Meeting;
