import MatomoTracker from "@datapunt/matomo-tracker-js";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

let matomoInstance: MatomoTracker | null = null;
const matomoUrlBase = Config.matomoBaseUrl;
const matomoSiteId = Config.matomoSiteId;

export const createMatomoInstance = (setDomains: string[]) => {
  if (matomoUrlBase && matomoSiteId) {
    const matomoInstance = createInstance({
      urlBase: matomoUrlBase,
      siteId: Number(matomoSiteId),
      trackerUrl: "https://www.classdojo.com/ma/m.cd",
      srcUrl: "https://www.classdojo.com/ma/m.js",
      configurations: {
        setDomains,
      },
    });
    return matomoInstance;
  }

  return null;
};

interface MatomoRouterProviderProps {
  children: React.ReactNode;
  domains: string[];
  userId?: string;
}

export const MatomoRouterProvider: React.FC<MatomoRouterProviderProps | any> = ({ children, domains, userId }) => {
  if (!matomoInstance) {
    matomoInstance = createMatomoInstance(domains);
    matomoInstance?.pushInstruction("requireCookieConsent");
  }

  const { pathname } = useLocation();

  useEffect(() => {
    matomoInstance?.trackPageView({ documentTitle: pathname });
  }, [pathname]);

  useEffect(() => {
    if (userId) {
      matomoInstance?.pushInstruction("setUserId", userId);
    }
  }, [userId]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - there seems to be a bug with Matomo's provider typing, doesn't allow children - hot fix for now
  return matomoInstance ? <MatomoProvider value={matomoInstance}>{children}</MatomoProvider> : children;
};

interface MatomoConfiguredProviderProps {
  children: React.ReactNode;
}

export const MatomoConfiguredProvider: React.FC<MatomoConfiguredProviderProps> = ({ children }) => {
  return <MatomoRouterProvider domains={["*.tutoring.classdojo.com"]}>{children}</MatomoRouterProvider>;
};
