import { CalendarDate, getLocalTimeZone, isSameDay, isSameMonth } from "@internationalized/date";
import { useCalendarCell } from "@react-aria/calendar";
import { useFocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import { type RangeCalendarState } from "@react-stately/calendar";
import { useCallback, useRef } from "react";
import { addDays, differenceInDays } from "src/utils/dates";
import css from "./CalendarCell.module.css";
import cn from "src/utils/cn";
import { UnstyledButton } from "@classdojo/web";

function getIsSelectionStart(state: RangeCalendarState, date: CalendarDate, isSelected: boolean) {
  return state.highlightedRange ? isSameDay(date, state.highlightedRange.start) : isSelected;
}

function getIsSelectionEnd(state: RangeCalendarState, date: CalendarDate, isSelected: boolean) {
  return state.highlightedRange ? isSameDay(date, state.highlightedRange.end) : isSelected;
}

export function CalendarCell(props: {
  onDateSelected: (date: Date) => void;
  state: RangeCalendarState;
  date: CalendarDate;
  currentMonth: CalendarDate;
}) {
  function getCSSClassName({
    isSelected,
    isSelectionStart,
    isSelectionEnd,
    isInteractive,
    isLastMinute,
  }: {
    isSelected: boolean;
    isSelectionStart: boolean;
    isSelectionEnd: boolean;
    isInteractive: boolean;
    isLastMinute: boolean;
  }) {
    let className = css.cellStyles;
    if (!isInteractive) {
      className = cn(className, css.isNotInteractive);
    }
    if (isLastMinute) {
      className = cn(className, css.isLastMinute);
    }
    if (isSelected) {
      if (isSelectionStart || isSelectionEnd) {
        if (isSelectionStart) {
          className = cn(className, css.isSelectedStart);
        }
        if (isSelectionEnd) {
          className = cn(className, css.isSelectedEnd);
        }
      } else {
        className = cn(className, css.internalDays);
      }
    }
    return className;
  }

  const ref = useRef(null);
  const { cellProps, buttonProps, formattedDate, isSelected, isDisabled, isOutsideVisibleRange } = useCalendarCell(
    props,
    props.state,
    ref,
  );
  const isOutsideMonth = !isSameMonth(props.currentMonth, props.date);
  const isHidden = isOutsideVisibleRange || isOutsideMonth;
  const isInteractive = !isDisabled && !isHidden;
  const isLastMinute =
    differenceInDays(props.date.toDate(getLocalTimeZone()), addDays(new Date(), 3)) < 0 &&
    differenceInDays(props.date.toDate(getLocalTimeZone()), new Date()) >= 0;

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isSelectionStart = getIsSelectionStart(props.state, props.date, isSelected);
  const isSelectionEnd = getIsSelectionEnd(props.state, props.date, isSelected);

  const { date: calendarDate, onDateSelected } = props;
  const { focusProps } = useFocusRing();
  const onClick = useCallback(() => {
    const { year, month, day } = calendarDate;
    onDateSelected(new Date(year, month - 1, day));
  }, [onDateSelected, calendarDate]);

  return (
    <td {...cellProps} aria-hidden={isOutsideVisibleRange || isDisabled}>
      <UnstyledButton
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange || isOutsideMonth}
        data-name={`calendarCell-${props.date.toString()}`}
        onClick={onClick}
        tabIndex={isDisabled || isOutsideVisibleRange || isOutsideMonth ? -1 : 0}
        sx={{
          width: "42px",
          height: "42px",
          ...(isLastMinute && !isSelected ? { padding: "1px" } : {}),
        }}
      >
        <div
          className={getCSSClassName({
            isSelected,
            isSelectionStart,
            isSelectionEnd,
            isInteractive,
            isLastMinute: isLastMinute && !isSelected && isInteractive,
          })}
        >
          {formattedDate}
        </div>
      </UnstyledButton>
    </td>
  );
}
