import * as logClient from "@classdojo/log-client";
import deployGroup from "./deployGroup";
import { isDev, isProd, isTesting } from "./env";
import { setLogEventFunction } from "@classdojo/web/utils/logEvent";
import { EventRecorder } from "./devTools";

if ((isProd && typeof window !== "undefined") || Config.cypressTest) {
  window.appVersion = Config.appVersion;

  logClient.init({
    env: isProd ? "prod" : "testing",
    deployGroup,
    site: "marketplace",
    buildNumber: Config.buildNumber,
    appVersion: Config.appVersion,
  });
} else if (isDev) {
  logClient.init({ env: "dev", site: "marketplace" });
} else if (isTesting) {
  logClient.init({ env: "testing", disabledForTest: true, site: "marketplace" });
}

EventRecorder.start();

setLogEventFunction(logClient.logEvent);
