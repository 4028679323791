const DAYS_NAMES_CONST = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"] as const;

export type DAY_KEYS = (typeof DAYS_NAMES_CONST)[number];

export const DAYS_NAMES = [...DAYS_NAMES_CONST] as DAY_KEYS[];

type DAY_VALUE = {
  isAvailable: boolean;
  time: string;
};

export const DAYS_SUBSCRIBE: Record<DAY_KEYS, DAY_VALUE> = {
  sunday: {
    isAvailable: false,
    time: "",
  },
  monday: {
    isAvailable: false,
    time: "",
  },
  tuesday: {
    isAvailable: false,
    time: "",
  },
  wednesday: {
    isAvailable: false,
    time: "",
  },
  thursday: {
    isAvailable: false,
    time: "",
  },
  friday: {
    isAvailable: false,
    time: "",
  },
  saturday: {
    isAvailable: false,
    time: "",
  },
};
