import { useCallback, useMemo, useState } from "react";
import { FieldErrors, FieldPath, FieldPathValue, FieldValues, UseFormReturn } from "react-hook-form";

type FieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue = FieldPathValue<TFieldValues, TName>,
> = {
  form: UseFormReturn<TFieldValues>;
  name: TName;
  render: (props: {
    value: TValue;
    onBlur: () => void;
    onChange: (value: TValue) => void;
    error?: FieldErrors<TFieldValues>[TName];
  }) => JSX.Element;
  fieldMayBeAutofilled?: boolean;
};
const Field = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  render,
  form,
  name,
  fieldMayBeAutofilled = false, // things like email may be provided by password mangers, never get blurred
}: FieldProps<TFieldValues, TName>) => {
  const value = form.getValues(name);
  const onChange = useCallback((v: typeof value) => form.setValue(name, v, { shouldValidate: true }), [form, name]);
  const [blur, setBlur] = useState(false);
  const onBlur = useCallback(() => setBlur(true), []);
  const error = blur || fieldMayBeAutofilled ? form.formState.errors[name] : undefined;
  return useMemo(() => render({ value, onBlur, onChange, error }), [error, onBlur, onChange, render, value]);
};

export default Field;
