import DayOffList from "src/pageComponents/TutorDayOff/DayOffList";
import AddDayOff from "src/pageComponents/TutorDayOff/AddDayOff";
import DayOffConfirmation from "src/pageComponents/TutorDayOff/DayOffConfirmation";
import useDayOffFormState, { DayOffFormStateContext } from "./useOOOForm";
import TooManyDaysOff from "src/pageComponents/TutorDayOff/DayOffConfirmation/TooManyDaysOff";

export default function () {
  const formState = useDayOffFormState();
  const { step } = formState;

  return (
    <DayOffFormStateContext.Provider value={formState}>
      {step === 0 && <DayOffList />}
      {step === 1 && <AddDayOff />}
      {step === 2 && <DayOffConfirmation />}
      {step === 3 && <TooManyDaysOff />}
    </DayOffFormStateContext.Provider>
  );
}
