/* eslint-disable no-console */
import escapeHtml from "escape-html";
import type { i18n } from "i18next";
import mapValues from "lodash/mapValues";
import { isDev } from "../../utils/env";

export type TranslatedString = string & { __translatedString: true };

let warningsHidden = false;
export function hideI18NKeysWarnings(val: boolean) {
  warningsHidden = val;
}

export function makeTranslate(i18n: i18n) {
  return function translate(
    str: string,
    fallback?: Record<string, unknown> | string,
    subs?: Record<string, unknown>,
  ): TranslatedString {
    if (subs == null && typeof fallback === "object") {
      subs = fallback;
      fallback = undefined;
    }

    if (isDev && !str && !warningsHidden) {
      console.groupCollapsed("🔑 I18N Missing Key");
      // eslint-disable-next-line no-console
      console.warn(`Missing translation key`);
      console.groupEnd();
      return str as TranslatedString;
    }

    if (!warningsHidden && isDev && !i18n.exists(str)) {
      console.groupCollapsed("🔑 I18N Invalid Key");
      // eslint-disable-next-line no-console
      console.warn(`Invalid translation key: ${str}`);
      console.groupEnd();
    }

    let options = subs;
    if (fallback) {
      options = {
        ...subs,
        defaultValue: fallback,
      };
    }

    // even with options.defaultValue provided, i18n.t() will return null if the internal translator
    // hasn't been init'd (e.g. if resource files fail to load). in that case, manually apply
    // fallback if possible.
    const output = options ? i18n.t(str, options) : i18n.t(str);
    if (output == null && typeof fallback === "string") {
      return (fallback || "") as TranslatedString;
    } else {
      return (output || "") as TranslatedString;
    }
  };
}

/**
 * Allows the first argument to be an object, like we use in components.
 *
 * If you pass an object, it looks like this:
 *
 * str: string. looks like "dojo.common:some.key"
 * fallback: string. value to use if `str` key couldn't be found in the currently loaded language. supports __underscore__ interpolation
 * subs: object. substitutions to use for __underscore__ interpolation
 *
 * htmlStr: string. similar to `str`, except the translated version includes inlined HTML. Only works if you use the <T /> component to render the translation.
 * text: string. this is a pass-through, meaning the text is already translated, but, for some reason, you must go through another call of translate()
 */
type Transatable = {
  fallback?: string;
  subs?: Record<string, string | number>;
} & (
  | {
      str: string;
    }
  | {
      htmlStr: string;
    }
);

export function makeBackwardsCompatibleTranslate(i18n: i18n) {
  const translate = makeTranslate(i18n);

  function backwardsCompatibleTranslate(
    firstArg: string | Transatable = "",
    ...rest: (string | Record<string, unknown> | undefined)[]
  ): TranslatedString {
    if (typeof firstArg !== "object") {
      return translate(firstArg, ...rest);
    } else {
      const str = "str" in firstArg ? firstArg.str : firstArg.htmlStr;
      const subs = "htmlStr" in firstArg && firstArg.subs ? mapValues(firstArg.subs, escapeHtml) : firstArg.subs;
      return translate(str, firstArg.fallback, subs);
    }
  }

  return (firstArg: string | Transatable = "", ...rest: (string | Record<string, unknown> | undefined)[]) => {
    const translation = backwardsCompatibleTranslate(firstArg, ...rest);
    if (!translation && isDev) {
      throw new Error(
        `Missing translation for ${firstArg} (is translate being called before translations are loaded?)`,
      );
    }
    return translation;
  };
}
