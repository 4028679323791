import CalendarWithNumber from "./CalendarWithNumber";
import DeleteOOOButton from "./DeleteOOOButton";
import css from "./DayOffRow.module.css";

export type DayOffInfo = {
  dayOffType: "partial" | "full" | "multiple";
  formattedDate: string;
  formattedHours: string;
  dayOffId: number;
  dayOfMonth: number;
  startOfMonth: Date;
  startTime: Date;
};

export type DayOffByDay =
  | { type: "partial"; daysOff: DayOffInfo[]; dayOfMonth: number; formattedDate: string }
  | { type: "full" | "multiple"; dayOff: DayOffInfo; dayOfMonth: number; formattedDate: string };

export default function ({
  dayOffByDay,
  dayOffIdToBeDeleted,
  deleteDayOff,
  onDeleteDayOffCanceled,
  onDayOffSelectedToBeDeleted,
  isMobile,
}: {
  key: number;
  dayOffByDay: DayOffByDay;
  dayOffIdToBeDeleted: number | undefined;
  deleteDayOff: (dayOffId: number) => void;
  onDeleteDayOffCanceled: () => void;
  onDayOffSelectedToBeDeleted: (dayOffId: number) => void;
  isMobile: boolean;
}) {
  const onClickOutsideDeleteButton = () => {
    if (isMobile) {
      onDeleteDayOffCanceled();
    }
  };

  const onMouseEventsProps = (dayOffId: number) => ({
    onMouseEnter: () => {
      if (!isMobile) {
        onDayOffSelectedToBeDeleted(dayOffId);
      }
    },
    onMouseLeave: () => {
      if (!isMobile) {
        onDeleteDayOffCanceled();
      }
    },
    onClick: () => {
      if (isMobile) {
        onDayOffSelectedToBeDeleted(dayOffId);
      }
    },
  });

  const isPartialDayOff = dayOffByDay.type === "partial";

  return (
    <div
      className={css.dayOffClicker}
      data-name="dayOffRow"
      sx={{ width: "100%" }}
      {...(!isPartialDayOff ? onMouseEventsProps(dayOffByDay.dayOff.dayOffId) : undefined)}
    >
      <div className={css.dayOffWrapper}>
        <CalendarWithNumber dateOfMonth={dayOffByDay.dayOfMonth} multipleDays={dayOffByDay.type === "multiple"} />
        <div className={css.dayOffDetails}>
          <div className={css.dayOffDate}>
            <div>{dayOffByDay.formattedDate}</div>
            {!isPartialDayOff && dayOffIdToBeDeleted === dayOffByDay.dayOff.dayOffId && (
              <DeleteOOOButton
                onDelete={() => deleteDayOff(dayOffByDay.dayOff.dayOffId)}
                onClickOutside={onClickOutsideDeleteButton}
              />
            )}
          </div>
          {isPartialDayOff &&
            dayOffByDay.daysOff.map((dayOff, idx) => (
              <div key={idx} className={css.dayOffTime} {...onMouseEventsProps(dayOff.dayOffId)}>
                {dayOff.formattedHours}
                {dayOffIdToBeDeleted === dayOff.dayOffId && (
                  <DeleteOOOButton
                    onDelete={() => deleteDayOff(dayOff.dayOffId)}
                    onClickOutside={onClickOutsideDeleteButton}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
