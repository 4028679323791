import { formatWithPattern } from "src/utils/dates";

// Extracted out of Moment:
const US_TIME_ZONES = [
  "America/Adak",
  "America/Anchorage",
  "America/Boise",
  "America/Chicago",
  "America/Denver",
  "America/Detroit",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Los_Angeles",
  "America/Menominee",
  "America/Metlakatla",
  "America/New_York",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Phoenix",
  "America/Sitka",
  "America/Yakutat",
  "Pacific/Honolulu",
];

export const getDisplayValue = (value: string) =>
  `${value.replace("_", " ")} (${formatWithPattern(new Date(), "z", { timeZone: value })})`;

export const getTimezones = (include?: string) => {
  const timezones = US_TIME_ZONES.filter((timezone) => timezone.split("/").length < 3);
  if (include && !timezones.includes(include)) {
    timezones.push(include);
  }
  const timezoneOptions = timezones.map((timezone) => ({ label: getDisplayValue(timezone), value: timezone }));

  return timezoneOptions;
};
