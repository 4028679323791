import { useMemo } from "react";

const TIME_SELECT_INTERVAL = 30;

const useTimeSelectIntervals = (filters: string[] = []) => {
  return useMemo(() => {
    const times = [];
    let tt = 0;
    const ap = ["AM", "PM"];

    for (let i = 0; tt < 24 * 60; i++) {
      const hh = Math.floor(tt / 60);
      const mm = tt % 60;
      times[i] = `${`${hh == 12 ? 12 : hh % 12 == 0 ? "12" : hh % 12}`.slice(-2)}:${`0${mm}`.slice(-2)} ${
        ap[Math.floor(hh / 12)]
      }`;
      tt = tt + TIME_SELECT_INTERVAL;
    }

    return times.filter((time: string) => !filters.includes(time));
  }, [filters]);
};

export default useTimeSelectIntervals;
