import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/upload"]["get"];
type Params = Endpoint["parameters"]["query"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export default (extension: string) => {
  const params: Params = { extension };
  return monolithApiClient.get<Response>(`/upload`, { params });
};
