import { UnstyledButton, WithClickOutside } from "@classdojo/web";
import { IconTrash } from "@web-monorepo/dds-icons";
import { useState } from "react";
import css from "./DeleteOOOButton.module.css";

export default function ({ onDelete, onClickOutside }: { onDelete: () => void; onClickOutside: () => void }) {
  const [isConfirmed, setIsConfirmed] = useState(false);

  return (
    <WithClickOutside
      onClickOutside={() => {
        setIsConfirmed(false);
        onClickOutside();
      }}
    >
      <div className={css.oooButtonWrapper}>
        {isConfirmed ? (
          <UnstyledButton onClick={onDelete} data-name="tutorDeleteOOO" className={css.delete}>
            Delete OOO?
          </UnstyledButton>
        ) : (
          <UnstyledButton
            onClick={() => setIsConfirmed(true)}
            data-name="tutorDeleteOOOToBeConfirmed"
            className={css.confirm}
          >
            <IconTrash size="s" />
          </UnstyledButton>
        )}
      </div>
    </WithClickOutside>
  );
}
