import RangeCalendar from "./RangeCalendar";
import { DDSButton, DDSTextField } from "@web-monorepo/dds";
import css from "./index.module.css";
import HoursPicker from "./HoursPicker";
import noop from "lodash/noop";
import { useDayOffFormStateContext } from "old/src/pageComponents/Teachers/Availability/useOOOForm";
import { formatDateTzAgnostic } from "src/pageComponents/TutorDayOff/utils";

const ButtonsRow = () => {
  const { resetFormState, goToNextStep, selectedDayOffs } = useDayOffFormStateContext();
  return (
    <div className={css.buttonsWrapper}>
      <DDSButton onClick={resetFormState} variant="secondary" data-name="backButtonConfirmDayOffCreation" width="fill">
        Cancel
      </DDSButton>
      <DDSButton
        onClick={goToNextStep}
        data-name="confirmDayOffCreation"
        width="fill"
        disabled={selectedDayOffs.length === 0}
      >
        Save
      </DDSButton>
    </div>
  );
};

export default function () {
  const formState = useDayOffFormStateContext();

  const {
    calendarRangeState: {
      startDate,
      endDate,
      minDate,
      calendarVisible,
      settingStartDate,
      onDateSelected,
      onStartInputClick,
      onEndInputClick,
      hideCalendar,
      isSameDay,
    },
  } = formState;

  const focusedRangeInputClassName = `${css.rangeInput} ${css.focus}`;

  return (
    <>
      <h2 className={css.subTitle}>Add OOO</h2>
      <div className={css.oooRange}>
        <div className={settingStartDate && calendarVisible ? focusedRangeInputClassName : css.rangeInput}>
          <DDSTextField
            data-name="teacher.marketplace.availability.startDate"
            label="Starts on"
            onClick={onStartInputClick}
            onChange={noop}
            placeholder="First OOO day"
            size="medium"
            value={formatDateTzAgnostic(startDate)}
            variant="default"
          />
        </div>

        <div className={!settingStartDate && calendarVisible ? focusedRangeInputClassName : css.rangeInput}>
          <DDSTextField
            data-name="teacher.marketplace.availability.endDate"
            label="Ends on"
            onClick={onEndInputClick}
            onChange={noop}
            placeholder="Last OOO day"
            size="medium"
            value={formatDateTzAgnostic(endDate)}
            variant="default"
          />
        </div>

        {calendarVisible && (
          <div className={css.rangeCalendar}>
            <RangeCalendar
              minDate={minDate}
              months={2}
              value={{ start: startDate, end: endDate }}
              onDateSelected={onDateSelected}
              onClickOutside={hideCalendar}
              selectingStart={settingStartDate}
            />
          </div>
        )}
      </div>

      {isSameDay && <HoursPicker />}

      <ButtonsRow />
    </>
  );
}
