import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";
import { sanitizeId } from "src/utils/sanitizeId";

type Endpoint = paths["/api/marketplace/parent/session/{sessionId}"]["get"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export type ParentSessionData = Response;

export default (id: string) => {
  const sanitizedId = sanitizeId(id);
  return monolithApiClient.get<Response>(`/parent/session/${sanitizedId}`);
};
