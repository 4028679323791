import { createStyles } from "@mantine/core";
import { M_BREAKPOINT, S_BREAKPOINT, XS_BREAKPOINT } from "old/src/UILibrary/constants";
import { HEADER_HEIGHT } from "src/constants";

export default createStyles(() => ({
  wrapper: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    columnGap: "30px",
    margin: "auto",
    maxWidth: "1338px",

    [`@media (max-width: ${M_BREAKPOINT}px)`]: {
      gridTemplateColumns: "repeat(8, 1fr)",
    },

    [`@media (max-width: ${S_BREAKPOINT}px)`]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      columnGap: "24px",
    },

    [`@media (max-width: ${XS_BREAKPOINT}px)`]: {
      columnGap: "18px",
    },
  },

  progressBar: {
    width: "100vw",
    position: "absolute",
    left: 0,
    top: HEADER_HEIGHT,
    display: "none",
    zIndex: 100,
    [`@media (max-width: ${M_BREAKPOINT}px)`]: {
      display: "block",
    },
  },

  backButton: {
    position: "absolute",
    left: 40,
    top: 140,
    [`@media (max-width: ${M_BREAKPOINT}px)`]: {
      left: 18,
      top: 110,
    },
  },

  content: {
    marginTop: "64px",
    gridColumn: "4/10",

    [`@media (max-width: ${M_BREAKPOINT}px)`]: {
      marginTop: "32px",
      gridColumn: "2/8",
    },

    [`@media (max-width: ${S_BREAKPOINT}px)`]: {
      gridColumn: "1/3",
    },
  },

  title: {
    fontWeight: 800,
    fontSize: "30px",
    lineHeight: "36px",
    letterSpacing: "-0.35px",
    color: "#2C2A50",
    marginBottom: "12px",
  },

  contentText: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "-0.24px",
    color: "#2C2A50",
  },
}));
