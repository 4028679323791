import { showNotification } from "@mantine/notifications";
import axios, { AxiosError } from "axios";
import axiosRateLimit from "src/utils/AxiosRateLimiter";

const fetchClient = ({ prefixPath }: { prefixPath: string }) => {
  const defaultOptions = {
    baseURL: prefixPath,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-sign-attachment-urls": "true",
    },
    withCredentials: true,
    credentials: "same-origin",
  };

  return axios.create(defaultOptions);
};

function errorHandler(error: AxiosError) {
  const originalRequest = error.config;
  if (error?.code === "ERR_NETWORK" && originalRequest?.url !== "/logout") {
    showNotification({
      title: "Server is down.",
      message: "An error has occured. Please try again in a few minutes or you may send us a message.",
      color: "red",
    });
  }

  return Promise.reject(error);
}

const monolithApiClient = fetchClient({
  prefixPath: "/api/marketplace",
});

monolithApiClient.interceptors.request.use((config) => {
  if (window.usePrecanary) {
    config.params = { ...config.params, usePrecanary: "true" };
  }
  return config;
});

axiosRateLimit(monolithApiClient, errorHandler);

const featureSwitchApiClient = fetchClient({
  prefixPath: "/api",
});

axiosRateLimit(featureSwitchApiClient, errorHandler);

export { featureSwitchApiClient, monolithApiClient };
