import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";
import useMutation from "src/hooks/useMutation";
import { BookingType } from "old/src/store";
import { BACKGROUND_QUESTIONS } from "src/utils/studentBackground";

type Endpoint = paths["/api/marketplace/parent/background"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

const putParentBackground = async (body: Body) => await monolithApiClient.post<Response>(`/parent/background`, body);

const useParentBackgroundMutation = () => {
  return useMutation(putParentBackground);
};

export const getBackground = (booking: BookingType) => {
  const background = [];

  if (booking && (booking.learningNeeds || booking.interests || booking.accommodations?.length)) {
    background.push({
      question: BACKGROUND_QUESTIONS.learningNeeds,
      answer: booking?.learningNeeds ?? [],
    });
    background.push({
      question: BACKGROUND_QUESTIONS.interests,
      answer: booking?.interests ?? [],
    });
    background.push({
      question: BACKGROUND_QUESTIONS.accommodations,
      answer: booking?.accommodations ?? [],
    });
    background.push({
      question: BACKGROUND_QUESTIONS.specializations,
      answer: booking?.specializations ?? [],
    });
    background.push({
      question: BACKGROUND_QUESTIONS.goals,
      answer: booking?.goals ?? [],
    });
  }

  return background;
};

export default useParentBackgroundMutation;
