import useWatch from "@classdojo/web/hooks/useWatch";
import { BodyText, Button, HeadlineText, theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { showErrorNotification } from "old/src/utils";
import useMutation from "src/hooks/useMutation";
import { Link, useParams } from "react-router-dom";
import consentSubscriptionSharing from "src/api/parent/consentSubscriptionSharing";
import { logTutorEvent } from "src/utils/log";

const ConsentConfirmation = () => {
  const { stripeSubscription } = useParams();
  const consent = useMutation(consentSubscriptionSharing);

  useWatch(stripeSubscription, () => {
    if (stripeSubscription) {
      consent.mutate(
        { stripeSubscription },
        {
          onSuccess: () => {
            logTutorEvent("parent.marketplace.consent_confirmation_page_completed");
          },
          onError: () => {
            logTutorEvent("parent.marketplace.consent_confirmation_page_failed");
            showErrorNotification("Error sharing subscription");
          },
        },
      );
    }
  });

  return (
    <div sx={sxWrapper}>
      <img width="auto" src="parents/consent/header.svg" alt="monster icon" />
      <div sx={sxTitleWrapper}>
        <HeadlineText as="h2" sx={sxTitle}>
          Thanks for confirming!
        </HeadlineText>
        <BodyText kind="tertiary">We will share your kid’s assessment with their classroom teacher.</BodyText>
      </div>
      <Link to="/">
        <Button sx={sxButton} data-name="parent_consent:go_to_dashboard">
          Go to dashboard
        </Button>
      </Link>
    </div>
  );
};

export default ConsentConfirmation;

const sxWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  width: "552px",
  margin: "auto",
  paddingTop: "dt_xxl",
  gap: "dt_l",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    width: "100%",
    paddingTop: "0",
    paddingBottom: "dt_xxl",
  },

  a: { textDecoration: "none" },
};

const sxTitle: ThemeUIStyleObject = {
  fontWeight: "800",
  fontSize: "28px",
  lineHeight: "36px",
  letterSpacing: "-0.2px",
};

const sxTitleWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: "dt_xs",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginRight: "dt_m",
    marginLeft: "dt_m",
  },
};

const sxButton: ThemeUIStyleObject = {
  width: "324px",
  height: "62px",
  margin: "auto",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    width: "222px",
    marginBottom: "dt_xl",
  },
};
