import React from "react";
import { generatePath, Link, Navigate, useLocation } from "react-router-dom";

export function components<
  Path extends string,
  Params extends Record<string, any>,
>() {
  return {
    Link: function <P extends Path>(
      props: {
        to: P;
        search?: string;
        hash?: string;
        className?: string;
        children?: React.ReactNode;
        ["data-name"]?: string;
        experiments?: string[];
      } & (P extends keyof Params ? { params: Params[P] } : {})
    ) {
      const path = generatePath(
        props.to,
        "params" in props ? props.params : ({} as any)
      );
      const { search, hash } = props;
      return (
        <Link
          to={{ pathname: path, search: search, hash: hash }}
          className={props.className}
          data-name={props["data-name"]}
          data-experiments={props.experiments}
        >
          {props.children}
        </Link>
      );
    },
    Navigate: function <P extends Path>(
      props: {
        to: P;
        hash?: string;
        replace?: boolean;
      } & (
        | { search?: string; preserveSearch?: false }
        | { search?: never; preserveSearch: true }
      ) &
        (P extends keyof Params ? { params: Params[P] } : {})
    ) {
      const path = generatePath(
        props.to,
        "params" in props ? props.params : ({} as any)
      );
      const { search, hash, preserveSearch } = props;
      const currentLocation = useLocation();
      return (
        <Navigate
          to={{
            pathname: path,
            search:
              search ?? (preserveSearch ? currentLocation.search : undefined),
            hash: hash,
          }}
          replace={props.replace}
        />
      );
    },
  };
}
