import { memo } from "react";
import { Link } from "react-router-dom";
import { Image } from "UILibrary";

const Logo = ({
  href = "/",
  onClick,
  className,
  noText = false,
}: {
  href?: string;
  onClick?: () => void;
  className?: string;
  noText?: boolean;
}) => {
  return (
    <Link to={href} aria-label="ClassDojo">
      <Image
        onClick={onClick && onClick}
        width={noText ? 56 : 156}
        height={46}
        fit="contain"
        src={noText ? "new-classdojo-logo-notext.svg" : "new-classdojo-logo.svg"}
        alt="ClassDojo Logo"
        className={className}
      />
    </Link>
  );
};

export default memo(Logo);
