import { IconSettingsFilled } from "@web-monorepo/dds-icons";
import { UnstyledButton } from "@classdojo/web";
import { useManualFeatureSwitch } from "src/hooks/useFeatureSwitches";
import useBoolean from "src/hooks/useBoolean";
import OutOfOfficeSlideSheet from "./OutOfOfficeSlideSheet";
import { logTutorEvent } from "src/utils/log";

const OutOfOfficeButton = () => {
  const { data: isNewOutofOfficeEnabled } = useManualFeatureSwitch("web_marketplace_ooo_oct2024");
  const { isTrue: mounted, setTrue: open, setFalse: close } = useBoolean(false);

  if (isNewOutofOfficeEnabled !== "test") {
    return null;
  }

  const openDayOffSlideSheet = () => {
    logTutorEvent("marketplace.tutor.day_off_open_slidesheet_clicked");
    open();
  };

  return (
    <>
      <UnstyledButton
        data-name=""
        type="button"
        sx={{ display: "flex", alignItems: "center", gap: "dt_xs", color: "dt_content_secondary", cursor: "pointer" }}
        onClick={openDayOffSlideSheet}
      >
        <IconSettingsFilled size="m" />
        <div>Out of office</div>
      </UnstyledButton>
      <OutOfOfficeSlideSheet mounted={mounted} close={close} />
    </>
  );
};

export default OutOfOfficeButton;
