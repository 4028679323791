import * as React from "react";
import T from "../../../components/misc/T";
import { theme } from "../../../nessie";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { ErrorBannerValue, ErrorBannerValues } from "../errorBanner";
import { useSelector } from "react-redux";
import { selectFinishedLoadingLocale } from "../../i18n";

declare const global: {
  errorBannerAllowed?: boolean;
};

type GoHomeProps = {
  href: string;
  children?: React.ReactNode;
  sx?: ThemeUIStyleObject;
};

const GoHome = ({ href, children, sx }: GoHomeProps) => {
  const handleClick = () => {
    window.location.assign(href);
    window.location.reload();
  };

  return (
    <a sx={sx} onClick={handleClick} href={href} data-name="error_banner:go_home_a">
      {children}
    </a>
  );
};

type ErrorBannerProps = {
  errorType: ErrorBannerValue;
  href?: string;
  useIsLocaleLoaded: () => boolean;
};

const useDefaultIsLocaleLoaded = () => useSelector(selectFinishedLoadingLocale);

// eslint-disable-next-line complexity
const ErrorBanner = ({ errorType, href = "/", useIsLocaleLoaded }: ErrorBannerProps): JSX.Element => {
  // The <T> component will not render a fallback if using the component's prop that
  // allows for templating the translation string/fallback and translations aren't loaded.
  // The "loggedOut" error type currently uses templating via the components prop and will
  // not render anything before translations load. Ideally our app is better structured so
  // that we don't end up in this case, but this is causing terrible UX to some users so the
  // interim fix is to render the "unknown" banner until we have translations loaded.
  const isLocaleLoaded = useIsLocaleLoaded();
  // IMPORTANT: Add any errorTypes here that use the components prop in the <T> component.
  if (!isLocaleLoaded && errorType === ErrorBannerValues.loggedOut) errorType = ErrorBannerValues.unknown;

  if (Config.nodeEnv === "test" && !global.errorBannerAllowed) {
    setTimeout(() => {
      throw new Error(`Should not show error banner in tests. Error type was: ${errorType}`);
    }, 0);
  }

  let contents;
  if (errorType === ErrorBannerValues.rateLimit) {
    contents = (
      <span sx={messageStyle}>
        <T
          fallback="Whoops! Looks like you're making too many requests too quickly. Please wait 10 seconds before continuing to use the site."
          str="dojo.common:error.api_too_many_requests"
        />
      </span>
    );
  } else if (errorType === ErrorBannerValues.apiDown) {
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error.api_trouble_connecting"
          fallback="Whoops! Looks like we're having trouble connecting. Please "
        />
        <GoHome href={href}>
          <T sx={refreshButtonStyle} str="dojo.common:error.api_error_refresh_page_link" fallback="refresh the page" />
        </GoHome>
        <T str="dojo.common:end_of_sentence_char" fallback="." />{" "}
        <T
          str="dojo.common:error.api_if_problem_persists"
          fallback="If the problem persists, you can check the status of our servers "
        />
        <a
          data-name="error_banner:trouble_connecting:error_api_if_problem_persists_link_text"
          target="_blank"
          rel="noopener noreferrer"
          href="http://status.classdojo.com"
        >
          <T sx={refreshButtonStyle} str="dojo.common:error.api_if_problem_persists_link_text" fallback="here" />
        </a>
        <T str="dojo.common:end_of_sentence_char" fallback="." />
      </span>
    );
  } else if (errorType === ErrorBannerValues.unknown) {
    // IMPORTANT: DO NOT USE <T fallback={...} components={...} /> in the unknown error banner.
    // See comment at top of component.
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error.api_something_went_wrong"
          fallback="Whoops! Looks like something went wrong. Please "
        />
        <GoHome href={href}>
          <T sx={refreshButtonStyle} str="dojo.common:error.api_error_refresh_page_link" fallback="refresh the page" />
        </GoHome>
        <T str="dojo.common:end_of_sentence_char" fallback="." />
      </span>
    );
  } else if (errorType === ErrorBannerValues.homeIslands) {
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error.api_something_went_wrong"
          fallback="Whoops! Looks like something went wrong. Please "
        />
        <GoHome href={href}>
          <T sx={refreshButtonStyle} str="dojo.common:error.api_error_refresh_page_link" fallback="refresh the page" />
        </GoHome>
        <T str="dojo.common:end_of_sentence_char" fallback="." />
      </span>
    );
  } else if (errorType === ErrorBannerValues.homeIslandsLoggedOut) {
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error:oops_something_went_wrong"
          fallback="Oops, something went wrong, please try again later"
        />
      </span>
    );
  } else if (errorType === ErrorBannerValues.loggedOut) {
    // Hack to get this double-span formulation to work with the ideal sentence-based <T> way of doing this:
    // (<T> only supports single-depth components in this mechanism:)
    const C = ({ children }: { children?: React.ReactNode }) => (
      <GoHome href={href} key="sign_in">
        <span sx={refreshButtonStyle}>{children}</span>
      </GoHome>
    );

    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:logged_out_or_timed_out"
          fallback="Whoops! It looks like your session has timed out. <0>Click here to sign in again</0>."
          components={[<C key="sign_in" />]}
        />
      </span>
    );
  } else if (errorType === ErrorBannerValues.apiDownLoadShedding) {
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error.api_down_load_shedding"
          fallback="We're so sorry - currently ClassDojo is seeing higher than normal usage. We're working hard to resolve this, and you can check our status "
        />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://status.classdojo.com"
          data-name="error_banner:api_down_load_shedding:error_api_if_problem_persists_link_text"
        >
          <T sx={refreshButtonStyle} str="dojo.common:error.api_if_problem_persists_link_text" fallback="here" />
        </a>
        <T str="dojo.common:end_of_sentence_char" fallback="." />
      </span>
    );
  } else if (errorType === ErrorBannerValues.moduleLoadError) {
    contents = (
      <span sx={messageStyle}>
        <T str="dojo.common:error.moduleLoadError" fallback="We're having trouble loading. Please " />
        <GoHome href={href}>
          <T sx={refreshButtonStyle} str="dojo.common:refresh_the_page" fallback="refresh the page" />
        </GoHome>
        <T str="dojo.common:end_of_sentence_char" fallback="." />
      </span>
    );
  } else if (errorType === ErrorBannerValues.pubnub) {
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error.pubnub"
          fallback="We're having difficulty syncing your actions to other devices. Please "
        />
        <GoHome href={href}>
          <T sx={refreshButtonStyle} str="dojo.common:refresh_the_page" fallback="refresh the page" />
        </GoHome>
        <T str="dojo.common:end_of_sentence_char" fallback="." />
      </span>
    );
  } else if (errorType === ErrorBannerValues.requestBlocked) {
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error.requestBlocked"
          fallback="Oh no! ClassDojo can't be accessed on this device. Check with your admin to help unblock it."
        />
      </span>
    );
  } else if (errorType === ErrorBannerValues.studentsNotInClass) {
    contents = (
      <span sx={messageStyle}>
        <T
          str="dojo.common:error.students_not_in_class"
          fallback="You tried to give points to one or more students that are not in this class."
        />{" "}
        <GoHome href={window.location.href}>
          <T sx={refreshButtonStyle} str="dojo.common:refresh_the_page" fallback="refresh the page" />
        </GoHome>
      </span>
    );
  }

  return (
    <div data-name="globalErrorBanner" sx={containerStyle} role="alert" aria-live="polite">
      {contents}
    </div>
  );
};

let _setBannerProps: React.Dispatch<React.SetStateAction<ErrorBannerStateProps>>;

type ErrorBannerContainerProps = {
  initialUrl?: string;
  useIsLocaleLoaded?: () => boolean;
};

type ErrorBannerStateProps = {
  type: ErrorBannerValue | null;
  refreshUrl?: string;
};

export default function ErrorBannerContainer({
  initialUrl,
  useIsLocaleLoaded = useDefaultIsLocaleLoaded,
}: ErrorBannerContainerProps) {
  const [bannerProps, setBannerProps] = React.useState<ErrorBannerStateProps>({
    type: null,
    refreshUrl: initialUrl,
  });
  _setBannerProps = setBannerProps;
  return bannerProps.type ? (
    <ErrorBanner errorType={bannerProps.type} href={bannerProps.refreshUrl} useIsLocaleLoaded={useIsLocaleLoaded} />
  ) : null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const showErrorBanner = (errorType: ErrorBannerValue, refreshUrl?: string) => {
  if (!_setBannerProps) return;
  _setBannerProps({ type: errorType, refreshUrl });
};

const containerStyle: ThemeUIStyleObject = {
  position: "fixed",
  left: 0,
  right: 0,
  textAlign: "center",
  padding: "dt_m",
  backgroundColor: "dt_background_danger",
  zIndex: theme.zIndexes.globalErrorBanner,
  transition: "top .5s ease",
  top: "0px",
} as const;

const messageStyle: ThemeUIStyleObject = {
  color: "dt_content_danger",
  fontWeight: 600,
} as const;

const refreshButtonStyle: ThemeUIStyleObject = {
  color: "dt_content_accent",
  position: "relative",
  border: "none",
  backgroundColor: "transparent",
  padding: "0",
} as const;
