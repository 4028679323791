import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/review"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export default function reviewClass(body: Body) {
  return monolithApiClient.post<Response>("/review", body);
}
