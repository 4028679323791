import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/enrollment/reschedule"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

export default (body: Body) => {
  return monolithApiClient.post<Response>(`/enrollment/reschedule`, body);
};
