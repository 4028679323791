/* eslint-disable @web-monorepo/marketplace-dates-imports -- this is the shim in question! */
import {
  parse,
  addHours,
  parseWithPattern as datesParseWithPattern,
  formatWithPattern as datesFormatWithPattern,
  isSame,
  startOf,
  subDays,
  overrideLocalTimezone,
  getLocalTimezoneOverride,
  toDate,
  enableGlobalTimezoneSupport,
  getTimezoneOffset,
} from "@web-monorepo/dates";

export * from "@web-monorepo/dates";

enableGlobalTimezoneSupport();

export function setDefaultTimezone(timezone: string | null) {
  overrideLocalTimezone(timezone);
}

export function getDefaultTimezone() {
  return getLocalTimezoneOverride() ?? getDeviceTimezone();
}

export function formatWithPattern(date: Date, pattern: string, options?: { timeZone?: string }): string {
  return datesFormatWithPattern(date, pattern === "PP" ? "MMMM dd, yyyy" : pattern, options);
}

export function formatDayOffRange(startTs: string, endTs?: string): string {
  const startMoment = parse(startTs);

  const startFormatted = formatWithPattern(startMoment, "MMMM d, yyyy");

  if (!endTs) return startFormatted;

  const endMoment = parse(endTs);
  let endFormatted;

  if (isSame(endMoment, startOf(endMoment, "day"))) {
    endFormatted = formatWithPattern(subDays(endMoment, 1), "MMMM d, yyyy");
  } else {
    endFormatted = formatWithPattern(endMoment, "MMMM d, yyyy");
  }

  return endFormatted !== startFormatted ? `${startFormatted} - ${endFormatted}` : startFormatted;
}

export const getHourWithDiffHour = (hour: string, tzDiffHours: number) => {
  /*
    Receive hour in format h:mm A, e.g.: 1:30 PM
    limited hours from 12:00 AM to 11:30 PM
  */
  return formatWithPattern(addHours(parseWithPattern(`${hour}`, ["h:mm a"]), tzDiffHours), "h:mm a");
};

function getDeviceTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getLocalizedDateTime = (date?: string, time?: string, timezone?: string) => {
  const timezoneToUse = timezone ?? getDefaultTimezone();

  const parsedDate = date
    ? datesParseWithPattern(date, ["PP", "MMMM dd yyyy", "MMMM dd, yyyy"], new Date(), timezoneToUse)
    : new Date();

  return time ? datesParseWithPattern(time, ["h:mm a", "HH:mm"], parsedDate, timezoneToUse) : parsedDate;
};

export const parseWithPatternInZone = (date: string, pattern: string | string[], timezone: string) => {
  const unadjusted = datesParseWithPattern(date, pattern);
  return dateInZone(unadjusted, timezone);
};

function dateInZone(date: Date, timezone: string) {
  return toDate(
    `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
    {
      timeZone: timezone,
    },
  );
}

export const parseWithPattern = (
  date: string | undefined,
  pattern: string | string[],
  referenceDate?: Date,
  timeZone?: string,
): Date => {
  // This is how moment behaves, but is not ideal:
  if (typeof date === "undefined") return new Date(NaN);

  // interop for a common pattern that is _close_:
  if (pattern === "PP") pattern = ["PP", "MMMM dd yyyy", "MMMM dd, yyyy"];

  return datesParseWithPattern(date, pattern, referenceDate, timeZone ?? getDefaultTimezone() ?? undefined);
};

export const getOffsetFromTimezone = (targetTimeZone: string) => {
  const userTimezone = getDefaultTimezone();
  const userOffset = getTimezoneOffset(userTimezone) / 60_000;
  const deviceOffset = getTimezoneOffset(targetTimeZone) / 60_000;
  return (userOffset - deviceOffset) / 60;
};
