import { paths } from "@classdojo/ts-api-types/api";
import useMutation from "src/hooks/useMutation";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/password-reset"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

const triggerResetPassword = (body: Body) => monolithApiClient.post<Response>(`/password-reset`, body);

const useTriggerResetPasswordMutation = () => useMutation(triggerResetPassword);

export default useTriggerResetPasswordMutation;
