import ReactDOM from "react-dom/client";
// TODO: importing routes from router, with routes.tsx nextdoor is confusing and unnecessary, and is left as an exercise to the reader to sort out.
import { routes } from "./router";
import { RouterProvider, createHashRouter } from "react-router-dom";
import "src/utils/initialization";

const router = createHashRouter(routes);

const root = document.getElementById("root");
if (!root) throw new Error("couldn't find #root element");

function App() {
  return <RouterProvider router={router} />;
}

ReactDOM.createRoot(root).render(<App />);
