import { ErrorHandler, ERROR_CATCH_TYPE } from "@classdojo/web/pods/errorHandling";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import { isApiDownStatus } from "@web-monorepo/infra/responseHandlers";
import { AxiosError } from "axios";
import type { Response } from "superagent";
import { ErrorExcluder } from "@classdojo/web/pods/errorHandling/components/AppTopLevelErrorBoundary";
import { ErrorBannerValues } from "@classdojo/web/pods/errorHandling/errorBanner";

const isIgnorableBadRequest = (response?: Response) => {
  if (!response) return false;
  return (
    response.status === 400 &&
    [
      "Invalid id",
      "Invalid targetId",
      "Invalid classId",
      "ChannelId was invalid",
      "Invalid marketplaceFunnelUUId",
    ].includes(response.body?.error?.detail)
  );
};

const EXPECTED_ERROR_CODES = [
  "ERR_REVIEW_ALREADY_SENT",
  "ERR_MARKETPLACE_SESSION_ALREADY_STARTED",
  "ERR_SUBSCRIPTION_ALREADY_EXISTS",
  "ERR_SUBSCRIPTION_ALREADY_CANCELLED",
  "ERR_DUPLICATE_EMAIL",
  "ERR_MARKETPLACE_INVALID_LOGIN_CODE",
];

// TODO: Some of these are real errors that we should handle elsewhere.
export const ignorableMarketplaceErrors: Array<ErrorExcluder<AxiosError>> = [
  {
    checker: (error) => error.message.includes("You must initialize the component library with a `translate` function"),
    id: "marketplace-translate",
  },
  {
    checker: (error) => error.request?.status === 404 && error.request.responseURL?.includes("/user/email"),
    id: "marketplace-user-email",
  },
  {
    checker: (error) => error.request?.status === 401 && error.request.responseURL?.includes("/session"),
    id: "marketplace-session",
  },
  {
    checker: (error) => {
      const data = error.response?.data as { error: { code: string } } | undefined;
      return data ? EXPECTED_ERROR_CODES.includes(data.error?.code) : false;
    },
    id: "marketplace-expected-err-codes",
  },
  // CDN Cookielaw errors
  { checker: (error) => !!error.stack?.match(/cdn\.cookielaw\.org\S+\.js/), id: "marketplace-cookielaw" },
  // Swallowing errors from empty event attack
  {
    checker: (error) =>
      error.message.includes("Cannot read properties of undefined (reading 'target')") &&
      error.message.includes("at Element.addEventListener") &&
      error.message.includes("at Set.forEach"),
    id: "marketplace-empty-event",
  },
];

export const createWebErrorHandler = (userActionsHistory: UserActionsHistory) => {
  return new ErrorHandler({
    userActionsHistory,
    // eslint-disable-next-line complexity
    onError: (error, context, errorHandlerUtils) => {
      // These errors happen when request where blocked by a filtering service.
      // Let's just show an error banner and ignore it.
      if (
        (error.message.includes("Error returned in API call: 307") && error.message.includes("Request Blocked")) ||
        error.message.includes("Empty response from filtering service")
      ) {
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.requestBlocked);
        return;
      }

      if (
        error.message.includes("Failed to fetch dynamically imported module") ||
        error.message.includes("Importing a module script failed")
      ) {
        errorHandlerUtils.showErrorBanner(
          ErrorBannerValues.moduleLoadError,
          window.location.pathname + window.location.hash,
        );
        return;
      }

      const response = isApiError(error) ? error.response : undefined;

      // Send 400 bad request errors as warning (via logRequestError) and add a metric with type `frontend.badRequest`
      // Redirecting and ignoring the error here because the user probably entered a bad url, not an actionable error.
      if (isIgnorableBadRequest(response)) {
        errorHandlerUtils.logRequestErrorWithContext("badRequest", error);
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown, "/");
        return;
      }

      // Logged out errors are sent as warning (via logRequestError) and add a metric.
      // Redirecting and ignoring, not an actionable error.
      if (response && [401, 403].includes(response.status)) {
        // Log authentication/authorization failure as a warning in logs, and add a metric so alarms can be
        // triggered.
        errorHandlerUtils.logRequestErrorWithContext(response.status === 401 ? "auth.401" : "auth.403", error);
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown, "/#/login");
        return;
      }

      // Not found errors are sent as warnings (via logRequestError) and add a metric.
      // The app already knows how to handle a page not found error
      if (response && response.status === 404) {
        // Log not found errors as a warning in logs, and add a metric so alarms can be
        // triggered.
        errorHandlerUtils.logRequestErrorWithContext("request.404", error);
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown);
        return;
      }

      if (response && response.status === 429) {
        errorHandlerUtils.logRequestErrorWithContext("rateLimit", error);
        errorHandlerUtils.showErrorBanner(ErrorBannerValues.rateLimit);
        return;
      }

      // API down error
      if (response && isApiDownStatus(response.status)) {
        errorHandlerUtils.logRequestErrorWithContext("down", error);
        const statusCode = response.statusCode;
        const errorMessage = response.body?.error?.message || "";
        const isLoadSheddingError = errorMessage.toLowerCase().includes("load shedding");

        if (statusCode === 503 && isLoadSheddingError) {
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.apiDownLoadShedding);
        } else {
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.apiDown);
        }
        return;
      }

      // Ignore Zoom failure to redirect
      if (
        error.message.includes("o.response is undefined") &&
        userActionsHistory.getHistory().find((h) => h.includes("https://tutor.classdojo.com/#/tutor/meeting"))
      ) {
        return;
      }

      switch (context.catchType) {
        case ERROR_CATCH_TYPE.REACT_ERROR_BOUNDARY: {
          errorHandlerUtils.logExceptionWithContext(error);
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown);
          break;
        }
        case ERROR_CATCH_TYPE.UNHANDLED_REJECTION: {
          errorHandlerUtils.logExceptionWithContext(error);
          break;
        }
        case ERROR_CATCH_TYPE.MODULE_LOAD_ERROR: {
          errorHandlerUtils.showErrorBanner(
            ErrorBannerValues.moduleLoadError,
            window.location.pathname + window.location.hash,
          );
          break;
        }
        // Handles REDUX and SAGAS errors:
        default: {
          errorHandlerUtils.logExceptionWithContext(error);
          errorHandlerUtils.showErrorBanner(ErrorBannerValues.unknown);
        }
      }
    },
  });
};

const isApiError = (error: Error): error is Error & { response: Response } => "response" in error;
