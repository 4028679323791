import { theme as nessieTheme } from "@classdojo/web/nessie";

const colors = {
  ...nessieTheme.colors,
  dt_content_accent: "#7D40FF",
  dt_background_accent: "#F5EBFF",
  dt_border_active: "#7D40FF",
  dt_brand_classDojo: "#7D40FF",
};

const marketplaceTheme = {
  ...nessieTheme,
  colors,
  borders: {
    ...nessieTheme.borders,
    dt_active: `2px solid ${colors.dt_border_active}`,
  },
};

export default marketplaceTheme;
