import { createStyles, getStylesRef } from "@mantine/core";
import marketplaceTheme from "src/utils/marketplaceTheme";

export default createStyles((_theme, _params) => ({
  root: {
    [`&:hover .${getStylesRef("test")}`]: {
      borderColor: "#7174A0 !important",
    },
  },

  [`.${getStylesRef("testInput")}:focused .${getStylesRef("test")}`]: {
    borderColor: "red !important",
  },

  input: {
    ref: getStylesRef("testInput"),
    height: "64px",
    padding: "20px 24px",
    border: "2px solid #8689b8",
    borderRadius: "16px",
    color: "#2C2A50",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",

    ":hover": {
      borderColor: "#7174A0",
      [`.${getStylesRef("test")}`]: {
        borderColor: "#7174A0 !important",
      },
    },

    ":focus": {
      borderColor: marketplaceTheme.colors.dt_border_active,
    },

    "::placeholder": {
      color: "#8689b8",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
    },
  },

  rightSection: {
    ref: getStylesRef("test"),
    pointerEvents: "none",
    width: 64,
  },

  dropdown: {
    borderRadius: "16px",
  },

  item: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    borderRadius: "12px",
  },

  label: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
  },
}));
