import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/availability/subscription-conflicts"]["post"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export type SubscriptionConflict = Response;

export default (body: Body) => monolithApiClient.post<Response>(`/availability/subscription-conflicts`, body);
