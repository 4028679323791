import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/enrollment/{classId}/generatedAssessment"]["get"];
type Response = Endpoint["responses"][200]["content"]["application/json"];

export type GeneratedAssessment = Response;

export default function getGeneratedAssessment(classId: string) {
  return monolithApiClient.get<Response>(`/enrollment/${classId}/generatedAssessment`);
}
