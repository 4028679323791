import { monolithApiClient } from "src/configs/axios.config";
import { useQuery } from "@tanstack/react-query";
import { paths } from "@classdojo/ts-api-types";

type Endpoint = paths["/api/marketplace/parent/session/{sessionId}/applicable-for-free-trial"]["get"];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

const getIsApplicableForFreeTrial = async (sessionId: string) =>
  await monolithApiClient.get<Response>(`/parent/session/${sessionId}/applicable-for-free-trial`);

export default (sessionId?: string) => {
  return useQuery(["getIsApplicableForFreeTrial"], () => getIsApplicableForFreeTrial(sessionId!), {
    enabled: !!sessionId,
  });
};
