import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/enrollment/cancel"]["put"];
type Body = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200];

export default (body: Body) => {
  return monolithApiClient.put<Response>(`/enrollment/cancel`, body);
};
