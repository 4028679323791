import { createContext, HTMLAttributes, ReactNode, useMemo } from "react";

type Props<TValue = unknown> = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
  children: ReactNode;
  value: TValue[];
  onChange: (value: TValue[]) => void;
};

type ContextType<TValue = unknown> = {
  id: number;
  value: TValue[];
  onChange(value: TValue[]): void;
};

const getId = (() => {
  let currentId = 0;
  return () => currentId++;
})();

export const CheckboxGroupContext = createContext<ContextType>({
  id: 0,
  onChange: () => {},
  value: [],
});

// eslint-disable-next-line comma-spacing
const CheckboxGroup = <TValue,>({ children, onChange, value, ...props }: Props<TValue>) => {
  const id = useMemo(getId, []);
  return (
    <CheckboxGroupContext.Provider value={{ id, value, onChange }}>
      <div {...props}>{children}</div>
    </CheckboxGroupContext.Provider>
  );
};

export default CheckboxGroup;
