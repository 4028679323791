import { Link } from "react-router-dom";
import useStyles from "./styles/Privacy.styles";
import { Box, Text, Image, Divider } from "UILibrary";

const personalInformationLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
    personal information
  </a>
);
const learnersLink = () => (
  <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
    learners
  </a>
);

const serviceProvidersLink = () => (
  <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a>
);

const Privacy = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.forTeachers}>
      <Box className={classes.wrapper}>
        <Box className={classes.hero} sx={{ marginBottom: "dt_l" }}>
          <Box>
            <Text component="h1">Privacy Policy Highlights</Text>
            <Box mb={16} />
            <Text className={classes.subHeader} sx={{ color: "#7174A0" } as any}>
              Last Updated: January 9, 2023
            </Text>
            <br />
            <Text className={classes.subHeader} sx={{ color: "#7174A0" } as any}>
              Note - this is a previous version of our Privacy Policy document. For the most recent version, please
              visit <Link to="/privacy">https://tutor.classdojo.com/#/privacy</Link>
            </Text>
          </Box>
        </Box>

        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We believe you should always know what data we collect from you, how we use it, and that you should have
            meaningful control over both. We want to empower users to make the best decisions about the information they
            share with us. In fact, we made a{" "}
            <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
              short video
            </a>{" "}
            for children that highlights the most important details in our Privacy Policy that they should know about.
          </Text>
        </Box>

        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Our privacy policy is based on several core principles. These principles should not be considered a legal
            document — the full <Link to="#dojo-tutoring-privacy-policy">privacy policy</Link> below is — but they are
            what underlie the privacy policy.
          </Text>
        </Box>

        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Transparency and Choice:</b> We tell you what data we collect, why we collect it, and how it’s used,
              retained, and shared with others. We are transparent about our practices so that you can make meaningful
              choices about how your{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              is used. If we make a material change, we will provide prominent notice by posting a notice on our service
              or this website page and/or we will notify you by email (if you have provided an email address to us). See{" "}
              <Link to="#providing-transparency-rights">here</Link> for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Personal Information We Collect:</b> Please see <Link to="/transparency">this page</Link> where we
              detail the{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              we collect from each user type, including the sources from which the information was collected, the
              business purpose for which the information was collected, and the third parties with whom we share or
              disclose personal information for a business purpose. You can also find more details{" "}
              <Link to="#what-information-does-dojo-tutoring-collect">below</Link>.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>How We Use the Information We Collect:</b> We use the{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              we collect from{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learners
              </a>{" "}
              (or about learners from tutors or their parents) to provide and improve the Service, for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_0f871510-2886-46eb-9bb4-e7ae17080447">
                {" "}
                educational purposes
              </a>
              , security, and safety purposes, or as allowed by law. We will not require learners to provide more
              personal information than is reasonably necessary to participate in the Service. For additional
              information regarding our use of information collected from tutors and other users, see{" "}
              <Link to="#how-does-dojo-use-info">here</Link>.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>We Never Sell Personal Information:</b> We will never{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
                sell
              </a>{" "}
              or rent your{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              or non-personal information. We will only share or disclose personal information as set forth in the
              Privacy Policy, such as with a limited set of{" "}
              <Link to="/third-party-service-providers/">third-party</Link> service providers necessary to provide or
              develop our Services (such as database hosting) or as required by law, and we will be transparent about
              who these service providers are. We will contractually require that these service providers process
              personal information in accordance with our instructions and consistent with this Privacy Policy. We
              ensure that these service providers employ reasonable and comprehensive data protection and security
              protocols. See <Link to="#how-we-share-you-personal-information">here</Link> for more details.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>We Never Use Learner Information for Behaviorally Targeted or Third-Party Advertising:</b> Information
              collected from learners (including personal information and information collected automatically) is never
              used or disclosed for
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
                {" "}
                third-party advertising
              </a>
              . We also do not place any{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
                third-party advertisements
              </a>{" "}
              in learner logged-in areas of the Service. Additionally, personal information collected from learners is
              never used for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
                behaviorally-targeted advertising
              </a>{" "}
              to learners by us or any third-party. We may present{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
                contextually relevant
              </a>{" "}
              educational content (not using learner personal information) for either our own products or third-party
              products and services ourselves that we believe may be of interest to a learner (e.g., our showing a child
              additional tutoring sessions for other school subjects they may be interested in).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Data Retention:</b> We will not retain learner{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              for any longer than is necessary for{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_0f871510-2886-46eb-9bb4-e7ae17080447">
                educational
              </a>{" "}
              purposes and legal obligations, or to provide the Service for which we receive or collect the learner
              personal information. We store non-learner user personal information for as long as it is necessary to
              provide products and Services to you and others. For non-learner users, personal information associated
              with your account will be kept until your account is deleted or until we no longer need the data to
              provide the Service, whichever occurs first. Please see{" "}
              <Link to="#how-long-does-dojo-keep-information">here</Link> for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>No Learner’s Information is Shown Publicly:</b> No{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learner
              </a>
              ’s account or profile is made available or visible to the general public through the Service. For a list
              of what logged in learner users’ information can be viewed by other logged-in Dojo Tutoring Service users,
              please see our <a href="https://classdojo.zendesk.com/hc/articles/12016402266125">FAQ</a>.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>You Own Your Data:</b> We don’t own any content or information you provide or we receive — you (
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learners
              </a>
              , tutors, and/or parents) will own your content and information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Security and Privacy by Design and Default:</b> We use security industry best practices to protect
              personal information, including using encryption and other security safeguards to protect personal
              information. We design products with security and privacy in mind from day one and also consult with
              outside experts to help us test and improve our controls. See{" "}
              <Link to="#how-does-tutoring-protect-information">here</Link> for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Right to Access, Correction, and Deletion of Data:</b> You can access, correct, and delete your
              personal information. See <Link to="#providing-transparency-rights">here</Link> for more information on
              all user’s rights, including about your additional rights of data portability and right to object or
              withdraw consent.
            </Text>
          </li>
        </ul>
        <Box
          className={classes.hero}
          sx={{ marginBottom: "dt_l", marginTop: "dt_l", scrollMarginTop: "130px" }}
          id="dojo-tutoring-privacy-policy"
        >
          <Box>
            <Text component="h1">Dojo Tutoring Privacy Policy</Text>
            <Box mb={16} />
            <Text className={classes.subHeader} sx={{ color: "#7174A0" } as any}>
              Last Updated: January 9, 2023
            </Text>
          </Box>
        </Box>
        <Box>
          <h3>Introduction and Scope</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring (<b>“Dojo Tutoring”</b>, <b>“us”</b>, <b>“we”</b>, or <b>“our”</b>) is a platform that helps
            children to further their learning by providing online live tutoring for children ages 4-16 and operates the
            website located at <a href="https://tutoring.classdojo.com/for-teachers">https://tutoring.classdojo.com</a>{" "}
            (<b>“Website”</b>) as well as the areas of the Website where users login to use the Dojo Tutoring platform
            (the <b>“Tutoring Platform”</b>), our mobile applications (the <b>“Dojo Tutoring Apps”</b>), or any of our
            other products or services that link to this Privacy Policy that Dojo Tutoring may provide now or in the
            future (collectively, the <b>“Service(s)”</b>). Dojo Tutoring may be acting as both a Controller and a
            Processor as those terms are defined under the European Union’s General Data Protection Regulation,
            including as implemented or adopted under the laws of the United Kingdom (“GDPR”) or as defined under any
            other applicable state privacy law in the United States. For more information, please see{" "}
            <Link to="#is-dojo-tutoring-a-controller">below</Link>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We respect the privacy of our website visitors, learners, and tutors (referred to as <b>“you”</b> or{" "}
            <b>“your”</b>) and the personal information you share with us. This privacy policy (as posted on our Website
            and as amended from time to time (<b>“Privacy Policy”</b>) explains:
          </Text>
          <ul>
            <li>
              <Text className={classes.listText}>
                What information Dojo Tutoring collects from you (and why we collect it),
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>How we use and share that information,</Text>
            </li>
            <li>
              <Text className={classes.listText}>
                The choices and rights you have, including how to access, update, delete your information, and take your
                information out of Dojo Tutoring.
              </Text>
            </li>
          </ul>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We will only collect, use, and share your personal information in accordance with this Privacy Policy. This
            Privacy Policy applies solely to the Dojo Tutoring Services and the data collected by us, or those working
            on our behalf. This Privacy Policy also covers Dojo Tutoring’s treatment of any personal information about
            our users that our partners or other services share with us, for example information shared from Zoom, or
            data imported from authorized and approved sources.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph} sx={{ fontWeight: "bold" }}>
            This Privacy Policy also does not apply to the ClassDojo product available at the logged in section of{" "}
            <a href="https://www.classdojo.com">https://www.classdojo.com</a> (the “ClassDojo Platform”), or the
            associated mobile applications (the “ClassDojo Apps”) (ClassDojo Platform and ClassDojo Apps collectively,
            the “ClassDojo Main Services”) or the informational website located at{" "}
            <a href="https://www.classdojo.com">https://www.classdojo.com</a> (the “ClassDojo Website”). The ClassDojo
            Main Services Privacy Policy is located <a href="https://www.classdojo.com/privacy/">here</a> and the
            ClassDojo Website Privacy Policy is located <a href="http://www.classdojo.com/website-privacy">here</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            This Privacy Policy also does not apply to data collected through other websites, products, or services that
            Dojo Tutoring does not own or control such as third-party services you might access through links or other
            features (e.g., social media buttons, email campaigns, push or in-app notifications, or YouTube videos) on
            the Service. These other services have their own privacy policies, and we encourage you to review them
            before providing them with personal information.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Our Privacy Policy is designed to provide transparency into our privacy practices and principles. We’ve done
            our best to write this policy in simple, clear terms, but if you’re not familiar with terms like personal
            information, cookies, IP address, pixel tags, and browsers, read about these{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms">
              key terms
            </a>
            .
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            For learners and children, we have a{" "}
            <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
              short video
            </a>{" "}
            that highlights the most important details in our Privacy Policy that they should know about. We’ve also
            added summaries below each section that provide short explanations in plain English (it starts with
            “Basically …’) to aid in understanding, but isn’t legally binding.
          </Text>
        </Box>
        <br />
        <Box sx={{ display: "grid", gridTemplateColumns: "60px 60px", columnGap: "dt_s" }}>
          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/spp_signatory+square_lg.jpg"
            alt="ClassDojo teacher profile"
            width="60px"
          />

          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/ikeepsafe_coppabadge.png"
            alt="ClassDojo teacher profile"
            width="60px"
          />
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We hope you find this clear and friendly. If you have any questions, we’re here to help. You can visit the{" "}
            <a href="http://www.classdojo.com/privacycenter">ClassDojo Privacy Center</a> to learn more about how we
            protect your privacy, or you can contact us as set forth in the{" "}
            <Link to="#contact-information">Contacting Dojo Tutoring</Link> section below.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Protecting your privacy is incredibly important to us. This Privacy Policy is here to help you understand
            how we treat any personal information that you share with us when you use the Dojo Tutoring Services. This
            Privacy Policy applies to every Dojo Tutoring product - other companies that Dojo Tutoring doesn’t own, or
            control will have their own privacy policies, and you should read them, too! This Privacy Policy applies
            solely to the Dojo Tutoring Services and does not apply to the ClassDojo Main Services or the informational
            ClassDojo Website - you can find the ClassDojo Main Services Privacy Policy here and ClassDojo Website
            Privacy Policy here.
          </Text>
        </Box>
        <br />
        <Divider />
        <br />
        <Box>
          <Text weight="bold" underline>
            Quick Reference
          </Text>
          <ul>
            <li>
              <Text className={classes.listText}>
                <Link to="#acknowledgment-and-consent">Acknowledgment and Consent</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#dojo-tutoring-privacy-certifications">Dojo Tutoring Privacy Certifications</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-does-dojo-tutoring-protect-children">How Does Dojo Tutoring Protect Children?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#what-information-does-dojo-tutoring-collect">
                  What Information Does Dojo Tutoring Collect?
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#online-analytics-advertising-and-do-not-track">
                  Online Analytics, Advertising, and Do-Not Track
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-does-dojo-use-info">How Does Dojo Tutoring Use the Information It Collects?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-we-share-you-personal-information">How We Share Your Personal Information</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-does-tutoring-protect-information">
                  How Does Dojo Tutoring Protect and Secure My Information?
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#how-long-does-dojo-keep-information">
                  How Long Does Dojo Tutoring Keep Information About Me?
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#providing-transparency-rights">
                  Dojo Tutoring Commitments to Providing Transparency and Your Rights
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#additional-article-27-GDPR">
                  Additional Information and Assistant and Article 27 GDPR Local Representative
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#communications-from-dojo-tutoring">
                  What Communications Will I Receive from Dojo Tutoring?
                </Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#third-party-authentication-services">Third-party Authentication Services</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#third-party-service-providers">Third-Party Services</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#what-if-i-am-not-in-the-united-states">What if I am not in the United States?</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#california-privacy-disclosures">California Privacy Disclosures</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#changes-to-our-privacy-policy">Changes to our Privacy Policy</Link>
              </Text>
            </li>
            <li>
              <Text className={classes.listText}>
                <Link to="#contact-information">How Can I Contact Dojo Tutoring with Questions?</Link>
              </Text>
            </li>
          </ul>
        </Box>
        <Box sx={{ scrollMarginTop: "130px" }} id="acknowledgment-and-consent">
          <h3>Acknowledgment and Consent</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            By visiting our Website or Platform, attending a tutoring session, applying to become a tutor, or using our
            Services in any manner, you acknowledge that you accept the terms, practices and policies described in this
            Privacy Policy (and as updated from time to time), and you hereby agree that we may collect, use, and share
            your information as described herein. If you do not agree with our policies and practices, you should not
            use our Website or our Services. Your use of the Website and our Services is at all times subject to our{" "}
            <a href="termsofservice">Terms of Use</a> (as updated from time to time (the “<b>Terms</b>”)), which
            incorporates this Privacy Policy. Any capitalized terms we use in this Privacy Policy without defining them
            have the definitions given to them in the Terms.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            By visiting our Website, using our Services or applying to become a tutor, you agree that we may collect,
            use and share your information as described in our Privacy Policy.
          </Text>
        </Box>
        <Box>
          <br />

          <Box sx={{ scrollMarginTop: "130px" }} id="dojo-tutoring-privacy-certifications">
            <h3 className={classes.paragraph}>Dojo Tutoring Privacy Certifications</h3>
          </Box>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring participates in the iKeepSafe Safe Harbor program. iKeepSafe has granted Dojo Tutoring the
            Children’s Online Privacy Protection Act (“COPPA”) Safe Harbor seal signifying its Website and Platform have
            been reviewed and approved for having policies and practices surrounding the collection, use, maintenance
            and disclosure of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            from children are consistent with the iKeepSafe COPPA Safe Harbor program guidelines.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring is also a signatory to the Student Privacy Pledge. We agree to a set of principles intended to
            safeguard student privacy, including responsible stewardship, protection, and transparent handling of
            student personal information. Read more about the Student Privacy Pledge{" "}
            <a href="https://studentprivacypledge.org/signatories/">here</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Dojo Tutoring is certified <a href="http://ikeepsafe.org/privacy/coppa/">COPPA-compliant</a>, and has also
            signed the <a href="https://studentprivacypledge.org/signatories/">Student Privacy Pledge</a>.
          </Text>
        </Box>
        <br />
        <Box sx={{ scrollMarginTop: "130px" }} id="how-does-dojo-tutoring-protect-children">
          <h3 className={classes.paragraph}>How Does Dojo Tutoring Protect Children?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Protecting children’s privacy is especially important to us - we’re educators and parents ourselves, after
            all. This is why we’ve signed the Student Privacy Pledge and received iKeepSafe’s COPPA Safe Harbor seal,
            signifying that this Privacy Policy and our practices with respect to the operation of the Service have been
            reviewed and approved for compliance with iKeepSafe’s COPPA Safe Harbor program. COPPA protects the online
            privacy of children under the age of 13 (“child” or “children”); for more information about COPPA and
            generally protecting children’s online privacy, please visit{" "}
            <a href="http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online">
              OnGuard Online
            </a>
            .
          </Text>
        </Box>
        <br />
        <Box sx={{ display: "grid", gridTemplateColumns: "60px 60px", columnGap: "dt_s" }}>
          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/spp_signatory+square_lg.jpg"
            alt="ClassDojo teacher profile"
            width="60px"
          />

          <Image
            styles={{
              image: {
                background: "#F7F8FF",
              },
            }}
            src="https://static.classdojo.com/img/page_privacy/ikeepsafe_coppabadge.png"
            alt="ClassDojo teacher profile"
            width="60px"
          />
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We’ve also made a{" "}
            <a href="https://customer-1rx0xkel5ma0m71e.cloudflarestream.com/4e05c5f0b0799412c9ec2bd12c4be391/watch">
              short video
            </a>{" "}
            for children and learners that highlights the most important details in our Privacy Policy that they should
            know about.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            This Children’s section explains our information, collection, disclosure, and parental consent practices
            with respect to information provided by children. This policy is in accordance with the U.S. COPPA.
          </Text>
        </Box>
        <br />
        <Box sx={{ scrollMarginTop: "130px" }} id="what-information-tutoring-collect">
          <h3 className={classes.paragraph}>What Information Does Dojo Tutoring Collect from Children?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            The statements we make regarding the information we collect from or about{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>{" "}
            and children using Dojo Tutoring and how we use this information applies equally to all children and
            learners regardless of their age. Accordingly, where this Privacy Policy references learners or any
            information collected from or about learners, our Privacy Policy applies to learners under 13 years old as
            well as learners 13 years old and above.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring collects the minimal amount of information from children and{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>{" "}
            necessary to register for an account on or otherwise use the Service. We will not require children to
            provide more{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            than is reasonably necessary in order to participate in the Service. If we discover that we have collected
            information from a child in a manner inconsistent with COPPA, we will take appropriate steps to either
            delete the information or immediately seek the{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">parent&apos;s consent</a> for that
            collection. This information is necessary to enable us to comply with legal obligations and given our
            legitimate interest in being able to provide and improve the Service and for security and safety purposes.
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Additionally, Dojo Tutoring follows the Federal Trade Commission’s (FTC) COPPA rules for parental consent.
            For more information on how we obtain parental consent, please see our{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">FAQ</a>. For more detail on the
            categories of personal information collected from learners, as well as the sources from which the
            information was collected, the purposes for which the information was collected, and the categories of third
            parties with whom we disclose such personal information please see <Link to="/transparency">here</Link>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>We collect information from your child in the following ways:</Text>
        </Box>
        <Box>
          <Text weight="bold">Learner Account Creation</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Currently, learners access the Services through a parent setting up the learner’s account and/or giving
            their child access to the Services through the parents device (“Learner Account”). When a parent sets up a
            Learner Account for their child, they will first need to{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12035564046093">create a parent account</a> and then they
            provide certain information about their child which is necessary to allow their child to participate in the
            Services (e.g. child’s name, grade level, and tutoring subject). In accordance with COPPA, we may ask the
            parent to provide the date of birth or age of their child, and if the child is under 13, we will also then
            seek to obtain verifiable{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">parental consent</a> in order to proceed
            with creating their child’s account or otherwise allowing their child to use the Service. The parent may
            choose to provide additional information - such as your child’s school name, qualitative description of how
            your child is doing and where they need the most help, your child’s IEP needs, and languages spoken.
            Although not required under COPPA, we may choose to impose parental consent requirements on learners 13 and
            above at our discretion.
          </Text>
        </Box>
        <Box>
          <Text weight="bold">Tutoring Session Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Information collected from both{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learners
              </a>{" "}
              and tutors such as tutoring subject areas and tutoring attendance and how long participants stayed in Zoom
              (reported from Zoom). The attendance data is shown to both the tutor and learner (to confirm they are
              following the attendance policy) and may also be shown to parents and other designated third parties with
              the learner’s or parent’s permission.
            </Text>
          </li>

          <li>
            <Text className={classes.listText}>
              Zoom recordings: Certain information is captured through your child’s participation in a tutoring session
              such as your child’s image, voice, movements, and other audio information as well as chat logs or other
              information provided during the tutoring session. Dojo Tutoring currently uses{" "}
              <a href="https://explore.zoom.us/en/isv/">Zoom ISV Program</a> to host its tutoring sessions. The tutoring
              sessions (including transcripts and Zoom chat logs) are recorded from both{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learners
              </a>{" "}
              (your child) and tutors to ensure the safety and quality of tutoring on the Services. These recordings are
              automatically deleted after 14 days and are reviewed by Dojo Tutoring’s safety and quality teams as
              needed. To learn more, read the <a href="https://explore.zoom.us/en/privacy/">Zoom Retention Policy</a>.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Messaging and Other Communications</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Contents of communications between{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learners
              </a>{" "}
              and their tutors - such as messaging within the Services or information the child may communicate with
              their tutor during a recorded tutoring session.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Information Collected About the Child from Parents and Tutors</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            While not collected directly from your child, we also collect and receive from the child’s parent and tutor
            certain personal information about the child that they choose to provide as set forth below and link this
            information to the child’s Learner Account. For additional information, please also see the Section{" "}
            <Link to="#what-information-does-dojo-tutoring-collect">
              “What Information Does Dojo Tutoring Collect?”
            </Link>{" "}
            <a href="https://tutoring.classdojo.com/transparency">This chart</a> details the personal information we
            collect, how we use it, and where it is stored.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            <i>Parent About Their Child:</i>
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>Name</Text>
          </li>
          <li>
            <Text className={classes.listText}>Birthday</Text>
          </li>
          <li>
            <Text className={classes.listText}>A unique username and password</Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Education information you provide about your child such as what grade your child is in. Additionally, you
              may choose to provide your child’s school name and location; whether your child was home schooled or went
              to a private or public school; and graduation year
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Qualitative description of how your child is doing and where they need the most help. This information is
              used by the tutor to plan their tutoring session
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>Languages spoken</Text>
          </li>
          <li>
            <Text className={classes.listText}>Gender pronouns</Text>
          </li>
          <li>
            <Text className={classes.listText}>Student IEP needs</Text>
          </li>
          <li>
            <Text className={classes.listText}>Tutoring subject areas</Text>
          </li>
        </ul>
        <Box>
          <Text className={classes.paragraph}>
            <i>Tutors About Learners (e.g. your child):</i>
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Tutor&apos;s assessments of the learners performance during their first tutoring session and over the
              course of their additional sessions: Tutors will provide learners with both positive & constructive
              feedback that can further their learning (e.g. areas of strength or improvement) as well as recommended
              activities, resources and a work plan). The feedback is shared with the learner and their parents, and may
              be shared by the parent to other family members, but is not made available to the general public.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Location Information</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We never request precise location data from learners and no learner location data is available publicly.
            From learners the primary form of location data is based on IP Addresses.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Push Notifications</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring may send{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/204474325-How-do-push-notifications-work-for-student-accounts-">
              push notifications
            </a>{" "}
            to learners. We require children to provide a parent’s email address before he or she can receive push
            notifications from Dojo Tutoring (or have a Learner Account previously set up on ClassDojo by their parent
            with any necessary <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">parental consent</a>{" "}
            obtained). We will then provide the parent with notice (if the account was set up by them or their child)
            that the push notifications are being sent to their child. The device identifier for a child’s account will
            only be associated with other{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            once parental consent is provided. These push notifications may contain information about taking an action
            in the Service, such as the child completing a task or other product feature or informing the child their
            learning assessment has improved. If you are a learner or a parent of a child and no longer want these
            communications to be sent, please either turn off the push notifications on your device or contact us at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold" sx={{ scrollMarginTop: "130px" }} id="automatically-collected-information">
            Automatically Collected Information
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            In addition to the information listed above, we automatically collect some information from any use of our
            Service as set forth in the{" "}
            <a href="https://tutor.classdojo.com/#/privacy?ref=information-we-collect-through-automatic-data-collection">
              &quot;Information collected automatically&quot;
            </a>{" "}
            section. This includes, device-specific information, such as device ID, unique device ID, device type and
            device attributes (e.g., operating system and battery level); and a device token (to send push
            notifications). Zoom may also collect the following from the device: information about the speakers,
            microphone, camera, OS version, hard disk ID, PC name, MAC address, WiFi information, and other device
            information (like Bluetooth signals). Please see the a Zoom{" "}
            <a href="https://explore.zoom.us/en/privacy/">Privacy Policy</a> for more information. We may also collect{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_b35dfe89-b21e-4bb2-8616-856d62ad6237">
              server log
            </a>{" "}
            information and product event data (e.g., if a child accesses a tutoring session, which are used to track
            progress, for internal analysis, on product usage and quality, or to send push or other notifications to
            encourage your child to take certain actions in the Dojo Tutoring Service)
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            The device and application related data is collected from the device and application being utilized to give
            the child access to Dojo Tutoring accounts (e.g., the parents device if the child is utilizing the parent’s
            device, or the child’s device if they are logging in separately on their own device). These are collected
            for internal analysis on product usage and quality, and we may also use it to track progress in the Service,
            and for sending{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/360050778411-Notifications-and-Your-Parent-Account#web">
              push
            </a>{" "}
            or email notifications to the child (as set forth below) to encourage them to take certain actions in the
            Dojo Tutoring Service.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Dojo Tutoring has been certified by iKeepSafe, an FTC-approved COPPA Safe Harbor, for compliance with their
            COPPA Safe Harbor program. We don’t ask for or require children to provide{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            beyond that which is reasonably necessary to use Dojo Tutoring. Information collected from learners is{" "}
            <b>never</b> used or disclosed for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_6fb13f0c-b840-412f-9956-2a519005384d">
              third-party advertising
            </a>{" "}
            or any kind of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
              behaviorally-targeted advertising
            </a>
            , and it is <b>never</b>{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sold
            </a>{" "}
            or rented to anyone, including marketers or advertisers. Please see{" "}
            <Link to="/transparency">this chart</Link> for the detailed categories of information we collect from each
            user type and how we use the information, as well as the sources from which the information was collected,
            the business purpose for which the information was collected and the third parties with whom we disclose
            personal information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <h3>How Does Dojo Use and Disclose Children’s Information?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>We use the information we collect from a children and learners:</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              To provide and improve the Service to the child, including administering our tutoring sessions, to send
              push notifications or other in-app notifications to your child (e.g., to encourage them to take certain
              actions in the Service){" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>For internal analytics on product usage</Text>
          </li>
          <li>
            <Text className={classes.listText}>
              For{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_0f871510-2886-46eb-9bb4-e7ae17080447">
                educational
              </a>{" "}
              purposes and as authorized by law
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>To identify, fix and troubleshoot bugs and Service errors</Text>
          </li>
          <li>
            <Text className={classes.listText}>For security and safety purposes, including enforcing our Terms</Text>
          </li>
          <li>
            <Text className={classes.listText}>To comply with legal process or other laws</Text>
          </li>
        </ul>
        <Box>
          <Text className={classes.paragraph}>
            Information collected from children and learners (including personal information and information collected
            automatically) is never used or disclosed for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_6fb13f0c-b840-412f-9956-2a519005384d">
              third-party advertising.
            </a>{" "}
            We also do not place any third-party advertisements in children’s logged-in areas of the Service.
            Additionally, personal information collected from children is <b>never</b> used for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
              behaviorally-targeted advertising
            </a>{" "}
            to learners by us or any third-party, and children’s personal information is <b>never</b>{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sold
            </a>{" "}
            or rented to anyone, including marketers and advertisers. We may present{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
              contextually relevant
            </a>{" "}
            educational content (not using learner personal information) for either our own products (e.g. showing a
            reading course while taking a math course) or third-party products and services ourselves that we believe
            may be of interest to a learner (e.g. an online platform that may help your kid practice math).
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We do not disclose any{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            about children to third-parties, except to{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a> necessary to
            provide the Service, other users as part of the Service (such as tutors), as directed or approved the
            child’s parents, as required by <Link to="#legal-requirements">law</Link>, or to protect the security of the
            Service or other users. You can visit our{" "}
            <Link to="/third-party-service-providers">service providers page</Link> to see the list of third-party
            operators collecting or maintaining personal information from children through the Service. Please contact
            us as set forth in the section “How Can I Contact Dojo Tutoring with Questions” with questions about the
            operators’ privacy policies and collection and use practices.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We may share aggregate or de-identified information, which cannot reasonably be used to identify your child,
            with third parties for various purposes, including compliance with various reporting obligations; to
            demonstrate how the Service is used; for business purposes; or to assist third parties in understanding our
            users’ interest, habits, and usage patterns for certain programs.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box mb={"8px"}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We use the{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            we collect from{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>{" "}
            (or about learners from tutors or their parents) to provide and improve the Service, for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_0f871510-2886-46eb-9bb4-e7ae17080447">
              educational purposes
            </a>
            , security, and safety purposes, or as allowed by law. We will not require learners to provide more personal
            information than is reasonably necessary to participate in the Service.
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Personal information collected from children is <b>never</b> used for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
              behaviorally-targeted advertising
            </a>{" "}
            to learners or children by us or any third-party, and children’s personal information is <b>never</b>{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sold
            </a>{" "}
            or rented to anyone, including marketers and advertisers. We do not disclose any{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            about children to third-parties, except to{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a> necessary to
            provide the Service, other users as part of the Service (such as tutors), as directed or approved the
            child’s parents, as required by <Link to="#legal-requirements">law</Link>, or to protect the security of the
            Service or other users.
          </Text>
        </Box>
        <br />
        <Box>
          <h3>What Children’s Information is Visible to Others?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            No Learner Account, profile or {personalInformationLink()} of a child is made available or visible to the
            general public through Dojo Tutoring. Any content uploaded by you, your child, or your child’s tutor
            (including tutor and learner comments or Zoom video recordings of tutoring sessions) through the Service,
            can be viewed by you and your child’s tutor. For a list of what logged in learner user’s information can be
            viewed by other logged in Dojo Tutoring users, please see our{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016402266125">FAQ</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            No Learner Account, profile or {personalInformationLink()} of a child is made available or visible to the
            general public through Dojo Tutoring. For a list of what logged in learner user’s information can be viewed
            by other logged in Dojo Tutoring users, please see our{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016402266125">FAQ</a>.
          </Text>
        </Box>
        <br />
        <Box sx={{ scrollMarginTop: "130px" }} id="how-long-keeps-children-information">
          <h3>How Long Does ClassDojo Keep Children’s Information?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We will not retain a child’s {personalInformationLink()} for any longer than is reasonably necessary for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_0f871510-2886-46eb-9bb4-e7ae17080447">
              educational purposes
            </a>{" "}
            or legal obligations, or to provide the Service for which we receive or collect the child’s personal
            information. Additionally, we only keep a child’s personal information for as long as his or her Learner
            Account is <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>, unless we are
            required by law to retain it, need it to ensure the security of our community or our Service, or to enforce
            our Terms. In addition, we implement the following polices:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>14-day deletion policy for tutoring session Recordings:</b> To protect learners, Dojo Tutoring sets
              limits on how long the Zoom recordings of tutoring sessions are maintained. These recordings are
              automatically deleted after 14 days (unless we’re doing a research study and have obtained explicit opt-in
              consent from users (or their parents) to retain longer). The Zoom video recordings are stored on Zoom’s
              hosting provider’s servers. To learn more, read the{" "}
              <a href="https://explore.zoom.us/en/privacy/">Zoom Retention Policy</a>.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Deleting inactive Learner Accounts:</b> If a child’s Learner Account is{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">inactive</a> for twelve months or more
              (meaning no tutor has given feedback to the learner, added content to a learner’s account, or messaged
              with the learner’s parent(s), and neither the parent(s) nor the learner has logged into their accounts),
              Dojo Tutoring will delete the Learner Account. Learn more about how we delete inactive Learner Accounts{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">here</a>. For more information on the
              deletion of content related to Learner Accounts, please see our{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQ</a>.
            </Text>
          </li>
        </ul>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box mb={"8px"}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We will not retain a child’s {personalInformationLink()} for any longer than is reasonably necessary for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_0f871510-2886-46eb-9bb4-e7ae17080447">
              educational purposes
            </a>{" "}
            or legal obligations, or to provide the Service for which we receive or collect the child’s personal
            information. We only keep a child’s {personalInformationLink()} for as long as the account is{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>, unless we are required by law
            to retain it, need it to enforce our policies or to ensure the security of our community.
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Additionally, we protect children and learners with the additional policies: (1) we automatically delete
            tutoring session Zoom recordings after 14 days, and (2) we delete Learners’ Accounts if they’re{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">inactive</a> for more than twelve months.
          </Text>
        </Box>
        <br />
        <Box sx={{ scrollMarginTop: "130px" }} id="parental-choices">
          <h3>Parental Choices and Controls</h3>
        </Box>
        <Box>
          <Text weight="bold">Access, Correction, and Deletion Rights</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            You may access, review, correct, or delete any of your child’s personal information in the Service by
            emailing <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> with the same
            email used to create your parent account.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            We will use commercially reasonable efforts to process such requests in a timely manner consistent with
            applicable law. We will need to verify your identity, for example by requiring that you provide acceptable
            forms of personal identification as set forth in our{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016488970381">FAQ</a>. Please keep in mind that a
            request to delete records may lead to a termination of an account, or the use of the Services. For
            additional information on further rights that you may have as well as what happens when an account is
            deleted, please see our section{" "}
            <Link to="#providing-transparency-rights">
              “Dojo Tutoring’s Commitments to Providing Transparency and Your Rights”
            </Link>
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Stop Collecting Further Personal Information</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Additionally, at any time, for Learner Accounts, you can refuse to permit us to collect further{" "}
            {personalInformationLink()} from your child by emailing{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email used
            to create your parent account. Please keep in mind that deleting records may require us to terminate the
            account in question.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We believe parents who have set up their child’s Learner Account, or otherwise allow their child access to
            the Service, should always be able to review their child&apos;s account, request for us to delete their
            child’s personal information, obtain a copy of their child&apos;s personal information, or stop us from
            further collecting their personal information. Just email us at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email used
            to create your parent account and we’ll be happy to help.
          </Text>
        </Box>
        <br />
        <Box sx={{ scrollMarginTop: "130px" }} id="what-information-does-dojo-tutoring-collect">
          <h3>What Information Does Dojo Tutoring Collect?</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We collect two types of information about you: (1) information that you voluntarily provide us by using the
            Service or Website (described below under{" "}
            <Link to="#what-information-does-dojo-tutoring-collect">“Information You Provide to Us”</Link>) and (2)
            information collected automatically as result of your use of the Service (described below under{" "}
            <Link to="#information-we-collect-through-automatic-data-collection">
              “Information We Collect Through Automatic Data Collection Technologies”
            </Link>
            ). We may also receive information about you from third-party sources (as described below under{" "}
            <Link to="#information-received-from-third-party-sources">
              “Information Received from Third-Party Sources”
            </Link>
            ). The types and amounts of information collected will vary depending on whether the user is a learner,
            tutor, parent, or general website visitor and how they use Dojo Tutoring (e.g., if a tutor wants to apply to
            become a tutor, we may need to collect certain employment related information). We have also created{" "}
            <a href="https://tutoring.classdojo.com/transparency">this chart</a> with the detailed categories of{" "}
            {personalInformationLink()} we collect from each user type, including the sources from which the information
            was collected, the business purpose for which the information was collected, and the third parties with whom
            we disclose personal information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Dojo Tutoring asks for some information directly from learners, tutors and parents (such as account
            information), and also collects some information automatically (such as crash reports when errors occur) in
            order to provide you with the best possible Dojo Tutoring experience. Please see{" "}
            <a href="https://tutoring.classdojo.com/transparency">this chart</a> for the detailed categories of personal
            information we collect from each user type, including the sources from which the information was collected,
            the business purpose for which the information was collected, and the third parties with whom we disclose
            personal information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <Text
            weight="bold"
            underline
            sx={{ fontSize: "20px", scrollMarginTop: "130px" }}
            id="what-information-does-dojo-tutoring-collect"
          >
            Information You Provide to Us
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We ask for and collect the following {personalInformationLink()} about you when you use the Service or
            Website. This information is necessary for the adequate performance of the contract between you and us, for
            our legitimate interest in being able to provide and improve the Service and Website, and to allow us to
            comply with our legal obligations. Without it, we may not be able to provide you with all the requested
            services. Our use of the information set forth below is described in the section{" "}
            <Link to="#how-does-dojo-use-info">“How Does Dojo Tutoring Use the Information it Collects.”</Link>
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            There are currently three categories of users on our Service:{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>
            , tutors and parents. Additionally, as a non-logged-in visitor to the Dojo Tutoring Website, you may provide
            personal information to us as set forth below. We describe the information collected from learners in the{" "}
            <Link to="#how-does-dojo-tutoring-protect-children">“How We Protect Children’s Personal Information”</Link>{" "}
            section above; for other users, we collect and store the following types of information from each type of
            user:
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Account Sign-up and User Profile Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Information you provide when registering for a tutoring session as a parent (or registering your child as
              a learner) or becoming a tutor, such as first and last name (and the name of your child if a parent),
              email address, password, unique username, zip code, country and phone number (e.g, if you want to receive
              SMS reminders). We may also ask you (as a parent) for the date of birth or age of your child to help us
              both comply with laws (such as COPPA and Age-Appropriate Design Codes) and assist with providing the
              appropriate Services to your child.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Any additional optional profile information you may provide during registration or related to your
              profile, such as a photo (or avatar), more detailed profile information (e.g., hobbies), responses to
              questions such as “how you heard about Dojo Tutoring”, and other login credentials to link your account
              for parents (e.g., your ClassDojo Main Services account user name).
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Tutor Application and Certification Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Information you may provide when applying to become a certified tutor, such as relevant employment or
              professional related experience (e.g., number of years as a teacher and which grades you taught, how long
              you have been a tutor and what subjects you’ve provided tutoring on, whether you have experience with
              tutoring online, etc.). Tutors may also provide the information in the category “Education or other
              Demographic Information”.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Videos a tutor submits of themselves responding to certain interview questions. These videos are used to
              help select qualified tutors for the Service.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Contract Signing information. We use a{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers/">third party</a> to help facilitate
              tutors signing consulting agreements with Dojo Tutoring and for supporting tutors on creating their tax
              filings. Tutors may share with this third party their full name, email, phone number, physical address and
              signature. Through the Deel platform we can access the personal information the tutor shared when signing
              the contract that include their full name, email, phone number, physical address and signature.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Background check information. With the consent of the tutor, Dojo Tutoring performs background checks
              (which includes Sex Offender Search (sources include but are not limited to National Sex Offender Public
              Website and national and state sources), Global Watchlist Search (sources include but are not limited to
              Office Of Inspector General, European Union Consolidated List, Drug Enforcement Agency Fugitives,
              Government Sanction Databases, US Terrorist List), National Search (sources include but are not limited to
              Warrant sources, Department of Corrections sources, Arrest sources, Court sources), and County Searches
              (sources include but are not limited to County Courthouse records) on all tutor applications using a{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers">third party service provider</a>.
              Applicants will share with this service provider their full name, phone number, email address, date of
              birth, social security number, zip code and electronic signature. Dojo Tutoring then has access to the
              background check information (through accessing the third party’s portal) and last four digits of Social
              Security Number.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Education or other Demographic Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Education information you may provide as a tutor:school name and location; whether you were home schooled
              or went to a private or public school; graduation year; grades or transcripts; and additional academic
              records pertaining to tutor applications and honorary certifications (e.g. assessments, standardized test
              scores). Tutors may submit their grades or transcripts with any personal information removed. Dojo
              Tutoring will delete grades, transcripts or other academic records after the tutor’s certification has
              been established upon request from the tutor.{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Education information you may provide as a parent about your child: school name and location; whether your
              child was home schooled or went to a private or public school; graduation year; grade your child is in;
              grades or transcripts (for tutors, for learners by parents); and additional academic records).{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              High-level details about schooling (e.g. stage of learner — K-12 ) that parents are required to provide
              about their child during registration for a tutoring session.{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Demographic information you may optionally provide as a tutor (or a parent about your child) such as your
              (or your child’s) birth date, gender pronouns, and languages spoken and your child’s IEP needs.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Qualitative description of how your child is doing and where they need the most tutoring help.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Tutoring Session Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Information collected from both{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learners
              </a>{" "}
              and tutors such as tutoring subject areas and tutoring attendance and how long participants stayed in Zoom
              (reported from Zoom). The attendance data is shown to both the tutor and learner (to confirm they are
              following the attendance policy) and may also be shown to parents.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Information collected when hosting a tutoring session, such as tutoring description from the tutor.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Zoom recordings: Certain information is captured through your participation as a tutor in a tutoring
              session such as your image, voice, movements, and other audio information as well as chat logs or other
              information provided during the tutoring session. Dojo Tutoring currently uses{" "}
              <a href="https://explore.zoom.us/en/isv/">Zoom ISV Program</a> to host its tutoring sessions. The tutoring
              sessions (including transcripts and Zoom chat logs) are recorded from both {learnersLink()} and tutors to
              ensure the safety and quality of tutoring on the Services. These recordings are automatically deleted
              after 14 days and are reviewed by Dojo Tutoring’s safety and quality teams as needed. To learn more, read
              the <a href="https://explore.zoom.us/en/privacy/">Zoom Retention Policy</a>.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Messaging, Assessments and Other Communications</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Parents&apos; feedback to tutors on sessions: Parents may choose to provide tutors and Dojo Tutoring with
              feedback on their child’s tutoring sessions.{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Tutor&apos;s assessments of learners: Tutors may provide learners with both positive &#38; constructive
              feedback that can further their learning (e.g., areas of strength or improvement) as well as recommended
              activities, resources and a work plan. The feedback is shared with the learner and their parents, and may
              be shared by the parent to other family members, but is not made available to the general public.{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Direct messages and group chats on the Services: A user may choose to direct message other users (either
              another individual or in a group associated with a tutoring series) such as for logistics corresponding to
              a tutoring session. These messages are shared with the other users in the messaging group as well as
              safety monitors that ensure the communications remain safe.{" "}
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Content (photos, videos, photographs, documents or audio files)</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Please see the “Tutor Application and Certification Information” category, and the “Tutoring Session”
              category listed above. Note that tutors and parents may also upload photos to their profiles.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Geolocation</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Dojo Tutoring does not collect precise geolocation information without user permission. Dojo Tutoring
              collects non-precise geolocation information such as{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f2f016f2-e461-4443-8c29-6bb597063447">
                IP address
              </a>{" "}
              information and zip code and may collect certain automatically collected location information as set forth{" "}
              <Link to="#information-we-collect-through-automatic-data-collection">below.</Link>
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Billing and Payment Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              When you purchase a tutoring session as a parent or if you are a tutor and Dojo Tutoring is paying you for
              your tutoring session, we may collect certain {personalInformationLink()} to process these transactions.
              We do not collect any credit card information directly from parents to purchase tutoring sessions, however
              we may receive, from our payment processor, the last four digits of your credit card to search for
              specific transactions in case we want to issue a refund or other related customer support assistance.
              Additionally, we do not collect any bank account information from tutors to process their payments for
              conducting the sessions, nor is any bank account information passed back to us. Please see{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers">here</a> for a list of what
              information we receive from third-parties in connection with your payment method as well as what purchase
              events and analytics we track through our service providers in connection with your purchases. These
              purchase events and analytics are collected for internal analysis on product usage and purchase. We may
              also use it to track purchases that you did not make, and for sending push and email notifications to a
              parent’s email or device to encourage them to take actions within the Service (such as finalizing a
              purchase).
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Survey Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              Your responses to surveys that we or our{" "}
              <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a> might ask you
              to complete for internal research purposes and product improvement. These surveys may also ask for your
              name and email as well as other free form and multiple-choice fields. We may also ask parents survey
              questions related to how the school year has been going for their child to get a sense of how their child
              is doing in school and personalize the experience as needed. We will not collect survey information of
              learners under 13.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Conduct and Safety Information</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              If a user reports another user on the Service (e.g., such as through a “report abuse” button) for conduct
              or other safety issues, Dojo Tutoring collects this information to investigate the report.
            </Text>
          </li>
        </ul>
        <Box>
          <Text weight="bold">Contact Information and Correspondence with Dojo Tutoring</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              As a user of the Service or Website, you may choose to provide us with your {personalInformationLink()},
              such as a name, email address, and telephone number. Some examples include when you send us an email
              asking a question, communicate with customer support, participate in a video testimonial about our
              Service, or choose to participate in any research efforts, including a customer feedback session, with
              Dojo Tutoring to improve the Service. We collect records and copies of your correspondence (including
              email address and chat messages) when contacting us for support. When you send us a message using the
              &quot;Contact Us&quot; page or via email, the email addresses and phone numbers collected are not further
              used to market to the individual beyond providing the services requested or responding to the requests.
            </Text>
          </li>
        </ul>
        <Box>
          <Text className={classes.paragraph}>Testimonials</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              We may collect certain {personalInformationLink()} (such as your name, photo, and/or video) if you choose
              to give us a testimonial. We post testimonials on our Service which may contain this personal information
              in the testimonial. We obtain the individual’s consent in advance to ensure we have permission to post
              this content publicly. To request the removal of your personal information from our testimonials, please
              contact us at <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com.</a>
            </Text>
          </li>
        </ul>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We ask you for different types of information based on who you are (i.e., tutor, parent, or learner) and
            which Dojo Tutoring features you use (e.g., sending a message between a tutor and a parent, or conducting a
            tutoring session via Zoom). We have also prepared{" "}
            <a href="https://tutoring.classdojo.com/transparency">this chart</a> of the personal information we collect
            from each user type, including details on the sources from which the information was collected, the business
            purpose for which the information was collected, and the third parties with whom we disclose personal
            information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <h3 id="information-we-collect-through-automatic-data-collection">
            Information We Collect Through Automatic Data Collection Technologies
          </h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            When you use the Service or Website, we (or our {serviceProvidersLink()}) may use various technologies that
            automatically record or collect certain information, including {personalInformationLink()}, from your
            browser or your device about the services you use and how you use them. This information is necessary for
            the adequate performance of the contract between you and us, to enable us to comply with legal obligations
            and given our legitimate interest in being able to provide and improve the Service and Website. For example,
            this could include the frequency and duration of your visits to Dojo Tutoring or the Website (similar to TV
            ratings that indicate how many people watched a particular show). If you use Dojo Tutoring on different
            devices, we may link the information we collect from those different devices to help us provide a consistent
            Service across your different devices. If we do combine any automatically-collected information with
            personal information, we will treat the combined information as personal information, and it will be
            protected as per this Privacy Policy.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>The technologies and information we automatically collect include:</Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Cookies and other similar technologies:</b> We (or our {serviceProvidersLink()}) may use{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_3cee3e5e-c072-4fc2-821d-84258d56c2c4">
                cookies or similar technologies
              </a>{" "}
              to identify your browser or device. We (or our service providers) may also use these technologies (never
              in {learnersLink()} logged in areas of our Service) in connection with advertising of our Service that may
              appear on other sites or in connection with advertising their products outside of our Service (e.g., if
              you view an embedded YouTube video player on our Service, YouTube may place cookies or similar
              technologies on your browser when you play the video off our site). We don’t allow these third-parties to
              advertise directly on our Service (i.e., such as when an advertiser would bid to place an advertisement
              directly on a platform such as Facebook), but we may serve{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
                contextually relevant
              </a>{" "}
              advertising for third-party products and services ourselves that we believe may be of interest to you
              (e.g., tutoring for other subjects that your kid might benefit from). Please read our{" "}
              <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a> for
              more details, including how to modify your cookie settings and a list of the tracking technologies we use.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_efdb3ae6-8829-4e77-a8e0-b47c865fbe1b">
                Local storage:
              </a>{" "}
              We may also use, collect, and store information and preferences locally on your device using mechanisms
              such as browser-based web storage (including HTTP cookies and HTML5) and application data caches. Like
              many services, Dojo Tutoring (or our service providers) uses both local storage and cookies and other
              similar technologies to analyze trends, gather demographic information about our users, understand your
              engagement with the Service and Website, administer the Service and Website, tailor the Service and
              Website for you, and to help the Service and Website work better for you - for example, by remembering
              your language preferences or other information so that you will not have to re-enter it during the next
              time you visit the Website or Services. Please read our{" "}
              <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a> for
              more details.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Device information:</b> We collect device-specific information such as your device type, device brand,
              operating system, hardware version and settings, device settings, file and software names and types,
              battery and signal strength, and device identifiers (including unique device IDs). We may also collect
              device event information such as crashes, system activity, browser type, browser language, the date and
              time of your request and referral URL. This helps us measure how the Website and Service is performing,
              improve Dojo Tutoring for you on your particular device, and send you push notifications if you’ve opted
              in to receive them. Zoom may also collect the following from the device: information about the speakers,
              microphone, camera, OS version, hard disk ID, PC name, MAC address, WiFi information, and other device
              information (like Bluetooth signals). Please see the Zoom{" "}
              <a href="https://explore.zoom.us/en/privacy/">Privacy Policy</a> for more information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Mobile application information:</b> Certain mobile applications or “apps” include a unique application
              number. This number and information about your installation (for example, the operating system type and
              application version number) may be sent to Dojo Tutoring when you install or uninstall that application,
              such as when you install the Dojo Tutoring App, when you open the application, or when that application
              periodically contacts our servers, such as for automatic updates. Additionally, we may receive application
              state and crash log information which will help us debug and improve the Dojo Tutoring App.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Server log information:</b> Like most online services, when you use our Service or Website we
              automatically collect and store certain information in our{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_b35dfe89-b21e-4bb2-8616-856d62ad6237">
                server logs
              </a>
              . Examples include things like:
              <ul>
                <li>
                  <Text className={classes.listText}>
                    Telephone log information like your phone number and SMS routing information
                  </Text>
                </li>
                <li>
                  <Text className={classes.listText}>
                    <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f2f016f2-e461-4443-8c29-6bb597063447">
                      IP address
                    </a>
                  </Text>
                </li>
                <li>
                  <Text className={classes.listText}>
                    Details of how you used our Service or Website, such as your activity on the Service or Website
                    (including product event data used to track progress or user activity), and the frequency and
                    duration of your visits to the Service or Website (similar to TV ratings that indicate how many
                    people watched a particular show)
                  </Text>
                </li>
              </ul>
              This information helps us make decisions about what we should work on next - for example, by showing which
              features are most popular and to send notifications to a device (such as push notifications if the user
              opted in) to encourage the user to finish an activity or start the next activity. We may use your IP
              address and share it with third parties to help diagnose problems with our servers, for security purposes,
              to manage the Service or Website, to enhance the Services or Website based on usage pattern data and, for
              analytics and for advertising. Dojo Tutoring will not share IP address information of learners with third
              parties for analytics and advertising purposes.{" "}
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Location information:</b> When you use our Service or Website, we may collect and process information
              about your geographic location, for example, based on your IP address. We collect both coarse (i.e.,
              city-level) location data and may collect precise location data. If we do request precise geolocation
              information (“precise” meaning sufficient to identify street name and name of city or town) from you (such
              as when we want to send merchandise to specific parents), we ask for your explicit opt-in permission. We
              **never** request precise geolocation data from learners . From all users, if they upload photos or videos
              from their computer, or from their phone&apos;s camera roll directly, as opposed to choosing to use Dojo
              Tutoring ’s own application camera, we collect what the device sends us. If the device sends us
              Exchangeable Image File Format (EXIF data) (metadata contained within the image file - e.g., camera model,
              lighting conditions, and location (if enabled)) this can contain location data (and may contain precise
              location data). We strip all EXIF data, including the location information, from the photo before it is
              viewed by other users (e.g., the parent in Messaging). If a user accesses and uses our mobile camera
              within the application itself, the camera has been configured so as to not collect any EXIF data,
              therefore the EXIF information is not stored (as opposed to if you use your device’s own camera roll).
            </Text>
          </li>
          <br />
          We will not store or track your precise device location on an ongoing basis or without your permission. We do
          not share precise geolocation data with third-parties, other than our {serviceProvidersLink()} as necessary to
          provide the Service. If you no longer wish to allow us to track your location information, you may opt-out at
          any time by turning it off at the device level.
          <li>
            <Text className={classes.listText}>
              <b>Cross-device collection:</b> To provide users with a seamless online experience, we may link your
              identifiers on different browsers and environments you are using. We may also work with third-party
              partners to employ technologies, including the application of statistical modeling tools, which permit us
              to recognize and contact you across multiple devices.
            </Text>
          </li>
        </ul>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We collect some information from you automatically (and additionally use cookies and other similar
            technologies to collect information) so that we know when things go wrong, or to help us understand what
            parts of Dojo Tutoring need some improvement. If we need to request street-level (“precise”) geolocation
            data from you as a tutor or parent, we’ll ask for your explicit, opt-in consent. We never request precise
            geolocation data from learners.
          </Text>
        </Box>
        <br />
        <Box>
          <h3 id="information-received-from-third-party-sources">Information Received from Third Party Sources</h3>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We may also obtain information, including {personalInformationLink()}, from third-party sources to update or
            supplement the information you provided, or we collected automatically. This may include aggregated
            anonymous information or certain personal information that may be provided to us (e.g., background check
            information). If we receive personal information from third-parties, we will handle it in accordance with
            this Privacy Policy. If we directly combine information we receive from other third-parties with personal
            information that we collect through the Service or Website, we will treat the combined information as
            personal information and handle it in accordance with this Privacy Policy. Additionally, we may use any
            aggregated anonymous information received by third-parties as set forth below under the heading{" "}
            <Link to="#aggregated-information-and-non-identifying">
              “Aggregated Information and Non-Identifying Information”
            </Link>
            . Local law may require you authorize the third-party to share your information with us before we can
            acquire it. We do not control, supervise, or respond to how third parties providing your information process
            your personal information, and any information request regarding the disclosure of your personal information
            to us should be directed to such third-parties. Please see this{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">chart</a> with the detailed
            categories of personal information we collect from each user type, including the sources from which the
            information was collected, the business purpose for which the information was collected, and the third
            parties with whom we disclose personal information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            We may collect some information from third-party sources, such as Checkr when we do background checks on
            tutors. That information will be handled under this Privacy Policy. Please see{" "}
            <Link to="/transparency">this chart</Link> with the detailed categories of personal information we collect
            from each user type, including the sources from which the information was collected, the business purpose
            for which the information was collected, and the third parties with whom we share or disclose personal
            information for a business purpose.
          </Text>
        </Box>
        <br />
        <Box>
          <h3 id="online-analytics-advertising-and-do-not-track">Online Analytics, Advertising and Do-Not-Track</h3>
        </Box>
        <Box>
          <Text weight="bold">Online Analytics Providers</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            We may collect analytics data, or use third-party analytics tools, to help us measure traffic and usage
            trends for the Services and Website and to understand more about the demographics of our users. These third
            parties use the sorts of technologies described in the{" "}
            <Link to="#automatically-collected-information">“Automatically Collected Information”</Link> section above.
            The information collected by this technology will be disclosed to or collected directly by these service
            providers. A current list of analytics providers that we use is located{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">here</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Advertising Technology Providers</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring does not display any{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
              behaviorally targeted advertising
            </a>{" "}
            on our Service to learners (i.e. in learner logged in areas of our Website). We may serve{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
              contextually relevant advertising
            </a>{" "}
            for third-party products and services that we believe may be of interest to you (e.g. a partnership with
            math online platform that can help your child practice at home).
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            However, data about your activities on our Website (outside of our Service) may be collected by us or by
            third-party advertising technology providers for use in delivering online advertising tailored to your
            individual characteristics, activities, interests and other factors. These ad services may track your online
            activities over time and across the web by collecting information through automated means, including through
            the use of the various technologies described above, and they may use this information, and other
            information they receive from us or other sources, to deliver advertisements to you on our website, our
            emails, and other websites. We and third-party vendors may use first-party cookies and third-party cookies
            together, and other automated technologies, and in some cases additional information, (i) to inform,
            optimize, and serve ads based on past visits to our Websites or other factors and (ii) to report how ad
            impressions, other uses of ad services, and interactions with these ad impressions and ad services are
            related to visits to our Websites or other factors.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            You may visit the{" "}
            <a href="http://www.networkadvertising.org/choices/">
              Network Advertising Initiative’s Consumer Opt-Out page
            </a>{" "}
            or the <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance’s Consumer Opt-Out page</a>{" "}
            to opt out of receiving tailored advertising based on your Internet browsing activities from companies that
            participate in those programs. You may also manage certain advertising cookies by visiting the{" "}
            <a href="http://www.youronlinechoices.eu/">EU-based Your Online Choices.</a> You may also be able to limit
            interest-based advertising through the settings on your mobile device by selecting “limit ad tracking” (iOS)
            or “opt-out of interest based ads” (Android). You may also be able to opt-out of some – but not all –
            interest-based ads served by mobile ad networks by visiting http://youradchoices.com/appchoices and
            downloading the mobile AppChoices app. Some of our advertising-related activities may involve the services
            of companies that do not participate in those programs. For example, we may use the Google AdWords
            remarketing service to advertise on third party websites (including Google) to previous visitors to our
            Website. You can set preferences for how Google advertises to you using the{" "}
            <a href="http://www.google.com/settings/ads/onweb/">Google Ad Preferences page.</a> The opt-outs apply only
            to the browser in which you set them. For example, if you set the opt-out while using Firefox, but then use
            Chrome, the opt-out will not be active in Chrome. To opt out in Chrome, you will need to repeat the opt-out
            process.
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            The opt-outs described in this Privacy Policy do not block or delete cookies, or prevent the use of cookies
            or other technologies for purposes other than selecting ads based on your interests as inferred by your
            online behavior. If you opt out, data may still be collected about your web browsing activities and you will
            still see advertising. Ads may be delivered based on other factors, such as the content of the web page in
            which they are shown, or demographic information obtained through other means.
          </Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Please read our{" "}
            <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a> for more
            details, including how to remove or modify your cookie settings and a list of the advertising tracking
            technologies we use.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">YouTube Dojo</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Tutoring uses YouTube to display video content on the Website and may use on the Services. YouTube
            incorporates tracking technologies, which may be present in the YouTube videos embedded on the Service,
            which may collect information from your browser when you view the video on the Service, including device
            identifiers and/or cookie IDs. This information is collected directly and automatically by YouTube and its
            partners, and Dojo Tutoring does not participate in these data transmissions. Dojo Tutoring does not provide
            any personal information, such as usernames, to YouTube.
          </Text>
        </Box>
        <br />
        <Box>
          <Text className={classes.paragraph}>
            Dojo Tutoring has taken steps to minimize this tracking activity, to the extent we have control over a
            third-party’s practices. We have turned on “Privacy-Enhanced Mode” for YouTube videos displayed on the
            Service and Website. We also utilize Google’s “child-directed treatment” tags to alert Google that these
            YouTube videos may be embedded on webpages visited by children or learners. You can learn more about
            Google’s practices at{" "}
            <a href="http://www.google.com/policies/privacy/partners">
              http://www.google.com/policies/privacy/partners
            </a>
            . You may also be able to control ad tracking through YouTube by visiting the Google Ad Settings page,
            currently available at: <a href="https://adssettings.google.com/">https://adssettings.google.com</a>.
          </Text>
        </Box>
        <br />
        <Box>
          <Text weight="bold">Do Not Track Disclosure</Text>
        </Box>
        <Box>
          <Text className={classes.paragraph}>
            Do Not Track (&quot;DNT&quot;) is a privacy preference that users can set in certain web browsers to inform
            websites and services that they do not want to be“tracked”. For more information on “do not track”, please
            visit <a href="http://www.allaboutdnt.org">www.allaboutdnt.org</a>. The industry working group (W3C Tracking
            Protection) to develop the DNT standard is now{" "}
            <a href="https://www.w3.org/2011/tracking-protection/">closed</a>, so Dojo Tutoring does not respond to
            these DNT signals. We will honor Global Privacy Control Signals or Opt-Out Preference Signals for certain
            users as set forth in our <Link to="#california-privacy-disclosures">“California Privacy Disclosures”</Link>{" "}
            section below (both as defined in the California Privacy Disclosures section).
          </Text>
        </Box>
        <br />
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
          </Text>
        </Box>
        <Box>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Dojo Tutoring does not recognize or respond to browser-initiated DNT signals given the lack of consensus on
            determining how to comply. However, if you are located in certain jurisdictions (such as California), we
            will honor Global Privacy Control Signals or Opt-Out Preference Signals as required by law. For more
            information, see our <Link to="#california-privacy-disclosures">California Privacy Disclosures</Link>{" "}
            section.
          </Text>
        </Box>

        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="how-does-dojo-use-info"
        >
          How Does Dojo Tutoring Use the Information it Collects?
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            First and foremost, you should know that Dojo Tutoring does not{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent any of your, or your child’s,{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            to any third-party for any purpose - including for advertising or marketing purposes.{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_6fb13f0c-b840-412f-9956-2a519005384d">
              Third-Party Advertising
            </a>{" "}
            is not permitted on areas where users are required to log in to Dojo Tutoring and personal information
            collected from{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>{" "}
            is never used for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_031173d7-11db-40b2-ade4-9e3e8c3f4d54">
              behaviorally-targeted advertising
            </a>{" "}
            to learners (by us or third-parties). We may serve{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_f3f313ab-9399-4e01-b840-1bba5756fab2">
              contextually relevant advertising
            </a>{" "}
            for third-party products and services that we believe may be of interest to you (e.g. we may suggest using
            external learning platforms to help your child practice the skills they are learning during tutoring). Dojo
            Tutoring commits that its collection, use, retention and sharing of personal information shall be reasonably
            necessary and proportionate to achieve the purposes for which the personal information was collected or
            processed and for the purposes disclosed in this Privacy Policy and{" "}
            <a href="https://tutoring.classdojo.com/transparency">information transparency chart</a> and that the
            personal information will not be further processed in a manner incompatible with these purposes.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We use your personal data only when we have a valid legal basis to do so. More specifically, this
            information collected is used to:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Power Our Services.</b> This includes, operating, providing, enhancing and improving the Service and
              Website, for example, by developing new products and features, and personalizing or customizing the
              Service and Website for you or providing access to restricted parts of our Website or Services.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Provide Internal Analytics and Reviews.</b> This includes using for internal audits and review, for
              analyzing how the Website and Services are used, providing statistics about the usage levels of the
              Website and Services, and diagnosing service or technical problems
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Process Your Transactions.</b> To fulfill your requests and provide any information you request, such
              as responding to your emails, submissions, questions, comments, requests for information or customer
              support or for event registration, scheduling or attendance; or for any other purpose for which the
              information was collected (e.g., to process your tutor application, fulfilling tutoring online session
              orders), or for which you provide your consent (if required).
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Communicate with You.</b> To send you information about features on our Service and Website or changes
              to our policies and Service or Website or communicate with you about your use of the Service and Website,
              your account, or transactions with us. We may also send you security alerts, and support and
              administrative messages and otherwise facilitate your use of, and our administration and operation of the
              Service and Website (including notifying you of data privacy incidents). Additionally, we may provide
              tutors, and parents information about events, announcements, offers, promotions, products, including
              third-party products, and services we think will be of interest to you and notify and contact contest and
              sweepstakes entrants.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Determine Eligibility or Evaluate Your Performance.</b> To determine eligibility for becoming a tutor,
              or other membership, credentials, designations, or volunteer opportunities or to evaluate your performance
              on continued learning courses and assist you in the tracking of your progress. For tutor applications and
              tutoring sessions, we may record, save, and share video and audio recordings for review by peer reviewers,
              a review board, and/or a team of internal reviewers. All such reviewers will be restricted from using or
              disclosing such videos other than for the purposes related to providing or improving the Services or our
              business.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Request Your Participation in Surveys or Focus Groups.</b> To request your participation in ratings,
              reviews, surveys, focus groups, or other initiatives which help us to gather information used to develop
              and enhance our Website, products and Services.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Security and Fraud Prevention.</b> Most crucially, to protect our community by making sure the Service
              and Website remains safe and secure, such as by detecting, investigating and preventing activities that
              may violate our policies or be illegal.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Comply with Law.</b> To comply with applicable law - for example, to satisfy tax or reporting
              obligations, to comply with a lawful governmental request or notify you of data privacy incidents when
              legally required.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We use automatically collected information (described in the{" "}
            <Link to="#automatically-collected-information">&quot;Information collected automatically&quot;</Link>{" "}
            section above) to provide and support our Service and the Website, and for the additional uses described in
            that section of our Privacy Policy. To learn more about how we use your information for personalization and
            tracking, please see the{" "}
            <a href="https://www.tutoring.classdojo.com/cookies-policy">Online Tracking Technologies Policy</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutoring doesn’t{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your information to third-parties, we don’t permit{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_6fb13f0c-b840-412f-9956-2a519005384d">
              third-party advertising
            </a>{" "}
            on Dojo Tutoring, and personal information collected from{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learners
            </a>
            is never used for{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#BehaviorallyTargetedAdvertising">
              behaviorally-targeted advertising
            </a>{" "}
            to learners. We use your information to provide and personalize the Service and Website to you, optimize and
            improve our Service and Website, communicate with you about our Service and Website, and for security and
            safety reasons.
            <br />
            We may also use the information provided by parents and tutors to provide parents and tutors information
            about events, announcements, offers, promotions, products, including third-party products, and services we
            think will be of interest to you.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="how-we-share-you-personal-information"
        >
          How We Share Your Personal Information
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Dojo Tutoring does not{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your (or your child’s){" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            to any third-party for any purpose - including for advertising or marketing purposes. Furthermore, we do not
            disclose personal information to any third-parties except in the limited circumstances described in this
            Privacy Policy and as set forth below:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              <b>Other Users You Share and Communicate with on Dojo Tutoring:</b> No parent, or{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learner
              </a>{" "}
              profiles are made available to the general public through our Service, but as a tutor, you may choose to
              share your profile publicly. If you are a learner, tutor, or parent, you may choose to share information
              or content through the Service with other Dojo Tutoring learners, tutors, and parents - for example,
              things like your account information, more detailed profile information or other information you share
              with tutors, learners, or parents you are communicating with through Dojo Tutoring Messaging or our other
              collaboration features.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Other Users You Share and Communicate with on Dojo Tutoring:</b> No parent, or{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
                learner
              </a>{" "}
              profiles are made available to the general public through our Service, but as a tutor, you may choose to
              share your profile publicly. If you are a learner, tutor, or parent, you may choose to share information
              or content through the Service with other Dojo Tutoring learners, tutors, and parents - for example,
              things like your account information, more detailed profile information or other information you share
              with tutors, learners, or parents you are communicating with through Dojo Tutoring Messaging or our other
              collaboration features.
              <br />
              <br />
              Please keep in mind that information (including personal Information or children&apos;s personal
              information) or content that you voluntarily disclose to others - including to other Dojo Tutoring users
              you interact with through the Service (such as messages you might send other users or other learners,
              tutors, and parents you collaborate with) - can be viewed, copied, stored, and used by the people you
              share it with. We cannot control the actions of people with whom you choose to share information and we
              are not responsible for the collection, use, or disclosure of such information or content by others.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Peer Review, Session Review and Mentoring:</b> We disclose personal information to approved reviewers
              for peer review, session review, and other operations of the Dojo Tutoring platform.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Third-Party Integrations on our Service:</b> When, as a learner, tutor, or parent, you use third-party
              apps, websites, or other services that use, or are integrated with, our Website or Service, they may
              receive information about what you post or share. For example, when you share feedback or your tutoring
              session summary on Twitter or Facebook, or as a tutor you provide third party links or integrations for
              your learners during a tutoring session, these services receive the information that you share through
              this functionality and information that you are sharing from Dojo Tutoring. Please see{" "}
              <Link to="#third-party-service-providers">here</Link> for more information on third-party services. Please
              see <Link to="/third-party-service-providers/">here</Link> for a list of third-party app integrations on
              our Service and the Website.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Service Providers:</b> We do work with vendors, service providers, and other partners to help us
              provide the Service and Website by performing tasks on our behalf. These service providers may be located
              inside or outside of the European Economic Area (“EEA”). We may need to provide information (including
              personal information) to them to help them perform these business functions, for example sending emails on
              our behalf, database management services, database hosting, video hosting and storage, providing customer
              support software, and security. We may also authorize these third-party companies to collect information
              on our behalf. These providers have limited access to your personal information to perform these tasks on
              our behalf and are contractually bound to protect and use it only for the purpose for which it was
              disclosed. Additionally, these partners and service providers must adhere to confidentiality and security
              obligations in a way that is consistent with this Privacy Policy. Please see{" "}
              <Link to="/third-party-service-providers/">here</Link> for a list of the third-parties we work with to
              provide the Service and the Website.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Social Media Platforms:</b> Where permissible according to applicable law, we may share certain limited
              personal information with social media platforms (such as Google or Facebook) and other websites,
              applications or partners, to generate leads, drive traffic to our websites or otherwise market and
              advertise our products or Services on those websites or applications (this will never include personal
              information of learners). These processing activities are based on our legitimate interest in undertaking
              marketing activities to offer you products and services that may be of your interest. These social media
              platforms with which we may share your personal information are not controlled or supervised by Dojo
              Tutoring. Therefore, any questions regarding how your social media platform processes your personal
              information should be directed to such provider.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Analytics Services:</b> We use analytics services, including mobile analytics software, to help us
              understand and improve how the Service and Website are being used. These services may collect, store and
              use information in order to help us understand things like how often you use the Service or Website, the
              events that occur within the application, usage, performance data, and from where the application was
              downloaded. A current list of analytics providers that we use is located{" "}
              <Link to="/third-party-service-providers/">here</Link>.
            </Text>
          </li>
          <li>
            <Text
              className={classes.listText}
              sx={{ scrollMarginTop: "130px" }}
              id="aggregated-information-and-non-identifying"
            >
              <b>Aggregated Information and Non-Identifying Information:</b> We may share aggregated information
              (information about our users that we combine together so that it no longer identifies or references an
              individual user) and other{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
                de-identified
              </a>{" "}
              or non-personally identifiable information (such as statistics about visitors, traffic and usage patterns)
              with third parties for any purpose, including with users, partners or the press in order to, for example,
              demonstrate how the Service or Website is used, spot industry trends, or to provide marketing materials
              for Dojo Tutoring. Any aggregated information and non-personalized information shared this way will not
              contain any personal information.
            </Text>
          </li>
          <li>
            <Text className={classes.listText} sx={{ scrollMarginTop: "130px" }} id="legal-requirements">
              <b>Legal Requirements:</b> We may disclose information, including personal information, if we have a good
              faith belief that doing so is necessary to comply with the law, such as complying with a subpoena or other
              legal process. We may also need to disclose personal information where, in good faith, we think it is
              necessary to protect the rights, property, or safety of Dojo Tutoring, our employees, our community, or
              others, or to prevent violations of our <Link to="/termsofservice">Terms of Service</Link> or other
              agreements. This includes, without limitation, responding to law enforcement and government requests and
              investigating and defending ourselves against third-party claims and allegations. Where appropriate, we
              may notify users about the legal requests, unless (i) providing notice is prohibited by the legal process
              itself, by court order we receive, or by applicable law; (ii) we believe that providing notice would be
              futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or
              increase a risk of fraud upon Dojo Tutoring, or its users, the Service or Website. In instances where we
              comply with legal requests without notice for these reasons, we will attempt to notify that user about the
              request after the fact where appropriate and where we determine in good faith that we are no longer
              legally prevented from doing so.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Fraud and Security Protection:</b> We may share information to assist with security operations for
              scheduled penetration testing, auditing, and continuous improvement of our security practices, including
              without limitation, exchanging information with other companies and organizations for fraud protection.
              Authorized parties of the information would comply with our current privacy and security policies.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Sharing with Dojo Tutoring Companies:</b> Over time, Dojo Tutoring may grow and reorganize. We may
              share your personal information with affiliates such as a parent company, subsidiaries, joint venture
              partners, or other companies that we control or that are under common control with us, in which case we
              will require those companies to agree to use your personal information in a way that is consistent with
              this Privacy Policy.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>Change of control:</b> The{" "}
              <a href="https://studentprivacypledge.org/signatories/">Student Privacy Pledge</a> requires us (and all
              pledgers) to commit to how we handle data in the event of a change to our organizations such that all or a
              portion of Dojo Tutoring or its assets are acquired by or merged with a third-party, or in any other
              situation where{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              that we have collected from users would be one of the assets transferred to or acquired by that
              third-party. Consistent with the Student Privacy Pledge and applicable state and federal laws, in
              connection with such a change to our organization, if any, this Privacy Policy will continue to apply to
              your information, and any acquirer would only be able to handle your personal information as per this
              policy (unless you give consent to a new policy). We will provide you with notice of an acquisition within
              thirty (30) days following the completion of such a transaction, by posting on our homepage, or by email
              to your email address that you provided to us. If you do not consent to the use of your personal
              information by such a successor company, subject to applicable law, you may request its deletion from the
              company. We may also make information, including personal information, available to a potential investor
              or purchaser (or their advisors) in advance of any such transaction completion. <br />
              <br /> In the unlikely event that Dojo Tutoring goes out of business, or files for bankruptcy, we will
              protect your personal information, and will not{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
                sell
              </a>{" "}
              it to any third-party. For more information on our practices regarding your data if we go out of business,
              please see our <Link to="https://classdojo.zendesk.com/hc/articles/115004809286">FAQ</Link>.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              <b>With your consent:</b> Other than the cases above, we won’t disclose your{" "}
              <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                personal information
              </a>{" "}
              for any purpose unless you consent to it.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutoring doesn’t{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            to any third-parties. We believe your information is yours, and you should own it - we think that’s the
            right way to operate. We share some information with{" "}
            <Link to="/third-party-service-providers">service providers</Link> who help us provide you with the Service
            and Website – like companies that help us send emails, for example, or if we have to comply with the law.
            Additionally, you may choose to share information with other users of the Service or to third-parties that
            are integrated on our Service, or to provide us consent for other specific sharing of your personal
            information. We may also share aggregated information or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identified
            </a>{" "}
            information (such as statistics about visitors, traffic and usage patterns) with third parties for any
            purpose (information shared this way will not contain any personal information). We may also share your
            personal information with other Dojo Tutoring companies (such as a parent or affiliate).
            <br />
            And, if Dojo Tutoring is ever acquired or goes out of business, our commitments don’t change: we still won’t{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            or rent your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            to anyone. Your personal information will continue to be protected by this policy, and any company that
            acquires Dojo Tutoring will have to abide by this policy unless you give consent to a new policy.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          mb={24}
          mt={48}
          id="how-does-tutoring-protect-information"
        >
          How Does Dojo Tutoring Protect and Secure My Information?
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            The security of your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            is important to us. The safety and security of your information also depends on you. You should maintain
            good internet security practices. Where you have password-protected access to an account or certain parts of
            the Website or Services, you are responsible for keeping this password secure at all times. You should not
            share your password with anyone. You must prevent unauthorized access to your account and{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal
            </a>{" "}
            information by selecting and protecting your password appropriately and limiting access to your computer or
            mobile device and browser by signing off after you have finished accessing your account. If you think that
            any of your accounts have been compromised you should change your account credentials with us, and in
            particular make sure any compromised account does not allow access to your account with us. We will never
            email you to ask for your password or other account login information. If you receive such an email, please
            send it to us so we can investigate.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We work hard to protect our community, and we maintain reasonable administrative, technical, and physical
            security procedures and practices appropriate to the nature of the personal information designed to protect
            personal information from unauthorized or illegal access, destruction, use, modification or disclosure.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            In particular:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>
              We perform application security testing; penetration testing; conduct risk assessments; and monitor
              compliance with security policies
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We periodically review our information collection, storage, and processing practices, including physical
              security measures, to guard against unauthorized access to systems
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Whenever we develop new features, we do in a manner to keep your personal information safe
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              When you enter any personal information anywhere on the Service, we encrypt the transmission of that
              information using secure socket layer technology (SSL/TLS) by default
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Dojo Tutoring’s databases where we store your personal information is encrypted at rest, which converts
              all personal information stored in the database to an unintelligible form.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We ensure passwords are stored and transferred securely using encryption and salted hashing
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              The Service and Website is hosted on servers at a{" "}
              <Link to="/third-party-service-providers">third-party facility</Link>, with whom we have a contract
              providing for enhanced security measures. For example, personal information is stored on a server equipped
              with industry-standard firewalls. In addition, the hosting facility provides a 24x7 security system, video
              surveillance, intrusion detection systems, and locked cage areas
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We also operate a ‘bug bounty’ security program to encourage an active community of third-party security
              researchers to report any security bugs to us. More information on this is available by contacting us at{" "}
              <a href="mailto:tutoringsecurity@classdojo.com">tutoringsecurity@classdojo.com</a>
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              We restrict access to personal information to authorized Dojo Tutoring employees, agents or independent
              contractors who need to know that information in order to process it for us, and who are subject to strict
              confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            For additional information on our security practices, please see our{" "}
            <a href="https://www.classdojo.com/security/">Security Whitepaper</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Although we make concerted good faith efforts to maintain the security of personal information, and we work
            hard to ensure the integrity and security of our systems, no practices are 100% immune, and we can’t
            guarantee the security of information. Outages, attacks, human error, system failure, unauthorized use, or
            other factors may compromise the security of user information at any time. If we learn of a security breach,
            we will attempt to notify you electronically (subject to any applicable laws and school reporting
            requirements) so that you can take appropriate protective steps; for example, we may post a notice on our
            homepage <Link to="/">www.tutoring.classdojo.com</Link>) or elsewhere on the Service or Website, and may
            send email to you at the email address you have provided to us. Depending on where you live, you may have a
            legal right to receive notice of a security breach in writing.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            The security of your information is important to us, and we take it very seriously. We’re always adding
            safeguards to ensure the safety and security of Dojo Tutoring and our community of tutors, learners and
            parents. You can help us out by keeping your password secret! When you enter information anywhere on the
            Service or the Website, we encrypt the transmission of that information using SSL by default and the
            databases where we store your personal information is encrypted at rest. Please see our{" "}
            <a href="https://www.classdojo.com/security/">Security Whitepaper</a> for more details.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          mb={24}
          mt={48}
          id="how-long-does-dojo-keep-information"
        >
          How long does Dojo Tutoring Keep Information About me?
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <i>Non-Learner Users:</i>
            <br />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            We store your <a>personal information</a> for as long as it is necessary to provide the Service or Website
            to you and others, including those described above. We determine the appropriate retention period for
            personal information on the basis of the amount, nature and sensitivity of the personal information being
            processed, the potential risk of harm from unauthorized use or disclosure of the personal information,
            whether we can achieve the purposes of the processing through other means, and on the basis of applicable
            legal requirements (such as applicable statutes of limitations). The time periods for which we retain your
            personal information depend on the purposes for which we use it. Personal information associated with your
            account will be kept until your account is deleted, unless we no longer need the data to provide the
            Website, products and Services, in which case we will delete prior to you deleting your account. Please see
            our information <Link to="/transparency">transparency chart</Link> for more specific information on
            retention periods.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            You can, of course, <Link to="#delete-your-account">delete your account</Link> at any time. Please note that
            we may have to retain some information after your account is deleted, to comply with legal obligations, to
            protect the safety and security of our community or our Website or Service, or to prevent abuse of our{" "}
            <Link to="/termsofservice">Terms</Link>. Additional examples of when we may need to retain personal
            information after your account is deleted:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>Our contractual and business relationships with you.</Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Financial recordkeeping – when you make a payment to us (or we make a payment to you (e.g. for your
              tutoring sessions) we often required to retain this information for long period of time for purposes of
              accounting, dispute investigation and resolution, and compliance with tax, anti-money laundering, and
              other financial regulations.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Direct communications with us – if you have directly communicated with us, through a customer support
              channel, feedback form, or a bug report, we may retain reasonable records of those communications.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            For more details, please read “What happens when I delete my account?” in our{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQs</Link>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Even if you delete your account, keep in mind that the deletion by our{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">service providers</a> may not be
            immediate and that the deleted information may persist in backup copies for a reasonable period of time. We
            may retain{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identified
            </a>{" "}
            information for as long as we deem appropriate.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <i>Learners and Children:</i>
            <br />
            Please see the{" "}
            <Link to="#how-long-keeps-children-information">
              “How Long Does ClassDojo Keep Children’s Information?”
            </Link>{" "}
            for details on how long we retain data for children and learner users.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            We keep tutor and parent personal information until your account is deleted, or until we no longer need it
            to provide you with the Service, products or Website. We only keep children and learner personal information
            for as long as the learner’s account is{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016429302157">active</a>, unless we are required by law
            to retain it, or need it to protect the safety of our users. Please see our information{" "}
            <Link to="/transparency">transparency chart</Link> for more specific information on retention periods. You
            can, of course, <Link to="#delete-your-account">delete your account</Link> at any time. For more details,
            please read “What happens when I delete my account?” in our{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQs</Link>. Even if you delete your
            account, keep in mind that the deletion by our{" "}
            <Link to="/third-party-service-providers">service providers</Link> may not be immediate and that the deleted
            information may persist in backup copies for a reasonable period of time. We may retain{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identified
            </a>{" "}
            information for as long as we deem appropriate.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          id="providing-transparency-rights"
          mb={24}
          mt={48}
        >
          Dojo Tutoring’s Commitments to Providing Transparency and Your Rights
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We try to be transparent about what information we collect so that you can make meaningful choices about how
            it is used. You may have certain rights relating to your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>
            , subject to local data protection laws. Please review local data protection laws for more information.
            Depending on the applicable local laws these rights may include the rights set forth in this Section. You
            also have a right not to be discriminated against for exercising any of your rights set forth in this
            Section.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                marginLeft: "20px",
              } as any
            }
          >
            <Link to="#access-correct-personal-information">Access or Correct Your Personal Information</Link>
            <br />
            <Link to="#delete-your-account">Delete Your Personal Information or Account from Dojo Tutoring </Link>
            <br />
            <Link to="#object-restrict-withdraw">Object or Restrict Processing</Link>
            <br />
            <Link to="#object-restrict-withdraw">Withdraw Consent</Link>
            <br />
            <a href="https://classdojo.zendesk.com/hc/articles/12038504162701">
              Take Your Information Out of Dojo Tutoring
            </a>
            <br />
            <Link to="#additional-article-27-GDPR">
              Additional Information or Assistance and Article 27 GDPR Local Representative
            </Link>
            <br />
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If you are a California resident, you may have additional rights as set forth under the California Consumer
            Privacy Act as amended by the California Privacy Rights Act and its implementing regulations (collectively
            the “CPRA”). Please see the Section “California Privacy Disclosures” for more information if you are a
            California resident.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            At Dojo Tutoring, we believe that more transparency is better. We try to give you control, with easy
            settings and options, so that you can make good choices when it comes to your information and how it is
            used. We also set forth the ways in which you can exercise your rights to access, correct, and delete your
            personal information as well as your right to take information out of Dojo Tutoring and object or withdraw
            consent.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="how-to-exercise-rights"
        >
          How to Exercise Your Rights
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            You may exercise any of these rights described in this Section by sending an email to{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email used
            to create your Dojo Tutoring account or as otherwise set forth in the{" "}
            <Link to="#contact-information">“Contacting Dojo Tutoring”</Link> section below. Please note that we may ask
            you to verify your identity before taking further action on your request, for example by requiring you to
            provide acceptable forms of personal identification as set forth{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016488970381">here</a>. Your personal information may
            be processed in responding to these rights. We try to respond to all legitimate requests within one month
            unless otherwise allowed or required by law, and will contact you if we need additional information from you
            in order to honor your request or verify your identity. Occasionally it may take us longer than a month,
            taking into account the complexity and number of requests we receive. If you are an employee of a Dojo
            Tutoring customer, we recommend you contact your employer’s system administrator for assistance in
            correcting or updating your information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We may reject requests for access, change, or deletion that are unreasonably repetitive (more than two times
            per year for access rights), risk the privacy of others, require disproportionate technical effort (for
            example, developing a new system or fundamentally changing an existing practice), or would be extremely
            impractical (for instance, requests concerning information residing on backup systems).
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            To exercise your rights, please send us an email to{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the same email used
            to create your Dojo Tutoring account. We will respond to all legitimate requests within one month unless
            otherwise allowed or required by law, and will contact you if we need additional information.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="access-correct-personal-information"
        >
          Access or Correct Your Personal Information
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Dojo Tutoring aims to provide you with easy access to any{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            we have collected about you and give you easy ways to update it or delete it, unless we have to keep that
            information for legitimate business purposes (e.g., we need at least an email address for your account if
            you maintain an account with us) or legal purposes. You have the right to correct inaccurate or incomplete
            personal information concerning you (and which you cannot update yourself within your Dojo Tutoring
            account).
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <u>Managing Your Information:</u> If you have registered for an account on Dojo Tutoring, you may update,
            correct, or delete some of your profile information or your preferences at any time by logging into your
            account on Dojo Tutoring and accessing your account settings page. You may have to verify your identity
            before you can do that. You may also, at any time, update or correct, certain personal information that you
            have provided to us by contacting us at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the email used to
            create your Dojo Tutoring account.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <u>Accessing Your Information:</u> Upon request, Dojo Tutoring will provide you with information about
            whether we hold any of your personal information, and, if you are a user of Dojo Tutoring, you may request
            access to all your personal information we have on file by contacting us as set forth above. In some cases,
            we won’t be able to guarantee complete access due to legal restrictions - for example, you will not be
            allowed to access files that contain information about other users or information that is confidential to
            us. Where we can provide information access and correction, we will do so for free, except where it would
            require a disproportionate technical effort.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <u>Accessing Your Child’s Information:</u> Visit our <Link to="#parental-choices">“Parental Choices”</Link>{" "}
            section to see how you can obtain copies of your child’s personal information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            You have certain rights to access, update and correct your personal information. You can always access and
            manage your personal information through your Dojo Tutoring account. Please see{" "}
            <Link to="#parental-choices">here</Link> for more information on accessing your child’s information.
            Additionally, you can exercise your rights to access, update and correct as set forth in the{" "}
            <Link to="#how-to-exercise-rights">“How to Exercise Your Rights section”</Link>.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="delete-your-account"
        >
          Delete Your Personal Information or Account from Dojo Tutoring
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If for some reason you ever want to delete your account (or your child’s account, if you are his or her
            parent), you can do that at any time by contacting us as set forth{" "}
            <Link to="#how-to-exercise-rights">above</Link>. If you are a parent or tutor using the Service, you may
            also delete your account by logging into your account and accessing your account settings page.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            When you delete your account, we delete your profile information and any other content you provide in your
            profile (such as your name, username, password, email address, and profile photos) and depending on the
            category of user you are (i.e., tutor, parent, or learner), the additional content or personal information
            uploaded for use will be deleted as set forth in our{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">FAQ</Link>. Information that you have
            shared with others, others have shared about you, or content other users may have copied and stored, is not
            part of your account and may not be deleted when you delete your account.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We aim to maintain our Services and Website in a manner that protects information from accidental or
            malicious destruction. Because of this, even after you update or delete personal information you have
            provided us from our Service, your personal information may be retained in our backup files and archives for
            a reasonable period of time as necessary for our legitimate business interests, such as fraud detection and
            prevention and enhancing safety. For example, if we suspend a Dojo Tutoring account for fraud or safety
            reasons, we may retain certain information from that account to prevent that user from opening a new account
            in the future. Also, we may retain and use your personal information to the extent necessary to comply with
            our legal obligations.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            You can always delete your account by visiting your account settings, or simply by contacting us at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the email used to
            create your Dojo Tutoring account. For more details on what happens when you delete your account, click{" "}
            <Link to="https://classdojo.zendesk.com/hc/articles/12706732718349">here</Link>.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="object-restrict-withdraw"
        >
          Object, Restrict, or Withdraw Consent
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Where you have provided your consent to the processing of your personal information by Dojo Tutoring you may
            withdraw your consent at any time by changing your account settings or by sending a communication to Dojo
            Tutoring as set forth <Link to="#how-to-exercise-rights">above</Link> specifying which consent you are
            withdrawing. Please note that the withdrawal of your consent does not affect the lawfulness of any
            processing activities based on such consent before its withdrawal. Dojo Tutoring provides parents and tutors
            with the opportunity to withdraw consent or ‘opt-out’ of receiving any future marketing communications from
            Dojo Tutoring and its partners at any time. Please see the{" "}
            <Link to="#communications-from-dojo-tutoring">
              “What communications will I receive from Dojo Tutoring?”
            </Link>{" "}
            section below for more information. Additionally, you can always decline to share personal information with
            us, or even block all cookies. However, it’s important to remember that many of Dojo Tutoring’s features may
            not be accessible, or may not function properly if you do that - for example, we may not be able to remember
            your language preferences for you.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            In some jurisdictions, applicable law may entitle you to require Dojo Tutoring not to process your personal
            information for certain specific purposes (including profiling) where such processing is based on legitimate
            interest. If you object to such processing Dojo Tutoring will no longer process your personal information
            for these purposes unless we can demonstrate compelling legitimate grounds for such processing or such
            processing is required for the establishment, exercise or defence of legal claims. Additionally, in some
            jurisdictions, applicable law may give you the right to limit the ways in which we use your personal
            information, in particular where (i) you contest the accuracy of your personal information; (ii) the
            processing is unlawful and you oppose the erasure of your personal information; (iii) we no longer need your
            personal information for the purposes of the processing, but you require the information for the
            establishment, exercise or defence of legal claims; or (iv) you have objected to the processing as set forth
            above and pending the verification whether the legitimate grounds of Dojo Tutoring override your own.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            You have the right to withdraw consent for the collection of your personal information, and also opt-out of
            marketing communications from us. You may also be able to limit the ways in which Dojo Tutoring uses your
            personal information. Email us at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a> from the email used to
            create your Dojo Tutoring account and we’ll help you right away.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={16}
          mt={24}
          sx={{ fontSize: "18px", scrollMarginTop: "130px" }}
          id="additional-article-27-GDPR"
        >
          <u>Additional Information or Assistance and Article 27 GDPR Local Representative</u>
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If you are located in the European Union or the EEA, you have a right to lodge complaints about the data
            processing activities carried about by Dojo Tutoring with the supervisory authority in your country.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <b>European Data Protection Officer</b>
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <i>EU Representative</i>
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Pursuant to Article 27 of the GDPR, Dojo Tutoring has appointed European Data Protection Office (EDPO) as
            its GDPR representative in the EU. You can contact EDPO regarding matters pertaining to the GDPR by sending
            an email to <a href="mailto:privacy@edpo.brussels">privacy@edpo.brussels</a>, using EDPO’s online request
            form, or writing to EDPO at Avenue Huart Hamoir 71, 1030 Brussels, Belgium.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            <i>UK Representative</i>
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Dojo Tutoring has appointed EDPO UK Ltd as its GDPR representative in the UK. You can contact EDPO UK
            regarding matters pertaining to the UK GDPR by using EDPO UK’s online{" "}
            <a href="https://edpo.com/uk-gdpr-data-request/">request form</a> or-by writing to EDPO UK at 8
            Northumberland Avenue, London WC2N 5BY, United Kingdom
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            If you&apos;re located in the EU you can contact ClassDojo&apos;s appointed local representative in the EU,
            European Data Protection Office (EDPO) using their{" "}
            <a href="https://www.edpo.brussels/contact">online request form</a>. If you are located in the UK, you can
            contact ClassDojo’s appointed local representative in the UK, EDPO using their{" "}
            <a href="https://edpo.com/uk-gdpr-data-request/">online request form</a>.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="communications-from-dojo-tutoring"
        >
          What Communications Will I Receive from Dojo Tutoring
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If you registered on Dojo Tutoring, provided an email address or phone number to us, or otherwise opted-in
            to receive communications from us, you consent to us sending you messages and updates regarding your
            account, the Website and Services, including privacy and security notices, updates regarding the Website and
            Service, and information regarding products, features or services from Dojo Tutoring (or third-parties we
            believe you may be interested in). These communications may include, but are not limited to, social media
            updates, SMS/MMS messages, push and in-app notifications, email, and postal mail. If you have an account
            with us, we’ll also use your email address to contact you for customer service purposes, or for any legal
            matters that arise in the course of business. We may receive a confirmation when you open an email from us
            if your device supports it. We use this confirmation to help us understand which emails are most interesting
            and helpful.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If you invite another person to join you on Dojo Tutoring by providing their email address or phone number,
            we may contact them regarding the Service using the appropriate form of communication. If they would prefer
            not to receive our communications, they may opt-out using the “Unsubscribe” or “STOP” instructions contained
            in those communications.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            You can always unsubscribe from receiving any of our 1) marketing emails or other marketing communications
            whenever you’d like by clicking the “Unsubscribe” link at the bottom of the email; 2) marketing SMS texts by
            replying or texting ‘STOP’; or 3) marketing push notifications by turning off push notifications on your
            device. You can further indicate your preferences by contacting us using the information in the{" "}
            <Link to="#contact-information">“How Can I Contact Dojo Tutoring with Questions?”</Link> section below.
            Please note that opting out of marketing communications does not opt you out of receiving important business
            communications related to your current relationship with us, such as communications about your subscriptions
            or event registrations, service announcements or security information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Please note that if you reside in Canada and you do not want to receive legal notices from us, such as this
            Privacy Policy, those legal notices will still govern your use of the Website, and you are responsible for
            reviewing those legal notices for changes.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            From time to time, we may send you useful messages about updates or new features. You can always opt-out of
            these messages if you’d rather we didn’t contact you.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="third-party-authentication-services"
        >
          Third-Party Authentication Services
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If you decide to register for a Dojo Tutoring account through an authentication service, such as Google
            Login (“Authentication Service”), Dojo Tutoring may collect{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            that is already associated with your account connected to the Authentication Service. If you choose to
            provide such information during registration, you are giving Dojo Tutoring the permission to store and use
            such information already associated with your Authentication Service in a manner consistent with this
            Privacy Policy. The current list of Authentication Services that we use is listed{" "}
            <Link to="/third-party-service-providers">here</Link>. Please note, that when using an Authentication
            Service for registering a{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RVTNXWXZ13AX7EY4G5GW5">
              learner
            </a>{" "}
            (or if a learner is directly using their own Authentication Service), Dojo Tutoring will not request more
            information from the Authentication Service than name and email address. We will only use the email
            collected from learners for the purposes of login and account management. The Authentication Service may
            collect certain other information from you in your use of that particular service (such as G-Suite for
            Education). You may revoke Dojo Tutoring ’s access to your account on any Authentication Service at any time
            by updating the appropriate settings in the account preferences of the respective Authentication Service.
            You should check your privacy settings on each Authentication Service to understand and change the
            information sent to us through each Authentication Service. Please review each Authentication Service’s
            terms of use and privacy policies carefully before using their services and connecting to our Service. Your
            use of Authentication Service is subject to the applicable third-party terms and privacy policies. Please
            see <Link to="/third-party-service-providers">here</Link> for more information on what we collect through
            the use of Authentication Services and how we use that information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            We may allow users to register on our Service or Website through an authentication service (e.g. with Google
            Login). If you choose to do so, we may collect personal information that is already associated with your
            connected authentication service, and you give us permission to use and store this information consistent
            with this Privacy Policy. You can revoke our access to your account on this authentication service at any
            time by updating the appropriate settings in the account settings of the authentication service. Please
            note, that when using an authentication service for registering a learner (or if a learner is directly using
            their own authentication service), Dojo Tutoring will not request more information from the authentication
            service than name and email address.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          sx={{ scrollMarginTop: "130px" }}
          mb={24}
          mt={48}
          id="third-party-service-providers"
        >
          Third-Party Services
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            The Service and Website may contain links to websites, applications, services, or other content provided by
            third-parties (e.g., video players or links to third-party materials provided by your tutors). Your use of
            these features may result in the collection, processing and sharing of information about you, depending on
            the features. Any information,{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              including personal information
            </a>
            , you choose to provide or that is collected by these third-parties is subject to that third-party’s
            policies governing privacy and security and is not covered by our Privacy Policy.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            These other websites may place their own cookies or other files on your computer, collect data or solicit
            personal information from you, including if you view a video through an embedded video player which is
            played off our Service or Website, but may appear to still be playing on our Service or Website. The fact
            that we link to a website or other third-party content is not an endorsement, authorization or
            representation that we are affiliated with that third-party, nor is it an endorsement of their privacy or
            information security policies or practices. We are not responsible for the content, features, privacy and
            security practices and policies of any third-parties. We encourage you to learn about third-parties’ privacy
            and security policies before providing them with personal information.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Links to other websites and services as well as embedded video players may be found within the Service —
            this Privacy Policy does not apply to those.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="what-if-i-am-not-in-the-united-states"
        >
          What if I am not in the United States?
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Dojo Tutoring is hosted and operated in the United States. If you use the Service or Website from the
            European Union or the United Kingdom, or any other region with laws governing data collection, protection
            and use that may differ from United States law, please note that you may be transferring your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            outside of those jurisdictions to the United States. The personal information we or our service providers
            collect may be stored and processed in servers within or outside of the United States and wherever we and
            our service providers have facilities around the globe, and certain information may be accessible by persons
            or companies outside of the United States who provide services for us.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Where we transfer, store and process your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            outside of the United Kingdom, European Union, EEA, or Switzerland we have ensured that appropriate
            safeguards are in place to ensure an adequate level of protection for the rights of data subjects based on
            the adequacy of the receiving country’s data protection laws, or an alternative mechanism for the transfer
            of data as approved by the European Commission (Art. 46 GDPR) or other applicable regulator. For more
            information on these safeguards, please see our{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12038310314509">FAQ</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            By using the Service, you consent to the transfer of your personal information outside your home
            jurisdiction, including to the United States, and to the storage of your personal information in the United
            States, for the purpose of hosting, using and processing the personal information in accordance with this
            Privacy Policy. You further acknowledge that these countries may not have the same data protection laws as
            the country from which you provided your personal information, and that Dojo Tutoring may be subject to
            access requests and be compelled to disclose your personal information to government authorities, courts and
            law enforcement officials in countries (including the United States) outside of your home country. You have
            the right to withdraw your consent at any time by contacting us as described in the{" "}
            <Link to="#contact-information">“Contacting Dojo Tutoring”</Link> section below.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Our servers are located in the U.S., so if you are using the Service from any other country, your data might
            be transferred to the U.S.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="is-dojo-tutoring-a-controller"
        >
          Is Dojo Tutoring a Controller?
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Dojo Tutoring processes personal information as a Controller, as defined in the GDPR and/or used under the
            CPRA.
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="california-privacy-disclosures"
        >
          California Privacy Disclosures
        </Text>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Your Rights and Choices</u>
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If you are a California resident, you have the right to request that Dojo Tutoring disclose certain
            information to you about our collection and use of your{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>
            the past twelve (12) months. Please see this <Link to="/transparency">chart</Link> for the detailed
            categories of personal information we collect from each user type, including the sources from which the
            information was collected, the business purpose for which the information was collected, the third parties
            with whom we disclose personal information for a business purpose, and information on retention periods for
            the categories of personal information (“Notice at Collection”).
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            California consumers also have specific rights of access, deletion, correction and data portability
            regarding their{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            under the CPRA. If you are a California resident, you have the right to request that Dojo Tutoring provide
            the specific pieces of information that we have collected about you. You also have the right to request
            deletion and correction of your personal information collected or maintained by Dojo Tutoring. We provide
            these same rights to all users regardless of location. Please see the Dojo Tutoring{" "}
            <Link to="#providing-transparency-rights">’s commitments to providing Transparency and Your Rights</Link>{" "}
            Section for more detail on how to exercise these rights, including how we will verify requests. If you are a
            California resident, you may also designate a natural person or business entity registered with the
            California Secretary of State to act on your behalf to exercise these rights. For more information please
            see our <a href="https://classdojo.zendesk.com/hc/articles/12038310314509">FAQ</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We will not discriminate against you for exercising any of your rights under CPRA. Unless permitted by the
            CPRA, we will not:
          </Text>
        </Box>
        <ul>
          <li>
            <Text className={classes.listText}>TDeny you goods or services.</Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Charge you different prices or rates for goods or services, including granting discounts or other
              benefits, or imposing penalties.
            </Text>
          </li>
          <li>
            <Text className={classes.listText}>
              Suggest that you may receive a different price or rate for goods or services or a different level or
              quality of goods or services.
            </Text>
          </li>
        </ul>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            However, we may offer you certain financial incentives permitted by the CPRA that can result in different
            prices, rates, or quality levels. Any CPRA-permitted financial incentive we offer will be reasonably related
            to your personal information’s value and contain written terms that describe the program’s material aspects.
            Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at
            any time.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            If you are a California resident, the CCPA affords you certain rights of access, deletion and data
            portability - which we also extend to all users. Please see the{" "}
            <Link to="#providing-transparency-rights">
              Dojo Tutoring’s commitments to providing Transparency and Your Rights
            </Link>{" "}
            Section for more detail on how to exercise these rights, including how we will verify requests. We will not
            discriminate against you for exercising any of your rights under the CPRA.
            <br />
            <br />
            Additionally, if you are a California resident you are entitled to certain disclosures regarding our
            collection and use of your personal information over the past twelve (12) months - Please see{" "}
            <Link to="/transparency">this chart</Link> (our Notice at Collection) for the detailed categories of
            personal information we collect from each user type, including the sources from which the information was
            collected, the business purpose for which the information was collected, the third parties with whom we
            disclose personal information for a business purpose and information on retention period for the categories
            of personal information.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Do Not Sell or Share My Personal Information</u>
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            As stated throughout this Privacy Policy, Dojo Tutoring does not{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              sell personal information
            </a>{" "}
            of any Dojo Tutoring Services user to any third-party for any purpose - including for advertising or
            marketing purposes.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Dojo Tutoring does not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> its
            users personal information to a{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ai)">third party</a> for{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(k)">
              cross-context behavioral advertising
            </a>
            , as those terms are defined under the CPRA. Furthermore, we do not share or disclose personal information
            with any third-parties except in the limited circumstances described in this Privacy Policy and as set forth{" "}
            <Link to="/third-party-service-providers">here</Link>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If Dojo Tutoring in the future does{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            any personal information of a Dojo Tutoring Services user located in California, we will provide the CPRA
            required “Do Not Sell or Share my Personal Information” link in the footer of our website as well as
            honoring a Opt-Out Preference Signal as set forth below. Dojo Tutoring will not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            the personal information of consumers under 16 years of age.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutoring does not{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              personal information
            </a>{" "}
            of any Dojo Tutoring Services user to any third-party for any purpose - including for advertising or
            marketing purposes. Currently, Dojo Tutoring does not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> its
            users personal information to a{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ai)">third party</a> for{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(k)">
              cross-context behavioral advertising
            </a>
            . If Dojo Tutoring in the future does{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            any personal information of a Dojo Tutoring Services user located in California, we will provide the CPRA
            required “Do Not Sell or Share my Personal Information” link in the footer of our website as well as
            honoring an Opt-Out Preference Signal as set forth below. Dojo Tutoring will not{" "}
            <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(ah)(1)">share</a> or{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_ac14c75d-d951-44ea-9140-eef33ca14bee">
              sell
            </a>{" "}
            the personal information of consumers under 16 years of age. For a list of third-party service providers we
            disclose information to, please see{" "}
            <a href="https://tutoring.classdojo.com/third-party-service-providers">here</a>.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Do Not Track or Opt-Out Preference Signal</u>
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers to inform websites
            and services that they do not want to be “tracked”. For more information on “do not track,” please visit{" "}
            <a href="http://www.allaboutdnt.org">www.allaboutdnt.org</a>. The industry working group (W3C Tracking
            Protection) to develop the DNT standard is now{" "}
            <a href="https://www.w3.org/2011/tracking-protection/">closed</a>, so Dojo Tutoring does not respond to
            these DNT signals. There are different (from DNT) opt-out preference signals set forth under the CPRA
            (“Opt-Out Preference Signals”) also called a Global Privacy Control (GPC) Signal. Dojo Tutoring will respond
            to Opt-out Preference or Global Privacy Control Signals. This signal will apply to the browser. A consumer
            can use an Opt-Out Preference Signal by following the instructions{" "}
            <a href="https://globalprivacycontrol.org/">here</a>.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Third-parties that have content embedded on the Service, such as a social feature, or an embedded video
            player, may set cookies on a user’s browser and/or obtain information about the fact that a web browser
            visited the Service from a certain IP address. Third-parties cannot collect any other personally
            identifiable information from the Service unless you provide it to them directly.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            Dojo Tutoring does not respond to DNT signals as there is lack of industry standard and the working group to
            resolve these issues is also now <a href="https://www.w3.org/2011/tracking-protection/">closed</a>. However,
            if you are a user based in California, Dojo Tutoring will respond to Opt-Out Preference or GPC Signals as
            those terms and signals are defined under the CPRA. This signal will apply to the browser and a consumer can
            use an opt-out preference signal by following the instructions{" "}
            <a href="https://globalprivacycontrol.org/">here</a>.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>Notice for Minors (Users under 18)</u>
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Although we offer deletion capabilities on our Service, you should be aware that the removal of content may
            not ensure complete or comprehensive removal of that content or information posted through the Service, as
            there may be{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
              de-identified
            </a>{" "}
            or recoverable elements of your content or information on our servers in some form. Additionally, we will
            not remove content or information that we may be required to retain under applicable laws.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            If you are a minor or parent of a minor, you may delete information per the{" "}
            <Link to="#delete-your-account">&quot;How can I delete my account?&quot;</Link> section.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={16} mt={24} sx={{ fontSize: "18px" }}>
          <u>“Shine the Light” Law</u>
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            Pursuant to California Civil Code Section 1798.83(c)(2), Dojo Tutoring does not share{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            with third parties for their own direct marketing purposes unless you affirmatively agree to such
            disclosure. California customers can receive more information about our compliance with this law or to make
            a request under California Civil Code Section 1798.83 by sending us an email at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com ">tutoring-privacy@classdojo.com</a> or by writing to us as
            set forth in <Link to="#contact-information">“How Can I Contact Dojo Tutoring with Questions”</Link> section
            below:
          </Text>
        </Box>
        <Text
          className={classes.subHeader}
          mb={24}
          mt={48}
          sx={{ scrollMarginTop: "130px" }}
          id="changes-to-our-privacy-policy"
        >
          Changes to Our Privacy Policy
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We are constantly working to improve our Website and Services, and we may need to change this Privacy Policy
            from time to time as well. You can see when the last update was by looking at the “Last Updated” date at the
            top of this page and any updates will be effective upon posting. We won’t reduce your rights under this
            Privacy Policy without your explicit consent. If we make any significant changes, we’ll provide prominent
            notice by posting a notice on the Service and/or notifying you by email (using the email address you
            provided), so you can review and make sure you know about them. In addition, if we ever make material
            changes to the types of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            we collect from children, or how we use it, we will notify parents in order to obtain{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">parental consent</a> or notice for those
            new practices.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We encourage you to review this Privacy Policy from time to time, to stay informed about our collection,
            use, and disclosure of personal information through the Service and Website. If you don’t agree with any
            changes to the Privacy Policy, you may terminate your account. By continuing to use the Service or Website
            after the revised Privacy Policy has become effective, you acknowledge that you accept and agree to the
            current version of the Privacy Policy.
          </Text>
        </Box>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            We reserve the right to notify you of updates to our Privacy Policy as part of communications, e.g., by
            email, and/or by some other means. Please note that if you have opted not to receive legal notice emails
            from us (or you have not provided us with your email address), those legal notices will still govern your
            use of the Services, and you are still responsible for reading and understanding them.
          </Text>
        </Box>
        <Box mb={8}>
          <Text sx={{ fontSize: "14px" }} className={classes.paragraph}>
            Basically,
            <br />
            We will let you know by email and/or on our website when we make significant changes to our Privacy Policy.
            If we make material changes to the types of{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/115004741703#h_2843be58-eb16-4535-8579-138369598e4d">
              personal information
            </a>{" "}
            we collect from children, or how we use it, we will notify parents, or ask for their{" "}
            <a href="https://classdojo.zendesk.com/hc/articles/12016320121101">consent</a>. You can see when the last
            update was by looking at the “Last Updated” date at the top of this page and any updates will be effective
            upon posting.
          </Text>
        </Box>
        <Text className={classes.subHeader} mb={24} mt={48} sx={{ scrollMarginTop: "130px" }} id="contact-information">
          Contact Information
        </Text>
        <Box mb={8}>
          <Text
            component="p"
            sx={
              {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                letterSpacing: -0.5,
                color: "#2C2A50",
                margin: 0,
              } as any
            }
          >
            If you have any questions about this Privacy Policy, our privacy practices, or for any other purposes,
            please contact us by email at{" "}
            <a href="mailto:tutoring-privacy@classdojo.com">tutoring-privacy@classdojo.com</a>. You may also write to us
            at:
            <br />
            Dojo Tutoring
            <br />
            735 Tehama Street San Francisco, California, 94103. United States of America.
            <br />
            Atten: Chief Privacy Officer
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Privacy;
