import { Link } from "react-router-dom";
import useStyles from "./styles/CommunityGuidelines.styles";
import { Box, Text } from "UILibrary";

const CommunityGuidelines = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.forTeachers}>
      <Box className={classes.wrapper}>
        <Box className={classes.hero} sx={{ marginBottom: "dt_l" }}>
          <Box>
            <Box mb={16}>
              <Text component="h1" mb={8}>
                Community Guidelines - Dojo Tutoring
              </Text>
            </Box>
            <Text className={classes.subHeader}>Last Updated: November 20, 2022</Text>
          </Box>
        </Box>

        <Box mb={24}>
          <Text component="p">
            Dojo Tutoring exists to help learners discover and develop their unique strengths. We hold all members of
            our community to the same standards of conduct. Please be respectful, kind, and follow our
            guidelines—failure to do so may result in being temporarily or permanently banned.
          </Text>
        </Box>

        <Box>
          <Text size={20}>
            <b>Interactions between tutors</b>
          </Text>
        </Box>

        <Box mb={20}>
          <ul>
            <li>
              <Text>
                <b>Respect all team members.</b> We have tutors from around the world, representing a wide variety of
                backgrounds and viewpoints. We value diverse perspectives and the opportunity to learn from each other.
                Disrespect toward any community member will not be tolerated.
              </Text>
            </li>
            <li>
              <Text>
                <b>Assume good intentions.</b> Every culture has its own communication style—what may seem rude in one
                culture is polite in another. Give your teammates the benefit of the doubt.
              </Text>
            </li>
            <li>
              <Text>
                <b>Resolve issues through feedback, not gossip.</b> If you have an issue with someone in the community,
                you can resolve it in either of these two ways: directly with the person or by sharing with
                tutoring-safety@classdojo.com.
              </Text>
            </li>
            <li>
              <Text>
                <b>Watch out for each other.</b> If you come across behavior that makes you or another member of the
                community feel unsafe or unwelcome, please immediately report it to tutoring-safety@classdojo.com. Your
                report will be kept confidential.
              </Text>
            </li>
            <li>
              <Text>
                <b>Keep things professional.</b> Keeping our personal and professional lives separate helps ensure that
                every member of our team feels safe and comfortable. Do not discuss or pursue any romantic involvement
                with other tutors.
              </Text>
            </li>
            <li>
              <Text>
                <b>Avoid self-promotion.</b> Do not promote your own businesses or projects to other community members,
                either individually or in a channel.
              </Text>
            </li>
          </ul>
        </Box>

        <Box>
          <Text size={20}>
            <b>Interaction between tutors and learners</b>
          </Text>
        </Box>

        <Box>
          <ul>
            <li>
              <Text>
                <b>Never share inappropriate content.</b> Content shared over video calls or asynchronously must always
                be appropriate for children. Please review our <Link to="/contentpolicy">Content Policy</Link> for more
                details on what is allowed.
              </Text>
            </li>
            <li>
              <Text>
                <b>Respect copyright.</b> You should not share content owned by someone else without their permission.
              </Text>
            </li>
            <li>
              <Text>
                <b>Keep your personal information private.</b> You will be asked to provide your phone number and email
                address when joining Dojo Tutoring. This is the only time you should share this information. Only
                communicate with learners and families through the Dojo Tutoring site.
              </Text>
            </li>
            <li>
              <Text>
                <b>Always be kind and respectful to your learner and their family.</b> Disrespectful, impatient, rude,
                or offensive behavior is unacceptable.
              </Text>
            </li>
            <li>
              <Text>
                <b>We don’t tolerate hate or harassment.</b> Any content that promotes hate or violence against
                individuals or groups based on race or ethnicity, religion, disability, gender, age, nationality, sexual
                orientation, or gender identity is strictly prohibited. Any report of stalking, threats, bullying,
                scamming, intimidation, or revealing other people’s private information may result in a permanent ban
                from Dojo Tutoring.
              </Text>
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunityGuidelines;
