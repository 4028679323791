import { localStorage } from "@web-monorepo/safe-browser-storage";

const KEY = "utmSource";

const useIsIOS = () => {
  const params = new URLSearchParams(window.location.search);
  const utmSource = params.get("utm_source");
  if (utmSource) localStorage.setItem(KEY, utmSource);

  return localStorage.getItem(KEY) === "ios";
};

export default useIsIOS;
