import orderBy from "lodash/orderBy";
import { addDays, formatWithPattern, parseWithPattern } from "src/utils/dates";

// date formatting
export const formatFullDate = (day: Date | null) => (day == null ? "" : formatWithPattern(day, "EEE, MMM d"));
export const formatHour = (day: Date) => formatWithPattern(day, "h:mm a");
export const formatDayOfMonth = (date: Date) => parseInt(formatWithPattern(date, "d"), 10);
export const formatMonthYear = (date: Date) => formatWithPattern(date, "MMMM yyyy");

export const formatDateTzAgnostic = (date: Date | null): string => {
  if (date == null) return "";

  return new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  }).format(date);
};

// date parsing
export const parseDateAndTime = (date: Date, time: string) => {
  const dateTimeString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${time}`;
  return parseWithPattern(dateTimeString, "yyyy-M-d h:mm a");
};

// date calculations
export const startOfMonth = (date: Date) => parseWithPattern(formatWithPattern(date, "MMMM 01, yyyy"), "MMMM dd, yyyy");

export const calculateTimesAfter = ({ startDate, after, times }: { startDate: Date; after: Date; times: string[] }) => {
  return times.filter((time) => {
    return parseDateAndTime(startDate, time) > after;
  });
};

export const getDayOffType = (
  dayOffs: { startTs: Date; endTs: Date }[],
): "isSingleDay" | "multipleDays" | "isPartialDay" => {
  const daysOff = orderBy(dayOffs, ({ startTs }) => startTs.getTime());
  if (daysOff.length === 1) {
    const dayOff = daysOff[0];
    const duration = Math.floor((dayOff.endTs.getTime() - dayOff.startTs.getTime()) / 1000);
    const isSingleDay = formatHour(dayOff.startTs) === "12:00 AM" && duration === 24 * 60 * 60;
    if (isSingleDay) {
      return "isSingleDay";
    }
    const startDateFormatted = formatFullDate(dayOff.startTs);
    const endDateFormatted = formatFullDate(dayOff.endTs);
    if (startDateFormatted !== endDateFormatted) {
      return "multipleDays";
    }
  }
  return "isPartialDay";
};

export const isDayOffTooClose = (dayOffs: { startTs: Date; endTs: Date }[]) => {
  if (dayOffs.length === 0) {
    return false;
  }
  const minDayOffStartTs = dayOffs.reduce(
    (min, dayOff) => (dayOff.startTs.getTime() < min.getTime() ? dayOff.startTs : min),
    dayOffs[0].startTs,
  );
  return minDayOffStartTs.getTime() < addDays(new Date(), 2).getTime();
};
