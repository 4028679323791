import { forwardRef } from "react";
import { useForwardedOrLocalRef, useUniqueIds } from "../../shared/hooks";
import { ClearButton } from "./components";
import type { BaseProps } from "./shared";
import styles from "./DefaultTextField.module.css";

export const Sizes = ["small", "medium", "large"] as const;
export const Variant = "default";

export interface Props extends BaseProps {
  /**
   * Docs:
   * - {@link https://www.figma.com/design/erhFTAWNMugPVrJGCAWsse/%E2%9D%96-Dojo-Design-System-%E2%80%93-DDS?node-id=781-11468 Figma}
   * - {@link https://components.classdojo.com/?path=/story/components-ddstextfield--default-variant Storybook}
   */
  variant?: typeof Variant;
  size: (typeof Sizes)[number];
  placeholder?: string;
}

export const DefaultTextField = forwardRef<HTMLInputElement, Props>(
  (
    { ["data-name"]: dataName, disabled, id, label, size = "medium", error, hint, placeholder, clearButton, ...rest },
    forwardedRef,
  ) => {
    const ref = useForwardedOrLocalRef(forwardedRef);
    const [labelId, inputId, hintId] = useUniqueIds(["DDSTextField.label", "DDSTextField.input", "DDSTextField.hint"]);

    const className = [styles.textField, styles[size], error ? styles.error : null].join(" ");

    return (
      <label className={className}>
        <span id={labelId} className={styles.label}>
          {label}
        </span>
        <div className={styles.inputBox}>
          <input
            aria-labelledby={labelId}
            className={styles.input}
            data-name={dataName}
            disabled={disabled}
            id={id ?? inputId}
            /* insert empty space when there isn't one provided to make it easier to detect an empty input in the css (more details there) */
            placeholder={placeholder ?? " "}
            ref={ref}
            aria-describedby={hintId}
            aria-invalid={error}
            {...rest}
          />
          {clearButton && <ClearButton className={styles.clearButton} forwardedRef={ref} />}
        </div>
        <span aria-live="polite" id={hintId} className={styles.hint}>
          {hint}
        </span>
      </label>
    );
  },
);
