import * as React from "react";
import { PropsWithChildren } from "react";
import { ErrorHandler, ERROR_CATCH_TYPE, type ErrorContext } from "../ErrorHandler";
import { ProductAreaContext, ProductAreas, ProductAreaValues } from "../productArea";
import * as logClient from "@classdojo/log-client";
import { isTesting } from "../../../utils/env";

// IMPORTANT NOTES:
// 1) only for use with React 16+
//
// 2) This Error Boundary component is intended to be used at the top level of our App. It encapsulates logic
// for sending errors to our logs. NOT INTENDED to be used in other parts of our tree of components.
// We don't currently support adding error boundaries down in the tree (other than this one). When we see
// a scenario that makes sense we would revisit this logic. An option is to split the logic that handles global error
// handling + logging out of the Error Boundary component.
//
// 3) We are not logging ALL errors that reach the global error handler. If we do so we see a crazy amount of errors
// logged to our servers, the reason is that we would be catching script errors (from external scripts or browser
// custom plugins). We need to revisit this as well and make sure we are not omitting errors that we actually care about.
//
// 4) This error boundary requires the ProductAreaContext to be set. Errors will be
// tagged with the default product area defined in the context if the errors are not already
// tagged before reaching this error boundary.

export type ErrorExcluder<E extends Error = Error> = { checker: (error: E) => boolean; id: string };

type AppTopLevelErrorBoundaryProps = {
  errorHandler?: ErrorHandler;
  excludeErrors?: Array<ErrorExcluder>;
};

type AppTopLevelErrorBoundaryState = {
  caughtErrorInRender?: boolean;
};

type AppError = Error & {
  response?: {
    req: unknown;
    status: number;
  };
  productArea?: ProductAreaValues;
};

class AppTopLevelErrorBoundary extends React.Component<
  PropsWithChildren<AppTopLevelErrorBoundaryProps>,
  AppTopLevelErrorBoundaryState
> {
  state = {
    caughtErrorInRender: false,
  };

  componentDidMount() {
    window.addEventListener("error", this.onWindowError);
    window.addEventListener("unhandledrejection", this.onUnhandledRejection);
    window.removeDojoErrorSos?.();
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.onWindowError);
    window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  render() {
    return (
      <React.Fragment>
        {/* NOTE: only render children if we haven't caught error in render. If we rerender children after
          catching an error in render, we're just going to hit the same error again. */}
        {!this.state.caughtErrorInRender ? this.props.children : null}
      </React.Fragment>
    );
  }

  static contextType = ProductAreaContext;
  declare context: React.ContextType<typeof ProductAreaContext>;

  errorsSeen: unknown[] = [];

  latestComponentCaughtError?: Error;

  componentDidCatch(e: AppError, info: React.ErrorInfo) {
    if (e && !e.message) {
      e.message = "componentDidCatch placeholder message";
    }

    if (
      e.message?.includes(
        `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
      )
    ) {
      logClient.sendMetrics([
        {
          type: "increment",
          metricName: "web.error.removeChildFailed",
          value: 1,
          tags: {
            app: Config.site,
            version: Config.appVersion || "not found",
          },
        },
      ]);
      this.setState({});
      return;
    }

    this.latestComponentCaughtError = e;
    this.setState({
      caughtErrorInRender: true,
    });

    const errorContext: ErrorContext = {
      catchType: ERROR_CATCH_TYPE.REACT_ERROR_BOUNDARY,
      componentStack: info.componentStack,
      productArea: e.productArea || (this.context && this.context.current ? this.context.current : undefined),
    };

    if (!e) {
      errorContext.recreated = true;
      e = new Error(`DEBUGGING: ${info.componentStack}`);
    }

    const { errorHandler } = this.props;

    errorHandler?.handleError(e, errorContext);
  }

  onWindowError = (event: ErrorEvent & { source?: string }) => {
    let error: Error;

    const errorContext: ErrorContext = {
      catchType: ERROR_CATCH_TYPE.GLOBAL_ERROR,
    };

    if (event.error instanceof Error) {
      error = event.error;
    } else {
      // If a non-error object was thrown, it may still be worth reporting as
      // it could be developer error not throwing a proper Error object. The ErrorEvent
      // still tells us the file/line/col where the error occurred, so it is still useful.
      errorContext.recreated = true;
      const recreatedError = new Error(event.message || event.error || "unknown");
      recreatedError.stack = `at (${event.filename}:${event.lineno}:${event.colno})`;
      error = recreatedError;
    }

    errorContext.productArea = this.getProductAreaFromError(error);

    if (this.shouldIgnoreError(error)) {
      return;
    }

    // prevent double logging of same error
    if (this.errorsSeen.includes(error)) {
      return;
    }
    this.errorsSeen.push(error);

    const handleError = () => {
      const { errorHandler } = this.props;

      errorHandler?.handleError(error, errorContext);
    };

    // In 'production' any error that React captures with componentDidCatch will not bubble up to window onError
    // In 'dev' any error that React captures with componentDidCatch also gets re-thrown up and catched by window onError
    //
    // This means that in 'dev' we would en up with 'two' calls to handleError one for componentDidCatch and another from our 'on window error'
    // handler. So we have the check below to avoid the 'double' log (when developing).
    //
    if (Config.nodeEnv === "development") {
      // setTimeout because the window error handler gets triggered prior to componentDidCatch
      setTimeout(() => {
        if (this.latestComponentCaughtError === error) {
          return;
        }

        handleError();
      }, 30);
    } else {
      handleError();
    }
  };

  onUnhandledRejection = (event: PromiseRejectionEvent) => {
    const { reason: error } = event;
    // Unhandled promise rejections of non-errors are useless. Without an error, there is no stack trace,
    // which means we are relying on the message only to debug or provide context. Additionally, without
    // a stacktrace, we have no way to determine if the error is coming from an external script or a
    // browser plugin that injected some script, effectively flooding our logs with non-actionable errors
    // and hiding the ones we might actually care about. Instead, we should use linting rules to enforce
    // promise rejections as errors.
    if (!(error instanceof Error) || this.shouldIgnoreError(error)) {
      return;
    }

    // prevent double logging of same error
    if (this.errorsSeen.includes(error)) {
      return;
    }
    this.errorsSeen.push(error);

    const errorContext: ErrorContext = {
      catchType: ERROR_CATCH_TYPE.UNHANDLED_REJECTION,
      productArea: this.getProductAreaFromError(error),
    };

    this.props.errorHandler?.handleError(error, errorContext);
  };

  // Almost all of these exclusions are from errors that are *very likely* not part of our app.
  // There will be instrumented with an ID so that we can see if any of them are still actively excluding errors
  // after adding a check to only report Dojo errors based on stacktrace (see isAllowedJsOriginInStacktrace).
  exclusions: Array<ErrorExcluder> = [
    // Chrome extension errors will contain 'chrome-extension:'
    { checker: (error) => /chrome-extension:/.test(error.stack || ""), id: "chrome-extension" },
    // Mozilla extension errors will contain 'moz-extension:'
    { checker: (error) => /moz-extension:/.test(error.stack || ""), id: "moz-extension" },
    // https://bugs.chromium.org/p/chromium/issues/detail?id=709132
    // https://bugs.chromium.org/p/chromium/issues/detail?id=590375
    {
      checker: (error) =>
        /Blocked a frame with origin.*from accessing a frame with origin.*filepicker\.io/.test(error.message),
      id: "filepicker-cross-origin",
    },
    // "Unexpected token '<'" is an error we see quite a lot on datadog,
    // but we don't have any info to find out it's cause.
    // We are going to ignore it the first time it occurs, because it doesn't
    // seem to cause issues for the app. However, we're keeping track to see if it
    // happens more than once, in which case, we go ahead and crash the app.
    // https://docs.google.com/document/d/1LREg816fQDZ4_MpETA_KCtm3gIbpDvHlN8ayWozwIO0/edit
    { checker: (error) => error.message === "Unexpected token '<'", id: "unexpected-html-for-json-parse" },
    { checker: (error) => error.message.includes("$ is not defined"), id: "$-not-defined" },
    { checker: (error) => error.message.includes("jQuery is not defined"), id: "jQuery-not-defined" },
    { checker: (error) => error.message.includes("KasperskyLab is not defined"), id: "kaspersky-lab" },
    {
      checker: (error) => error.message.includes("Cannot read property '_avast_submit' of undefined"),
      id: "avast-submit",
    },
    {
      checker: (error) =>
        error.message.includes(`can't redefine non-configurable property "userAgent"`) &&
        !!error.stack?.includes("at (unknown)"),
      id: "redefine-user-agent",
    },
    {
      checker: (error) =>
        error.message.includes("Cannot read property 'closingEls' of undefined") &&
        !!error.stack?.includes("at <anonymous>:1:19"),
      id: "cannot-read-closing-els",
    },
    {
      checker: (error) =>
        error.message.includes("Cannot read property 'getAttribute' of null") &&
        !!error.stack?.includes("at <anonymous>:1:57"),
      id: "cannot-read-get-attribute",
    },
    {
      checker: (error) => error.message.includes("Identifier 'originalPrompt' has already been declared"),
      id: "redeclare-original-prompt",
    },
    { checker: (error) => error.message.includes("showCsCursorNonHD is not defined"), id: "show-cs-cursor-non-hd" },
    { checker: (error) => error.message.includes("vc_request_action is not defined"), id: "vc-request-action" },
    { checker: (error) => error.message.includes("Cannot redefine property: googletag"), id: "redefine-google-tag" },
    { checker: (error) => error.message.includes("zaloJSV2 is not defined"), id: "zaloJSV2" },
    { checker: (error) => error.message.includes("Can't find variable: zaloJSV2"), id: "zaloJSV2" },
    { checker: (error) => error.message.includes("PADDINGXXPADDINGPADDINGXXPADDING"), id: "PADDINGXX" },
    { checker: (error) => error.message.includes("Cannot redefine property: BetterJsPop"), id: "better-js-pop" },
    // Goguardian errors
    { checker: (error) => error.stack?.includes("https://asset.goguardian/asset.js") || false, id: "goguardian" },
    // CDN Cookielaw errors
    { checker: (error) => !!error.stack?.match(/cdn\.cookielaw\.org\S+\.js/), id: "cookielaw" },
    // change_ua chrome error
    {
      checker: (error) => error.message.includes("Identifier 'change_ua' has already been declared"),
      id: "redeclare-change-ua",
    },
    // Marketplace errors
    {
      checker: (error) =>
        (error.stack?.includes("Quill5.getFormat") ?? false) &&
        error.message.includes("Cannot read properties of null"),
      id: "mantine-rte-v5-error",
    },
    // Poor network connection errors
    { checker: (error) => error.message.includes("Loading chunk 996 failed"), id: "loading-chunk-996" },
    { checker: (error) => error.message.includes("Failed to load Stripe.js"), id: "failed-to-load-stripe" },
    // Probably some browser extension
    {
      checker: (error) => error.message.includes("198230182308109283091823098102938908128390"),
      id: "extension-with-numbers",
    },
  ];

  shouldIgnoreError(error: Error) {
    // Strip out sentry before forwarding
    const sanitizedStack = error.stack?.replace(/^(.+)(\/assets\/sentry-.+\.js:.+)$/gm, "[SENTRYHOST]$2");
    // First line of defense: does the error stack even contain a Dojo JS file?
    if (!this.isAllowedJsOriginInStacktrace(sanitizedStack)) {
      return true;
    }

    // Second line of defense: is the error message explicitly excluded?
    // I'm hopeful we can remove this and that the first line of defense will be enough.
    // Using a metric to track which exclusions are needed after the first line of defense.
    const { excludeErrors = [] } = this.props;
    const exclusions = [...this.exclusions, ...excludeErrors];

    const excluder = exclusions.find((exclusion) => exclusion.checker(error));

    if (excluder) {
      logClient.sendMetrics([
        {
          type: "increment",
          metricName: "web.error.excluded",
          value: 1,
          tags: {
            id: excluder.id,
          },
        },
      ]);
      return true;
    }

    return false;
  }

  // Copied from dojo-error-sos. Will eventually move most of the error logic to
  // a single package. For now, just need something to trim out noisy unnecessary errors.
  isAllowedJsOriginInStacktrace(stack: string = ""): boolean {
    if (isTesting) {
      return true;
    }

    return /https:\/\/([\w-]+\.)*classdojo\.(com|test)(:\d+)?\/(.*\/)?[^\/]+\.js/.test(stack);
  }

  getProductAreaFromError = (error: AppError): ProductAreaValues => {
    return error.productArea ?? (this.context && this.context.current ? this.context.current : ProductAreas.unknown);
  };
}

export default AppTopLevelErrorBoundary;

export class RecreatedError extends Error {
  name = "RecreatedError";
  recreated = true;
}
