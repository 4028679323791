import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { createSearchParams, useNavigate } from "react-router-dom";
import { z } from "zod";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import Field from "src/components/form/Field";
import SingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import { logTutorEvent } from "src/utils/log";
import { useSessionQuery } from "src/hooks/useSession";
import { useMediaQuery } from "react-responsive";
import { usePublicTeacherProfileQuery } from "old/src/hooks/useParent";
import { useMemo } from "react";
import { tutorSubjectsByGrade } from "src/utils/tutorGradeAndSubject";
import SubjectSelect from "src/components/SubjectSelect";

const FormSchema = z.object({
  subject: z.string(),
});
type FormData = z.infer<typeof FormSchema>;

const DirectBookingSubjectSelection = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
  });
  const { sessionData, isLoading: isSessionLoading } = useSessionQuery();
  const isAuthenticated = !!sessionData && sessionData.roles;
  const isMobile = useMediaQuery({ query: `(max-width: ${theme.breakpoints.s})` });
  const tutorId = state.booking?.teacher_uid;
  const { teacher: tutor } = usePublicTeacherProfileQuery(tutorId);
  const tutorGrade = state.booking?.grade;

  useOnFirstRender(() => {
    if (state.booking) {
      if (!tutorId || !tutorGrade || !state.booking.first_name_child) {
        push("/direct-booking-subscription/tutor-introduction");
      } else {
        logTutorEvent({
          eventName: "parent.marketplace.directbooking.viewed.subject_selection",
          metadata: { tutor_id: tutorId },
        });
        const { subject } = state.booking;
        if (subject) form.setValue("subject", subject, { shouldValidate: true });
      }
    }
  });

  const tutorSubjects = useMemo(() => {
    if (!tutor || !tutorGrade) return [];
    return tutorSubjectsByGrade(tutorGrade).filter(({ subject }) =>
      tutor.tutoring_information[subject].includes(tutorGrade),
    );
  }, [tutor, tutorGrade]);

  const onNextStep: SubmitHandler<FormData> = async (values) => {
    logTutorEvent({
      eventName: "parent.marketplace.directbooking.completed.subject_selection",
      metadata: { tutor_id: tutorId },
    });
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state?.booking,
        subject: values.subject,
      },
    });
    if (isAuthenticated) {
      const searchParams = createSearchParams({ directBookingFlow: "true" }).toString();
      push({ pathname: "/subscription/booking/select-class-frequency", search: searchParams });
    } else {
      push("/direct-booking-subscription/parent-signup");
    }
  };

  return (
    <SingleClassForm
      disabled={!form.formState.isValid || isSessionLoading}
      onSubmit={form.handleSubmit(onNextStep)}
      contentMaxWidth={430}
      buttonText="Continue"
      title={
        !isMobile
          ? `Which subject do you want tutoring in for ${state?.booking?.first_name_child ?? "your child"}?`
          : undefined
      }
      subtitle={!isMobile ? "Select one" : undefined}
      progressBarPercentage={29}
    >
      <div sx={sxGeneralWrapper}>
        <div sx={sxFieldsWrapper}>
          {isMobile && (
            <div sx={sxTitle}>
              Which subject do you want tutoring in for {state?.booking?.first_name_child ?? "your child"}?
              <div sx={sxSubtitle}>Select one</div>
            </div>
          )}
          <Field
            form={form}
            name="subject"
            render={({ value, onChange }) => (
              <div sx={sxField}>
                <SubjectSelect subjects={tutorSubjects} onChange={onChange} value={value} />
              </div>
            )}
          />
        </div>
      </div>
    </SingleClassForm>
  );
};

export default DirectBookingSubjectSelection;

const sxGeneralWrapper: ThemeUIStyleObject = {
  width: "100%",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
};

const sxFieldsWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  marginTop: "dt_xl",
  marginBottom: "dt_xl",
  width: "100%",
  gap: "dt_xl",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: "0",
    gap: "dt_xl",
    marginBottom: "0",
    paddingBottom: "dt_xxl",
  },
};

const sxField: ThemeUIStyleObject = { display: "flex", flexDirection: "column", gap: "dt_xs" };

const sxTitle: ThemeUIStyleObject = {
  textAlign: "left",
  fontSize: "23px",
  fontWeight: 800,
  lineHeight: "28px",
  letterSpacing: "-0.1px",
  color: "dt_content_primary",
  display: "flex",
  flexDirection: "column",
  gap: "dt_xxs",
};

const sxSubtitle: ThemeUIStyleObject = {
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "22px",
  color: "dt_content_secondary",
};
