import { paths } from "@classdojo/ts-api-types/api";
import { monolithApiClient } from "src/configs/axios.config";

type Endpoint = paths["/api/marketplace/generatedAssessment/{generatedAssessmentId}/review"]["put"];
export type GeneratedAssessmentReviewPayload = Endpoint["requestBody"]["content"]["application/json"];
type Response = Endpoint["responses"][200];

export default ({
  generatedAssessmentId,
  ...body
}: { generatedAssessmentId: string } & GeneratedAssessmentReviewPayload) => {
  return monolithApiClient.put<Response>(`generatedAssessment/${generatedAssessmentId}/review`, body);
};
